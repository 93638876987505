@import '~bm-public.less';

.bm-p-deferred-invoices {
  .page-content {
    position: relative;
    background-color: var(--bm-dark-background-color);
  }
  .page-content-wrapper {
    background-color: var(--bm-dark-background-color);
  }
  .section {
    display: block;
    margin-top: auto;
    margin-bottom: 0;
    padding: 15PX 20PX 20PX;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0);
  }
  .background-top {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    height: 200PX;
    background-color: var(--bm-primary-color);
  }
  .div-block-485 {
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .left-block {
    width: auto;
    height: auto;
    margin-top: 0;
    margin-right: 20PX;
    padding: 0;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-radius: 12PX;
    background-color: rgba(255, 255, 255, 0);
    box-shadow: none;
    flex: 1;
  }
  .right-block {
    position: -webkit-sticky;
    position: sticky;
    left: auto;
    top: 100PX;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 40%;
    height: auto;
    margin-top: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .left-block._58 {
    width: 58%;
  }
  .po-history-block {
    width: 100%;
    margin-bottom: 20PX;
    padding: 20PX;
    border-radius: 8PX;
    background-color: #fff;
    box-shadow: 0 0 8PX -1PX rgb(0 0 0 / 66%);
    box-sizing: border-box;
  }
  .div-block-652 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .div-block-652.head {
    margin-bottom: 20PX;
    padding-bottom: 0PX;
    padding-left: 10PX;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-bottom: 1PX none rgba(160, 21, 28, 0.4);
    border-left: 5PX none #a0151c;
  }
  .deffered-invoices-supplier {
    margin: 0 auto 10PX 0;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: #a0151c;
    font-size: 20PX;
    line-height: 150%;
    font-weight: 700;
  }
  .div-block-652 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .div-block-652._100 {
    width: 100%;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .div-block-653 {
    background-color: transparent;
  }
  .price-cart {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 100%;
    margin-top: auto;
    margin-bottom: 10PX;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .text-block-6 {
    max-width: none;
    margin-top: 0;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    font-family: 'Sf pro display', sans-serif;
    color: #333;
    font-size: 18PX;
    font-weight: 600;
  }
  .text-block-6._18.space {
    margin-right: 8PX;
  }
  .text-block-223 {
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: #666;
    font-size: 18PX;
  }
  .w-inline-block {
    max-width: 100%;
    display: inline-block;
  }
  .link-block-10 {
    margin-bottom: 5PX;
    margin-left: 10PX;
    text-decoration: none;
  }
  .primary-payment-confirmation-buttons {
    padding: 10PX;
    background-color: var(--bm-primary-color);
    color: var(--bm-light-background-color);
    border-radius: 4PX;
  }
  .div-block-572 {
    display: block;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .div-block-561 {
    width: auto;
    margin-right: 0;
    padding: 10PX;
    border-style: solid;
    border-width: 1PX;
    border-color: var(--bm-border-color);
    border-radius: 4PX;
    background-color: #f2f3f8;
  }
  .div-block-561.non-color {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 10PX;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff;
    text-decoration: none;
  }
  .heading-4-1 {
    margin-top: 0;
    margin-bottom: 5PX;
    font-family: 'Sf pro display', sans-serif;
    color: #333;
    font-size: 14PX;
    font-weight: 600;
    text-align: left;
  }
  .text-block-91-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: #a0151c;
    font-size: 12PX;
    text-align: center;
  }
  .text-block-98 {
    margin-right: auto;
    margin-bottom: 0;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-size: 10PX;
    font-weight: 400;
    color: #666;
  }
  .text-block-98.space {
    margin-bottom: 10PX;
  }
  .div-block-482-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .div-block-481-copy {
    width: 50PX;
    height: 50PX;
    min-height: 80PX;
    min-width: 80PX;
    margin-right: 10PX;
    border-radius: 6PX;
    background-position: 50% 50%;
    background-size: cover;
  }
  .div-block-578 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .item-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 100%;
    margin-bottom: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .item-info.checkout-process {
    position: relative;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }
  .item-info ._107 {
    position: absolute;
    left: auto;
    top: 1PX;
    right: 0%;
    bottom: 0%;
    margin-bottom: 0;
    margin-left: auto;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: #333;
    font-size: 14PX;
    font-weight: 400;
  }
  .text-block-6._18.rightalign {
      margin-left: auto;
  }

  .right-block-birdymart {
    position: -webkit-sticky;
    position: sticky;
    left: auto;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 40%;
    height: auto;
    margin-top: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .info-tool {
    box-sizing: border-box;
    position: -webkit-sticky;
    position: sticky;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: auto;
    max-width: 100%;
    min-width: 100%;
    // margin-top: 10PX;
    margin-right: 0;
    margin-bottom: 0;
    padding: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 6PX;
    background-color: rgba(255, 255, 255, 0);
    box-shadow: none;
  }
  // store info
  .div-block-510 {
    box-sizing: border-box;
    margin-bottom: 20PX;
    padding: 20PX;
    border-radius: 8PX;    
    background-color: var(--bm-light-background-color);
    // background-color: var(--bm-primary-color);
    box-shadow: 1PX 1PX 10PX 0 rgba(0, 0, 0, 0.24);
  }
  .div-block-514 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    .div-block-515  {
      flex: 1;
    }
  }
  .div-block-511 {
    width: 60PX;
    height: 60PX;
    min-height: 60PX;
    min-width: 60PX;
    margin-right: 20PX;
    border-radius: 50%;
    // background-image: url(https://assets.website-files.com/605173a…/60790db…_dp2.jpeg);
    background-position: 50% 50%;
    background-size: cover;
  }
  .text-block-104 {
    display: flex;
    justify-content: space-between;
    font-size: 16PX;
    line-height: 18PX;
    font-weight: 700;
    >div:first-child {
      padding-top: 6PX;
      color: var(--bm-primary-color);
    }
    .down-outlined {
      width: 28PX;
      height: 28PX;
    }
  }
  .info-tool {
    ._107 {
      display: flex;
      font-size: 14PX;
      font-weight: 400;
      color: var(--bm-text-color);
      .map-outlined {
        width: 20PX;
        height: 20PX;
        color: var(--bm-primary-color);
      }
    }
  }
  .div-block-574 {
    margin-top: 10PX;
  }
  .quick-filter-group {
    display: flex;
    flex-wrap: wrap;
    .status {
      padding: 6PX;
      border-radius: 6PX;
      color: var(--bm-text-secondary-color);
      border: 1PX solid var(--bm-text-secondary-color);
      margin: 0 10PX 10PX 0;
      font-size: 16PX;
    }
    .selected {
      color: var(--bm-light-background-color);
      background-color: var(--bm-primary-color);
      border: 1PX solid var(--bm-primary-color);
    }
  }

  @media screen and (max-width: 991px) {
    .background-top {
      height: 100PX;
    }
    .div-block-510 {
      margin-bottom: 20PX;
    }
    .div-block-485 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .left-block {
      width: 100%;
      margin-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      box-shadow: none;
    }
    .left-block._58 {
      width: auto;
      margin-right: 0;
      -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
    }
    .po-history-block {
      margin-bottom: 40PX;
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 8PX;
      background-color: rgba(255, 255, 255, 0);
      box-shadow: none;
    }
    .div-block-652.head {
      padding: 16PX;
      border-style: solid;
      border-width: 1PX;
      border-color: #f5f5f5;
      border-radius: 8PX;
      background-color: #fff;
    }
    .text-block-6 {
      font-size: 14PX;
    }
    .text-block-6._18 {
      font-size: 18PX;
    }
    .price-cart {
      -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
    }
    .div-block-561 {
      padding: 8PX;
    }
    .div-block-561.non-color {
      box-shadow: none;
    }
    .heading-4-1 {
      margin-bottom: 5PX;
    }
    .div-block-482-copy {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .item-info {
      max-width: 90%;
    }
    .item-info.checkout-process {
      max-width: none;
    }
    .right-block-birdymart {
      position: static;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      overflow: visible;
      width: 100%;
      -webkit-box-ordinal-group: 0;
      -webkit-order: -1;
      -ms-flex-order: -1;
      order: -1;
      border-top-left-radius: 6PX;
      border-top-right-radius: 6PX;
    }
    .info-tool {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 0;
      padding: 0 20PX;
      border-radius: 0;
      box-shadow: none;
    }
    .div-block-510.no-shadow-tablet {
      padding: 5PX 0;
      background-color: hsla(0, 0%, 100%, 0);
      box-shadow: none;
    }
  }
  @media screen and (max-width: 767px) {
    .left-block {
      box-shadow: none;
    }
    .po-history-block {
      padding-right: 0;
      padding-left: 0;
    }
    .deffered-invoices-supplier {
      font-size: 20PX;
    }
    .text-block-223 {
      font-size: 16PX;
    }
    .div-block-561.non-color {
      box-shadow: none;
    }
    .heading-4-1 {
      margin-bottom: 5PX;
    }
    .text-block-98 {
      max-width: 40%;
      margin-right: 0;
    }
    .item-info.checkout-process {
      max-width: 100%;
    }
    .info-tool {
      padding-right: 0;
      padding-left: 0;
    }
    .div-block-510.no-shadow-tablet {
      padding-right: 0;
    }
  }
  @media screen and (max-width: 479px) {
    .background-top {
      height: 100PX;
    }
    .deffered-invoices-supplier {
      font-size: 20PX;
    }
    .price-cart {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .text-block-6 {
      -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
      align-self: flex-end;
      font-family: 'Sf pro display', sans-serif;
      font-size: 14PX;
      font-weight: 600;
    }
    .text-block-6._18 {
      font-size: 14PX;
    }
    .text-block-6._18.space {
      margin-right: 4PX;
    }
    .text-block-223 {
      font-size: 14PX;
    }
    .quick-filter-group {
      .status {
        font-size: 14PX;
      }
    }
    .div-block-510.no-shadow-tablet.margin {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
  .no-content-panel {
    display: flex;
    flex-direction: column;
    margin-top: 15PX;
    justify-content: center;
    align-items: center;

    .bm-icon {
      font-size: 150PX;
    }

    .no-content-info {
      color: var(--bm-text-secondary-color);
    }
  }
}
