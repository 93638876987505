@import '~bm-public.less';
.bm-c-layout.bm-p-stores-by-category {
  .pc-header {
    height: var(--bm-safe-area-top);
  }
}
.bm-p-stores-by-category {
  background-color: var(--bm-dark-background-color);
  .in-page-link {
    margin-right: unset;
    padding: 8PX 30PX;
    border-radius: 40PX;
  }
  .page-content-wrapper {
    bottom: 0;
    overflow: auto;
  }
  .section {
    background-color: var(--bm-dark-background-color);
    margin-top: 0;
    padding: 55PX 40PX 40PX;
  }
  .nav_bg.nav-blur {
    position: fixed;
    top: calc(var(--bm-safe-area-top) + 70PX);
    min-height: unset;
  }
  .space-1 {
    padding-top: 53PX;
  }
  &-store-list {
    padding: unset;
  }
  .bm-p-stores-by-category-store-list {
    margin-top: 0;
    padding: 0;
  }
  .w-container {
    box-sizing: border-box;
  }
  &-skeleton {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10001;
    background-color: #fff;

    .skeleton-icon {
      border-radius: 0 !important;
      height: 75px;
      .skeleton-color;
      width: 100%;
      padding: 11px 0 8px 20px;
    }
    .left-outlined {
      background-color: #FFF;
      border-radius: 50%;
    }
    .skeleton-types {
      background-color: #fff;
      width: 100%;
      height: 35px;
      padding: 20px 0 20px 15px;
      overflow-x: auto;
      overflow-y: hidden;
      .skeleton-types-wrap {
        width: 110%;
        height: 100%;
        display: flex;
      }
      .skeleton-types-item {
       margin-left: 25px;
       flex: 1;
       .skeleton-color;
      }
    }
    .skeleton-store {
      width: 100%;
      padding: 30px;
      height: 100%;
      box-sizing: border-box;
      background-color: var(--bm-dark-background-color);

      .skeleton-store-item {
        width: 100%;
        display: flex;
        flex-direction:column;
        margin-bottom: 30px;
      }
      .skeleton-store-img {
        height: 0;
        padding-bottom: 50%;
        .skeleton-color;
      }
      .skeleton-store-info {
        background-color: #fff;
        padding: 25px;

        .store-title {
          width: 300px;
          height: 32px;
          .skeleton-color;
          border-radius: 8px;
        }
        .store-nav {
          display: flex;
          margin-top: 12px;
          height: 28px;
          width: 100%;
          position: relative;
          .second:before {
            content: " ";
            .skeleton-color;
            width: 1px;
            height: 100%;
            position: absolute;
            top: 0;
            left: -15px;
          }
          .second:after {
            content: " ";
            .skeleton-color;
            width: 1px;
            height: 100%;
            position: absolute;
            top: 0;
            right: -15px;
          }
        }
        .store-nav-item:first-child {
          flex: 2;
        }
        .second {
          position: relative;
          border-radius: 8px;
        }
        .store-nav-item {
          flex: 1;
          margin-right: 30px;
          .skeleton-color;
          border-radius: 3px;
        }
        .store-wrap {
          margin-top: 12px;
          width: 80%;
          height: 50px;
          display: flex;
          .store-wrap-item {
              flex: 1;
              margin-right: 20px;
              .skeleton-color;
              border-radius: 8px;
          }
          .store-wrap-item:first-child {
            flex: 1;
          }
          .store-wrap-item.second {
            flex: 1;
          }
        }
      }
    }
    .skeleton-list {
      height: 128px;
      width: 100%;
      display: flex;
      padding: 30px;
      .skeleton-list-item {
        border-radius: 20px;
        width: 196px;
        .skeleton-color;
        height: 100%;
      }
      .skeleton-list-item:not(:first-child) {
        margin-left: 20px;
      }
    } 
    .skeleton-list-title {
      width: 100%;
      padding-left: 30px;
      background-color: var(--bm-dark-background-color);
      div {
        height: 40px;
        width: 10%;
        .skeleton-color;
      }
    }
  }
}
.bm-p-stores {
  .skeleton-store  {
    padding: 0;
  }
  .container.fixed-nav {
    box-sizing: content-box;
  }
}
.bm-p-stores-by-category-skeleton.skeleton {
  top: calc(var(--bm-safe-area-top) + 150PX);
  background-color: var(--bm-dark-background-color);
  .skeleton-list {
    background-color: var(--bm-light-background-color);
    height: 160px;
    box-sizing: border-box;
  }
  .skeleton-store  {
    padding-left: 0;
    padding-right: 0;
    padding-top: 10PX;
    .bm-p-stores-by-category-store-list.container._1 {
      .skeleton-store-item {
        width: 47%;
        height: 470PX;
        margin-bottom: 10PX;
      }
      .skeleton-store-item:first-child {
        margin-right: 0;
      }
      .skeleton-store-info {
        .store-title {
          width: 98%;
        }
      }
    }
  }
}
.nav_bg.nav-blur.colored {
  max-height: none;
  background-color: var(--bm-primary-color);
}

.style-relative {
  position: relative;
}
.category-onself.nav_bg {
  left: unset;
  right: unset;
  width: 100%;
}
.container-stretched-copy.w-container {
  margin-right: 60PX;
  margin-left: 60PX;
  max-width: none;
}
.div-block-7.open-modal {
  position: relative;
  top: 0px;
  z-index: 800;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  cursor: pointer;
}

.div-block-7-copy.open-modal {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  cursor: pointer;
}
.div-block-7-copy {
  position: absolute;
  top: 26PX;
  background-color: transparent;
}
.filter-icon {
  position: relative;
  display: inline-block;
  margin-left: auto;
  cursor: pointer;
}
.left {
  width: 25PX;
  height: 16PX;
  color: var(--bm-light-background-color);
}
.left.stores-by-category {
  height: unset;
  width: 35PX;
}
.right {
  width: 30PX;
  height: 24PX;
  margin-left: 0px;
  color: var(--bm-light-background-color);
}
.stores-by-category.right {
  width: 32PX;
  height: unset;
}
.container.fixed-nav {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-height: 70PX;
  min-height: 70PX;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.nav-bar-title {
  margin: 0px auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-light-background-color);
  font-size: 28PX;
  font-weight: 400;
}
.drag-nav-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  padding: 20PX 20PX 20PX 30PX;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  cursor: -webkit-grab;
  cursor: grab;
}
.in-page-link.current {
  padding: 8PX 30PX;
  border-radius: 40PX;
  background-color: var(--bm-primary-color);
  color: var(--bm-light-background-color);
}
.category-type {
  box-sizing: border-box;
}
.no-stores {
  position: absolute;
  top: 88px;
  bottom: 152px;
  width: 100%;
}
.store-page {
  top: 70PX !important;
}
.div-content {
  overflow-x: auto;
}
.bm-p-stores-by-category-skeleton.skeleton {
  .skeleton-store {
    .skeleton-store-img {
      padding-bottom: 44%;
    }
  }
  .skeleton-list-title {
    width: unset;
  }
}
.mark {
  position: absolute;
  width: 14PX;
  height: 14PX;
  background-color: var(--bm-light-background-color);
  border-radius: 50%;
  top: 10%;
  right: -10%;
}

@media screen and (max-width: 991px) {
  .container-stretched-copy.w-container {
    margin-right: 30PX;
    margin-left: 30PX;
  }
  .bm-p-stores-by-category {
    .section {
      padding: 45PX 35PX;
      padding-top: 35PX;
    }
    .space-1 {
      padding-top: 60PX;
    }
  }
  .drag-nav-bar {
    overflow: auto;
    padding-top: 20PX;
    padding-bottom: 20PX;
  }
}

@media screen and (max-width: 767px) {
  .bm-p-stores-by-category {
    .section {
      padding: 25PX 25PX 20PX;
    }
    .nav_bg.nav-blur {
      top: calc(var(--bm-safe-area-top) + 60PX);
    }
    .space-1 {
      padding-top: 65PX;
    }
  }
  .nav_bg.nav-blur {
    max-height: none;
    max-width: none;
    min-height: 60PX;
  }
  .container-stretched-copy {
    z-index: 800;
    max-height: 60PX;
    min-height: 60PX;
  }
  .div-block-7 {
    top: 17PX;
  }
  .left {
    width: 30PX;
    height: 14PX;
    color: var(--bm-light-background-color);
  }
  .div-block-7-copy.open-modal {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .div-block-7-copy {
    top: 17PX;
  }
  .filter-icon {
    z-index: 999999999999;
  }
  .right {
    width: 40PX;
    height: 21PX;
    color: var(--bm-light-background-color);
  }
  .container.fixed-nav {
    max-height: 60PX;
    min-height: 60PX;
  }
  .nav-bar-title {
    font-size: 24PX;
  }
  .drag-nav-bar {
    overflow: auto;
    padding-top: 15PX;
    padding-bottom: 15PX;
  }
  .bm-p-stores-by-category-skeleton.skeleton {
    top: calc(var(--bm-safe-area-top) + 130PX);
    .skeleton-store {
      padding: 20px;
      margin: 0;
      height: unset;
      .bm-p-stores-by-category-store-list.container._1 {
        .skeleton-store-item {
          height: unset;
          margin-left: 0;
          margin-bottom: 50px;
        }
      }
    }
  }
  .store-page {
    top: 60PX !important;
  }
}
@media screen and (max-width: 479px) {
  .bm-p-stores-by-category {
    .section {
      padding: 20PX 12PX;
    }
    .nav_bg.nav-blur {
      top: calc(var(--bm-safe-area-top) + 50PX);
    }
  }
  .nav_bg.nav-blur {
      max-height: 50PX;
      min-height: 50PX;
  }
  .container-stretched-copy.w-container {
    max-height: 50PX;
    min-height: 50PX;
    margin-right: 16PX;
    margin-left: 17PX;
  }
  .div-block-7.open-modal {
    position: relative;
    top: 0px;
    z-index: 90000000;
    margin-left: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .left {
    margin-right: auto;
  }
  .div-block-7-copy.open-modal {
    position: relative;
    top: 0px;
    z-index: 90000000;
    margin-left: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .container.fixed-nav {
    max-height: 50PX;
    min-height: 50PX;
  }
  .nav-bar-title {
    font-size: 22PX;
  }
  .drag-nav-bar {
    overflow: auto;
    max-width: 100vw;
    padding: 15PX;
  }
  .store-page {
    top: 50PX !important;
  }
  .bm-p-stores-by-category-skeleton.skeleton {
    top: calc(var(--bm-safe-area-top) + 120PX);
    .skeleton-store {
      .bm-p-stores-by-category-store-list.container._1 {
        .skeleton-store-item {
          padding: 12PX;
          margin-bottom: 0;
          width: 100%;
        }
      }
    }
  }
  .mark {
    width: 12PX;
    height: 12PX;
  }
  
  
}
