@import '~bm-public.less';

.bm-p-store-edit {
  .light-bc;

  .pc-header {
    height: calc(var(--bm-safe-area-top) + 88px);
  }

  .back-icon {
    color: var(--bm-text-title-color);
  }

  .page-content {
    margin-bottom: 196px;
  }

  .am-navbar {
    .light-bc;

    border-bottom: 2px solid var(--bm-border-color);

    .am-navbar-title {
      color: var(--bm-text-title-color);
      .font-size(40px);
    }

    .am-navbar-right {
      font-weight: 500;
      font-size: 32px;
      line-height: 32px;
      color: var(--bm-primary-color);
    }
  }

  .shop-type-input {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .shop-type {
      line-height: 36px;
      font-size: 32px;
    }

    .right-icon {
      font-size: 32px;
      margin-right: 42px;
    }
  }

  .input-line {
    margin: 32px 0 0 32px;
    padding-bottom: 32px;
    border-bottom: 2px solid var(--bm-border-color);

    .title {
      margin-bottom: 8px;
      color: var(--bm-text-secondary-color);
    }
  }

  .input-line:last-child {
    border-bottom: none;
  }

  .am-list-item.am-input-item {
    padding: 0;
    border: none;
    background: none;
    height: 36px;
  }

  .am-list-item.am-input-error {
    .am-input-error-extra {
      display: none;
    }
  }

  .am-list-item {
    height: 36px;
    min-height: 36px;
    line-height: 36px;

    .am-input-control {
      font-size: 32px;

      input {
        padding: 0;
        font-size: 32px;
        line-height: 36px;
      }

      input::-webkit-input-placeholder {
        font-size: 32px;
        line-height: 36px;
      }
    }

    .card-icon-close {
      background: var(--bm-modal-b-color);
      color: var(--bm-light-background-color);
      border-radius: 50%;
      margin: 0;
      padding: 0;

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  .bm-c-user-components-phone-number {
    .am-list-item.am-input-item {
      padding: 0;
      border: none;
      background: none;
      height: 36px;
      min-height: 36px;
      line-height: 36px;
      border-radius: 0;
    }

    .am-list-item.am-input-item.am-input-focus {
      border: none;
    }

    input {
      font-size: 32px;
    }
  }

  .shop-type-input {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .shop-type {
      line-height: 36px;
      font-size: 32px;
    }

    .right-icon {
      font-size: 32px;
      margin-right: 42px;
    }
  }

  .shop-type-list {
    padding-left: 32px;
    border-top: 2px solid var(--bm-border-color);

    .shop-type-wrap {
      display: flex;
      height: 104px;
      justify-content: space-between;
      align-items: center;
      padding-right: 32px;
      border-bottom: 2px solid var(--bm-border-color);

      .shop-type {
        font-size: 32px;
        color: var(--bm-text-title-color);
      }

      .checked {
        font-size: 40px;
        color: var(--bm-primary-color);
      }
    }
  }
  .input-image {
    width: 320px;
    height: 320px;
    margin-top: 12px;

    .am-image-picker-list {
      padding: 0px;

      .am-image-picker-item {
        .am-image-picker-item-content {
          border-radius: 12px;
          background-position: center center;
          background-repeat: no-repeat;
        }
      }

      .am-flexbox-item {
        margin-top: 20px;
        margin-right: 22px;
      }

      .am-image-picker-item .am-image-picker-item-remove {
        width: 40px;
        height: 40px;
        background-size: cover;
        right: -22px;
        top: -20px;
      }

      .am-image-picker-upload-btn {
        border: 2px dashed rgba(189, 189, 189, 0.2);
        background: var(--bm-checkout-backgroud-color);
        border-radius: 12px;
      }

      .am-image-picker-upload-btn::before {
        width: 4px;
        height: 28px;
        background-color: var(--bm-text-secondary-color);
      }

      .am-image-picker-upload-btn::after {
        width: 28px;
        height: 4px;
        background-color: var(--bm-text-secondary-color);
      }
    }
  }

  .input-line-store-image {
    margin: 32px 0 0 32px;
    padding-bottom: 32px;

    .title {
      margin-bottom: 8px;
      color: var(--bm-text-secondary-color);
    }
  }

  .disable {
    opacity: 0.5;
  }

  .country-input {
    display: flex;

    .areacode-name {
      display: flex;
      align-items: center;
      margin-left: 30px;
    }

    .down-icon {
      flex: 1;
      justify-content: flex-end;
      font-size: 32px;
      margin-right: 42px;
    }
  }
  .bottom-botton {
    display: flex;
    position: fixed;
    bottom: 0;
    .phone & {
      left: 0;
      right: 0;
    }
    z-index: 990;
    background-color: #fff;
    overflow: hidden;
    box-shadow: 0 0 10px 0 var(--bm-shadow-color);
    padding: 32px;

    .activated-botton {
      flex: 1;
      padding: 34px 0;
      font-size: 32px;
      font-weight: bold;
      background-color: var(--bm-primary-color-tap);
      text-align: center;
      border-radius: 12px;
      color: var(--bm-light-background-color);
    }

    .settled {
      margin-left: 16px;
    }
  }

  .category {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--bm-text-disabled-color);

    .category-title {
      font-weight: 500;
      font-size: 32px;
      line-height: 32px;
      margin-left: 32px;
    }

    .fold-icon {
      background-color: #FFFFFF;
      border-radius: 50%;
      margin: 20px 20px 20px 0;
      .bm-icon {
        font-size: 48px;
        display: flex;
        padding: 0;
      }
    }
  }

  .settings {
    .settings-option {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 32px;
      border-radius: 12px;
      border: 2px solid #BDBDBD;
  
      &-title {
        margin-left: 32px;
        font-size: 30px;
        line-height: 32px;
      }
  
      .bm-icon {
        font-size: 48px;
        display: flex;
        padding: 0;
        margin: 32px 32px 32px 0;
      }
    }

    .select {
      border: 2px solid var(--bm-primary-color);

      .bm-icon {
        color: var(--bm-primary-color);
      }
    }
  }

  &-google-map {
    max-height: 98%;

    .header-icon {
      display: none;
    }

    .header-title {
      font-weight: bold;
      font-size: 36px;
      line-height: 40px;
      text-align: center;

      .title {
        display: flex;
        align-items: center;

        .title-left {
          flex: 1;
          text-align: left;
        }

        .title-centre {
          flex: 1;
        }

        .title-right {
          flex: 1;
        }
      }
    }

    .bm-c-google-map-search {
      height: 80vh;
    }

    .bm-c-google-map-search-group {
      height: 25vh;
    }

    .am-modal-content {
      text-align: left;
      border-radius: 20px 20px 0px 0px;
    }

    .am-modal-body {
      color: var(--bm-text-title-color);

      .bm-c-modal-popup-header {
        border-bottom: 2px solid #EBEFF2;;
      }
    }

    .place {
      margin: 32px;

      &-name {
        font-weight: 600;
        font-size: 32px;
        line-height: 36px;
      }

      &-vicinity {
        margin-top: 8px;
        font-size: 28px;
        line-height: 32px;
        color: var(--bm-skeleton-color);
      }
    }

    .dividing {
      height: 20px;
      background: #F8F8F8;
      opacity: 0.8;
    }

    .apt-suite {
      margin-left: 32px;
      display: flex;
      align-items: center;
      border-bottom: 2px solid rgba(157, 173, 192, 0.1);

      &-title {
        font-weight: 500;
        font-size: 32px;
        line-height: 36px;
      }

      &-input {
        flex: 1;

        .am-list-item {
          border-bottom: 0;
          padding: 0;

          .am-input-control {
            font-size: 28px;
            line-height: 32px;
          }

          .card-icon-close {
            background: var(--bm-modal-b-color);
            color: var(--bm-light-background-color);
            border-radius: 50%;
            margin: 0;
            padding: 0;
      
            svg {
              width: 32px;
              height: 32px;
            }
          }

          input {
            font-weight: 500;
            font-size: 28px;
            line-height: 32px;
          }
        }
      }
    }

    .save-button {
      margin: 32px;
      margin-top: 20vh;
      background-color: var(--bm-primary-color);
      border-radius: 12px;
      color: var(--bm-light-background-color);
      padding: 34px 0;
      text-align: center;
    }

    .disable {
      opacity: 0.5;
    }
  }
}
