@import '~bm-public.less';

.bm-p-stores-by-brand {
  // .dark-bc;

  .pc-header {
    height: calc(var(--bm-safe-area-top) + 180px);
    // background-color: var(--bm-primary-color);
    background-color: var(--bm-dark-background-color);
  }
  
  .am-navbar {
    background-color: var(--bm-primary-color);
    color: var(--bm-light-background-color);
  }

  .store-name {
    // display: flex;
    // align-items: center;
    // font-weight: 500;
    // font-size: 36px;
    // line-height: 36px;

    .down {
      font-size: 40px;
      margin-left: 10px;
    }
  }

  &-store-list {
    // margin-top: 40px;
    // padding: 0 32px;
    
    .store-other-info {
      flex-wrap: wrap
    }
  }

  &-types {
    position: fixed;
    top: 88px;
    left: 0;
    right: 0;
    background-color: #FFF;
    display: flex;
    height: 92px;
    align-items: center;
    z-index: 1;

    &-wrapper {
      display: flex;
      margin: 0 32px;
      overflow-x: auto;
    }

    &-item {
      height: 50px;
      line-height: 50px;
      padding: 0 28px;
      font-size: 26px;
      // line-height: 30px;
      .font-family-b;
      .title-color;
      border-radius: 25px;
      white-space: nowrap;

      &:not(:first-child) {
        margin-left: 32px;
      }

      &.selected {
        color: #FFF;
        background-color: var(--bm-primary-color);
      }
    }
  }
}