@import '~bm-public.less';

.bm-p-pay-no-order-results {
  .light-bc;

  .pc-header {
    height: calc(var(--bm-safe-area-top) + 0);
  }

  &.bm-app-page {
    .light-bc;
  }

  &-wapper {
    display: flex;
    margin-top: 400px;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &-logo {
      .font-size(3rem);
    }
  }
}
