@import '~bm-public.less';

.bm-c-modal {
  &-popup {
    display: flex;
    flex-direction: column;
    max-height: 100%;

    &-wrapper {
      z-index: 999;
      overflow: hidden;
    }

    .am-modal-content,
    .am-modal-body {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    &-header {
      padding: 0 32px;

      .header-icon {
        padding: 14px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 72px;
      }

      .header-title {
        padding: 32px 0;
        text-align: left;
        .font-size(36px);
        .title-color;
        .font-family-b;
      }
    }

    &-content {
      overflow: auto;
      flex: 1;
    }
    
  }
}
.terms-condition {
  height: 100%;
  .am-modal-content .section {
    padding: calc(var(--bm-safe-area-top) + 40px) 30px 80px;
    p, li, a, span {
      word-break: break-word;
    }
  }
  .am-modal-content {
    text-align: initial;
  }
  .bm-c-modal-popup-header {
    padding: 0;
  }
  .header-title {
    padding: 0;
    padding-top: 0;
    height: 88px;
    padding-top: var(--bm-safe-area-top);
    background-color: var(--bm-primary-color);
    font-weight: normal;
  }
  .setting-header {
    height: 88px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid var(--bm-border-color);
    background-color: var(--bm-primary-color);
    div {
      flex: 1;
    }
    .title-left {
      padding-left: 30px;
      color: var(--bm-light-background-color);
    }
    .title-center {
      text-align: center;
      color: var(--bm-light-background-color);
      .font-size(40px);
      flex: 3;
    }
    .title-right {
      padding-right: 32px;
    }
  }
  .terms-condition-content-wrapper {
    padding: 0 32px 32px;
  }
}
