@import '~bm-public.less';

.bm-p-menu-grid {
  .dark-bc;
  .am-navbar-title {
    color: #fff;
    .font-size(40px);
  }

  .pc-header {
    // height: calc(var(--bm-safe-area-top) + 88px);
    // background-color: var(--bm-primary-color);
    background-color: var(--bm-dark-background-color);
  }

  .pc-footer {
    // height: 152px;
    background-color: var(--bm-dark-background-color);
  }

  .page-content {
    .dark-bc;

    margin-top: 16px;
    // padding: 0 32px;
  }

  &.web {
    .page-content {
      // padding: 0 16px;

      .bar-left {
        padding: 0 16px;
      }
    }
  }

  .bm-c-menu-grid {
    margin-top: 32px;
  }
}
