@import '~bm-public.less';

.bm-p-qrcode-shopping-result {
  .dark-bc;

  .pc-header {
    height: calc(var(--bm-safe-area-top) + 88px);
  }
  .page-content {
    padding: 0 32px;
  }
  .pr-icon {
    display: flex;
    justify-content: center;
    padding-top: 176px;

    .bm-icon {
      font-size: 300px;
    }
  }
  .pr-msg {
    .font-size(32px);
    .font-family-b;

    text-align: center;
    .p-color;
  }
  .pr-btns {
    margin-top: 120px;

    a {
      flex: 1;
    }

    .back-home {
      margin-top: 24px;
    }
  }
}
