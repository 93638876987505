@import '~bm-public.less';

.bm-c-status {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &-wrapper {
    padding: 340px 122px 122px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .bm-icon {
    font-size: 300px;
  }

  .status-title {
    .title-color;
    .font-family-b;
    .font-size(32px);

    margin-bottom: 16px;
  }

  .status-describe {
    .text-s-color;
    .font-family-m;

    margin-bottom: 16px;
  }

  .status-img {
    margin-bottom: 40px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
