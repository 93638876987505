
.bm-c-google-map {
  &-item {
    display: flex;
    align-items: center;
    margin: 10px;
    color: var(--bm-primary-color);
  }

  &-marker {
    font-size: 40px;
    color: var(--bm-primary-color);
    position: absolute;
    left: 50%;
    top: 50%;
    user-select: none;
    transform: translate(-50%, -50%);
  }

  &-content{
    background-color: var(--bm-light-background-color);
    overflow-y: hidden;
  }

  &-search{
    width: 100%;
    height: 100vh;
    font-size: 28px;

    &-group {
      width: 100%;
      height: 45vh;
    }
  }

  &-search-wapper {
    display: flex;
    height: 72px;

    &-box {
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 20px 0px 20px 20px;
      border: 0px;
    }

    &-icon {
      font-size: 40px;
      position: absolute;
      right: 8px;
      margin-top: 8px;
    }
  }

  &-show-location {
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    margin-top: 32px;
    right: 32px;
    border-radius: 99px;
    box-shadow: 0px 0px 4px var(--bm-border-color);
    z-index: 4;
    background-color: var(--bm-light-background-color);

    &-icon {
      font-size: 40px;
    }
  }

  &-search-here {
    display: flex;
    position: absolute;
    height: 100px;
    left: 16px;
    right: 16px;
    bottom: 132px;
    align-items: center;
    justify-content: center;
    background-color: var(--bm-primary-color);
    color: var(--bm-light-background-color);
    border-radius: 16px;
  }

  &-search-list {
    display: flex;
    flex-direction: column;

    &-item {
      display: flex;
      align-items: center;
      margin-top: 32px;
      border-radius: 18px;
      background-color: var(--bm-light-background-color);
      height: 100px;
      border-bottom: 2px solid var(--bm-border-color);

      &-font {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &-icon {
        margin-left: 36px;
        margin-right: 36px;
        font-size: 20px;
      }

      &-placename {
        font-weight: 700;
      }
    }
  }

  &-edit-suite {
    &-placename {
      margin: 36px 0px 8px 36px;
      font-weight: 700;
    }

    &-vicinity {
      margin-left: 36px;
    }

    &-space {
      height: 20px;
      background-color: var(--bm-dark-background-color);
      padding-left: 0px;
      margin-top: 32px;
    }

    .am-list-item {
      font-size: 24px;
      background-color: var(--bm-light-background-color);
      padding-left: 0px;
      border: hidden;
      border-bottom: 2px solid var(--bm-border-color);
      margin-left: 36px;
    }

    .am-textarea-control textarea {
      font-size: 24px;
    }

    .am-textarea-label {
      font-size: 24px;
    }

    &-button {
      margin: 64px 32px 32px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 18px;
      background-color: var(--bm-primary-color);
      color: var(--bm-light-background-color);
    }
  }
}
