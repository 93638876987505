@import '~bm-public.less';

.bm-p-good-received-note-detail {
  .dark-bc;

  .pc-header {
    height: var(--bm-safe-area-top);
  }

  .pc-footer {
    .footer-07 {
      margin: 0;
    }
  }

  .back-icon {
    color: var(--bm-light-background-color);
  }

  .am-navbar {
    .am-navbar-title {
      color: var(--bm-light-background-color);
      .font-size(40px);
    }
  }

  .content-panel-wrap {
    position: -webkit-sticky;
    position: sticky;
    left: auto;
    top: 40PX;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 40%;
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }

  .good-received-note-detail {
    position: relative;
    z-index: 0;

    &-style-b-color {
      position: absolute;
      top: 0;
      width: 100%;
      height: 190PX;
      background-color: var(--bm-primary-color);
      z-index: 1;
    }

    &-section {
      position: relative;
      z-index: 2;
      padding: 0 40PX 40PX;
    }

    &-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 940PX;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    &-data {
      position: relative;
      z-index: 0;
  
      &-tab {
        margin-top: 20PX;
        position: relative;
        z-index: 2;
        padding: 36px 34px 22px 32px;
        border-radius: 12px;
        background-color: var(--bm-light-background-color);
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.18);

        &-number-and-state {
          display: flex;
          justify-content: space-between;
          padding-bottom: 16px;
          margin-bottom: 30px;
          align-items: center;
          border-bottom: 2px solid rgba(235, 239, 242, 0.5);
        }
  
        &-number {
          font-weight: 600;
          font-size: 28px;
          line-height: 32px;
          color: var(--bm-text-title-color);
        }
  
        &-information-line {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 16px;
          line-height: 32px;
  
          &-title {
            color: var(--bm-skeleton-color);
            font-size: 14PX;
            font-weight: 400;
          }
  
          &-data {
            color: var(--bm-text-title-color);
            font-size: 18PX;

            &.deferred {
              border-radius: 8px;
              border: 1px solid var(--bm-error-color);
              padding: 6PX;
              color: var(--bm-error-color);
              font-size: 16PX;
              line-height: 20PX;
            }

            &.canceled {
              border-radius: 8px;
              padding: 6PX;
              font-size: 16PX;
              line-height: 20PX;
              color: var(--bm-skeleton-color-t30);
              border: 1px solid var(--bm-skeleton-color-t30);
            }

            &.received {
              border-radius: 8px;
              padding: 6PX;
              font-size: 16PX;
              line-height: 20PX;
              color: var(--bm-tag-delivery-color);
              border: 1px solid var(--bm-tag-delivery-color);
            }
          }
        }

        &-bottom {
          background-color: var(--bm-light-background-color);
          display: flex;
        }
    
        &-button {
          margin-top: 20PX;
          font-size: 18PX;
          line-height: 20PX;
          font-weight: bold;
          border-radius: 12px;
          flex: 1;
          display: flex;
          justify-content: center;
          padding: 15PX 0;
    
          &.cancel-button {
            border: 2px solid #828282;
            color: #828282;
          }
    
          &.receive-button {
            margin-left: 20PX;
            background-color: var(--bm-primary-color);
            color: var(--bm-light-background-color);
          }
        }
      }
    }

    &-line-item {
      display: flex;
      align-items: center;
      padding-bottom: 40px;
      border-bottom: 2px solid rgba(235, 239, 242, 0.5);

      &-img {
        width: 140px;
        height: 140px;
        margin-right: 20px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px;
        background-image: url(/images/default-line-item.png);
      }

      &-info {
        flex: 1;

        &-line {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: var(--bm-text-title-color);

          &-name {
            flex: 1;
            font-weight: bold;
            font-size: 28px;
            line-height: 32px;
            margin-bottom: 12px;
          }

          &-quantity {
            font-size: 28px;
            line-height: 28px;
            margin-bottom: 12px;
          }

          &-code {
            margin-bottom: 36px;
            font-size: 24px;
            line-height: 28px;
            color: var(--bm-text-secondary-color);
          }

          &-price {
            font-size: 24px;
            line-height: 28px;
            color: var(--bm-text-secondary-color-t60);
          }

          &-total {
            font-size: 28px;
            line-height: 36px;
          }
        }
      }

      &-card {
        height: auto;
        width: 58%;
        margin-top: 60PX;
        margin-right: 20PX;
        padding: 20PX;
        align-self: flex-start;
        -webkit-align-self: flex-start;
        -ms-flex-item-align: start;
        border-radius: 12PX;
        background-color: var(--bm-light-background-color);
        box-shadow: 0px 4px 10px rgba(60, 80, 111, 0.05);

        &-top-title {
          font-weight: 600;
          font-size: 32px;
          line-height: 32px;
          color: var(--bm-text-title-color);
          margin-bottom: 30px;
          border-bottom: 2px solid var(--bm-border-color-t60);
          padding-bottom: 32px;
        }

        &-information-line {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 16px;
          font-size: 28px;
          line-height: 32px;
          color: var(--bm-text-title-color);

          &.bold {
            font-weight: bold;
          }
          
          &.dividing {
            margin-top: 32px;
            padding-top: 24px;
            border-top: 1px solid rgba(235, 239, 242, 0.5);
          }
        }
      }
    }

    &-remarks {
      &-title {
        font-weight: bold;
        font-size: 28px;
        line-height: 32px;
        color: var(--bm-text-title-color);
        margin-top: 30px;
        margin-bottom: 28px;
      }

      &-data {
        padding-bottom: 32px;
        font-size: 28px;
        line-height: 32px;
        color: var(--bm-text-secondary-color);
      }
    }

    &-bottom {
      background-color: var(--bm-light-background-color);
      padding: 36px 56px;
      display: none;
    }

    &-button {
      font-size: 32px;
      line-height: 40px;
      font-weight: bold;
      border-radius: 12px;
      flex: 1;
      display: flex;
      justify-content: center;
      padding: 20px 0;

      &.cancel-button {
        border: 2px solid #828282;
        color: #828282;
      }

      &.receive-button {
        margin-left: 27px;
        background-color: var(--bm-primary-color);
        color: var(--bm-light-background-color);
      }
    }

    &-bottom-ipad {
      display: none;
    }
  }

  @media screen and (max-width: 991px) {
    .good-received-note-detail {
      &-data-tab-bottom {
        display: none;
      }

      &-style-b-color {
        height: 110PX;
      }

      &-section {
        padding: 0 35PX 30PX;
      }

      &-content {
        display: block;
      }

      .content-panel-wrap {
        position: relative;
        margin: 0 20PX;
        width: auto;
      }

      &-line-item-card {
        margin: 70PX 20PX 0;
        width: auto;
        background-color: transparent;
        background-color: var(--bm-light-background-color);
      }

      &-bottom-ipad {
        display: flex;
        padding: 36px 56px;
        background-color: var(--bm-light-background-color);
        position: -webkit-sticky;
        position: sticky;
        left: 0%;
        top: 0%;
        right: 0%;
        bottom: 0%;
        z-index: 9000;
        height: auto;
        margin-top: 0vh;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-box-pack: end;
        -ms-flex-pack: end;

        .purchase-order-detail-button {
          font-size: 14PX;
          line-height: 20PX;
          font-weight: bold;
          border-radius: 12px;
          flex: 1;
          display: flex;
          justify-content: center;
          padding: 15PX 0;
    
          &.cancel-button {
            border: 2px solid #828282;
          }
    
          &.receive-button {
            margin-left: 27px;
            background-color: var(--bm-primary-color);
            color: var(--bm-light-background-color);
          }
        }
      }
    }
  }

  @media screen and (max-width: 479px) {
    .good-received-note-detail {
      &-section {
        padding: 0 12PX 15PX;
      }

      
      &-data-tab {
        margin-top: 0;
        &-information-line {
          &-data {
            font-size: 14PX;
          }
        }
      }

      .content-panel-wrap {
        margin: 0;
      }

      &-line-item-card {
        margin: 120px 0 0;
      }

      &-bottom-ipad {
        display: none;
      }

      &-bottom {
        display: flex;
      }
    }
  }
}