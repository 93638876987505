@import '~bm-public.less';

.bm-c-side-bar {
  visibility: hidden;
  transition: all 0.4s ease-out;
  user-select: none;
}
.bm-c-side-bar.show-side-bar-wrap {
  visibility: visible;
  transition: all 0.4s ease-out;
}
.side-bar-mask {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--bm-modal-b-color);
  width: 100%;
  height: 100%;
  transition: opacity 0.4s ease-out;
  opacity: 0;
  z-index: 111;
  visibility: hidden;
}
.side-bar-content {
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  width: 280PX;
  height: 100%;
  background-color: var(--bm-light-background-color);
  z-index: 111;
  transition: transform 0.4s ease-out;
}
.show-side-bar-mask {
  opacity: 1;
  visibility: visible;
}
.show-side-bar-content {
  left: 0;
  transform: translateX(0%);
}
.user-msg-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  padding-top: calc(var(--bm-safe-area-top) + 40PX);
  padding-bottom: 40PX;
  padding-left: 20PX;
  padding-right: 20PX;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  .light-bc;
  .default-avatar-outlined {
    top: 50%;
    transform: translateY(-50%);
  }
  .avatar-icon-wrap .avatar-icon {
    color: var(--bm-light-background-color);
    font-size: 34PX;
  }
  .avatar-icon-wrap {
    width: 80PX;
    height: 80PX;
    margin-right: 20PX;
    border-radius: 40PX;
    background-position: 50% 50%;
    background-size: cover;
    background-color: var(--bm-primary-color-t12);
    min-width: 80PX;
    .avatar {
      width: 100%;
      height: 100%;
      border-radius: 40PX;
      background-size: cover;
    }
    .avatar-icon {
      color: var(--bm-light-background-color);
    }
  }
  .login-in-tips {
    font-family: 'Sf pro display', sans-serif;
    font-size: 18PX;
    color: var(--bm-text-title-color);
    margin-bottom: 10PX;
    word-break: break-all;
    font-weight: 600;
  }
  .membership-name {
    padding: 5PX 20PX;
    border-radius: 20PX;
    font-family: 'Sf pro display', sans-serif;
    color: var(--bm-light-background-color);
    font-size: 14PX;
    line-height: 20PX;
    max-width: 100PX;
  }
}

.side-bar-list {
  .am-accordion  {
    border-top: none;
  }
  .bar-item {
    display: flex;
    align-items: center;
    .bar-item-icon {
      width: 25PX;
      margin-right: 10PX;
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-items: center;
      padding: 0;
      font-size: 60px;
      color: var(--bm-text-title-color);
      svg {
        max-height: 25PX;
      }
    }
  }
  .my-accordion-list {
    display: flex;
    font-size: 32PX;
    color: var(--bm-text-title-color);
  }
  .side-bar-list .am-accordion  {
    border-top: none;
  }
  #sideBar .am-accordion::before {
    height: 0;
  }
  .am-list-item {
    border: none;
  }
  .am-accordion .am-accordion-item .am-accordion-header:hover {
    background-color: hsla(0,0%,96.5%,.4);
  }
  #sideBar .am-accordion-item .am-accordion-header::after {
    height: 0;
  }
  .am-accordion .am-accordion-item .am-accordion-header i {
    top: 22PX;
    height: 15PX;
    right: 17PX;
    color: var(--bm-text-secondary-color);
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(90deg) skew(0deg, 0deg); 
    transform-style: preserve-3d;
  }
  .bm-c-side-bar .am-accordion.my-accordion .am-accordion-item .am-accordion-header[aria-expanded~='true'] i {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(270deg) skew(0deg, 0deg );
    transform-style: preserve-3d;
  }
  #sideBar .am-list-body div:not(:last-child) .am-list-line::after {
    height: 0;
  }
  .my-accordion-list {
    font-size: 17PX;
    line-height: 19PX;
  }
  .am-accordion {
    cursor: pointer;
  }
  .am-accordion-header,
  .side-bar-item {
    padding: 20PX;
    font-size: 17PX;
    border-top: 1PX solid var(--bm-border-color);
  }

  .am-accordion .am-accordion-item .am-accordion-header {
    height: unset;
    line-height: unset;
    padding: 20PX;
  }
  .bm-icon {
    padding: unset;
  }
  .my-accordion-list .bm-icon {
    width: 25PX;
    margin-right: 10PX;
    height: 20PX;
  }
  .am-list-body {
    border-bottom: none !important; 
    .am-list-item {
      margin-right: 0px;
      margin-left: 0px;
      background-color: hsla(0,0%,96.5%,.4);
      // -webkit-transition: background-color .2s ease;
      // transition: background-color .2s ease;
      font-family: 'Fontsfree net sfprodisplay', sans-serif;
      color: var(--bm-text-title-color);
      border-radius: 0;
      min-height: 0;
      .am-list-line .am-list-content {
        font-size: 16PX;
        padding: 0;
        color: var(--bm-text-color);
      }
    }
    .am-list-item-middle {
      padding: 20PX;
      color: #222222;
      position: relative;
      vertical-align: top;
      text-decoration: none;
      text-align: left;
      margin-left: auto;
      margin-right: auto;
      white-space: nowrap;
      border: none
    }
  }
  .am-list-body::after,
  .am-accordion-content-box::after  {
    height: 0 !important;
  }
  .am-accordion-content-box,
  .am-accordion-header,
  .am-list-line  {
    border-bottom: none !important;
  }
}
.am-list-body div:not(:last-child) .am-list-line::after {
  height: 0 !important;
}
.am-accordion-item .am-accordion-header::after {
  height: 0 !important;
}
  
@media screen and (max-width: 991px){
  .bm-c-side-bar {
    .nav-link-block,
    .side-bar-item  {
      line-height: 15PX;
    }
  }
}
@media screen and (max-width: 767px){
  .bm-c-side-bar {
    .side-bar-content {
      left: 0%;
      top: 0%;
      right: auto;
      bottom: 0%;
      overflow: visible;
      width: 60%;
      height: auto;
      padding-bottom: 0;
    }
    .user-msg-wrap {
      padding-top: 20PX;
      padding-bottom: 20PX;
      padding-left: 20PX;
      padding-right: 30px;
    }
    .avatar-icon-wrap {
      width: 75PX;
      height: 75PX;
      max-width: 75PX;
    }
    .side-bar-list {
      display: block;
      width: 100%;
      min-width: auto;
      margin-right: 0;
      background-color: #fff;
      text-align: left;
    }
    .side-bar-list .side-bar-item  {
      font-size: 15PX;
      padding-right: 20PX;
    }
    .my-accordion-list,
    .side-bar-item,
    .bar-item {
      font-size: 15PX;
      line-height: 19PX;
    }
    .side-bar-list .am-accordion .am-accordion-item .am-accordion-header,
    .side-bar-item {
      padding-right: 40PX;
      padding-left: 20PX;
      padding-bottom: 15PX;
      padding-top: 15PX;
      
    }
    .am-accordion .am-accordion-item .am-accordion-header i {
      top: 17PX;
    }
  }
}
@media screen and (max-width: 479px){
  .bm-c-side-bar {
    .navbar {
      width: 80%;
    }
    .user-msg-wrap {
      height: auto;
      padding-top: calc(var(--bm-safe-area-top) + 20PX);
    }
    .avatar-icon-wrap {
      width: 65PX;
      height: 65PX;
      min-width: 65PX;
    }
    .membership-name {
      padding: 5PX 10PX;
    }
    .side-bar-list {
      min-width: 70%;
      margin-right: 0;
    }
    .side-bar-content {
      width: 70%;
    }
  }
}
