@import '~bm-public.less';
.bm-p-menu-list {
  padding: 0;
  margin-top: 0;
  .dining-style,
  .nav_bg.streched.without-line {
    padding-top: var(--bm-safe-area-top);
  }
  .nav-time-picker.open-modal {
    z-index: 99;
  }
  .bm-c-news-list{
    padding: 0;
    margin: 0;
    .news-true {
      display: -ms-grid;
      display: grid;
      grid-auto-columns: 1fr;
      grid-column-gap: 22PX;
      grid-row-gap: 22PX;
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      -ms-grid-rows: auto auto;
      grid-template-rows: auto auto;
    }
  }
  .menu-w-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 940PX;
  }
  .dining-style {
    max-height: 70PX;
    min-height: 70PX;
  }
  .branch-card {
    width: 100%;
    height: unset;
    padding-bottom: 0;
  }
  .news-true {
    .branch-card {
      height: 450PX;
    }
  }
  .nav_bg.nav-blur.w-nav {
    width: 100%;
    left: unset;
    right: unset;
  }
  .submenus-title-wrapper {
    background-color: unset;
  }
  .submenus-title {
    background-color: var(--bm-dark-background-color);
  }

  .menu-panel-bc {
    background-color: var(--bm-dark-background-color);
  }
  .menu-panel {
    padding: 0;
    .q-dec,
    .q-inc {
      display: flex;
      width: 38PX;
      height: 38PX;
      margin-right: 10PX;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      background-color: var(--bm-light-background-color);
      color: var(--bm-primary-color);
      padding: 0px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .q-dec.disable-minus {
      opacity: 0.6;
    }
    .q-flex {
      margin-right: unset;
    }
    .q-num {
      height: unset;
    }
    .text-block-5 {
      margin-bottom: 0;
    }
    .section.tinted.grey {
      background-color: var(--bm-dark-background-color);
    }
    .submenus-title-wrapper {
      .submenus-title {
        font-size: 14PX;
        color: #999;
      }
    }
    // .menu-panel-bc,
    // .submenus-title {
    //   background-color: var(--bm-border-color);
    // }
  }
  .submenus-title-wrapper {
    .submenus-title-line {
      height: 1PX;
    }
  }
  .div-block-89 {
    position: -webkit-sticky;
    position: fixed;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    padding: 16PX;
    padding: 16PX 16PX  calc(var(--bm-safe-area-top) + 20PX);
    box-shadow: 1PX 1PX 3PX 0 #aaa;
  }
  .bm-c-news-list-panel {
    margin-bottom: 0;
  }
  .about-us-wrap {
    background-color: var(--bm-dark-background-color);
  }

  .store-address-wrap {
    padding: 0 32px;
    background-color: var(--bm-light-background-color);

    .store-address {
      padding: 32px 0;
      display: flex;
      align-items: center;
      border-bottom: 2px solid var(--bm-border-color);

      .address-icon {
        font-size: 36px;
        margin-right: 32px;
        color: var(--bm-primary-color);
      }
      .foodpanda-icon {
        font-size: 36px;
        margin-right: 32px;
        color: #CC2F70;
      }
      .deliveroo-icon {
        font-size: 36px;
        margin-right: 32px;
        color: #35B8B2;
      }
      .store-url-icon {
        font-size: 36px;
        margin-right: 32px;
        color: #10254E;
      }

      .msg-con {
        display: flex;
        flex: 1;
        justify-content: space-between;

        .con {
          font-size: 28px;
          line-height: 36px;
          color: var(--bm-text-title-color);
        }

        .right-icon {
          font-size: 28px;
        }
      }
    }
    .about-store {
      font-size: 28px;
      line-height: 32px;
      color: var(--bm-text-title-color);
      font-weight: bold;
      margin: 44px 0 28px;
    }
    .store-description {
      font-size: 28px;
      line-height: 36px;
      color: var(--bm-text-title-color);
      padding-bottom: 20px;
      white-space: pre-wrap;
    }
  }


  &-skeleton {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10001;
    background-color: #fff;

    &-img {
      width: 100%;
      height: 420px;
      .skeleton-color;
    }

    &-info {
      padding: 0 32px;
      display: flex;
      flex-direction: column;

      .title {
        margin-top: 32px;
        height: 88px;
        .skeleton-color;
      }

      .describe {
        margin-top: 28px;
        display: flex;

        &-item {
          width: 120px;
          height: 32px;
          margin-right: 16px;
          .skeleton-color;
        }
      }

      .action {
        margin-top: 32px;
        display: flex;

        &-item {
          flex: 1;
          height: 72px;
          .skeleton-color;

          &:not(:first-child) {
            margin-left: 16px;
          }
        }
      }
    }

    &-action-nav {
      margin-top: 32px;
      padding: 20px 0 20px 32px;
      display: flex;
      background-color: #f5f7f8;

      .action-nav-item {
        width: 160px;
        height: 52px;
        .skeleton-color;

        &:not(:first-child) {
          margin-left: 16px;
        }
      }
    }

    &-menu-nav {
      margin-top: 22px;
      padding: 20px 0 20px 32px;
      display: flex;
      flex-direction: row;
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;

      .skeleton-menu-nav-wrapper {
        display: flex;
      }

      .skeleton-menu-nav-item {
        width: 112px;
        height: 52px;
        border-radius: 26px;
        .skeleton-color;

        &:not(:first-child) {
          margin-left: 16px;
        }
      }
    }

    &-menu-list {
      margin-top: 32px;
      padding-left: 32px;

      .tab-title {
        width: 96px;
        height: 28px;
        .skeleton-color;
      }

      .menu-item {
        margin-top: 20px;
        display: flex;

        &-icon {
          width: 144px;
          height: 144px;
          .skeleton-color;

          border-radius: 12px;
        }

        &-content {
          display: flex;
          flex-direction: column;
          flex: 1;
          margin-left: 20px;
          margin-right: 32px;

          &-1,
          &-2,
          &-3 {
            .skeleton-color;
          }

          &-1 {
            width: 50%;
            height: 32px;
          }

          &-2 {
            margin-top: 8px;
            height: 28px;
          }

          &-3 {
            width: 25%;
            height: 28px;
            margin-top: 40px;
          }
        }
      }

      .menu-item-space {
        width: 100%;
        height: 2px;
        margin-top: 32px;
        margin-bottom: 12px;
        .skeleton-color;
      }
    }
  }
}
.div-block-77 {
  display: block;
  margin-top: calc(var(--bm-safe-area-top) + 16PX);
  margin-right: 21.6PX;
  margin-left: 21.6PX;
  border-radius: 0PX;
}
.menu-list-wrap {
  background-color: var(--bm-light-background-color);
  // position: fixed;
  z-index: 9800000;
  // left: 0%;
  // top: 0%;
  // right: 0%;
  // overflow: scroll;
  // height: 100%;
}
.header-image {
  overflow: hidden;
  height: 330PX;
  max-width: 100vw;
  min-width: 100vw;
  background-position: 50% 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.nav_bg {
  position: -webkit-sticky;
  position: sticky;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  height: 52PX;
  background-color: var(--bm-primary-color);
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

.nav_bg.nav-blur {
  position: fixed;
  z-index: 101;
  height: auto;
  border-bottom: 0PX solid rgba(151, 114, 114, 0.1);
  background-color: transparent;
  // background-color: var(--bm-light-background-color);
  background-image: none;
  transition: all .3s ease;
}

.div-block-66 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 940PX;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.tab-pane-tab-1 {
  width: auto;
  max-width: none;
  min-width: 100vw;
}

.w-nav {
  position: relative;
  background: #dddddd;
  z-index: 1000;
}
.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940PX;
  flex: 1;
}

.container-stretched-copy {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  max-height: 70PX;
  max-width: 100%;
  min-height: 70PX;
  margin-right: 60PX;
  margin-left: 60PX;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.w-container:after {
  clear: both;
}
.w-container .w-row {
  margin-left: -10PX;
  margin-right: -10PX;
}

.div-block-7 {
  position: absolute;
  top: 17PX;
  background-color: transparent;
}

.left-btn {
  margin-left: 32px;
}

.nav-button-main {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-height: 32PX;
  max-width: 32PX;
  min-height: 32PX;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-bar-on-scroll {
  display: none;
}

.container-stretched {
  position: relative;
  display: block;
  max-height: 633PX;
  max-width: 100%;
  min-height: 63.3PX;
  margin-right: 21.6PX;
  margin-left: 21.6PX;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.container-horizontal {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  max-width: 940PX;
  margin: 0PX auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.container-horizontal.stretch {
  display: block;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.container-horizontal.top {
  margin-top: 16PX;
}

.nav-time-picker {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  max-width: none;
  min-height: auto;
  margin-right: 0PX;
  padding-top: 0PX;
  padding-bottom: 0PX;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  cursor: pointer;
}


.text-block-4 {
  margin: 0PX;
  padding-right: 10PX;
  padding-left: 10PX;
  color: rgba(215, 83, 44, 0.65);
  font-size: 18PX;
  text-decoration: none;
}

.text-block-4.without {
  margin-right: 0PX;
}

.text-block-4.with-top-padding {
  margin-top: 5PX;
  margin-right: 20PX;
  padding-right: 0PX;
  padding-left: 0PX;
}

.section {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  padding: 40PX;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.section.tinted {
  padding-top: 0PX;
  padding-bottom: 0PX;
  background-color: #f5e6e0;
}

.section.tinted.white {
  padding-top: 20PX;
  padding-bottom: 20PX;
  background-color: #fff;
  cursor: pointer;
}

.section.tinted.white.open-modal {
  display: block;
  padding-top: 30PX;
}

.section.tinted.grey {
  margin-bottom: 0PX;
  padding-top: 10PX;
  padding-bottom: 0PX;
  background-color: var(--bm-border-color);
}

.section.cms {
  padding-top: 0PX;
  padding-bottom: 0PX;
}

.container {
  position: relative;
  display: block;
  overflow: visible;
}

.h1 {
  margin-top: 0PX;
  margin-bottom: 30PX;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  font-size: 38PX;
  line-height: 44PX;
  font-weight: bold;
}

.div-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30PX;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0PX;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.store-msg-icon {
  font-size: 20PX;
  color: var(--bm-primary-color);
  margin-right: 10PX;
  padding: 0;
}
.score-star {
  color: #ffb802;
}

.text-block-1 {
  margin-right: 5PX;
  color: var(--bm-text-title-color);
  font-size: 17PX;
  text-decoration: none;
}

.text-block-5---subtitle {
  margin-bottom: 0PX;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: #999;
  font-size: 16PX;
  text-align: left;
}

.line-separator {
  width: 1PX;
  height: 18PX;
  margin-right: 20PX;
  margin-left: 15PX;
  padding-right: 0PX;
  background-color: #eaeaea;
  font-size: 2PX;
}

.div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0PX;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.current-status {
  color: var(--bm-text-title-color);
  font-size: 17PX;
}

.current-opening-hours {
  margin-right: 5PX;
  color: var(--bm-text-title-color);
  font-size: 17PX;
  cursor: pointer;
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30PX;
  margin-right: -15PX;
  margin-left: -15PX;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}


.col {
  max-width: 50%;
  margin-bottom: 15PX;
  padding-right: 15PX;
  padding-left: 15PX;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.col.col-sm-1 {
  max-width: none;
  margin-bottom: 0PX;
}

.col.col-sm-11 {
  max-width: none;
  margin-bottom: 0PX;
}

.booking-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 64PX;
  margin-top: 0PX;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1PX;
  border-color: #eaeaea;
  border-radius: 12PX;
}

.div-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 24PX;
}

.text-block-3 {
  font-family: 'Sf pro display', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 22PX;
  font-weight: 600;
}

.h3 {
  margin-top: 0PX;
  margin-bottom: 8PX;
  font-family: 'Sf pro display', sans-serif;
  color: #636363;
  font-size: 20PX;
  font-weight: 600;
}

.h3.non-selected {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: #999;
  font-weight: 400;
}

.booking-icon {
  font-size: 22PX;
  margin-right: 10PX;
  color: #a88aec;
}

.queueing-icon {
  color: #ff8a30;
}

.div-block-68 {
  position: relative;
}

.color-banner {
  position: absolute;
  width: 100vw;
  height: 58PX;
  background-color: #f5e6e0;
}

.tabs-menu {
  max-height: 60PX;
  max-width: 940PX;
  min-height: 60PX;
  min-width: 940PX;
  margin-right: 0PX;
  margin-left: 0PX;
  padding-top: 8PX;
  >div:first-child {
    padding-left: 0;
  }
}

.tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: none;
  min-width: auto;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  background-color: transparent;
}
.tab-link-tab-1 {
  padding-right: 20PX;
  padding-left: 0PX;
  background-color: transparent;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: #636363;
}

.tab-link-tab-1.w--current {
  padding-right: 20PX;
  padding-left: 0PX;
  background-color: transparent;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: #d7532c;
  font-weight: 400;
}

.heading-5 {
  color: #636363;
}
.w-tabs {
  position: relative;
}
.w-tabs:before,
.w-tabs:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}
.w-tabs:after {
  clear: both;
}
.w-tab-menu {
  position: relative;
}
.w-tab-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  padding: 9PX 30PX;
  text-align: left;
  cursor: pointer;
  color: #222222;
  background-color: #dddddd;
}
.w-tab-link.w--current {
  background-color: #C8C8C8;
}
.w-tab-link:focus {
  outline: 0;
}
.w-tab-content {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.w-tab-pane {
  position: relative;
  // display: none;
}
.w--tab-active {
  display: block;
}

.tab-link-tab-1.w--current {
  padding-right: 20PX;
  padding-left: 0PX;
  background-color: transparent;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: #d7532c;
  font-weight: 400;
}

.tab-link-tab-1 {
  padding-right: 20PX;
  padding-left: 0PX;
  background-color: transparent;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: #636363;
}


.tab-link-tab-2 {
  padding-right: 20PX;
  padding-left: 20PX;
  background-color: transparent;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: #636363;
  font-weight: 400;
}
.tab-link-tab-2.w--current {
  padding-right: 20PX;
  padding-left: 20PX;
  background-color: transparent;
  color: #d7532c;
}

.underline {
  height: 3PX;
  border-radius: 8PX;
  background-color: #d7532c;
}
h3 {
  margin-top: 0PX;
  margin-bottom: 8PX;
  font-size: 24PX;
  line-height: 30PX;
  font-weight: 400;
}


.div-block-4 {
  display: block;
  height: auto;
  max-width: 100%;
  min-height: auto;
  margin-right: 0PX;
  padding-top: 0PX;
  padding-bottom: 0PX;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.nav-time-picker {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  max-width: none;
  min-height: auto;
  margin-right: 0PX;
  padding-top: 0PX;
  padding-bottom: 0PX;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  cursor: pointer;
}

.text-block-5 {
  margin-bottom: 10PX;
  font-family: 'Sf pro display', sans-serif;
  color: #999;
  font-size: 18PX;
  font-weight: 600;
}

.div-12 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0PX;
  padding-top: 40PX;
  padding-bottom: 40PX;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1PX solid #f5f5f5;
  cursor: pointer;
}

.div-12.last {
  border-bottom-width: 0PX;
}

.item-info-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex: 1;
  max-width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.item-info-wrapper.sold-out {
  opacity: .6;
  padding-right: 20PX;
}

.heading-3 {
  margin-top: 0PX;
  font-family: 'Sf pro display', sans-serif;
  font-size: 22PX;
  font-weight: 600;
  text-align: left;
  margin-bottom: 10px;
}

.paragraph-2 {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  font-size: 17PX;
}
.price {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
}

.text-block-6 {
  max-width: none;
  margin-top: 10PX;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 18PX;
}

.image-open {
  overflow: hidden;
  width: 140PX;
  height: 140PX;
  min-width: 140PX;
  border-radius: 6PX;
  background-position: 50% 50%;
  background-size: cover;
}

.modal-wrapper {
  position: fixed;
  left: 0PX;
  top: 0;
  right: 0PX;
  bottom: 0PX;
  z-index: 999999999;
  display: none;
  padding: 40PX;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-perspective: 850PX;
  perspective: 850PX;
  cursor: default;
}

.modal-body {
  position: relative;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 600PX;
  height: 90%;
  padding: 0PX;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-radius: 12PX;
  background-color: #fff;
  box-shadow: 0 9PX 50PX -14PX transparent;
  color: #313131;
  opacity:0;
  -webkit-transform:translate3d(0, 370PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
  -moz-transform:translate3d(0, 370PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
  -ms-transform:translate3d(0, 370PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
  transform:translate3d(0, 370PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
}

.modal-wrapper-show {
  display: flex;
}

.modal-wrapper-show .modal-body {
  opacity: 1;
  transform: translate3d(0PX, 0PX, 0PX) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transition: all 0.3s ease;
}

.item-image-pop-up {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 800000;
  width: 100%;
  // height: 400PX;
  max-width: none;
  min-width: auto;
  background-color: transparent;
  background-position: 50% 50%;
  background-size: cover;
  height: 33%;
}

.food-description {
  position: relative;
  z-index: 98000000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 65%;
  margin-top: auto;
  padding: 20PX;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  // -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  // justify-content: space-between;
  border-radius: 16PX;
  background-color: #fff;
}

.c-popup-close-time {
  position: absolute;
  left: 0PX;
  top: 0PX;
  right: 0PX;
  bottom: 0PX;
  z-index: 5;
  display: block;
  background-color: rgba(0, 0, 0, 0.09);
  cursor: pointer;
}
.div-block-69 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 10PX;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1PX solid #f5f5f5;
}

.q-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  min-width: auto;
  margin-right: 20PX;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.q-dec {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  // width: 45PX;
  // height: 45PX;
  margin-right: 10PX;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
}

.q-num {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50PX;
  height: 45PX;
  margin-bottom: 0PX;
  padding-right: 0PX;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-width: 0PX;
  background-color: transparent;
  font-family: 'Sf pro display', sans-serif;
  font-size: 18PX;
  font-weight: 600;
  text-align: center;
}

.q-inc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  // width: 45PX;
  // height: 45PX;
  margin-left: 10PX;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  // background-color: #d7532c;
}

.add-to-cart-button {
  align-items: center;
  display: flex;
  width: 100%;
  height: 50PX;
  padding-top: 12PX;
  padding-bottom: 12PX;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 12PX;
  background-color: var(--bm-primary-color);
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  font-size: 18PX;
  text-align: center;
}

.text-block-12 {
  max-width: none;
  margin-top: 0PX;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: #fff;
  font-size: 18PX;
}


.div-block-89 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.opening-hours {
  position: relative;
  z-index: 10;
  display: block;
  overflow: visible;
  width: 600PX;
  height: auto;
  margin: auto;
  padding: 20PX 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 0px solid transparent;
  border-radius: 6PX;
  background-color: #fff;
  box-shadow: 0 9PX 50PX -14PX #000;
  color: var(--bm-text-title-color);
  text-align: center;
  box-sizing: border-box;
}

.opening-hours-icon {
  font-size: 20PX;
  padding: 0;
  color: var(--bm-text-title-color);
}

.opening-time-titles {
  padding-right: 20PX;
  padding-left: 20PX;
}
.opening-day {
  background-color: var(--bm-primary-color-t10);
}
.opening-hour-display {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
  padding: 15PX 20PX;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1PX solid #f5f5f5;
}

.text-block-2 {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 16PX;
  font-weight: 400;
}
.heading-3.procurement {
  margin-bottom: 30PX;
  font-size: 24PX;
}
.opening-hour-display.lst {
  border-bottom-width: 0px;
}
.tab-pane-tab-3 {
  min-width: 100vw;
}
.section.store-info {
  padding-top: 40PX;
}
.add-express-order {
  margin-top: 20PX;
}
.inventory-options {
  color: var(--bm-text-color);
  font-size: 17PX;
}
.add-item-wrap {
  display: flex;
  margin-top: 10PX;
  font-size: 17PX;
  .checked {
    color: var(--bm-primary-color);
    padding: 0;
    font-size: 17PX;
  }
}
.add-item {
  font-weight: 600;
  margin-left: 10PX;
}
.item-information-box {
  display: contents;
  >div {
    margin: 25PX 0 0;
    font-size: 17PX;
  }
}
.product-name-wrap,
.product-price-wrap {
  display: flex;
  border-bottom: 1PX solid #f5f5f5;
  padding-bottom: 8PX;
  .product-name {
    line-height: 30PX;
  }
  input {
    height: 30PX;
  }
  .am-list-item {
    .am-input-control {
      input {
        font-size: 17PX;
      }
    }
  }
  .am-list-item.am-input-item  {
    border: none;
    background-color: transparent;
    height: unset;
    min-height: unset;
    padding-bottom: 0;
  }
  .am-list-line .am-input-control input::-webkit-input-placeholder {
    font-size: unset;
  }
}
.product-price-wrap {
  display: block;
  .product-price {
    margin-bottom: 15PX;
  }
  input {
    width: 100%;
  }
}

@media screen and (max-width: 991PX) {
  .bm-p-menu-list {
    .menu-w-container {
      max-width: 728PX;
    }
    .submenus-title-wrapper {
      .submenus-title {
        font-size: 14PX;
        padding-left: 0;
      }
    }
    .news-true {
      .branch-card {
        height: 400PX;
      }
    }
  }
  .c-popup-close-time {
    cursor: pointer;
  }
  .w-container {
    max-width: 728PX;
  }
  .section {
    padding: 30PX 35PX;
  }

  .section.tinted.white {
    width: auto;
  }

  .section.tinted.white.open-modal {
    padding-top: 20PX;
  }

  .section.tinted.grey {
    width: auto;
  }

  .section.cms {
    width: auto;
    min-width: auto;
  }
  .container-horizontal {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .container-horizontal.stretch {
    position: relative;
    display: block;
    max-width: none;
  }

  .container-horizontal.right-side {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .text-block-4 {
    margin-right: 10PX;
  }

  .h1 {
    margin-top: 0PX;
  }
  .div-block-2 {
    margin-bottom: 20PX;
  }

  .text-block-1 {
    font-size: 16PX;
  }

  .text-block-5---subtitle {
    font-size: 14PX;
  }

  .text-block-3 {
    font-size: 20PX;
  }

  .div-block-66 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    min-width: auto;
  }

  .tab-pane-tab-1 {
    min-width: auto;
  }
  .tabs-menu {
    width: 698PX;
    max-width: none;
    min-width: auto;
    padding-left: 0PX;
  }

  .tab-link-tab-1 {
    padding-right: 20PX;
  }

  .tab-link-tab-1.w--current {
    padding-right: 20PX;
  }

  .tab-link-tab-2 {
    padding-right: 20PX;
    padding-left: 20PX;
  }

  .tab-link-tab-2.w--current {
    padding-right: 20PX;
    padding-left: 20PX;
  }

  .tab-link-tab-1.w--current {
    padding-right: 20PX;
  }

  .tab-link-tab-1 {
    padding-right: 20PX;
  }

  .heading-5 {
    font-size: 20PX;
  }

  .heading-3 {
    margin-bottom: 10PX;
    font-size: 17PX;
  }

  .text-block-6 {
    font-size: 18PX;
  }

  .modal-wrapper {
    display: none;
  }
  .modal-wrapper-show {
    display: flex;
  }
  .text-block-12 {
    font-size: 18PX;
  }

  .add-to-cart-button {
    position: relative;
    z-index: 999999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0PX;
    padding-bottom: 0PX;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
  }
  .container-stretched-copy {
    margin-right: 30PX;
    margin-left: 30PX;
  }
}

@media screen and (max-width: 767PX) {
  .bm-p-menu-list {
    .news-true {
      .branch-card {
        height: 300PX;
        border-radius: 8PX;
      }
    }
    .bm-c-news-list{ 
      .news-true {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
      }
    }
    .dining-style {
      max-height: 60PX;
      min-height: 60PX;
    }
    .menu-panel {
      .q-inc,
      .q-dec {
        width: 35PX;
        height: 35PX;
      }
    }
    .submenus-title-wrapper {
      .submenus-title {
        font-size: 12PX;
        padding-left: 25PX;
      }
    }
  }
  .add-express-order {
    padding: 0 20PX;
  }

  .store-msg-icon {
    font-size: 18PX;
  }

  .nav-button-1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
  }
  .c-popup-close-time {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  .nav_bg.nav-blur {
    max-width: none;
  }
  .w-row,
  .w-container .w-row {
    margin-left: 0;
    margin-right: 0;
  }
  .header-image {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .container-stretched-copy {
    max-height: 50PX;
    min-height: 50PX;
  }

  .container-stretched {
    margin-right: 16PX;
    margin-left: 17PX;
  }

  // .html-embed-7 {
  //   width: 14PX;
  //   height: 14PX;
  //   color: #636363;
  // }
  .nav-bar-on-scroll {
    display: none;
  }

  .container-horizontal {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .container-horizontal.right-side {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .section {
    padding: 15PX 25PX 20PX;
  }

  .section.tinted.white {
    padding-top: 10PX;
    padding-bottom: 10PX;
  }

  .section.tinted.white.open-modal {
    padding-bottom: 20PX;
  }

  .section.tinted.grey {
    margin-bottom: 0PX;
    padding-top: 10PX;
    padding-bottom: 0PX;
  }

  .text-block-4 {
    margin-right: 0PX;
    font-size: 16PX;
  }

  .h1 {
    margin-bottom: 10PX;
    font-size: 28PX;
  }

  .div-block-2 {
    margin-bottom: 15PX;
  }

  .text-block-1 {
    font-size: 14PX;
  }

  .text-block-5---subtitle {
    margin-bottom: 0PX;
    font-size: 12PX;
    line-height: 16PX;
  }

  .current-status {
    font-size: 14PX;
  }

  .current-opening-hours {
    font-size: 14PX;
  }

  .row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 0PX;
    margin-bottom: 20PX;
    margin-left: 0PX;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .col.col-sm-1 {
    max-width: none;
    min-width: auto;
    margin-bottom: 0PX;
    padding-right: 10PX;
    padding-left: 0PX;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .col.col-sm-11 {
    max-width: none;
    min-width: auto;
    padding-right: 0PX;
    padding-left: 10PX;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .booking-button {
    height: 50PX;
    border-radius: 4PX;
  }

  .text-block-3 {
    font-size: 18PX;
  }

  .h3 {
    margin-bottom: 0PX;
    font-size: 16PX;
  }

  .h3.non-selected {
    font-size: 16PX;
  }

  .div-block-66 {
    width: auto;
  }

  .tabs-menu {
    display: block;
    width: auto;
    padding-right: 25PX;
    padding-left: 25PX;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  .tab-link-tab-1 {
    padding-right: 20PX;
  }

  .tab-link-tab-1.w--current {
    padding-right: 20PX;
  }

  .tab-link-tab-2 {
    padding-right: 20PX;
    padding-left: 20PX;
  }

  .tab-link-tab-2.w--current {
    padding-right: 20PX;
    padding-left: 20PX;
  }

  .tab-link-tab-1.w--current {
    padding-right: 20PX;
  }

  .tab-link-tab-1 {
    padding-right: 20PX;
  }

  .heading-5 {
    font-size: 18PX;
  }

  .text-block-5 {
    margin-bottom: 5PX;
    font-size: 16PX;
  }

  .div-12 {
    margin-bottom: 0PX;
    padding-top: 20PX;
    padding-bottom: 20PX;
  }

  .div-12.last {
    margin-bottom: 0PX;
    padding-bottom: 20PX;
  }

  .heading-3 {
    margin-bottom: 10PX;
    font-size: 18PX;
  }

  .paragraph-2 {
    font-size: 14PX;
  }

  .text-block-6 {
    font-size: 14PX;
  }

  .modal-wrapper {
    display: none;
    padding: 0PX;
  }

  .modal-wrapper-show {
    display: flex;
  }

  .modal-body {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    border-radius: 0PX;
  }

  .item-image-pop-up {
    z-index: auto;
  }

  .food-description {
    z-index: 999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0PX;
    padding-bottom: 0PX;
    padding-left: 0PX;
    border-radius: 0PX;
  }
  .div-block-69 {
    padding-right: 20PX;
    padding-left: 20PX;
  }
  // .q-dec {
  //   width: 40PX;
  //   height: 40PX;
  // }

  .q-num {
    font-size: 15PX;
  }

  // .q-inc {
  //   width: 40PX;
  //   height: 40PX;
  // }
  .text-block-12 {
    font-size: 14PX;
  }

  .add-to-cart-button {
    border-radius: 8PX;
  }
  
  .opening-hours {
    width: 100vw;
    margin-bottom: 0px;
    border-radius: 0px;
    box-shadow: none;
  
  }
  .screen.opening-hours {
    position: absolute;
    bottom: 0;
  }
  .heading-3.procurement {
    font-size: 20PX;
  }
  .text-block-2 {
    font-size: 14PX;
  }
    
}

@media screen and (max-width: 479PX) {
  .bm-p-menu-list {
    .menu-w-container {
      max-width: none;
    }
    .dining-style {
      max-height: 50PX;
      min-height: 50PX;
    }
    .menu-panel {
      .q-inc,
      .q-dec {
        width: 32PX;
        height: 32PX;
        margin-right: 5PX;
      }
    }
    .submenus-title-wrapper {
      .submenus-title {
        font-size: 12PX;
        padding-left: 12PX;
      }
    }
  } 
  .div-block-77 {
    margin-top: calc(var(--bm-safe-area-top) + 17PX);
    margin-right: 17PX;
    margin-left: 14PX;
  }
  .div-block-7 {
    top: 7PX;
  }
  .w-container {
    max-width: none;
  }
  .header-image {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 215PX;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .nav-button-main {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
  }

  .container-horizontal {
    max-width: 100vw;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .container-horizontal.right-side {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .section {
    padding: 15PX 12PX;
  }

  .section.tinted {
    margin-top: 10PX;
  }

  .section.tinted.white {
    display: block;
    margin-top: 0PX;
    margin-bottom: 0PX;
    padding: 0PX 12PX;
  }

  .section.tinted.white.open-modal {
    padding-top: 10PX;
    padding-bottom: 10PX;
  }

  .section.tinted.grey {
    margin-top: 0PX;
    margin-bottom: 0PX;
    padding: 10PX 12PX;
  }

  .section.cms {
    padding-right: 12PX;
    padding-left: 12PX;
  }

  .text-block-4 {
    margin-right: 5PX;
    padding-right: 0PX;
    padding-left: 5PX;
    font-size: 14PX;
  }

  .text-block-4.with-top-padding {
    margin-right: 5PX;
    font-size: 14PX;
  }

  .container {
    margin-top: 0PX;
    margin-bottom: 0PX;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .h1 {
    margin-top: 0PX;
    margin-bottom: 15PX;
    font-size: 22PX;
    line-height: 27PX;
  }

  .div-block-2 {
    margin-bottom: 15PX;
  }

  .text-block-1 {
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-size: 12PX;
  }

  .text-block-5---subtitle {
    font-size: 12PX;
    line-height: 16PX;
  }

  .line-separator {
    margin-right: 10PX;
    margin-left: 10PX;
  }

  .current-status {
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-size: 12PX;
  }

  .current-opening-hours {
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-size: 12PX;
    font-weight: 400;
  }

  .row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 10PX 0PX;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .col {
    max-width: 100%;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .col.col-sm-1 {
    min-width: auto;
    padding-right: 5PX;
    padding-left: 0PX;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .col.col-sm-11 {
    min-width: auto;
    padding-right: 0PX;
    padding-left: 5PX;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .booking-button {
    height: 38PX;
    margin-top: 0PX;
    border-radius: 4PX;
  }

  .text-block-3 {
    font-size: 14PX;
  }

  .h3 {
    margin-bottom: 10PX;
    font-size: 16PX;
  }

  .div-block-66 {
    width: auto;
  }

  .tabs-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    padding-left: 12PX;
    padding-right: 0;
  }

  .div-block-66 {
    width: auto;
  }

  .tab-link-tab-1 {
    padding-right: 10PX;
  }

  .tab-link-tab-1.w--current {
    padding-right: 10PX;
  }

  .tab-link-tab-2 {
    padding-right: 10PX;
    padding-left: 10PX;
  }

  .tab-link-tab-2.w--current {
    padding-right: 10PX;
    padding-left: 10PX;
  }

  .tab-link-tab-1.w--current {
    padding-right: 10PX;
  }

  .tab-link-tab-1 {
    padding-right: 10PX;
  }

  .heading-5 {
    font-size: 16PX;
  }

  .div-block-4 {
    max-width: 100%;
    padding-top: 10PX;
  }

  .text-block-5 {
    margin-bottom: 5PX;
    font-size: 14PX;
  }

  .div-12 {
    margin-bottom: 0PX;
    padding-top: 25PX;
    padding-bottom: 25PX;
  }

  .div-12.last {
    border-bottom-width: 0PX;
  }

  .item-info-wrapper {
    max-width: 73%;
    min-width: auto;
  }

  .item-info {
    overflow: visible;
    max-height: none;
    max-width: unset;
  }

  .heading-3 {
    margin-top: 0PX;
    margin-bottom: 7PX;
    font-size: 14PX;
    line-height: 16PX;
  }

  .paragraph-2 {
    margin-bottom: 5PX;
    font-size: 12PX;
    line-height: 14PX;
  }

  .text-block-6 {
    font-size: 14PX;
  }
  .image-open {
    width: 72PX;
    height: 72PX;
    min-width: auto;
  }

  .modal-wrapper {
    display: none;
    height: 100%;
    padding: 0PX;
  }
  .modal-wrapper-show {
    display: flex;
  }

  .modal-body {
    width: 100%;
    height: 100%;
    min-height: auto;
    min-width: auto;
    padding: 0 0PX 0PX;
    border-radius: 0PX;
  }
  .modal-body.modal-wrapper-show {
    padding-bottom: 50px;
  }

  .c-popup-close-time {
    display: block;
  }


  .div-block-69 {
    margin-right: 12PX;
    margin-left: 12PX;
    padding-right: 0PX;
    padding-bottom: 15PX;
    padding-left: 0PX;
  }

  .q-flex {
    margin-right: 10PX;
  }

  // .q-dec {
  //   width: 32PX;
  //   height: 32PX;
  //   margin-right: 5PX;
  // }
  .q-num {
    width: 30PX;
    padding-right: 0PX;
    padding-left: 0PX;
    font-size: 14PX;
  }

  // .q-inc {
  //   width: 32PX;
  //   height: 32PX;
  //   margin-left: 5PX;
  // }
  .text-block-12 {
    font-size: 14PX;
  }

  .add-to-cart-button {
    height: 45PX;
  }

  .container-stretched-copy {
    max-height: 50PX;
    min-height: 50PX;
    margin-right: 16PX;
    margin-left: 17PX;
  }
  .nav-button-1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
  }

  .heading-3.procurement {
    margin-top: 20PX;
    margin-bottom: 10PX;
  }
  .opening-hours {
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    width: 100vw;
    height: auto;
    min-height: auto;
    min-width: 100vw;
    margin-top: auto;
    padding: 20PX 12PX 15PX;
  }
  .text-block-2 {
    font-size: 12PX;
    line-height: 18PX;
  }
  .inventory-options {
    font-size: 14PX;
  }
  .add-express-order {
    margin-right: 12PX;
    margin-left: 12PX;
    margin-top: 20PX;
  }
  .product-name-wrap,
  .product-price-wrap {
    font-size: 14PX;
    input {
      font-size: 14PX;
    }
    .product-name {
      line-height: 20PX;
    }
    .am-list-item.am-input-item {
      height: 20PX;
    }
  }
  .add-item-wrap {
    font-size: 14PX;
    .bm-icon  {
      font-size: 14PX;
    }
  }
  .item-information-box {
    >div {
      margin: 20PX 12PX 0;
      font-size: 14PX;
    }
  }
}

.error-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  &-content {
    background-color: var(--bm-light-background-color);
    max-height: 640PX;
    border-radius: 12PX;
    width: 260PX;
    padding: 16PX;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .am-button {
      width: 100%;
      margin-top: 24px;
    }
    .msg {
      color:var(--bm-error-color);
      font-weight: 500;
      margin-top: 16PX;
      font-size: 18PX;
      margin-bottom: 12px;
      text-align: center;
      padding: 0 16PX;
      line-height: 24PX;
    }
  }
}

