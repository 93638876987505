@import '~bm-public.less';

.bm-p-my-stores {
  background-color: var(--bm-dark-background-color);

  .pc-header {
    height: calc(var(--bm-safe-area-top) + 88px);
  }

  .back-icon {
    color: var(--bm-text-title-color);
  }

  .page-content {
    margin-bottom: 196px;
  }

  &-nav {
    display: flex;
    align-items: center;
    height: 88px;
    background-color: var(--bm-light-background-color);
    justify-content: space-between;
    color: #fff;
    position: fixed;
    z-index: 9;
    left: 0;
    right: 0;
    border-bottom: 2px solid var(--bm-border-color);
    padding: 0 32px;
    padding-top: var(--bm-safe-area-top);

    &-title {
      color: var(--bm-text-title-color);
      font-size: 40px;
      font-weight: 600;
    }
  }

  .right-icon-location {
    color: var(--bm-text-title-color);
    width: 56px;
    height: 56px;
  }

  .right-icon-search {
    color: var(--bm-text-title-color);
    width: 44px;
    height: 44px;
  }

  .result {
    margin: 40px 32px 0 32px;
    display: flex;
    align-items: center;

    &-rectangle {
      height: 28px;
      width: 8px;
      background: var(--bm-primary-color);
      border-radius: 200px;
      margin-right: 16px;
    }

    &-data {
      font-weight: bold;
      font-size: 36px;
      line-height: 32px;
    }
  }

  .store-list {
    .store-item {
      margin: 32px 32px 0 32px;
      background-color: var(--bm-light-background-color);
      border-radius: 12px;

      .store-logo {
        height: 330px;
        background-size: cover;
        border-radius: 12px 12px 0 0;
        background-position: center center;
        background-repeat: no-repeat;
        position: relative;
        background-image: url(/images/default-store.png);

        .inactive {
          transform: rotate(-45deg);
          font-weight: 900;
          font-size: 20px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.4px;
          text-transform: capitalize;
          position: absolute;
          top: 30px;
          left: 8px;
          color: #fff;
        }

        .inactive-background {
          border: 60px solid;
          width: 0;
          height: 0;
          border-color: #fbac36 transparent transparent #fbac36;
          border-radius: 12px 0 0 0;
        }
      }

      .store-name {
        margin-left: 24px;
        font-weight: bold;
        font-size: 32px;
        line-height: 32px;
        padding-top: 22px;
      }

      .store-phone {
        color: var(--bm-text-secondary-color);
        font-size: 26px;
        line-height: 28px;
        display: flex;
        align-items: center;
        margin: 16px 0 0 24px;
      }

      .store-address {
        color: var(--bm-text-secondary-color);
        font-size: 26px;
        line-height: 28px;
        display: flex;
        align-items: flex-start;
        margin: 16px 24px 0 24px;
        padding-bottom: 24px;
      }

      .store-item-icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        padding: 0;
      }
    }
  }

  .bottom-botton {
    position: fixed;
    bottom: 0;
    .phone & {
      left: 0;
      right: 0;
    }
    z-index: 990;
    background-color: #fff;
    overflow: hidden;
    box-shadow: 0 0 10px 0 var(--bm-shadow-color);
    padding: 32px;

    .create-store-botton {
      padding: 34px 0;
      font-size: 32px;
      font-weight: bold;
      background-color: var(--bm-primary-color-tap);
      text-align: center;
      border-radius: 12px;
      color: var(--bm-light-background-color);
    }
  }
  
  .loading-data {
    display: flex;
    .font-size(32px);

    justify-content: center;
    align-items: center;
    padding: 32px;

    .loading-msg {
      margin-left: 12px;
    }

    .am-icon-loading {
      width: 32px;
      height: 32px;
    }
  }
}

.bm-p-search-nav {

  .pc-header {
    height: calc(var(--bm-safe-area-top) + 112px);
  }

  .am-navbar {
    height: 112px;
  }

  .store-list {
    margin-bottom: 32px;
  }

  &-nav {
    display: flex;
    align-items: center;
    height: 112px;
    background-color: var(--bm-light-background-color);
    color: #fff;
    position: fixed;
    z-index: 9;
    left: 0;
    right: 0;
  }

  &-input {
    margin: 20px 32px;
    width: 100%;

    .card-icon-close {
      font-size: 40px;
      color: var(--bm-text-color);
    }

    .am-input-item {
      height: 72px;
      border-radius: 12px;
      border: 1px solid var(--bm-border-color);
      width: 100%;

      .bm-icon {
        padding: 0;
      }
    }

    .am-list-item.am-input-item {
      background-color: var(--bm-dark-background-color);
      height: 72px;
      min-height: 72px;
    }

    .am-list-line {
      background-color: var(--bm-dark-background-color);
    }

    .error-msg {
      .font-size(18px);
      .font-family-s;

      color: var(--bm-error-color);
      margin-top: 16px;
    }

    .am-input-control input {
      .font-size(28px);
      .font-family-m;
    }

    .am-input-label {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .am-input-extra {
      margin-left: 29px;
      max-height: 40px;
    }

    .am-list-item .am-input-label.am-input-label-5 {
      width: auto;
    }
  }

  &-button {
    margin: 32px;
    width: 84px;
    color: var(--bm-primary-color);
  }

  &-inputextra-icon {
    font-size: 36px;
    color: var(--bm-text-secondary-color);
  }
}
