#root.web {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;

  .root-space {
    display: flex;
    flex: 1;
    z-index: 100;
    background-color: #000000;
  }

  .router-wrapper {
    position: relative;
    // width: 1920px;
    width: 100%;
    
  
    & .bm-c-layout {
      bottom: 0;
      overflow: auto;
    }
  
    .bm-pm-footer,
    .bm-p-search-nav,
    .no-content-btns,
    .bottom-botton,
    .menu-nav.menu-fixed,
    .bm-p-stores-by-category-types,
    .bm-store-service-types-box,
    .bm-c-service-types,
    .navbar-buttom {
      // width: 1920px;
      width: 100%;
    }
    .bm-c-tab-bar,
    .bm-c-shopping-cart-bar,
    .confirm-pay,
    .bm-c-splash,
    .bm-navbar-fixed,
    .navbar-buttom,
    .bm-c-select-stores-title,
    .bm-c-store-filter-modal,
    .checkout-btn-wrapper {
      width: 100%;
      // max-width: 1920px;
    }
    .splash-slider {
      .slider-list {
        width: 100% !important;
      }
      .slider-slide {
        width: 100% !important;
      }
    }
  }
  // .bm-c-shopping-cart-bar-wrap,
  // .confirm-pay-box,
  // .bm-c-tab-bar,
  // .btns-wrapper,
  // .country-code-wrap {
  //   max-width: 1920px;
  // }
  .bm-app-page-loading {
    // width: 1920px;
    width: 100%;
    left: auto;
    right: auto
  }
  #bm-store-service-types-box {
    margin: auto;
    // width: 1920px;
    width: 100%;
  }
  .bm-p-search {
    .bm-p-search-nav {
      margin: auto;
    }
  }
  .bm-p-express-order.web {
    .confirm-pay.has-tab-bar {
      max-width: 24.42rem;
      box-sizing: content-box;
    }
  }
}

.bm-c-modal-popup-wrapper.web {
  display: flex;
  justify-content: center;
  align-items: center;
  .am-modal-popup {
    // max-width: 1920px;
    left: auto;
  }
}
.navbar-buttom {
  margin: auto;
}
.listing-action-wrap {
  width: 100%;
  // max-width: 1920px;
  box-sizing: border-box;
}
.am-picker-popup-wrap  {
  // max-width: 1920px;
  margin: auto;
}
