@import '~bm-public.less';
.bm-c-qrcode-scan {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: #000;

  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .scan-bar {
    position: absolute;
    top: calc(var(--bm-safe-area-top) + 0);
    height: 88px;
    top: var(--bm-safe-area-top);
    color: #fff;
    z-index: 1;
    display: flex;
    justify-content: center;

    .bm-icon {
      margin-left: 16px;
    }
  }
}
