.bm-c-pay-card {
  .card-icon {
    font-size: 36px;
  }

  .card-icon-close {
    font-size: 40px;
    color: var(--bm-text-color);
  }

  .card-icon-eye {
    font-size: 40px;
    color: var(--bm-primary-color);
  }

  .cc-column {
    display: flex;
  }

  .cc-exp-date,
  .cc-cvv {
    flex: 1;
  }

  .cc-cvv {
    margin-left: 32px;
  }

  .confirm-bindo-cc {
    display: flex;
    align-items: center;

    &-icon {
      font-size: 40px;

      &.selected {
        color: var(--bm-primary-color);
      }
    }

    &-text {
      color: var(--bm-text-color);
      margin-left: 18px;
    }
  }
  .bm-c-data-entry  {
    .am-list-item {
      margin-bottom: 20PX;
    }
  }
  .bm-c-data-entry {
    .dew-title {
      margin-bottom: 10PX;
      margin-left: 0px;
      font-family: 'Fontsfree net sfprodisplay', sans-serif;
      font-size: 18PX;
      font-weight: 400;
      text-align: left;
    }
  }
  .bm-c-data-entry {
    .am-list-item.am-input-item {
      padding: 0;
    }
  }
  .am-list-line {
    margin-left: 15PX;
    .bm-icon  {
      width: 30PX;
      margin-right: 5PX;
    }
    .am-input-control input {
      padding-left: 12PX;
      font-size: 16PX;
      font-weight: 400;
    }
  }
  .bm-c-data-entry  {
    .am-list-item {
      height: 60PX;
    }
  }
  .cc-column {
    display: block;
  }
  .bm-c-data-entry.cc-cvv {
    margin: 0;
  }
  .confirm-bindo-cc {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 40PX;
    padding-left: 0px;
  }
  .confirm-bindo-cc-icon { 
    width: 20PX;
    height: 20PX;
    margin-top: 0px;
    margin-right: 10PX;
    margin-left: 0px;
    font-size: unset;
  }
  .confirm-bindo-cc-text {
    margin-bottom: 0px;
    font-family: 'Sf pro display', sans-serif;
    font-size: 16PX;
    font-weight: 600;
  }
  .am-list-line .am-input-control input::-webkit-input-placeholder {
    font-size: 16PX;
  }
  .am-input-extra {
    .close-outlined {
      width: 30PX;
      height: 30PX;
    }
  }
}
@media screen and (max-width: 767px) {
  .am-list-line .am-input-control input {
    font-size: 14px;
  }
  .am-input-extra {
    .close-outlined {
      width: 25PX;
      height: 25PX;
    }
  }

}

@media screen and (max-width: 479px) {
  .bm-c-pay-card {
    .am-list-line {
      .bm-icon {
        margin-right: 5px;
      }
    }
    .am-list-line {
      .am-input-control input {
        padding-left: 10PX;
      }
    }
    .bm-c-data-entry  {
      .am-list-item {
        height: 50PX;
      }
    }
    .confirm-bindo-cc {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }
    .selected-outline {
      min-height: 20PX;
      min-width: 20PX;
    }
    .confirm-bindo-cc-text {
      font-size: 14PX;
    }
    .am-input-extra {
      .close-outlined {
        width: 20PX;
        height: 20PX;
      }
    }
  }
}

