@import '~bm-public.less';

.bm-p-orders {
  background-color: var(--bm-dark-background-color);

  .pc-header {
    height: calc(var(--bm-safe-area-top) + 180px);
  }

  .pc-footer {
    height: 152px;
  }

  .order-item {
    margin: 32px;
    padding: 26px 32px 32px 32px;
    background-color: var(--bm-light-background-color);
    border-radius: 12px;
    color: var(--bm-text-color);

    &:last-child {
      margin-bottom: 0;
    }

    &-top {
      display: flex;
      align-items: center;
      height: 48px;
      padding-bottom: 10px;

      .order-item-shipping-method {
        height: 48px;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--bm-primary-color-t10);
        border-radius: 50%;

        .img {
          width: 32px;
          height: 32px;
          color: var(--bm-primary-color);
        }
      }

      .title {
        margin-left: 20px;
        font-weight: bold;
        align-items: center;
        line-height: 36px;

        &.store {
          font-size: 32px;
        }

        &.platform {
          font-size: 30px;
        }
      }

      .order-state {
        font-size: 28px;
        line-height: 32px;
        align-items: center;
        flex-grow: 1;
        text-align: right;
        color: var(--bm-primary-color);
        white-space: nowrap;
      }
    }

    .store-logo {
      width: 104px;
      height: 104px;
      display: flex;
      margin-right: 24px;
      align-items: center;
      border-radius: 12px;
      background-image: url(/images/default-store.png);

      .image {
        width: 104px;
        height: 104px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 12px;
      }
    }

    &-contemt-single {
      padding-top: 32px;
      margin-bottom: 12px;
      display: flex;

      .order-item-contemt-branch-state {
        flex-grow: 1;
        text-align: right;
        font-weight: 500;
        font-size: 28px;
        line-height: 32px;
        color: var(--bm-text-title-color);
        opacity: 0.5;
      }

      .order-item-contemt-branch-title {
        font-size: 28px;
        line-height: 32px;
        color: var(--bm-text-title-color);
        font-weight: 600;
      }

      .order-item-contemt-branch-reference-id {
        margin: 8px 0 4px 0;
        font-size: 26px;
        line-height: 30px;
      }

      .order-item-contemt-single-time {
        font-size: 26px;
        line-height: 30px;
      }

      .order-item-contemt-single-reference-id {
        margin: 8px 0;
        font-size: 26px;
        line-height: 30px;
      }

      .order-item-contemt-total {
        font-size: 26px;
        line-height: 30px;
      }
    }

    &-contemt-branch {
      display: flex;
      padding-top: 32px;
      padding-bottom: 16px;

      .order-item-contemt-branch-state {
        flex-grow: 1;
        text-align: right;
        font-weight: 500;
        font-size: 28px;
        line-height: 32px;
        color: var(--bm-text-title-color);
        opacity: 0.5;
      }

      .order-item-contemt-branch-title {
        font-size: 28px;
        line-height: 32px;
        color: var(--bm-text-title-color);
        font-weight: 600;
      }

      .order-item-contemt-branch-reference-id {
        margin: 8px 0 4px 0;
        font-size: 26px;
        line-height: 30px;
      }

      .order-item-contemt-time {
        padding-top: 28px;
        font-size: 26px;
        line-height: 30px;
      }

      .order-item-contemt-branch-total {
        font-size: 26px;
        line-height: 30px;
      }
    }

    .order-item-buttom {
      display: flex;
      text-align: right;
      justify-content: flex-end;
      padding-top: 32px;

      .button {
        background: var(--bm-primary-color);
        color: var(--bm-light-background-color);
        height: 60px;
        width: 176px;
        font-size: 24px;
        line-height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
      }
    }

    .hr {
      margin: 16px 0 0 0;
      border-top: 1px solid var(--bm-border-color);
    }

    .order-item-contemt-time {
      padding-top: 28px;
      font-size: 26px;
      line-height: 30px;
    }

    .order-item-contemt-reference-id {
      padding: 8px 0 12px 0;
      font-size: 26px;
      line-height: 30px;
    }
  }

  .loading-data {
    display: flex;
    .font-size(32px);

    justify-content: center;
    align-items: center;
    padding: 32px;

    .loading-msg {
      margin-left: 12px;
    }

    .am-icon-loading {
      width: 32px;
      height: 32px;
    }
  }

  .page-content {
    padding-bottom: 32px;
  }

  .navbar-top {
    font-size: 40px;
    line-height: 44px;
    color: var(--bm-light-background-color);
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bm-primary-color);
  }

  .navbar-buttom {
    position: fixed;
    top: calc(var(--bm-safe-area-top) + 88px);
    height: 92px;
    padding: 0 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--bm-light-background-color);
    width: 100%;
    box-sizing: border-box;

    .order-type {
      padding: 8px 20px;
      font-weight: bold;
      font-size: 28px;
      line-height: 32px;

      &.selected {
        .font-family-b;
        .p-b-color;

        color: var(--bm-light-background-color);
        border-radius: 800px;
      }
    }
  }

  .no-orders {
    margin-top: 204px;
    display: flex;
    align-items: center;
    justify-content: center;

    .img {
      text-align: center;

      svg {
        height: 300px;
        width: 300px;
      }
    }

    .no-order {
      margin-top: 20px;
      font-weight: bold;
      font-size: 32px;
      line-height: 36px;
      color: var(--bm-text-color);
      text-align: center;
    }

    .no-order-tip {
      margin-top: 16px;
      font-weight: 500;
      font-size: 28px;
      line-height: 40px;
      text-align: center;
      color: var(--bm-text-secondary-color);
    }
  }
}
