@import '~bm-public.less';

.bm-p-pay-bill {
  .dark-bc;

  // .pc-header {
  //   height: calc(var(--bm-safe-area-top) + 88px);
  //   background-color: var(--bm-primary-color);
  // }

  .pc-footer {
    background-color: var(--bm-light-background-color);
  }

  .page-content {
    .dark-bc;
  }

  .error-modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    &-content {
      background-color: var(--bm-light-background-color);
      max-height: 640PX;
      border-radius: 12PX;
      width: 260PX;
      padding: 16PX;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .am-button {
        width: 100%;
        margin-top: 24px;
      }
      .msg {
        color:var(--bm-error-color);
        font-weight: 500;
        margin-top: 16PX;
        font-size: 18PX;
        margin-bottom: 12px;
        text-align: center;
        padding: 0 16PX;
        line-height: 24PX;
      }
      &-icon {
        max-width: 120px;
        max-height: 120px;
        overflow: hidden;
      }
    }
  }

  &-content {
    height: 100%;
    // background-color: var(--bm-dark-background-color);

    &-header {
      background-color: var(--bm-light-background-color);
      padding-top: 40px;
      border-bottom: 0.05333rem;
      border-bottom: 4px solid #e6e9ea;
      padding-bottom: 0.7rem;
    }

    &-amount {
      display: flex;
      height: 80px;
      align-items: center;
      background-color: var(--bm-dark-background-color);
      padding-left: 0.85334rem;
      font-size: 32px;
    }
    .store-box {
      padding-left: 0.42667rem;
      margin-left: 0.42667rem;
    }
    .store-title {
      .font-size(32px);
      font-weight: 700;
      line-height: 64px;
    }
    .store-address {
      .font-size(24px);
      color: var(--bm-text-secondary-color);
      margin-bottom: 36px;
      margin-top: 10px;
    }
    .payInfo-box {
      background-color: #fff;
      margin-top: -30px;
    }
    .bill-reference {
      margin-top: 20px;
      padding: 40px 60px 40px 40px;
      padding-left: 0.85334rem;
      font-weight: 600;
      background-color: #fff;
    }

    &-price-wapper {
      margin: 0.7rem 32px auto;
      display: flex;
      flex-direction: row;

      &-tag {
        .font-size(36px);
        margin-top: 0.38rem;
        margin-left: 32px;
        margin-right: 0.30rem;
      }

      .am-list-item.am-input-item {
        padding-left: 0;
        margin-top: 0.3rem;
      }

      .am-list-item {
        border: none;
        background-color: var(--bm-light-background-colorr);
        padding-left: 0;
      }
      .am-list-item .am-list-line .am-input-control input {
        font-size: 0.75rem;
      }
      .am-list-item .am-list-line .am-input-control input::placeholder {
        font-size: 0.45rem;
        padding-left: 10px;
        // line-height: 0.9rem;
      }
    }
    &-pay-info-wapper {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &-paynow {
    margin: 32px;
    height: 80px;
    border-radius: 8px;
  }

  .co-checking-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 8900;
    background-color: transparent;

    .co-checking {
      width: 216px;
      height: 216px;
      border-radius: 12px;
      background-color: var(--bm-loading-background);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .co-checking-loading {
      width: 60px;
      height: 60px;

      .am-icon-loading {
        width: 100%;
        height: 100%;
      }
    }

    .co-checking-text {
      .font-size(32px);
      text-align: center;

      margin-top: 24px;
      color: var(--bm-light-background-color);
    }
  }


  &-skeleton {
    // padding-top: 88px;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 999;
    .skeleton-content {
      background-color: #f7f7f7;
      overflow: hidden;
      height: 100%;
    }
    .content-box1 {
      padding: 45px 56px 30px 32px;
      box-sizing: border-box;
      background-color: #fff;
      div {
        width: 100%;
        .skeleton-color;
        margin-bottom: 10px;
        height: 28px;
      }
      .content-box2-div1 {
        width: 78%;
        height: 42px;
        margin-bottom: 20px;
      }
    }
    .content-box2 {
      padding: 25px 0 22px 32px;
      background-color: #f5f7f8;
      div {
        width: 32%;
        height: 40px;
        background-color: #fff;
      }
    }
    .content-box3 {
      padding: 46px 0 30px 32px;
      background-color: #fff;
      border-bottom: 4px solid #e6e9ea;
      display: flex;
      div {
        .skeleton-color;
      }
    }
    .content-box3-left {
      margin-right: 12px;
      height: 44px;
      width: 5%;
    }
    .content-box3-right {
      height: 32px;
      width: 64%;
      height: 86px;
    }
    .content-box4 {
      padding: 32px;
      margin-top: 16px;
      background-color: #fff;
      box-sizing: border-box;
    }
    .content-box4-div1 {
      border-bottom: 2px solid  #f5f7f8;
      padding-bottom: 24px;
      div {
      .skeleton-color;
        width: 110px;
        height: 24px;
      }
    }
    .content-box4-div2 {
      display: flex;
      justify-content: space-between;
      padding-top: 24px;
    }
    .content-box4-div2-left {
      width: 45%;
      display: flex;
      div {
        height: 30px;
        .skeleton-color;
      }
    }
    .content-div1-left {
      width: 16%;
      margin-right: 18px;
    }
    .content-div1-right {
      width: 66%;
    }
    .content-box4-div2-right {
      width: 30%;
      .skeleton-color;
    }
    .content-box5 {
      padding: 34px 36px 22px 32px;
      display: flex;
      justify-content: space-between;
      margin-top: 26px;
      background-color: #fff;
      div {
        height: 30px;
        .skeleton-color;
      }
    }
    .content-box5-left {
      width: 46%;
    }
    .content-box5-right {
      width: 7%;
    }
  }
  .status-failure {
    margin-top: 1.2rem;
  }
  .status-failure svg {
    height:  3.65333rem;
    width: 9.8667rem;
  }
  .icon-bottom {
    text-align: center;
    margin-top: 0.2rem;
  }
}
.web {
  .status-failure {
    width: 100%;
    svg {
      margin: auto;
    }
  }
}