@import '~bm-public.less';

.bm-p-return-note-detail {
  .dark-bc;

  .pc-header {
    height: var(--bm-safe-area-top);
  }

  .back-icon {
    color: var(--bm-light-background-color);
  }

  .am-navbar {
    .am-navbar-title {
      color: var(--bm-light-background-color);
      .font-size(40px);
    }
  }

  .return-note-detail {
    &-data {
      position: relative;
      z-index: 0;
  
      .style-b-color {
        position: absolute;
        top: 0;
        width: 100%;
        height: 64px;
        z-index: 1;
        .p-b-color;
      }
  
      &-tab {
        position: relative;
        z-index: 2;
        flex: 1;
        margin: 0px 60px 28px 50px;
        padding: 36px 34px 22px 32px;
        border-radius: 12px;
        background-color: var(--bm-light-background-color);
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.18);

        &-number-and-state {
          display: flex;
          justify-content: space-between;
          padding-bottom: 16px;
          margin-bottom: 30px;
          align-items: center;
          border-bottom: 2px solid rgba(235, 239, 242, 0.5);
        }
  
        &-number {
          font-weight: 600;
          font-size: 28px;
          line-height: 32px;
          color: var(--bm-text-title-color);
        }
  
        &-information-line {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 16px;
          line-height: 32px;
  
          &-title {
            color: var(--bm-skeleton-color);
            font-size: 24px;
          }
  
          &-data {
            color: var(--bm-text-title-color);
            font-size: 26px;

            &.deferred {
              border-radius: 8px;
              border: 1px solid var(--bm-error-color);
              padding: 2px 16px;
              color: var(--bm-error-color);
            }

            &.canceled {
              border-radius: 8px;
              padding: 2px 16px;
              color: var(--bm-skeleton-color-t30);
              border: 1px solid var(--bm-skeleton-color-t30);
            }

            &.received {
              border-radius: 8px;
              padding: 2px 16px;
              color: var(--bm-tag-delivery-color);
              border: 1px solid var(--bm-tag-delivery-color);
            }
          }
        }
      }
    }

    &-line-item {
      display: flex;
      align-items: center;
      padding-bottom: 40px;
      border-bottom: 2px solid rgba(235, 239, 242, 0.5);

      &-img {
        width: 140px;
        height: 140px;
        margin-right: 20px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px;
        background-image: url(/images/default-line-item.png);
      }

      &-info {
        flex: 1;

        &-line {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: var(--bm-text-title-color);

          &-name {
            flex: 1;
            font-weight: bold;
            font-size: 28px;
            line-height: 32px;
            margin-bottom: 12px;
          }

          &-quantity {
            font-size: 28px;
            line-height: 28px;
            margin-bottom: 12px;
          }

          &-code {
            margin-bottom: 36px;
            font-size: 24px;
            line-height: 28px;
            color: var(--bm-text-secondary-color);
          }

          &-price {
            font-size: 24px;
            line-height: 28px;
            color: var(--bm-text-secondary-color-t60);
          }

          &-total {
            font-size: 28px;
            line-height: 36px;
          }
        }
      }

      &-card {
        margin: 0px 54px 48px 54px;
        padding: 40px 26px 30px 26px;
        border-radius: 12px;
        box-shadow: 0px 4px 10px rgba(60, 80, 111, 0.05);
        background-color: var(--bm-light-background-color);

        &-top-title {
          font-weight: 600;
          font-size: 32px;
          line-height: 32px;
          color: var(--bm-text-title-color);
          margin-bottom: 30px;
          border-bottom: 2px solid var(--bm-border-color-t60);
          padding-bottom: 32px;
        }

        &-information-line {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 16px;
          font-size: 28px;
          line-height: 32px;
          color: var(--bm-text-title-color);

          &.bold {
            font-weight: bold;
          }
          
          &.dividing {
            margin-top: 32px;
            padding-top: 24px;
            border-top: 1px solid rgba(235, 239, 242, 0.5);
          }
        }
      }
    }

    &-remarks {
      &-title {
        font-weight: bold;
        font-size: 28px;
        line-height: 32px;
        color: var(--bm-text-title-color);
        margin-top: 30px;
        margin-bottom: 28px;
      }

      &-data {
        padding-bottom: 32px;
        font-size: 28px;
        line-height: 32px;
        color: var(--bm-text-secondary-color);
      }
    }

    &-bottom {
      background-color: var(--bm-light-background-color);
      padding: 36px 56px;
      display: flex;
    }

    &-button {
      font-size: 40px;
      line-height: 44px;
      font-weight: bold;
      border-radius: 12px;
      flex: 1;
      display: flex;
      justify-content: center;
      padding: 31px 0;

      &.cancel-button {
        border: 2px solid #828282;
        color: #828282;
      }

      &.receive-button {
        margin-left: 27px;
        background-color: var(--bm-primary-color);
        color: var(--bm-light-background-color);
      }
    }
  }
}