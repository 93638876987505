@import '~bm-public.less';

.bm-c-listings-panel {
  .animation {
    overflow: hidden;
    transition: max-height .2s;
    animation-timing-function: ease-in;
  }
  &-actions {
    &-share {
      .bm-icon {
        background-color: var(--bm-light-background-color);
        font-size: 32PX;
        padding: 0;
      }
    }

    &-remove {
      .bm-icon {
        background-color: var(--bm-light-background-color);
        padding: 0;
        font-size: 32PX;
      }
    }
  }

  .payment-method-wrap {
    display: flex;
    color: var(--bm-text-title-color);
    padding: 20PX 0;
    .title {
      margin: 0;
    }
    .payment-method-item {
      text-align: right;
      flex: 1;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .order-block {
    margin-bottom: 20PX;
    padding: 20PX;
    border-radius: 8PX;
    background-color: var(--bm-light-background-color);
    box-shadow: 0 0 10PX 0 #b6b6b6;
  }
  .check-out-info.restaurant-info {
    padding: 20PX 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .check-out-info {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 20PX 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 0px 0px 1PX;
    border-color: var(--bm-border-color-t60);
  }
  .delivery {
    border-bottom: 1PX solid var(--bm-border-color-t60);
  }
  .text-block-67.bold {
    margin-bottom: 0px;
    font-family: 'Sf pro display', sans-serif;
    font-weight: 600;
    text-transform: none;
  }
  .text-block-67 {
    margin-bottom: 0px;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-size: 18PX;
    font-weight: 400;
  }
  .div-block-487 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: auto;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    color: var(--bm-primary-color);
    cursor: pointer;
    .bm-icon {
      color: var(--bm-text-secondary-color);
    }
  }
  .text-block-69 {
    margin-right: 0px;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-size: 16PX;
  }
  .html-embed-34.rotated {
    width: 28PX;
    height: 24PX;
    margin-right: 0px;
    padding-left: 0;
  }
  .html-embed-34 {
    position: static;
    left: auto;
    top: auto;
    right: 0%;
    bottom: auto;
    width: 8PX;
    height: auto;
    margin-right: 0px;
    margin-left: 10PX;
  }
  .div-block-501.no-line {
    border-bottom-width: 0px;
  }
  .div-block-501 {
    margin-right: 0px;
    margin-left: 0px;
    border-bottom-width: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 15PX 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1PX solid #ebeff2;
    font-family: 'Sf pro display', sans-serif;
    color: var(--bm-text-title-color);
  }
  .html-embed-38 {
    width: 31PX;
    height: 34PX;
    margin-right: 10PX;
  }
  .tinted-background {
    margin: 0px -20PX 20PX;
    padding: 0px 20PX;
    background-color: var(--bm-input-backgroud-color);
    >div:last-child {
      border-bottom: 0;
    }
  }
  .div-block-508 {
    padding-top: 18PX;
    padding-bottom: 18PX;
  }
  .order-block {
    .div-block-482 {
      border-bottom: none;
    }
    .listings-item {
      padding: 0;
    }
  }
  .div-block-482 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    // margin-bottom: 20PX;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .div-block-482>div:last-child {
    flex: 1;
  }
  .div-block-481 {
    width: 120PX;
    height: 120PX;
    min-height: 120PX;
    min-width: 120PX;
    margin-right: 20PX;
    border-radius: 12PX;
    background-position: 50% 50%;
    background-size: cover;
  }
  .item-info-wrapper.cart-process {
    max-width: 100%;
    min-width: 100%;
    // height: 120PX;
  }
  .price-cart {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .q-flex-cart {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: auto;
    min-width: auto;
    margin-right: 0px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    cursor: pointer;
  }
  .q-dec-cart,
  .q-inc-cart {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 30PX;
    height: 30PX;
    margin-right: 10PX;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    // border-style: solid;
    // border-width: 1px;
    // border-radius: 60PX;
    color: var(--bm-primary-color-t30);
  }
  .q-inc-cart {
    color: var(--bm-primary-color);
    margin-left: 10PX;
    margin-right: 0;
  }
  .count {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50PX;
    height: 45PX;
    margin-bottom: 0px;
    padding-right: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-width: 0px;
    background-color: transparent;
    font-family: 'Sf pro display', sans-serif;
    font-size: 18PX;
    font-weight: 600;
    text-align: center;
  }
  .am-list-item.am-input-item {
    background-color: var(--bm-light-background-color);
    padding: 0;
    border: hidden;
  }
  .am-list-item .am-list-line {
    padding: 0;
    input {
      text-align: center;
    }
  }
  .time-modal .am-list-item .am-list-line {
    padding-left: 30px;
    input {
      text-align: start;
    }
  }
  .am-list-line .am-input-control .input {
    font-size: 18PX;
  }
  .text-block-6 {
    max-width: none;
    margin-top: 0px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    font-family: 'Sf pro display', sans-serif;
    font-size: 16PX;
    font-weight: 600;
    color: var(--bm-text-title-color);
  }
  .check-out-info.delivery {
    margin-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .div-block-483 {
    width: 100%;
    height: 1PX;
    margin-top: 30PX;
    margin-bottom: 0px;
    background-color: var(--bm-border-color-t60);
  }
  .remark-block-tablet {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: auto;
    max-width: 100%;
    min-width: 100%;
    margin-right: 0px;
    margin-bottom: 0px;
    padding: 13PX;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 12PX;
    background-color: var(--bm-light-background-color);
    box-shadow: none;
    box-sizing: border-box;
  }
  .text-block-90 {
    margin-bottom: 10PX;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-title-color);
    font-size: 18PX;
    font-weight: 700;
  }
  .am-textarea-control {
    height: 100%;
    padding-right: 38PX;
  }
  .am-textarea-control textarea {
    font-size: 16PX;
    padding-top: unset;
    height: 100%;
  }
  .am-textarea-count {
    font-size: 16PX;
    height: 100%;
    top: 16PX;
  }
  .div-block-493 {
    padding-top: 20PX;
    padding-bottom: 20PX;
    // border-bottom: 1PX solid var(--bm-border-color);
  }
  .div-block-512 {
    border-top: 1PX solid var(--bm-border-color);
  }
  .text-block-98 {
    margin-right: auto;
    margin-bottom: 0px;
    font-size: 18PX;
    font-weight: 400;
  }
  .text-block-68 {
    font-size: 18PX;
  }
  .text-block-67 {
    margin-bottom: 0px;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-title-color);
    font-size: 18PX;
    font-weight: 400;
  }
  .text-block-68 {
    font-size: 18PX;
  }
  .text-block-67.bold {
    margin-bottom: 0px;
    font-family: 'Sf pro display', sans-serif;
    font-weight: 600;
    text-transform: none;
  }
  .text-block-68.bold {
    font-family: 'Sf pro display', sans-serif;
    font-weight: 600;
  }
  .delivery-moq {
    font-size: 12PX;
  }
  .div-block-512 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .individual-checlout {
    position: relative;
    left: auto;
    top: 0px;
    right: 0%;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 200PX;
    height: 55PX;
    margin-top: 20PX;
    margin-right: 0px;
    margin-left: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 8PX;
    background-color: var(--bm-primary-color);
    color: var(--bm-light-background-color);
    text-decoration: none;
    box-sizing: border-box;
  }
  .share,
  .rubbish {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50PX;
    height: 50PX;
    margin-top: 20PX;
    margin-right: 20PX;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 1PX;
    border-color: var(--bm-text-secondary-color);
    border-radius: 70PX;
    color: var(--bm-text-secondary-color);
    .bm-icon {
      width: 30PX;
      height: 30PX;
    }
  }
  .text-block-17 {
    font-family: 'Sf pro display', sans-serif;
    font-size: 18PX;
    font-weight: 600;
  }
  .div-block-484 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 15PX;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .address-fields.no-line {
    padding: 20PX 0px 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom-width: 0px;
    color: var(--bm-text-title-color);
    cursor: pointer;
  }
  .address-fields {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 20PX 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 0px 0px 1PX;
    border-color: #000 #000 #eaeaea;
    color: var(--bm-text-title-color);
    cursor: auto;
    .bm-icon {
      width: 25PX;
      min-width: 25PX;
      height: 25PX;
      padding: 0;
    }
  }
  .text-block-77 {
    margin-right: auto;
    margin-bottom: 5PX;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-size: 18PX;
    font-weight: 700;
    text-align: left;
    color: var(--bm-text-title-color);
  }
  .tinted-background {
    border-bottom: 1PX solid var(--bm-border-color-t60);
    .div-block-508:first-child {
      border-bottom: 1PX solid var(--bm-border-color-t60);
    }
  }
  .tinted-background {
    .div-block-508:last-child {
      border-bottom: none;
    }
  }
  //地址弹窗css
  .modal-wrapper-address {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 999999999;
    display: none;
    overflow: auto;
    padding: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-perspective: 850px;
    perspective: 850PX;
    cursor: default;
    -o-object-fit: none;
    object-fit: none;
  }
  .modal-wrapper-address.adress-show {
    display: flex;
  }
  .c-popup-close-2 {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 5;
    display: block;
    background-color: rgba(0, 0, 0, 0.09);
    cursor: pointer;
  }
  .flex-pop-up {
    position: relative;
    z-index: 10;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    width: 600PX;
    height: auto;
    margin: auto;
    padding: 0px 0px 40PX;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border: 0px solid transparent;
    border-radius: 6PX;
    box-shadow: 0 9PX 50PX -14PX #000;
    color: #313131;
    text-align: center; 
    background-color: var(--bm-light-background-color);
    // background-color: var(--bm-dark-background-color);
  }
  .div-block-497 {
    background-color: var(--bm-light-background-color);
  }
  .top-header.widen {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 20PX;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    box-sizing: border-box;
  }
  .top-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 80PX;
    margin-bottom: 0px;
    padding-top: 10PX;
    padding-bottom: 10PX;
    padding-left: 20PX;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1PX solid #eaeaea;
    background-color: var(--bm-light-background-color);
  }
  .heading-2 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'Sf pro display', sans-serif;
    font-size: 28PX;
    font-weight: 600;
  }
  h2 {
    margin-top: 20PX;
    margin-bottom: 10PX;
    font-size: 32PX;
    line-height: 36PX;
    font-weight: bold;
  }
  .x-icon {
    .close-modal {
      width: 30PX;
      height: 33PX;
    }
  }
  .x-icon {
    height: 47PX;
  }
  .popup-body-content {
    margin-bottom: 0px;
    padding-right: 20PX;
    padding-left: 20PX;
    margin-top: 10PX;
  }
  .choose-existing-address.active.close-modal {
    cursor: pointer;
  }
  .choose-existing-address.close-modal {
    cursor: pointer;
  }
  .choose-existing-address {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 20PX 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 0px 0px 1PX;
    border-color: #000 #000 #eaeaea;
    color: #666;
    cursor: auto;
  }
  .html-embed-41 {
    position: static;
    left: auto;
    top: auto;
    right: 0%;
    bottom: auto;
    width: 24PX;
    height: auto;
    margin-right: 0px;
    margin-left: 10PX;
    cursor: pointer;
    font-size: unset;
  }
  .item-info.checkout-process {
    flex-direction: unset;
    justify-content: space-between;
    max-width: unset;
  }
  .lazyload-wrapper {
    width: unset;
  }
  .item-info.checkout-process>div {
    font-size: 16PX;
    line-height: 20PX;
  }
  .modifier-item,
  .modifier-item.mi-indent {
    margin-top: unset;
    color: var(--bm-text-secondary-color);
    font-size: 17PX;
  }
  .div-block-482 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .modifier-item div {
    line-height: 17PX;
  }
  .div-block-502 {
    height: 100PX;
    margin-top: 0px;
    padding: 16PX;
    border-style: solid;
    border-width: 1PX;
    border-color: #f3f5f6;
    border-radius: 8PX;
    background-color: hsla(0,0%,96.5%,.4);
    box-sizing: border-box;
  }
  .order-shipping-address {
    position: absolute;
    right: 34PX;
    font-size: 18PX;
  }
  &-discount {
    margin: 12px 34px;
    display: flex;
    align-items: center;
    .am-list-item {
      flex: 1;
    }
    .am-button {
      min-width: 150px;
      margin-left: 12px;
      height: 100% !important;
    }
  }

}
@media screen and (max-width: 991px) {
  .div-block-508 {
    border-style: solid;
    border-width: 0 0 1PX;
    border-color: var(--bm-border-color-t60);
  }
  .tinted-background .div-block-508:last-child {
    border-top: 0;
  }
 
  .bm-c-listings-panel {
    .order-block {
      border-radius: 8PX;
    }
    .check-out-info.restaurant-info {
      margin-right: 0px;
      margin-left: 0px;
      padding-right: 0px;
      padding-left: 0px;
    }
    .check-out-info {
      margin-right: 20PX;
      margin-left: 20PX;
    }
    .text-block-67.bold {
      font-size: 18PX;
    }
    .html-embed-34 {
      height: 15PX;
    }
    .div-block-501 {
      border-bottom-width: 0px;
    }
    .tinted-background {
      padding-top: 0px;
      padding-bottom: 0px;
      border-bottom: 1PX solid var(--bm-border-color-t60);
      background-color: var(--bm-input-backgroud-color);
    }
    .div-block-481.checkout-process {
      width: 100PX;
      height: 100PX;
      min-height: 100PX;
      min-width: 100PX;
    }
    .heading-4-1.checkout-process {
      -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
      font-size: 16PX;
    }
    .text-block-6 {
      font-size: 14PX;
    }
    .div-block-482 {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .check-out-info.delivery {
      margin-right: 0px;
      margin-left: 0px;
      padding-right: 0px;
      padding-left: 0px;
    }
    .div-block-483 {
      margin-top: 20PX;
      margin-bottom: 20PX;
    } 
    .remark-block-tablet {
      display: block;
      margin-bottom: 20PX;
      padding: 0px;
      border-radius: 0px;
      box-shadow: none;
    }
    .am-textarea-control textarea {
      font-size: 16PX;
    }
    .div-block-484 {
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 15PX;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .div-block-512 {
      padding-top: 20PX;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .rubbish,
    .share {
      margin-top: 0px;
      padding: 0;
    }
    .individual-checlout {
      position: relative;
      top: 0px;
      right: 0%;
      width: 200PX;
      margin-top: 0px;
      margin-right: 0px;
      padding: 20PX;
    }
    // .item-info-wrapper.cart-process {
    //   height: 100PX;
    // }
    .address-fields {
      margin-right: 0px;
      margin-left: 0px;
    }
    //地址弹窗css
    .modal-wrapper-address {
      display: none;
    }
    .c-popup-close-2 {
      cursor: pointer;
    }
    .choose-existing-address {
      margin-right: 20PX;
      margin-left: 20PX;
    }
    .html-embed-41 {
      height: 24PX;
    }
  }
  .background-top.with-bg-v2 {
    height: 600PX;
  } 
}
@media screen and (max-width: 767px) {
  .bm-c-listings-panel {
      .tinted-background {
        padding-bottom: 0px;
    }
    .text-block-6 {
      font-size: 14PX;
    }
    .q-dec-cart.checkout-process,
    .q-inc-cart.checkout-process {
      width: 35PX;
      height: 35PX;
    }
    .q-dec-cart,
    .q-inc-cart {
      width: 40PX;
      height: 40PX;
    }
    .count {
      font-size: 14PX;
    }
    .am-list-line .am-input-control input {
      font-size: 15PX;
    }
    .check-out-info.delivery {
      cursor: pointer;
    }
    .div-block-502 {
      height: 110PX;
    }
    .text-block-67.bold {
      font-size: 18PX;
    }
    .text-block-67 {
      margin-bottom: 0px;
      font-size: 14PX;
    }
    .individual-checlout {
      left: auto;
      top: 0%;
      right: 0%;
      bottom: 0%;
    }
    .text-block-17 {
      font-size: 18PX;
    }
    .address-fields.no-line {
      padding-top: 10PX;
      padding-left: 0px;
      cursor: pointer;  
    }
    .text-block-77 {
      margin-right: 0px;
      margin-left: 0px;
      font-size: 15PX;
    }
    //地址弹窗css
    .modal-wrapper-address {
      display: none;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
      align-self: flex-end;
      background-color: var(--bm-light-background-color);
    }
    .c-popup-close-2.close-modal {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }
    .c-popup-close-2 {
      background-color: rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
    .flex-pop-up {
      z-index: 800;
      width: 100vw;
      height: auto;
      margin-bottom: 0px;
      padding-bottom: 20PX;
      border-radius: 0px;
      box-shadow: none;
    }
    .top-header.widen {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 0px;
      height: calc(var(--bm-safe-area-top) + 100PX);
      padding-top: calc(var(--bm-safe-area-top) + 30PX);
    }  
    .top-header {
      height: 60PX;
      padding-top: 20PX;
      padding-right: 20PX;
      padding-bottom: 20PX;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
    .popup-body-content._2 {
      height: 100vh;
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 20PX;
      background-color: var(--bm-dark-background-color);
    }
    .popup-body-content {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .div-block-497 {
      border-radius: 12PX;
    }
    .modifier-item,
    .modifier-item.mi-indent {
      font-size: 15PX;
    }
    .order-shipping-address {
      font-size: 16PX;
    }

  }
}

@media screen and (max-width: 479px) {
  .bm-c-listings-panel {
    .order-block {
      padding: 13PX;
      box-shadow: 0 0 10PX 0 rgba(0, 0, 0, 0.27);
    }
    .check-out-info.restaurant-info {
      margin-right: 0px;
      margin-left: 0px;
      padding: 13PX 0 13PX 0;
    }
    .check-out-info {
      padding: 13PX;
    }
    .text-block-67.bold {
      font-size: 14PX;
    }
    .text-block-67 {
      margin-bottom: 0px;
      font-size: 14PX;
    }
    .div-block-487 {
      margin-left: auto;
    }
    .text-block-69 {
      margin-right: 0px;
      font-size: 14PX;
    }
    .html-embed-34.rotated {
      position: relative;
    }
    .html-embed-34 {
      position: relative;
      margin-right: 0px;
    }
    .div-block-501 {
      padding: 9PX 0px;
      border-bottom-width: 0px;
    }
    .html-embed-38 {
      width: 26PX;
      height: 28PX;
    }
    .tinted-background {
      margin-right: -13PX;
      margin-left: -13PX;
      padding-right: 13PX;
      padding-bottom: 0px;
      padding-left: 13PX;
    }
    .div-block-508 {
      padding-top: 11PX;
      padding-bottom: 11PX;
      border-style: solid;
      border-width: 0 0 1PX;
      border-color: var(--bm-border-color-t60);
    }
    .check-out-info.delivery {
      margin-right: 0px;
      margin-left: 0px;
      padding: 13PX 0px;
    }
    // .listings-item.div-block-482>div {
    //   flex: 1;
    // }
    .div-block-481.checkout-process {
      width: 80PX;
      height: 80PX;
      min-height: 80PX;
      min-width: 80PX;
      margin-right: 10PX;
      border-radius: 4PX;
    }
    .item-info-wrapper.cart-process {
      max-width: none;
      min-width: auto;
      // height: 80PX;
    }
    .heading-4-1.checkout-process {
      margin-bottom: 5PX;
      font-size: 14PX;
      max-width: 80%;
    }
    .price-cart {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .text-block-6 {
      font-family: 'Sf pro display', sans-serif;
      font-size: 14PX;
      font-weight: 600;
    }
    .q-flex-cart {
      margin-right: 10PX;
    }
    .q-dec-cart.checkout-process,
    .q-inc-cart.checkout-process {
      width: 30PX;
      height: 30PX;
      margin-right: 0px;
      margin-left: 0;
    }
    .q-dec-cart,
    .q-inc-cart {
      width: 32PX;
      height: 32PX;
      margin-right: 5PX;
    }
    .count {
      width: 30PX;
      padding-right: 0px;
      padding-left: 0px;
      font-size: 14PX;
      height: 30PX;
    }
    .am-list-line .am-input-control input {
      font-size: 14PX;
    }
    .check-out-info.delivery {
      margin-right: 0px;
      margin-left: 0px;
      padding: 13PX 0px;
    }
    .div-block-502 {
      height: 90PX;
      padding: 13PX;
    }
    .am-textarea-control textarea {
      font-size: 14PX;
    }
    .am-textarea-count {
      font-size: 14PX;
    }
    .div-block-484 {
      margin-bottom: 10PX;
    }
    .text-block-98,
    .text-block-68 {
      font-size: 14PX;
    }
    .text-block-67.bold {
      font-size: 14PX;
    }
    .text-block-67 {
      margin-bottom: 0px;
      font-size: 14PX;
    }
    .text-block-68 {
      font-size: 14PX;
    }
    .rubbish {
      width: 40PX;
      height: 40PX;
      margin-right: 10PX;
      padding: 10PX;
    }
    .share {
      width: 40PX;
      height: 40PX;
      padding: 10PX;
    }
    .rubbish,
    .share {
      .bm-icon {
        width: 20PX;
        height: 20PX;
      }
    }
    .individual-checlout {
      left: auto;
      top: 0%;
      right: 0%;
      bottom: 0%;
      width: 120PX;
    }
    .text-block-17 {
      font-size: 14PX;
    }
    .address-fields.no-line {
      margin-right: 0px;
      margin-left: 0px;
      padding-right: 5PX;
      padding-left: 0;
    }
    .address-fields {
      padding: 10PX 13PX;
    }
    .text-block-77 {
      margin-bottom: 0px;
    }
    //地址弹窗css
    .modal-wrapper-address {
      display: none;
      height: 100vh;
      min-height: 100vh;
      padding: 0px;
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
      -ms-flex-align: end;
      align-items: flex-end;
      -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
      align-self: flex-end;
      background-color: hsla(0, 0%, 100%, 0);
    }
    .c-popup-close-2.close-modal {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }
    .c-popup-close-2 {
      display: block;
    }
    .flex-pop-up {
      position: absolute;
      left: 0%;
      top: auto;
      right: 0%;
      bottom: 0%;
      width: 100vw;
      height: 100vh;
      min-height: auto;
      min-width: 100vw;
      margin-top: auto;
      padding: 0px 0px 15PX;
      -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
      align-self: flex-end;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
    .top-header.widen {
      padding-bottom: 10PX;
      height: calc(var(--bm-safe-area-top) + 80PX);
      padding-top: calc(var(--bm-safe-area-top) + 20PX);
    }
    .top-header {
      margin-right: 0px;
      margin-bottom: 10PX;
      margin-left: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .popup-body-content._2 {
      height: 100%;
      min-height: 100%;
      margin-bottom: 0px;
      padding: 0px 12PX 40PX;
    }
    .popup-body-content {
      height: auto;
      min-height: auto;
      padding-right: 12PX;
      padding-left: 12PX;
    }
    .div-block-497 {
      overflow: scroll;
      height: auto;
      margin-right: -12PX;
      margin-left: -12PX;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      border-radius: 0px;
      margin-top: 10PX;
    }
    .choose-existing-address {
      padding: 13PX;
    }
    .html-embed-41 {
      position: relative;
      margin-right: 0px;
    }
    .close-modal {
      .x-icon {
        height: 47PX;
      }
    }
    .modifier-item,
    .modifier-item.mi-indent {
      font-size: 13PX;
    }
    .modifier-item div {
      line-height: 13PX;
    }
    .background-top.with-bg-v2 {
      height: 450PX;
    }
    .am-textarea-count {
      top: 13PX;
    }
    .order-shipping-address {
      font-size: 14PX;
    }
  }
}
