@import '~bm-public.less';

.bm-p-pay-method {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999999999;
  display: flex;
  overflow: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-perspective: 850PX;
  perspective: 850PX;
  cursor: default;
  background-color: transparent;
  .c-popup-close-2 {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 5;
    display: block;
    background-color: var(--bm-modal-b-color);
    cursor: pointer;
  }
  .close-modal.c-popup-close-2 {
    width: unset;
    height: unset;
  }
  .top-header.no-line {
    border-bottom-width: 0px;
  }
  .top-header {
    box-sizing: border-box;
  }
  .heading-2 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'Sf pro display', sans-serif;
    font-size: 28PX;
    font-weight: 600;
  }
  .close-modal {
    width: 35PX;
    height: 35PX;
  }
  .subtitle-popup {
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding: 20PX;
    border-radius: 0px;
    background-color: #f6f6f6;
    box-shadow: none;
  }
  .div-block-499 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .text-block-94 {
    margin-bottom: 0px;
    margin-left: 10PX;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-size: 18PX;
    font-weight: 400;
    text-align: left;
  }
  
}
@media screen and (max-width: 991px) {
  .bm-p-pay-method {
    .modal-wrapper-2 {
      display: none;
    }
    .c-popup-close-2 {
      cursor: pointer;
    }
    .subtitle-popup {
      border-radius: 0px;
    }

  }
}

@media screen and (max-width: 767px) {
  .bm-p-pay-method {
    .modal-wrapper-2 {
      left: 0%;
      top: 0%;
      right: 0%;
      bottom: auto;
      z-index: 9007199254740991;
      display: none;
      height: auto;
      min-height: 100vh;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
      -ms-flex-align: end;
      align-items: flex-end;
      -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
      align-self: flex-end;
    }
    .c-popup-close-2.close-modal {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }
    .c-popup-close-2 {
      background-color: rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
    .heading-2 {
      font-size: 27PX;
    }
    .flex-pop-up.widen {
      display: block;
      height: 100vh;
      padding-top: 20PX;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .subtitle-popup {
      border-radius: 12PX;
    }
    .choose-payment-block {
      overflow: visible;
      height: auto;
      border-radius: 12PX;
    }

  }
}
@media screen and (max-width: 479px) {
  .bm-p-pay-method {
    .modal-wrapper-2 {
      display: none;
      height: 100vh;
      min-height: 100vh;
      padding: 0px 0px 60PX;
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
      -ms-flex-align: end;
      align-items: flex-end;
      -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
      align-self: flex-end;
      background-color: hsla(0, 0%, 100%, 0);
    }
    .c-popup-close-2.close-modal {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }
    .c-popup-close-2 {
      display: block;
    }
    .top-header.no-line {
      margin-bottom: 0px;
      padding-top: calc(var(--bm-safe-area-top) + 20PX);
      padding-bottom: calc(var(--bm-safe-area-top) + 10PX);
      background-color: var(--bm-light-background-color);;
    }
    .heading-2 {
      font-size: 24PX;
    }


  }
}
