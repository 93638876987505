@import '~bm-public.less';
.bm-p-search {
  background-color: var(--bm-dark-background-color);
  .bm-p-search-nav {
    width: 100%;
  }
  .pc-header {
    height: unset;
  }
  .pc-footer {
    background-color: var(--bm-light-background-color);
  }

  .page-content {
    .light-bc;
  }
  .nav_bg.streched {
    padding-top: unset;
  }
  .am-list-item:not(:last-child) .am-list-line {
    border: none;
  }
  * {
    box-sizing: border-box;
  }
  .bm-p-search-nav {
    display: flex;
    align-items: center;
    height: 140px;
    background-color: var(--bm-primary-color);
    color: #fff;
    position: fixed;
    z-index: 6;
    padding-top: var(--bm-safe-area-top);
    .nav_bg {
      position: -webkit-sticky;
      position: sticky;
      left: 0%;
      top: 0%;
      right: 0%;
      bottom: auto;
      height: 52PX;
    }
    .nav_bg.streched {
      position: fixed;
      z-index: 9800000;
      height: auto;
      max-height: 70PX;
      min-height: 70PX;
      background-image: none;
      background-size: auto;
    }
    .w-container {
      margin-left: auto;
      margin-right: auto;
      max-width: 940PX;
    }
    .container {
      position: relative;
      display: block;
      overflow: visible;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: auto;
    }
    .nav_bg.streched.expanded {
      position: -webkit-sticky;
      position: sticky;
      z-index: 98000;
      background-color: var(--bm-light-background-color);
    }
    .nav_bg.streched.expanded.blue {
      position: -webkit-sticky;
      position: sticky;
      background-color: var(--bm-primary-color);
      color: var(--bm-light-background-color);
      width: 100%;
    }
    .container.fixed-nav {
      position: absolute;
      left: 0%;
      top: 0px;
      right: 0%;
      bottom: 0px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      max-height: 70PX;
      min-height: 70PX;
      margin-top: 0px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      color: var(--bm-text-title-color);
    }
    .div-block-645 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .am-list-item.am-input-item {
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-top: 0px;
      margin-bottom: 0px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      min-height: unset;
      height: 44PX;
      padding-left: 45PX;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      border-radius: 6PX;
      background-color: var(--bm-light-background-color);
      color: var(--bm-text-title-color);
      padding: 8PX 12PX;
    }
    .am-input-label.am-input-label-5 {
      width: unset;
    }
    .bm-p-search-nav-search-icon-1 {
      width: 23PX;
      height: 29PX;
      color: var(--bm-text-secondary-color);
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .div-block-646 {
      padding: 20px;
    }
    .text-block-231 {
      font-family: 'Sf pro display', sans-serif;
      color: var(--bm-light-background-color);
      font-size: 16PX;
      font-weight: 600;
    }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    .am-list-item:not(:last-child) .am-list-line::after {
        height: 0;
      }
    } 
    .bm-icon {
      font-size: unset;
      padding: unset;
      width: 23PX;
      height: 35PX;
    }
    .am-list-line .am-input-control input::-webkit-input-placeholder {
      font-size: unset;
    }
    .am-input-control input {
      font-size: 18PX;
    }
    @media screen and (max-width: 991px) {
      .w-container {
        max-width: 728PX;
      }
      .am-list-item.am-input-item {
        width: 100%;
        height: 50PX;
      }
      .div-block-646 {
        padding-right: 0px;
        padding-left: 10PX;
      }
      .branch-card.bm-c-store-list-item {
        padding-bottom: 0;
      }
    }
  
    @media screen and (max-width: 767px) {
      .branch-card.bm-c-store-list-item {
        margin-bottom: 0;
      }
      .nav_bg.streched {
        max-height: none;
        max-width: none;
        min-height: 60PX;
      }
      .nav_bg.streched.expanded {
        z-index: 800000;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
      }
      .nav_bg.streched.expanded.blue {
        position: -webkit-sticky;
        position: sticky;
        min-height: 70PX;
        padding-right: 0PX;
        padding-left: 0PX;
      }
      .container.fixed-nav {
        top: 0px;
        bottom: 0px;
        max-height: 60PX;
        min-height: 60PX;
        margin-top: 0PX;
        margin-bottom: 0PX;
      }
      .container.fixed-nav.expanded {
        max-height: 70PX;
        min-height: 70PX;
        padding-right: 30PX;
        padding-left: 30PX;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
      }
      .am-list-item.am-input-item {
        height: 45PX;
      }
      .am-input-control input {
        font-size: 16PX;
      }
    }
  
    @media screen and (max-width: 479px) {
      .nav_bg.streched {
        max-height: 50PX;
        min-height: 50PX;
      }
      .nav_bg.streched.expanded.blue {
        position: -webkit-sticky;
        position: sticky;
        max-height: 65PX;
        min-height: 65PX;
      }
      .w-container {
        max-width: none;
      }
      .container {
        margin-top: 0px;
        margin-bottom: 0px;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
      }
      .container.fixed-nav {
        max-height: 50PX;
        min-height: 50PX;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
      }
      .container.fixed-nav.expanded {
        max-height: 65PX;
        min-height: 65PX;
        padding-right: 0px;
        padding-left: 0px;
      }
      .div-block-645 {
        padding-right: 20PX;
        padding-left: 20PX;
      }
      .am-list-item.am-input-item {
        width: 100%;
      }
      .bm-icon {
        height: unset;
      }
    }
  
  }
}
