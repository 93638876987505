@import '~bm-public.less';

.bm-c-procurement-method {
  padding: 20PX 40PX;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.dining-style-fixed {
    background-color: var(--bm-primary-color);
    border-bottom: none;
    .bm-c-procurement-method-name, 
    .bm-c-procurement-method-note-text {
      color: var(--bm-light-background-color);
      min-width: auto;
    }
  }
  &-name {
    display: flex;
    flex: 1;
    align-items: center;
    height: 100%;
    .font-size(32px);
    .font-family-b;
    .title-color;

    justify-content: flex-start;
  }

  &-note {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 12px;
    .font-family-m;
    .text-s-color;

    justify-content: flex-end;
  }

  &-note-text {
    color: var(--bm-primary-color);
    min-width: 128px;
    text-align: right;
  }

  &-icon {
    width: 32px;
    height: 32px;
    .text-s-color;
  }
  &-modal {
    .bm-c-modal-popup-content {
      padding: 16px 32px;
      min-height: 1218px;
      // min-height: 662px;
    }

    .pm-panel {
      padding: 32px 0;
    }

    .panel-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 36px;
      line-height: 44px;
      .title-color;
      .font-family-b;
    }

    .ctn-tips {
      margin-top: 8px;
      .font-size(24px);
      .font-family-m;
      .text-s-color;

      text-align: left;
    }

    .pm-time-info {
      .font-size(24px);
      .font-family-b;

      color: var(--bm-primary-color);
    }

    .pm-box {
      display: flex;
      margin-top: 32px;

      .pm-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        border: 1px solid #ebeff2;
        background-color: rgba(247, 247, 247, 0.3);
        height: 140px;
        border-radius: 8px;
        transition: all 300ms;

        &:not(:first-child) {
          margin-left: 16px;
        }

        .bm-icon {
          margin-top: 16px;
          height: 60px;
          font-size: 48px;
        }

        .pm-item-title {
          margin-top: 8px;
          font-size: 24px;
          line-height: 32px;
          .font-family-m;
        }

        &.selected {
          border: 1px solid var(--bm-primary-color-t60);
          background-color: var(--bm-primary-color-t10);
          color: var(--bm-primary-color);
        }
      }
    }

    .am-input-extra {
      max-height: 50px;
    }

    .c-icon-scan {
      margin-top: -5px;
      font-size: 50px;
      color: var(--bm-primary-color);
    }

    .table-number {
      margin-top: 32px;

      &-error {
        border: 2px solid var(--bm-error-color);
      }

      &-error-msg {
        text-align: left;
        color: var(--bm-error-color);
        .font-size(24px);
      }
      
      .am-input-extra {
        max-height: 50px;
      }
    }

    .confirm-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 64px;
      height: 100px;
      border-radius: 12px;
      background-color: var(--bm-primary-color);
      color: #fff;
      .font-size(32px);
      .font-family-b;

      &.btn-disable {
        background-color: var(--bm-primary-color-t60);
      }
    }

    .panel-space {
      height: 2px;
      background-color: var(--bm-border-color-t60);
    }

    .choose-time {
      margin-top: 32px;
    }
  }
  .container-horizontal {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    // max-width: 940PX;
    margin: 0PX auto;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .container-horizontal.stretch {
    display: block;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  .container-horizontal.top {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    flex: 1;
  }
  .nav-time-picker {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    max-width: none;
    min-height: auto;
    margin-right: 0PX;
    padding-top: 0PX;
    padding-bottom: 0PX;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    cursor: pointer;
    align-items: center;
    .bm-c-procurement-method-name,
    .bm-c-procurement-method-note-text {
      font-size: 22PX;
      line-height: 22PX;
    }
  }
  &.dining-style-top {
    .nav-time-picker {
      width: auto;
    }
    .container-horizontal.top {
      margin-top: 20PX;
    }
  }
}

.bm-c-choose-time {
  position: relative;
  height: 292px;
  margin-top: 40px;
  padding: 48px 32px;
  background: rgba(250, 251, 251);
  border-radius: 12px;
  display: flex;
  justify-content: space-between;

  .ct-list-panel {
    display: flex;
    flex: 1;
  }

  .ct-list {
    display: flex;
    padding-top: 56px;
    padding-bottom: 152px;
    overflow: auto;
    height: 84px;

    .am-picker-col-mask {
      background-image:
        linear-gradient(to bottom, rgba(250, 251, 251, 0.95), rgba(250, 251, 251, 0.6)),
        linear-gradient(to top, rgba(250, 251, 251, 0.95), rgba(250, 251, 251, 0.6));
    }

    .am-picker {
      width: 100%;
      height: 100%;
    }

    &:not(:first-child) {
      margin-left: 51px;
    }

    &.ct-item-day {
      // width: 260px;
      flex: 75;
    }

    &.ct-item-time {
      // width: 136px;
      flex: 34;
    }

    &.ct-item-ap {
      // width: 124px;
      flex: 31;
    }
  }

  .marking-line {
    position: absolute;
    top: 104px;
    left: 32px;
    right: 32px;
    height: 84px;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.0001);
    border-top: 2px solid var(--bm-border-color);
    border-bottom: 2px solid var(--bm-border-color);
  }
}
.choose-time-type-wrap {
  .choose-time-type {
    text-align: left;
    display: flex;
    align-items: center;
    padding: 28px 0;
    font-size: 32px;
    color: var(--bm-text-title-color);
    border-bottom: 2px solid var(--bm-border-color-t60);
    .bm-icon {
      margin-right: 32px;
      padding: 0;
      color: var(--bm-primary-color);
      font-size: 40px;
    }
    &-disable {
      opacity: 0.6;
    }
  }
}
.modal-wrapper-2 {
  position: fixed;
  left: 0PX;
  top: 0PX;
  right: 0PX;
  bottom: 0PX;
  z-index: 999999999;
  display: none;
  overflow: auto;
  padding: 0PX 40PX;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-perspective: 850PX;
  perspective: 850PX;
  cursor: default;
}
.modal-wrapper-2-show {
  opacity: 1;
  display: flex;
}
.bm-c-procurement-method-modal.modal-wrapper-2-show {
  opacity: 1;
  display: flex;
}
.c-popup-close-time {
  position: absolute;
  left: 0PX;
  top: 0PX;
  right: 0PX;
  bottom: 0PX;
  z-index: 5;
  display: block;
  background-color: rgba(0, 0, 0, 0.09);
  cursor: pointer;
}


.time-modal {
  box-sizing: border-box;
  position: relative;
  z-index: 10;
  display: block;
  overflow: visible;
  width: 600PX;
  height: auto;
  margin: auto;
  padding: 0 20PX 40PX;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 0PX solid transparent;
  border-radius: 6PX;
  background-color: #fff;
  box-shadow: 0 9PX 50PX -14PX #000;
  color: #313131;
  text-align: center;
  .pm-box .pm-item {
    height: 90PX;
    align-items: center;
    justify-content: center;
    .bm-icon {
      font-size: 24PX;
      height: auto;
      margin-top: 0;
    }
    .pm-item-title {
      font-size: 18PX;
    }
  }
  .panel-title {
    font-size: 24PX;
  }
  .bm-c-choose-time {
    padding-top: 0;
  }
  .choose-time-type {
    font-size: 22PX;
  }
  .confirm-btn {
    margin-top: 0;
  }
}
.time-modal-show {
  transform: translate3d(0PX, 0PX, 0PX) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 1;
}
.modal-wrapper-2-close {
  transform: translate3d(0PX, 370PX, 0PX) scale3d(0.8, 0.8, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 0;
}

@media screen and (max-width: 991PX) {
  .c-popup-close-time {
    cursor: pointer;
  }
  .bm-c-procurement-method { 
    padding: 20PX 35PX;
  }
}
@media screen and (max-width: 767PX) {
  .modal-wrapper-2 {
    padding: 0;
  }
  .container-horizontal {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .container-horizontal.right-side {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .bm-c-procurement-method.dining-style-top .container-horizontal.top {
    margin-top: 17PX;
  }

  .time-modal {
    width: 100vw;
    margin-bottom: 0PX;
    border-radius: 0PX;
    box-shadow: none;

    .panel-title {
      font-size: 20PX;
    }
    .pm-box .pm-item {
      .pm-item-title {
        font-size: 14PX;
      }
    }
    .choose-time-type {
      font-size: 20PX;
    }
  }
  .c-popup-close-time {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  .bm-c-procurement-method { 
    padding: 20PX 25PX;
    .nav-time-picker {
      .bm-c-procurement-method-name,
      .bm-c-procurement-method-note-text {
        margin-bottom: 0;
        font-size: 16PX;
        line-height: 16PX;
      }
    }
  }
}
@media screen and (max-width: 479PX) {
  .bm-c-procurement-method { 
    padding: 10PX 12PX 20PX;
  }
  .container-horizontal {
    max-width: 100vw;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .container-horizontal.right-side {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .bm-c-procurement-method.dining-style-top .container-horizontal.top {
    margin-top: 11PX;
  }

  .time-modal {
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    width: 100vw;
    height: auto;
    min-height: auto;
    min-width: 100vw;
    margin-top: auto;
    padding: 20PX 12PX 15PX;

    .choose-time-type {
      font-size: 18PX;
    }
  }

  .c-popup-close-time {
    display: block;
  }
}

