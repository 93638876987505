@import '~bm-public.less';

.bm-p-privacy {
  .light-bc;

  .pc-header {
    height: 88px;
  }

  .back-icon {
    color: var(--bm-light-background-color);
  }

  .am-navbar {
    .am-navbar-title {
      color: var(--bm-light-background-color);
      .font-size(40px);
    }
  }
  .terms-condition-content {
    padding: 0 32px 32px;
    font-size: 30px;
    line-height: 40px;
    a {
      word-break: break-word;
    }
    img {
      width: 100%;
    }
  }
}
