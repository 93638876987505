
@import '~bm-public.less';
.bm-c-layout.bm-p-brands {
  padding-top: 0;
  .nav_bg.streched.without-line {
    background-color: transparent;
  }
}
.bm-c-layout.bm-p-brands {
  .nav-bar {
    position: absolute;
  }
}
.menu-nav-fixed.bm-p-brands {
  .nav_bg.streched.without-line {
    background-color: var(--bm-primary-color);
  }
}
.bm-p-brands {
  .bm-c-news-list {
    .section.tinted.white.open-modal {
      padding-top: 0;
    }
    .section.tinted.white {
      padding: 0;
    }
  }
  .tab-pane-tab-3 {
    min-width: unset;
  }
  .nav-logo {
    margin-top: 12PX;
  }
  .page-content {
    background-color: var(--bm-light-background-color);
  }
}
.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940PX;
}
.bm-p-brands {
    // overflow: scroll;
  background-color: var(--bm-light-background-color);
  &.position {
    position: absolute;
    z-index: 1;
  }
  &.phone {
    position: absolute;
  }
  .am-navbar-dark {
    width: unset !important;
    padding: 0 60PX;
    max-width: 100% !important;
  }
  .am-navbar-left {
    padding-left: 0;
  }
  .bm-navbar-fixed {
    position: -webkit-sticky;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    height: 52PX;
    background: -webkit-gradient(linear,left top,left bottom,color-stop(6.73%,hsla(0,0%,41.2%,.3)),to(rgba(89,89,89,0)));
    background: -webkit-linear-gradient(top,hsla(0,0%,41.2%,.3) 6.73%,rgba(89,89,89,0));
    background: -o-linear-gradient(top,hsla(0,0%,41.2%,.3) 6.73%,rgba(89,89,89,0) 100%);
    background: linear-gradient(180deg,hsla(0,0%,41.2%,.3) 6.73%,rgba(89,89,89,0));
  }
  .am-navbar {
    position: fixed;
    z-index: 10;
    height: auto;
    max-height: 70PX;
    min-height: 70PX;
    border-bottom: 0PX solid rgba(0, 0, 0, 0.1);
    background-color: transparent;
    background-image: none;
  }
  .side-bar-content {
    z-index: 13;
    position: fixed;
  }
}

.side-bar-icon {
  width: 25PX;
  height: 16PX;
  color: #fff;
  svg {
    width: 100%;
    height: 144%;
  }
}
.nav-logo {
  width: 158PX;
  max-width: 100%;
}
.nav-right-content {
  display: flex;
  .top-nav-membership {
    display: flex;
    padding: 5PX 12PX;
    align-items: center;
    max-width: 200PX;
    .thumbnail-dp {
      width: 35PX;
      height: 35PX;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5PX;
      border-radius: 50%;
      .avatar-icon {
        width: 25PX;
        height: 25PX;
      }
    }
    .member-name {
      font-weight: 600;
      color: var(--bm-light-background-color);
      font-size: 16PX;
      overflow: hidden;
      word-break: inherit;
    }
  }
  .cart-block {
    margin-left: 10px;
    padding: 9px;
    cursor: pointer;
    width: 25PX;
    height: 25PX;
  }
}
.bm-p-brands.menu-nav-fixed {
  .bm-navbar-fixed {
    background: var(--bm-primary-color);
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
  }
}
.box-brands-img,
.brands-img {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 390PX;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.63)), to(hsla(0, 0%, 100%, 0))), url('/images/default-store.png');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.63), hsla(0, 0%, 100%, 0)), url('/images/default-store.png');
  background-position: 0px 0px, 100% 50%;
  background-size: cover;
  .brands-carousel {
    touch-action: pan-y;
  }
}
.carousel-item {
  height: 390PX;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.order-category-wrap {
  display: flex;
  width: unset;
  margin-top: auto;
  margin-bottom: 0PX;
  padding: 10PX 40PX;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 1PX 1PX 4PX -2PX #bebebe;

  .order-category-wrap-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex: 1;
    overflow: visible;
    margin: 0px auto;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .food-order {
    height: 90PX;
    width: 33.33333333%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    position: relative;
    color: var(--bm-text-color);
  }
  .food-order:first-child:before {
    height: 0;
  }
  .food-order:before {
    content: ' ';
    background-color: var(--bm-border-color);
    width: 1PX;
    height: 80%;
    position: absolute;
    left: 0;
  }

  .second-title {
    margin-right: 5PX;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-size: 17PX;
    text-decoration: none;
  }
}
.brands-title {
  padding: 40PX 40PX 0 40PX;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30PX;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 36PX;
  position: relative;
  .title {
    color: var(--bm-text-title-color);
    margin-top: 0PX;
    margin-bottom: 0PX;
    font-family: 'Sf pro display', sans-serif;
    font-size: 24PX;
    font-weight: 600;
  }
  .title-item {
    font-weight: 600;
    padding-left: 0px;
  }
  .title-item.select {
    color: #333333;
  }
  .title-item:last-child {
    padding-right: 18PX;
  }
  .see-all {
    position: absolute;
    cursor: pointer;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-secondary-color);
    font-size: 16PX;
    right: 40PX;
    display: flex;
    align-items: center;
    margin-right: 5PX;
    .viewmore-icon {
      width: 25PX;
    }
  }
}
.title-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  padding: 9PX 30PX;
  text-align: left;
  cursor: pointer;
  color: #999999;
  min-width: 64PX;
  font-weight: 600;
}

.tab {
  padding-top: 0PX;
  padding-left: 0PX;
  background-color: hsla(0, 0%, 86.7%, 0);
  font-family: Montserrat, sans-serif;
  color: #999;
  font-weight: 400;
}


.bm-p-brands {
  .bm-c-news-list {
    padding: 0;
    margin: 0;
    position: relative;
    display: flex;
    overflow: scroll;
    width: 100%;
  }
  .news-true {
    display: flex;
    grid-column-gap: 0;
  }
  .bm-c-news-list-panel-wrap {
    display: flex;
  }
  .bm-c-news-list-panel {
    overflow: visible;
    width: 400PX;
    margin-right: 20PX;
    margin-left: auto;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    color: var(--bm-text-title-color);;
    cursor: pointer;
    box-shadow: unset;
  }
  .box-news-image,
  .news-image {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      overflow: hidden;
      width: auto;
      height: 250PX;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 12PX;
      background-image: url(/images/default-store.png);
      background-size: cover;
      background-position: 50%;
  }
  .box-news-image {
    margin-bottom: 20PX;
  }
  .news-image {
    width: 100%;
  }
  .news-msg {
    padding: 0;
    .news-title {
      font-family: 'Sf pro display', sans-serif;
      font-weight: 600;
      font-size: 18PX;
      line-height: 24PX;
      margin-top: 10PX;
      word-wrap: break-word;
    }
  }
  .side-bar-mask {
    z-index: 12;
    position: fixed;
  }
}

.no-content-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20PX 0;
  .bm-icon {
    font-size: 180PX;
  }
  .no-content-info {
    color: var(--bm-text-secondary-color);
    font-size: 18PX;
  }
}

.bm-p-brands-skeleton {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10010;
  background-color: var(--bm-light-background-color);
  .bm-icon  {
    color: var(--bm-light-background-color);
    left: 80px;
    top: 20px;
    position: absolute;
    font-size: 40px;
  }
  .skeleton-nav {
    padding: 30px 100px;
    height: 140px;

    .skeleton-category-wrap {
      display: flex;
      bottom: 0;
      width: 100%;
      height: 100%;

      .food-order {
        padding: 0 40px;
        flex: 1;
        position: relative;
        display: flex;
        flex-direction:column;
        .bm-icon-item {
          flex: 2;
          .skeleton-color;
          height: 60px;
          margin-bottom: 10px;
          justify-content:center;
        }
        .second-title {
          flex: 1;
          height: 20px;
          .skeleton-color;
        }
      }
      .booking:before {
        content: " ";
        background-color: var(--bm-border-color);
        width: 4px;
        height: 110px;
        position: absolute;
        top: 12px;
        left: 0;
      }
    }
  }
  .skeleton-line {
    width: 100%;
    height: 4px;
    .skeleton-color;
  }
  .skeleton-footer-line {
    position: fixed;
    bottom: 1.7rem;
    left: 0;
    right: 0;
    height: 2px;
    z-index: 9;
    .skeleton-color;
  }
  .skeleton-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1.3rem;
    z-index: 9;
    display: flex;
    padding: 16px 20px;
    background-color: #fff;
    .skeleton-icon {
      flex: 1;
      margin: 0 30px;
      display: flex;
      flex-direction:column;
      .icon-item {
        flex: 3;
        margin-bottom: 14px;
        .skeleton-color;
      }
      .icon-item-title {
        flex: 1;
        margin: 0;
        .skeleton-color;
      }
    }
  }
}
.skeleton-item {
  position: relative;
  width: 400PX;
  border-radius: 12px;

  &:not(:first-child) {
    margin-left: 20px;
  }
  &:last-child {
    padding-right: 32px;
  }

  &:last-child {
    padding-right: 32px;
  }


  .skeleton-categories-title,
  .skeleton-categories-description,
  .skeleton-categories-description-item {
    margin-top: 30px;
    width: 400px;
    height: 30px;
    border-radius: 8px !important;
    .skeleton-color;
  }
  .skeleton-categories-description {
    height: 25px;
    width: 100%;
    margin-top: 12px;
  }
  .skeleton-categories-description-item {
    height: 25px;
    width: 250px;
    margin-top: 8px;
  }
  .info {
    height: 40px;
    width: 350px;
  }
}
.skeleton-categories-img {
  width: auto;
  height: 250PX;
  margin-bottom: 20PX;
  border-radius: 12PX !important;
  .skeleton-color;
}
.bm-p-brands-skeleton-img {
  height: 390PX;
  width: 100%;
  .skeleton-color;
}
.skeleton-blog-wrap {
  padding: 40PX 40PX 0 40PX;
  margin-bottom: 30PX;
  overflow: hidden;
  .left {
    float: left;
    width: 160px;
    height: 50px;
    .skeleton-color;
  }
  .right {
    float: right;
    width: 120px;
    .skeleton-color;
    height: 35px;
    margin-top: 10px;
  }
}
.skeleton-brands-categories {
  padding: 0 40PX 40PX;
  display: flex;
  width: auto;
  background-color: #fff;

  .skeleton-wrapper {
    display: flex;
    flex: 1;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .skeleton-wrapper-box {
    display: flex;
  }
}
.skeleton-brands-title {
  height: 50px;
  display: flex;
  background-color: #fff;
  padding: 40PX 40PX 30PX 40PX;
  .brands-title-item {
    flex: 2;
    .skeleton-color;
  }
  .brands-title-item:nth-child(2) {
    margin: 0 60px 0 30px;
  }
  .brands-title-item:last-of-type {
    flex: 1;
    height: 30px;
    margin-top: 11px;
  }
}


@media screen and (max-width: 991px) {
  .bm-p-brands {
    .am-navbar-dark {
      width: unset !important;
      padding: 0 30PX;
    }
    .news-msg {
      .news-title {
        margin-bottom: 5PX;
      }
    }
  }
  .box-brands-img,
  .brands-img,
  .carousel-item,
  .bm-p-brands-skeleton-img {
    height: 400PX;
  }
  .order-category-wrap {
    padding: 20PX 35PX;
    .food-order {
      height: 60PX;
    }
    .second-title {
      font-size: 16PX;
    }
  }
  .brands-title,
  .skeleton-blog-wrap{
    padding: 30PX 35PX 0 35PX;
    margin-bottom: 30PX;
  }
  .see-all {
    right: 35PX;
  }
  .w-container {
    max-width: 728PX;
  }
  .order-category-wrap-container {
    position: relative;
    display: block;
    max-width: none;
  }
  .blog-wrap {
    padding: 0 35PX 30PX;
  }
  .skeleton-brands-categories {
    padding: 0 35PX 30PX;
  }
  .skeleton-brands-title {
    padding: 30PX 35PX 30PX 35PX;
  }
}
@media screen and (max-width: 767px) {
  .bm-p-brands {
    .am-navbar {
      max-height: 50PX;
      min-height: 60PX;
    }
    .top-nav-membership {
      max-width: 90PX;
    }
  }
  .side-bar-icon {
    width: 30PX;
    height: 14PX;
  }
  .box-brands-img,
  .brands-img,
  .carousel-item,
  .bm-p-brands-skeleton-img {
      height: 270PX;
  }
  .order-category-wrap {
    padding: 15PX 25PX;
    .food-order {
      height: 50PX;
    }
    .bm-icon {
      width: 37PX;
    }
    .second-title {
      font-size: 14PX;
    }
  }
  .brands-title,
  .skeleton-blog-wrap {
    padding: 20PX 25PX 0 25PX;
    margin-bottom: 20PX;
    .title {
      font-size: 22PX;
    }
    .see-all {
      right: 25PX;
    }
  }
  .order-category-wrap-container{
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .blog-wrap {
    padding: 0 25PX 20PX;
  }
  .skeleton-brands-categories {
    padding: 0 25PX 20PX;
  }
  .skeleton-categories-img {
    height: 200PX;
    border-radius: 8PX;
  }
  .skeleton-brands-title {
    padding: 20PX 25PX 20PX 25PX;
  }
  .skeleton-item {
    width: 300PX;
  }
  .bm-p-brands {
    .box-news-image,
    .news-image {
      height: 200PX;
      border-radius: 8PX;
    }
    .bm-c-news-list-panel {
      width: 300PX;
    }
  }
  .no-content-panel {
    .bm-icon {
      font-size: 150PX;
    }
  }
}

@media screen and (max-width: 479px) {
  .bm-p-brands {
    .am-navbar-dark {
      padding: 0 16PX 0 17PX;
    }
    .am-navbar {
      max-height: 50PX;
      min-height: 50PX;
    }
  }
  .side-bar-icon {
    margin-right: auto;
  }
  .nav-logo {
    width: 158PX;
    max-width: 80%;
  }
  .nav-right-content .top-nav-membership {
    display: none;
    max-width: 88PX;
  }
  .box-brands-img,
  .brands-img,
  .carousel-item,
  .bm-p-brands-skeleton-img {
    height: 220PX;
  }
  .order-category-wrap {
    padding: 15PX 12PX;
    .bm-icon {
      width: 32PX;
      margin-bottom: 3PX;
    }
    .second-title {
      font-family: 'Fontsfree net sfprodisplay', sans-serif;
      font-size: 12PX;
    }
  }
  .brands-title,
  .skeleton-blog-wrap {
    padding: 20PX 12PX 0 12PX;
    margin-bottom: 20PX;
    .title {
      font-size: 16PX;
    }
    .see-all {
      font-size: 14PX;
      right: 12PX;
    }
  }
  .w-container {
    max-width: none;
  }
  .order-category-wrap-container {
    max-width: 100vw;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .title-item {
    display: block;
  }
  .tab {
    padding-right: 10PX;
    padding-bottom: 0PX;
    padding-left: 10PX;
  }
  .blog-wrap {
    padding: 0 12PX 15PX;
  }

  .bm-p-brands {
    .bm-c-news-list-panel {
      width: 290PX;
      margin-right: 15PX;
    }
    .news-msg {
      .news-title {
        margin-top: 5PX;
        margin-bottom: 5PX;
        font-size: 14PX;
        line-height: 22PX;
      }
    }
    .box-news-image,
    .news-image {
      height: 170PX;
      border-radius: 10PX;
    }
    .box-news-image {
      margin-bottom: 10PX;
    }
  }

  // .bm-p-brands {
  //   .bm-c-news-list-panel {
  //     width: 250PX;
  //     // margin-right: 15PX;
  //   }
  //   .box-news-image,
  //   .news-image {
  //     height: 180PX;
  //     border-radius: 10PX;
  //   }
  //   .box-news-image {
  //     margin-bottom: 10PX;
  //   }

  // }

  .skeleton-brands-categories {
    padding: 0 15PX 25PX;
  }
  .skeleton-categories-img {
    height: 180PX;
    border-radius: 10PX;
  }
  .skeleton-brands-title {
    padding: 20PX 12PX 20PX 12PX;
  }
  .skeleton-item {
    width: 250PX;
  }
  .no-content-panel {
    padding: 15PX 0;
    .no-content-info {
      color: var(--bm-text-secondary-color);
      font-size: 16PX;
    }
    .bm-icon {
      font-size: 110PX;
    }
  }

}
