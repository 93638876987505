@import '~bm-public.less';
.menu-nav-item {
  padding: 6PX 10PX;
  white-space: nowrap;
}
.menu-nav-item.selected {
  font-style: normal;
  font-weight: 700;
  background-color: var(--bm-primary-color);
  color: #fff;
  border-radius: 16PX;
}
.overlay-nav {
  position: absolute;
  z-index: 90000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  max-height: none;
  max-width: 100vw;
  min-height: auto;
  min-width: 100vw;
  margin-right: 0PX;
  margin-left: 0PX;
  padding-top: 0PX;
  padding-bottom: 0PX;
  background-color: #fff;
  box-shadow: 0 1PX 2PX 0 rgba(0, 0, 0, 0.16);
}
.drag-nav-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  padding: 30PX 20PX 30PX 30PX;
  cursor: -webkit-grab;
  cursor: grab;
}

.div-block-88-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  // -webkit-box-flex: 1;
  // -webkit-flex: 1;
  // -ms-flex: 1;
  // flex: 1;
  background-color: #fff;
  box-shadow: none;
  color: #fff;
}
.div-block-88-left .menu-nav-left-tag {
  display: none;
  &.show-tag {
    display: block;
  }
}

.div-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  // -webkit-box-flex: 1;
  // -webkit-flex: 1;
  // -ms-flex: 1;
  // flex: 1;
  background-color: #fff;
  box-shadow: none;
  color: #fff;
  height: 40PX;
}

.in-page-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 30PX;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: #666;
  font-size: 18PX;
  font-weight: 400;
  text-decoration: none;
}

@media screen and (max-width: 991PX) {
  .in-page-link {
    margin-right: 20PX;
  }
  .drag-nav-bar {
    overflow: auto;
  }
}

@media screen and (max-width: 767PX) {
  .overlay-nav {
    width: 100vw;
    max-width: none;
    min-width: auto;
    padding-right: 25PX;
    padding-left: 25PX;
  }

  .drag-nav-bar {
    overflow: auto;
  }

  .in-page-link {
    margin-right: 20PX;
    font-size: 16PX;
  }
}

@media screen and (max-width: 479PX) {
  .overlay-nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    // width: auto;
    max-height: none;
    min-height: auto;
    padding: 0PX;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .in-page-link {
    margin-right: 20PX;
    font-size: 14PX;
  }
  .drag-nav-bar {
    overflow: auto;
    max-width: 100vw;
    padding: 20PX 15PX;
  }
}

