@import '~bm-public.less';

.bm-p-purchase-order {
  padding-bottom: 90PX;
  .dark-bc;

  .pc-header {
    height: var(--bm-safe-area-top);
  }

  .back-icon {
    color: var(--bm-light-background-color);
  }

  .am-navbar {
    .am-navbar-title {
      color: var(--bm-light-background-color);
      .font-size(40px);
    }
  }
  .store-info-details {
    flex: 1;
  }

  .purchase-order-items-search {
    padding: 20PX 0 0 20PX;
  }

  .purchase-order {
    position: relative;
    z-index: 0;

    &-style-b-color {
      position: absolute;
      top: 0;
      width: 100%;
      height: 190PX;
      background-color: var(--bm-primary-color);
      z-index: 1;
    }

    &-section {
      position: relative;
      z-index: 2;
      padding: 0 40PX 40PX;
    }

    &-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 940PX;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    &-items {
      height: auto;
      width: 58%;
      margin-top: 20PX;
      margin-right: 20PX;
      padding: 0;
      align-self: flex-start;
      -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
      border-radius: 12PX;
      background-color: var(--bm-light-background-color);
      
      &-search {
        &-type {
          margin-bottom: 32px;

          &-title {
            color: var(--bm-text-color);
            margin-bottom: 16px;
          }

          &-options {
            display: flex;
            flex-wrap: wrap;
          }

          &-option {
            font-weight: normal;
            padding: 16px 28px;
            border-radius: 12px;
            border: 2px solid #999999;
            color: var(--bm-text-color);
            margin: 16px 16px 0 0;
            background: var(--bm-light-background-color);
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);

            &.select {
              background: #FBAC36;
              border-color: transparent;
              color: var(--bm-light-background-color);
            }
          }
        }
      }

      &-content {
        padding: 20PX;
      }
    }

    &-item {
      border-radius: 8PX;
      padding: 20PX;
      margin-bottom: 20PX;
      border: 1PX solid #ebeff2;
  
      &-number-and-state {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        font-size: 24px;
      }

      &-number {
        display: flex;
        align-items: center;

        &-reference {
          color: var(--bm-text-title-color);
          font-weight: bold;
          font-size: 28px;
        }
      }

      &-state {
        display: flex;
        font-size: 26px;
        white-space: nowrap;
  
        &.pay {
          padding: 6PX;
          border-radius: 8px;
          color: var(--bm-error-color);
          border: 1px solid var(--bm-error-color);
  
          &.paid {
            color: #FF9530;
            border-color: #FF9530;
          }
        }
  
        &.arrival {
          border-radius: 8px;
          margin-left: 6px;
          padding: 6PX;
          border: 1px solid;

          &.created {
            color: var(--bm-warning-color);
            border-color: var(--bm-warning-color);
          }

          &.accepted {
            color: #01A0D9;
            border-color: #01A0D9;
          }
  
          &.canceled {
            color: var(--bm-skeleton-color-t30);
            border-color: var(--bm-skeleton-color-t30);
          }
  
          &.fulfilled {
            color: var(--bm-tag-delivery-color);
            border-color: var(--bm-tag-delivery-color);
          }
  
          &.partially_fulfilled {
            color: var(--bm-tag-book-color);
            border-color: var(--bm-tag-book-color);
          }
        }
      }
  
      &-supplier-name {
        font-size: 32px;
        line-height: 32px;
        font-weight: bold;
        color: var(--bm-text-title-color);
        margin-bottom: 20px;
      }
  
      &-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: var(--bm-skeleton-color);
        opacity: 0.8;
  
        &-total-date {
          display: flex;
        }
  
        &-dividing {
          width: 1PX;
          margin: 0 10PX;
          height: 18PX;
          background-color: var(--bm-skeleton-color-t20);
        }
  
        &-view-details {
          display: flex;
          &-icon {
            padding: 0;
            width: 25PX;
            height: 20PX;
            font-size: 25PX;
          }
        }
      }
    }

    .content-panel-wrap {
      position: -webkit-sticky;
      position: sticky;
      left: auto;
      top: 40PX;
      right: 0%;
      bottom: 0%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 40%;
      height: auto;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
      align-self: flex-start;

      .store {
        &-card {
          margin-top: 20PX;
          padding: 20PX;
          border-radius: 8PX;
          background-color: var(--bm-light-background-color);
        }
      }

      .store-info {
        display: flex;
        align-items: center;
        -webkit-box-align: center;

        &-logo {
          width: 65PX;
          height: 65PX;
          margin-right: 20PX;
          border-radius: 50%;
          background-position: 50% 50%;
          background-size: cover;
        }

        &-details {
          &-title {
            margin-bottom: 10PX;
            font-size: 18PX;
            font-weight: 700;
            text-align: left;
            color: var(--bm-primary-color);
          }

          &-address {
            display: flex;

            .bm-icon {
              .font-size(28px);
              display: inline;
              padding: 0;
              margin-right: 8px;
              color: var(--bm-primary-color);
            }

            &-data {
              font-size: 16PX;
              font-weight: 400;
              color: var(--bm-text-color)
            }
          }
        }
      }
    }
  }

  .loading-data {
    display: flex;
    .font-size(32px);

    justify-content: center;
    align-items: center;
    padding: 32px;

    .loading-msg {
      margin-left: 12px;
    }

    .am-icon-loading {
      width: 32px;
      height: 32px;
    }
  }

  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 356px;

    .img {
      svg {
        width: 300px;
        height: 300px;
      }
    }

    .no-data-tip {
      margin-top: 20px;
      font-weight: bold;
      font-size: 32px;
      line-height: 36px;
      color: var(--bm-text-color);
      text-align: center;
    }
  }

  @media screen and (max-width: 991px) {
    .purchase-order-items-search {
      padding: 0;
    }
    .purchase-order {
      &-style-b-color {
        height: 110PX;
      }

      &-section {
        padding: 0 35PX 30PX;
      }

      &-content {
        display: block;
      }

      .content-panel-wrap {
        position: relative;
        margin: 0 20PX;
        width: auto;

        .store-card {
          margin: 0;
        }
      }
      
      &-items {
        margin: 70PX 20PX 0;
        width: auto;
        background-color: transparent;

        &-content {
          padding: 0;
        }
      }

      &-item {
        background-color: var(--bm-light-background-color);
      }
    }
  }
  
  @media screen and (max-width: 479px) {
    .purchase-order-items-search {
      padding: 0;
    }
    .purchase-order {
      &-section {
        padding: 0 12PX 15PX;
      }

      .content-panel-wrap {
        margin: 0;
      }

      &-items {
        padding: 120px 0 0;
        margin: 0;
        width: auto;
        background-color: transparent;

        &-content {
          padding: 0;
        }
      }

      &-item {
        padding: 22px;
        &-state {
          .arrival {
            font-size: 26px;
          }
        }
      }
    }
  }
}