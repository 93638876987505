@import '~bm-public.less';
.bm-p-shopping-cart  {
  background-color: var(--bm-dark-background-color);
  .page-content-wrapper-web {
    overflow: unset; 
  }
  .page-content {
    min-height: 650PX;
  }
  &-margin {
    padding-bottom: 100PX;
    background-color: var(--bm-dark-background-color);
  }
  .nav_bg.streched.w-nav {
    background-color: var(--bm-primary-color);
    svg {
      color: var(--bm-light-background-color);
    }
  }
  .section {
    background-color: var(--bm-dark-background-color);
  }
  .nav_bg.streched {
    position: fixed;
    z-index: 5;
    height: auto;
    max-height: 70PX;
    min-height: 70PX;
    border-bottom: 0px solid rgba(0, 0, 0, 0.1);
    background-color: hsla(0, 0%, 100%, 0);
    background-image: none;
    background-size: auto;
  }
  .search-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10PX;
    padding-right: 30px;
    background-color: var(--bm-light-background-color);
    height: 45PX;
    line-height: 45PX;
    border-radius: 10PX;
    border: 1PX solid var(--bm-border-color);
    .search-item {
      flex: 1;
      height: 45PX;
      line-height: 45PX;
      min-height: auto;
      background-color: var(--bm-light-background-color);
      border: none;
      .am-list-line {
        border: none;
      }
      .am-input-control input {
        border: none;
        background-color: var(--bm-light-background-color);
      }
    }
    .search-icon-wrap {
      display: flex;
      align-items: center;
      .search-icon {
        font-size: 22PX;
      }
    }
  }
  .div-block-7.open-modal {
    z-index: 99999;
  }
  .container-stretched_1 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    max-height: 70PX;
    max-width: none;
    min-height: 70PX;
    margin-right: 60PX;
    margin-left: 60PX;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .scanner_block {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 10PX;
    padding: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border: 0px solid #000;
    border-radius: 40PX;
    cursor: pointer;
    z-index: 99999;
  }
  .background-top {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    height: 260PX;
    background-color: var(--bm-primary-color);
  }
  .container.top {
    z-index: auto;
    margin-top: 0px;
  }
  .div-block-485 {
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .left-block {
    width: auto;
    height: auto;
    margin-top: 0;
    margin-right: 20PX;
    padding: 0px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-radius: 12PX;
    background-color: rgba(255, 255, 255, 0);
    box-shadow: none;
    flex: 1;
  }
  .nav-time-picker {
    .bm-c-procurement-method-name,
    .bm-c-procurement-method-note-text {
      margin-bottom: 0px;
      font-family: 'Fontsfree net sfprodisplay', sans-serif;
      font-size: 18PX;
      font-weight: normal;
    }
  }
  .right-block {
    position: -webkit-sticky;
    position: sticky;
    left: auto;
    top: 100PX;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 40%;
    height: auto;
    margin-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;

    align-self: flex-start;
  }
  .check-out {
    position: -webkit-sticky;
    position: sticky;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: auto;
    max-width: 100%;
    min-width: 100%;
    margin-top: 0;
    margin-right: 0px;
    margin-bottom: 0px;
    padding: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 12PX;
    background-color: rgba(255, 255, 255, 0);
    box-shadow: none;
  }
  .div-block-510 {
    box-sizing: border-box;
    margin-bottom: 20PX;
    padding: 20PX;
    border-radius: 8PX;    
    background-color: var(--bm-light-background-color);
    // background-color: var(--bm-primary-color);
    box-shadow: 1PX 1PX 10PX 0 rgba(0, 0, 0, 0.24);
  }
  .text-block-90.less-spacing {
    margin-bottom: 5PX;
    font-size: 20PX;
  }
  .text-block-90 {
    margin-bottom: 10PX;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-title-color);
    font-size: 18PX;
    font-weight: 700;
  }
  .text-block-91 {
    color: var(--bm-text-secondary-color);
    font-size: 14PX;
  }
  .pay-now-block {
    position: relative;
    left: auto;
    top: 0px;
    right: 0%;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 55PX;
    margin-top: 20PX;
    margin-right: 0px;
    margin-left: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 8PX;
    text-decoration: none;
  }
  .text-block-17 {
    font-family: 'Sf pro display', sans-serif;
    font-size: 18PX;
    font-weight: 600;
  }
  .make-payment {
    position: -webkit-sticky;
    position: sticky;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 8;
    display: none;
    height: auto;
    margin-top: 0vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    background-color: transparent;
  }
  .bottom-nav {
    position: -webkit-sticky;
    position: sticky;
    top: auto;
    z-index: auto;
    width: 100vw;
    height: auto;
    padding-top: 15PX;
    padding-bottom: 15PX;
    background-color: var(--bm-light-background-color);
    box-shadow: 1PX 1PX 4PX -1PX #5e5e5e;
    box-sizing: border-box;
  }
  .html-embed-32 {
    width: 37PX;
    height: 37PX;
  }
  .no-content-btns {
    width: 100% !important;
    position: absolute;
    bottom: 150PX;
    height: 70PX;
    padding: 0 25PX;
    box-sizing: border-box;
    .start-ordering {
      font-weight: 600;
      text-align: center;
      width: 100%;
      background-color: var(--bm-primary-color);
      box-sizing: border-box;
      height: 100%;
      align-items: center;
      line-height: 70PX;
      font-size: 20PX;
      color: var(--bm-light-background-color);
      border-radius: 10PX;
    }
  }
  .has-tab-bar.no-content-btns {
    bottom: 250PX;
  }
  .no-content-panel {
    margin-top: 300PX;
  }
  .text-block-91 {
    .share {
      width: 30PX;
      height: 30PX;
      padding: 5PX;
      margin-top: 20PX;
      margin-left: 15PX;
      margin-right: 20PX;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      border-style: solid;
      border-width: 1PX;
      border-color: var(--bm-text-secondary-color);
      border-radius: 70PX;
      color: var(--bm-text-secondary-color);
      svg {
        color: var(--bm-primary-color-t60);
      }
    }
  }
  // .animation {
  //   overflow: unset;
  // }

  //B端快速订单的StoreInfo的CSS
  .div-block-514 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    .div-block-515  {
      flex: 1;
    }
  }
  .div-block-511 {
    width: 60PX;
    height: 60PX;
    min-height: 60PX;
    min-width: 60PX;
    margin-right: 20PX;
    border-radius: 50%;
    // background-image: url(https://assets.website-files.com/605173a…/60790db…_dp2.jpeg);
    background-position: 50% 50%;
    background-size: cover;
  }
  .text-block-104 {
    display: flex;
    justify-content: space-between;
    font-size: 18PX;
    line-height: 18PX;
    font-weight: 700;
    >div:first-child {
      padding-top: 6PX;
      color: var(--bm-primary-color);
    }
    .down-outlined {
      width: 28PX;
      height: 28PX;
    }
  }
  ._107 {
    font-size: 16PX;
    font-weight: 400;
    color: var(--bm-text-color);
    display: flex;
    .map-outlined {
      width: 20PX;
      height: 20PX;
      color: var(--bm-primary-color);
    }
  }
  .html-embed-7.side-bar-icon {
    width: 32PX;
    font-size: unset;
  }
  .bm-c-procurement-method {
    padding: 0;
  }
  .html-embed-7 {
    width: 45PX;
    height: 45PX;
    color: var(--bm-light-background-color);
    padding: 0;
  }
}

@media screen and (max-width: 991px) {
  .bm-p-shopping-cart {
    .page-content {
      min-height: auto;
    }
    .container-stretched_1 {
      margin-right: 30PX;
      margin-left: 30PX;
    }
    .div-block-485 {
      margin-top: 0;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .left-block {
      width: 100%;
      margin-top: 0px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      box-shadow: none;
    }
    .right-block {
      position: static;
      width: 100%;
      -webkit-box-ordinal-group: 0;
      -webkit-order: -1;
      -ms-flex-order: -1;
      order: -1;
      border-top-left-radius: 12PX;
      border-top-right-radius: 12PX;
    }
    .check-out {
      display: none;
      margin-bottom: 0px;
      padding: 0px;
      border-radius: 0px;
      box-shadow: none;
    }
    .has-suppliers {
      display: flex;
      margin-top: 8PX;
      .confirm-pay {
        display: none;
      }
    }
    .div-block-510 {
      margin-bottom: 20PX;
    }
    .make-payment {
      position: fixed;
      bottom: 0;
      top: unset;
      display: block;
      margin-top: 0vh;
    }
    .bottom-nav.cart-process {
      padding: 27PX;
    }
    .bottom-nav {
      padding-top: 15PX;
      padding-bottom: 15PX;
    } 
    .checkout-tablet {
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .pay-now-block {
      position: static;
      top: 0px;
      right: 0%;
      width: 200PX;
      margin-top: 0px;
      margin-right: 0px;
      padding: 20PX;
      color: var(--bm-light-background-color);
    }
    .share {
      margin-top: 0px;
    }
    .footer {
      display: none;
    }
  }

}

@media screen and (max-width: 767px) {
  .bm-p-shopping-cart {
    .make-payment.has-tab-bar {
      bottom: 75PX;
    }
    .html-embed-7.side-bar-icon {
      width: 29PX;
    }
    .nav_bg.streched {
      max-height: none;
      max-width: none;
      min-height: 60PX;
    }
    .container-stretched_1.both {
      width: auto;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
    .scanner_block {
      left: auto;
      top: 0%;
      right: 0%;
      bottom: 0%;
    }
    .left-block {
      box-shadow: none;
    }
    .bottom-nav {
      top: 100vh;
    }
    .container.bottom {
      max-width: 100%;
    }
    .checkout-tablet {
      position: static;
    }
    .pay-now-block {
      left: auto;
      top: -22%;
      bottom: 0%;
    }
    .text-block-17 {
      font-size: 18PX;
    }
    .html-embed-7 {
      width: 37PX;
      height: 34PX;
      color: var(--bm-light-background-color);
    }
  }
}
@media screen and (max-width: 479px) {
  .bm-p-shopping-cart  {
    .section {
      padding-bottom: 100PX;
    }
    .nav_bg.streched {
      max-height: 50PX;
      min-height: 50PX;
    }
    .container-stretched_1 {
      max-height: 50PX;
      min-height: 50PX;
      margin-right: 16PX;
      margin-left: 17PX;
    }
    .background-top {
      height: 180PX;
    }
    .nav-time-picker {
      .bm-c-procurement-method-name,
      .bm-c-procurement-method-note-text {
        margin-bottom: 0px;
        font-size: 14PX;
      }
    }
    .bm-c-procurement-method-icon {
      height: 26PX;
      width: 31PX;
    } 
    .bottom-nav.cart-process {
      padding: 20PX 12PX;
    }
    .bottom-nav {
      padding-top: 10PX;
      padding-bottom: 10PX;
    }
    .container {
      margin-top: 0px;
      margin-bottom: 0px;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
    .checkout-tablet {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      max-width: none;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: stretch;
      -webkit-align-items: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
    }
    .text-block-90.less-spacing {
      -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
      align-self: flex-start;
      font-size: 16PX;
    }
    .text-block-91 {
      -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
      align-self: flex-start;
    }  
    .pay-now-block {
      left: auto;
      top: 0%;
      right: 0%;
      bottom: 0%;
      width: auto;
    }  
    .text-block-17 {
      font-size: 14PX;
    }
    .html-embed-32 {
      width: 27PX;
      height: 27PX;
    }
    .share {
      width: 40PX;
      height: 40PX;
    }
    .has-make-payment {
      margin-bottom: 75PX;
    }
    .html-embed-7 {
      width: 30PX;
      height: 30PX;
    }
    .make-payment.has-tab-bar {
      bottom: 75PX;
    }
    .has-tab-bar.no-content-btns {
      bottom: 180PX;
    }
    .no-content-btns {
      height: 50PX;
      .start-ordering {
        line-height: 50PX;
        font-size: 16PX;
      }
    }
    .no-content-panel {
      margin-top: 45%;
    }
  }
}

