@import '~bm-public.less';

.bm-c-menu-list {
  // margin-top: 10PX;
  background-color: var(--bm-light-background-color);
  padding-bottom: 316PX;

  // .menu-panel {
  //   padding: 0 32PX;
  // }

  .menu-panel-title {
    width: 506PX;
    padding: 32PX 0 16PX;

    .font-size(32PX);
    .font-family-b;
    .title-color;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .submenus-title-wrapper {
    position: relative;
    display: flex;
  }

  .submenus-title {
    position: relative;
    height: 28PX;
    .font-size(24PX);

    z-index: 2;
    padding-right: 16PX;
    // background-color: var(--bm-light-background-color);
  }

  .submenus-title-line {
    position: absolute;
    height: 2PX;
    top: 13PX;
    left: 0;
    right: 0;
    // background-color: var(--bm-border-color);
    z-index: 1;
  }

  .submenus-content-wrapper {
    &.content-grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .menu-item {
        width: 328px;
        display: flex;
        flex-direction: column;

        .item-img-wrapper {
          width: 100%;
          height: 240px;
        }

        .item-content {
          width: 100%;
          margin-left: 0;
          margin-top: 20px;

          .item-info {
            height: 32px;
          }

          .bm-icon {
            font-size: 48px;
          }

          .price-action {
            margin-top: 8px;
          }
        }
      }
    }
  }

  .menu-item {
    display: flex;
    padding: 24PX 0;


    .item-img-wrapper {
      width: 140px;
      height: 140px;
      background-image: url(/images/default-line-item.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 6PX;
    }

    .item-img {
      width: 100%;
      height: 100%;
      border-radius: 6PX;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .item-content {
      width: 342px;
      margin-left: 24px;
      flex: 1;

      &.sold-out {
        opacity: 0.6;
      }

      .item-info {
        height: 96px;
      }

      .title {
        .font-size(28px);
        .font-family-b;
        .title-color;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .code {
        .font-size(28px);
        color: var(--bm-text-secondary-color);
        margin-top: 18px;
      }

      .describe {
        .font-size(24px);
        margin-top: 8px;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        // height: 52PX;
        overflow: hidden;
        display: -webkit-box;

        color: var(--bm-text-secondary-color);
        p {
          margin: 0;
        }
      }

      .price-action {
        display: flex;
        height: 44PX;
        justify-content: space-between;

        .price {
          display: flex;
          align-items: center;
          .font-size(24PX);
          .font-family-b;
          .title-color;
        }
      }

      .item-count {
        display: flex;
        align-items: center;

        .count {
          justify-content: center;
          // .font-size(28px);
          // .font-family-s;
          // .text-color;

          //padding: 0 8px;
          .am-list-item {
            background-color: var(--bm-light-background-color);
            border: hidden;
            padding: 0;
            height: 32px;
            min-height: initial;
            width: 40px;
          }

          .am-list-line {
            padding: 0;

            input {
              text-align: center;
              padding: 16px 0px;
              .font-size(28px);
              .font-family-s;
              .text-color;
            }
          }
        }

        .sold-out {
          .font-size(24PX);
          .font-family-b;
          .title-color;
        }
      }

      .item-minus {
        margin-right: 10PX;
        color: var(--bm-primary-color-t30);
      }

      .item-plus {
        margin-left: 10PX;
        color: var(--bm-primary-color);
      }
    }
  }

  .menu-panel-special {
    padding: 0;
    .menu-panel-title {
      padding: 12PX 32PX;
      background-color: var(--bm-dark-background-color);
      width: 100%;
      color: var(--bm-text-color);
      font-size: 28PX;
      font-weight: 500;
      line-height: 32PX;
      box-sizing: border-box;
    }
    .submenus-title-wrapper {
      // padding: 0 32PX;
      background-color: var(--bm-dark-background-color);
      align-items: center;
      .submenus-title {
        background-color: var(--bm-dark-background-color);
        padding-bottom: 12PX;
        margin-right: 16PX;
        color: var(--bm-text-secondary-color);
        height: auto;
      }
      .submenus-title-line {
        width: 60PX;
        height: 2PX;
        // background-color: #EAEAEA;
        position: initial;
      }
    }


  }
}
.submenus-content-wrapper {
  &.content-special {
    display: -ms-grid;
    display: grid;
    justify-items: start;
    grid-auto-columns: 1fr;
    grid-column-gap: 28PX;
    grid-row-gap: 25PX;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    max-width: 940PX;
    margin: 0 auto 20PX;
    .item-wrapper {
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
      -ms-grid-column: span 1;
      grid-column-start: span 1;
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
      width: 100%;
      box-sizing: border-box;
      .div-12 {
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 0PX;
        padding-top: 40PX;
        padding-bottom: 40PX;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        border-bottom: 1PX solid #f5f5f5;
        cursor: pointer;
        &.open-modal {
          padding: 20PX;
          -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
          border-bottom-width: 0PX;
          -webkit-transition: all 200ms ease;
          transition: all 200ms ease;
          &:hover {
            border-radius: 12px;
            background-color: #fff;
            box-shadow: 1px 1px 14px -1px rgba(0, 0, 0, 0.18);
          }
          .item-info-wrapper {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            max-width: 100%;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;
          }
        }
      }
    }
    .menu-item {
      padding-right: 32PX;
      position: relative;
      align-items: center;
      &:not(:last-child) {
        border-bottom: 2PX solid var(--bm-border-color);
      }
      .item-content {
        margin-left: 0;
      }
      .item-img-wrapper {
        margin-left: 24PX;
        width: 132PX;
        height: 132PX;
      }
      .item-action {
        position: absolute;
        right: 8PX;
        top: 12PX;
        .item-plus {
          font-size: 42PX;
          color: var(--bm-primary-color);
        }
        .count {
          display: flex;
          min-width: 18PX;
          padding: 0 10PX;
          height: 38PX;
          border-radius: 18PX;
          justify-content: center;
          align-items: center;
          .font-size(20PX);
          .font-family-m;

          color: #fff;
          background-color: var(--bm-primary-color);
        }
      }
    }

    .left-swipe {
      padding-right: 72PX;

      .item-action {
        right: 48PX;
      }
    }

    .right-side-wrap {
      display: flex;
      height: 100%;

      &-left {
        flex: 1;
        display: flex;
        justify-content: center;
        background-color: var(--bm-border-color);
      }

      &-mid {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .am-list-item {
          background-color: var(--bm-light-background-color);
          border: hidden;
          padding: 0PX 16PX;
          color: var(--bm-primary-color);
        }

        .am-list-line {
          padding: 0PX;

          input {
            text-align: center;
          }
        }
      }

      &-right {
        flex: 1;
        display: flex;
        justify-content: center;
        background-color: var(--bm-border-color);
      }

      &-icon {
        color: var(--bm-primary-color);
      }
    }
  }
}
.item-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: unset;
  margin-bottom: 0PX;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  .heading-4-1 {
    margin-top: 0PX;
    margin-bottom: 10PX;
    font-family: 'Sf pro display', sans-serif;
    color: #333;
    font-size: 20PX;
    font-weight: 600;
    text-align: left;
  }
  .paragraph-2 {
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: #333;
    font-size: 14PX;
    line-height: 18PX;
    overflow:hidden;
    text-overflow:ellipsis;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
  }
}
.item-wrapper  .item-action  .count{
  position: absolute;
  top: 10PX;
  right: 12PX;
  bottom: 20PX;
  display: flex;
  width: 25PX;
  height: 25PX;
  justify-content: center;
  align-items: center;
  border-radius: 16PX;
  background-color: var(--bm-primary-color);
  font-family: 'Sf pro display', sans-serif;
  color: var(--bm-light-background-color);
  font-weight: 600;
}
.price {
  max-width: 100%;
  .text-block-6 {
    max-width: none;
    margin-top: 0PX;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: #333;
    font-size: 16PX;
  }
}
.image-open {
  overflow: hidden;
  width: 120PX;
  height: 120PX;
  min-width: 120PX;
  border-radius: 6PX;
  background-position: 50% 50%;
  background-size: cover;
}
.new-menu-item {
  .item-info-wrapper {
    margin-left: 15PX;
  }
}
@media screen and (max-width: 991PX) {
  .submenus-content-wrapper {
    &.content-special {
      grid-column-gap: 16PX;
      grid-row-gap: 16PX;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }
  }
  .item-info {
    .heading-4-1 {
      margin-bottom: 10PX;
      font-size: 17PX;
    }
  }
  .price {
    .text-block-6 {
      font-size: 14PX;
    }
  }
  .image-open {
    width: 80PX;
    height: 80PX;
    min-width: 80PX;
  }
}
@media screen and (max-width: 767PX) {
  .submenus-content-wrapper {
    &.content-special {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      .item-wrapper {
        .div-12 {
          margin-bottom: 0PX;
          padding-top: 20PX;
          padding-bottom: 20PX;
          &.open-modal {
            padding-right: 20PX;
            padding-left: 20PX;
            border-bottom-width: 1PX;
            &:hover {
              border-bottom-width: 1px;
              border-radius: 0px;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
  .item-info {
    .heading-4-1 {
      margin-bottom: 10PX;
      font-size: 17PX;
    }
  }
  .price {
    .text-block-6 {
      font-size: 14PX;
    }
  }
}
@media screen and (max-width: 479PX) {
  .submenus-content-wrapper {
    &.content-special {
      .item-wrapper {
        padding-right: 12PX;
        padding-left: 12PX;
        .div-12{
          &.open-modal {
            padding: 10PX 0 10PX;
            .item-info-wrapper {
              min-width: auto;
            }
          }
        }
      }
    }
  }
  .item-info {
    overflow: visible;
    max-height: none;
    .heading-4-1 {
      margin-top: 0PX;
      margin-bottom: 15PX;
      font-size: 16PX;
      line-height: 16PX;
    }
    .paragraph-2 {
      margin-bottom: 5PX;
      font-size: 12PX;
      line-height: 14PX;
    }
  }
  .price {
    .text-block-6 {
      font-family: 'Sf pro display', sans-serif;
      font-size: 14PX;
      font-weight: 600;
      margin-bottom: 10PX;
    }
  }
  .image-open {
    width: 72PX;
    height: 72PX;
    min-width: auto;
  }

.item-wrapper  .item-action  .count{
  width: 22PX;
  height: 22PX;
  font-size: 12PX;
  right: -5PX;
  top: 3PX;
}
}
