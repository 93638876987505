@import '~bm-public.less';
.space-1 {
  padding-top: 117PX;
}
.bm-p-stores-store-list {
  padding-top: 16PX;
}
.bm-c-store-list-item  {
  .store-info {
    padding: 20PX;
  }
  .store-name {
    font-size: 22PX;
    line-height: 22PX;
    color: var(--bm-text-title-color);
    font-style: normal;
    font-weight: bold;
    height: 24PX;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.bm-c-store-list {
  .bm-c-layout.web &,
  &.group-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .loading-data {
    display: flex;
    .font-size(32px);
    margin: auto;

    justify-content: center;
    align-items: center;
    padding: 32px;

    .loading-msg {
      margin-left: 12px;
    }

    .am-icon-loading {
      width: 32px;
      height: 32px;
    }
  }
  &-store-tag {
    display: flex;
    height: 44px;
    .font-family-s;

    padding: 0 12px;
    align-items: center;
    border-radius: 8px;
    margin-right: 16px;
    margin-top: 8px;

    .bm-icon {
      font-size: 32px;
      padding: 0;
      margin-left: 8px;
    }

    &.booking {
      color: #a68bea;
      background-color: #a68bea1a;
    }

    &.take-away {
      color: #fbac36;
      background-color: #fbac361a;
    }

    &.delivery {
      color: #86cc7a;
      background-color: #86cc7a1a;
    }

    &.quick {
      color: #4c95ff;
      background-color: #4c95ff1a;
    }

    &.voucher {
      color: #ff5d5d;
      background-color: #ff5d5d1a;
    }

    &-box {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &-icon-left {
    .lazyload-wrapper {
      width: auto;
      border-radius: 8px;
    }

    &-wapper {
      display: flex;
      padding: 16px 32px;
    }

    &-img {
      height: 132px;
      width: 176px;
      border-radius: 8px;
      border: 1px solid var(--bm-border-color);
    }

    &-line {
      margin: auto 32px;
      height: 2px;
      background-color: var(--bm-border-color);
    }

    &-content {
      margin-left: 20px;
    }
  }
  .store-other-info {
    display: flex;
    align-items: center;
    margin-top: 15PX;
    .store-address {
      color: var(--bm-text-secondary-color);
      font-size: 18PX;
    }
    .store-score {
      display: flex;
      align-items: center;
      font-size: 18PX;
    }

    .bm-icon {
      color: #ffb802cc;
      font-size: 14PX;
      margin-right: 4PX;
    }

    .splace-line {
      height: 16PX;
      width: 1PX;
      margin: 0 10PX;
      background-color: #EAEAEA;
      border-radius: 1PX;
    }

    .store-category {
      color: var(--bm-text-secondary-color);
      font-size: 18PX;
    }
  }
  .section {
    background-color: var(--bm-dark-background-color);
  }
  .text-block-5---subtitle.store-category {
    font-size: 16PX;
  }
  .section.bm-c-store-list-section {
    padding-top: 0;
  }
  &-store-tag-box {
    margin-top: 0;
    .take-away {
      color: #fbac36;
      background-color: #fbac361a;
    }
    .quick {
      color: #4c95ff;
      background-color: #4c95ff1a;
    }
    &-store-tag {
      height: 32PX;
      font-size: 16PX;
    }
    .bm-icon {
      font-size: 18PX;
    }
  }
}
.bm-c-store-list.space-1 {
  display: block !important;
}
.container._1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}
.branch-card {
  overflow: hidden;
  width: 47%;
  height: 300PX;
  margin-right: auto;
  margin-bottom: 30PX;
  margin-left: auto;
  padding-bottom: 15PX;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 6PX;
  background-color: #fff;
  color: var(--bm-text-title-color);
  text-decoration: none;
  cursor: pointer;
}
.branch-card-width.branch-card {
  width: 48.5%;
}
.bm-c-store-list-item.branch-card {
  height: unset;
  margin-left: unset;
}
.bm-c-store-list-item.branch-card:last-child {
  margin-left: 0;
}
.bm-c-store-list-item.branch-card:nth-child(2n) {
  margin-right: 0;
}
.image-card-upper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: auto;
  height: 78%;
  margin-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px;
  background-image: url(/images/default-store.png);
  background-size: cover;
  background-position: center center;
}
.store-img {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 8PX;
  border-top-right-radius: 8PX;
  position: absolute;
  left: 0;
  top: 0;
}
._1 {
  .image-card-upper {
    height: 71%;
  }
  .bgimg-size.image-card-upper  {
    height: 0;
    padding-bottom: 52%;
    width: 100%;
    position: relative;
  }
}
.div-block-99 {
  height: auto;
  padding: 0px 15PX;
}
.heading-4 {
  font-family: 'Sf pro display', sans-serif;
  font-weight: 600;
}
h4 {
  font-size: 18PX;
  line-height: 24PX;
  margin-top: 10PX;
  margin-bottom: 10PX;
}
.lazyload-wrapper {
  width: unset;
}
.phone {
  ._1 {
    // display: block;
    // >div {
    //   width: 100%;
    // }
    img {
      width: 100%;
      height: 100%;
    }
  }
  // .bm-c-store-list-item.branch-card {
  //   height: unset;
  // }
  // .bgimg-size.image-card-upper {
  //   padding-bottom: 41%;
  // }
}
.no-more-content {
  position: relative;
  display: flex;
  color: var(--bm-text-secondary-color-t60);
  justify-content: center;

  span {
    padding: 0 24px;
    background-color: var(--bm-dark-background-color);
    z-index: 2;
    font-size: 28px;
  }

  &::before {
    content: ' ';
    background-color: var(--bm-text-secondary-color-t60);
    width: 50%;
    height: 1px;
    position: absolute;
    top: 20px;
  }
}
@media screen and (max-width: 991px) {
  .space-1 {
      padding-top: 131PX;
  }
  .branch-card.bm-c-store-list-item {
    width: 47%;
    margin-bottom: 30PX;
  }
  .heading-4 {
    margin-bottom: 5PX;
  }
  .text-block-5---subtitle {
    font-size: 14PX;
  }
  .bm-c-store-list {
    &-store-tag {
      height: 32PX;
      font-size: 14PX;
    }
    .bm-icon {
      font-size: 18PX;
    }
  }
}
@media screen and (max-width: 767px) {
  .bm-c-store-list-item  {
    .store-info {
      padding: 15PX;
    }
    .store-name {
      font-size: 18PX;
      line-height: 18PX;
      height: 18PX;
    }
  }
  .bm-c-store-list {
    .store-other-info {
      .store-address {
        font-size: 16PX;
      }
    }
  }
  .space-1 {
    padding-top: 115PX;
  }
  .branch-card.bm-c-store-list-item {
    width: 48%;
    margin-bottom: 15PX;
  }
  ._1 {
    .image-card-upper {
      height: 152PX;
      border-radius: 0px;
    }
  }

  .text-block-5---subtitle {
    margin-bottom: 0px;
    font-size: 14PX;
    line-height: 16PX;
  }
  .bm-c-store-list {
    &-store-tag {
      height: 30PX;
      font-size: 14PX;
    }
    .bm-icon {
      font-size: 18PX;
    }
  }
}
@media screen and (max-width: 479px) {
  .bm-c-store-list-item  {
    .store-info {
      padding: 10PX;
    }
    .store-name {
      font-size: 14PX;
      line-height: 14PX;
      height: 14PX;
    }
  }
  .bm-c-store-list {
    .store-other-info {
      margin-top: 10PX;
      .store-address {
        font-size: 12PX;
      }
    }
  }
  .bm-c-layout.web {
    .bm-c-store-list-item.branch-card {
      // width: 47%;
      width: 100%;
      height: unset;
      margin-right: auto;
      margin-bottom: 10PX;
    }
  }

  ._1 {
    .image-card-upper {
      margin-bottom: 10PX;
      border-radius: 0px;
    }
    img {
      height: 100%;
    }
  }

  .div-block-99 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 40%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .heading-4 {
    margin-top: 5PX;
    margin-bottom: 5PX;
    font-size: 16PX;
    line-height: 22PX;
  }
  .text-block-5---subtitle {
    font-size: 12PX;
    line-height: 16PX;
  }
  .bgimg-size.image-card-upper  {
    background-size: cover;
  }
  .branch-card.bm-c-store-list-item {
    width: 100%;
  }
  .bm-c-store-list {
    &-store-tag {
      height: 30PX;
      font-size: 12PX;
    }
    .bm-icon {
      font-size: 16PX;
    }
  }
}
