@import '~bm-public.less';

.bm-c-mini-program-tips {
  .add-tips-wrap {
    position: fixed;
    top: 10px;
    z-index: 99;
    right: 16px;
    .add-tips {
      padding: 20px 28px; 
      background-color: #fff;
      color: #333;
      border-radius: 40px;
      font-size: 26px;
      line-height: 30px;
      display: flex;
      align-items: center;
    }
    .triangle-up {
      width: 0;
      height: 0;
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-bottom: 13px solid #fff;
      position: absolute;
      right: 110px;
      top: -10px
    }
    .close-tip {
      font-size:32px;
      color: #333;
      padding: 0;
    }
  }
}
.bm-c-mini-program-tips.hide {
  display: none;
}
