@import '~bm-public.less';

.bm-c-splash {
  position: absolute;
  .phone & {
    left: 0;
    right: 0;
  }
  top: 0;
  bottom: 0;
  height: calc(var(--bm-safe-area-top) * 2 + 100%);
  z-index: 10000;
  background-color: var(--bm-dark-background-color);
  transition: all 0.4s ease;
  .splash-nav-bar {
    position: fixed;
    top: 0;
    padding-top: 75PX;
    width: 100%;
  }

  .splash-slider {
    position: relative;
    display: block;
    overflow: hidden;
    height: 100%;
    margin: 0;
    padding: 0;
    transform: translate3d(0, 0, 0);
    box-sizing: border-box;
  }

  .slider-list {
    transform: translate3d(0, 0, 0);
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 2250px;
    cursor: inherit;
    box-sizing: border-box;
    transition: all 0.3s ease;
  }

  ul {
    list-style-type: none;
  }

  .slider-slide {
    position: absolute;
    top: 0;
    display: inline-block;
    list-style-type: none;
    vertical-align: top;
    height: 100%;
    box-sizing: border-box;
    margin: auto 0;
  }

  .btns-wrapper {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 276px;
    color: var(--bm-text-title-color);
    .font-size(32px);
    .font-family-b;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .splash-done-btn,
  .splash-next-btn {
    height: 100px;
    margin: 0 40px;
    border-radius: 100px;
    line-height: 100px;
    text-align: center;
    background-color: var(--bm-light-background-color);
    border: 1px solid var(--bm-text-color);
  }

  .splash-done-btn {
    width: 100%;
  }

  .splash-next-btn {
    width: 240px;
  }

  .splash-dots {
    height: 100px;
    margin: 0;
    padding-left: 40px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;

    li {
      width: 16px;
      height: 16px;
      border-radius: 16px;
      margin-right: 16px;
      opacity: 0.6;
      background-color: var(--bm-light-background-color);
      transition: all 0.3s;
      border: 1px solid var(--bm-text-color);

      &.dot-selected {
        width: 40px;
        opacity: 1;
        border-radius: 10px;
        transition: all 0.3s;
      }
    }
  }

  &-item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100% !important;
    background-color: var(--bm-primary-color);

    .image-item-img-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--bm-dark-background-color);
    }

    .image-item-img {
      width: 100%;
      height: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .itme-space {
      flex: 1;
    }

    .image-item-content-wrapper {
      position: absolute;
      left: 0;
      bottom: 325px;
      width: 100%;
      height: 50%;
      display: flex;
      flex-direction: column;
      // padding: 0 40px 300px 40px;
    }

    // .logo-item-content-wrapper {
    //   // flex: 1;
    // }

    .image-item-content {
      padding: 0 40px 0 40px;
      color: #fff;
      text-align: center;
    }

    .logo-item-img-wrapper {
      flex: 1;
      display: flex;
      justify-items: center;
      align-items: center;

      img {
        margin: 0 auto;
        max-width: 80%;
        max-height: 80%;
      }
    }

    .logo-item-img-box {
      width: 440px;
      height: 240px;
    }

    .logo-item-content {
      background-color: #fff;
      padding: 0 40px 300px 40px;
      .text-color;
    }

    .image-item-title,
    .logo-item-title {
      padding-top: 64px;
      font-size: 40px;
      line-height: 60px;
      .font-family-b;
    }

    .image-item-describe,
    .logo-item-describe {
      margin-top: 40px;
      font-size: 32px;
      line-height: 48px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }

    .item-video {
      width: 100%;
      height: 100%;
      background: #000;
    }
  }
}
