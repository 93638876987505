@import '~bm-public.less';

.bm-c-forgot {
  &-wrap {
    padding: 48px 32px 0;
  }

  &-email-text {
    margin-top: 20px;
    color: var(--bm-text-secondary-color);
    font-size: 28px;
  }

  &-mobile-verify {
    margin-top: 32px;

    .am-input-item {
      height: 88px;
      border-radius: 12px;
      border: 2px solid var(--bm-border-color);
      background-color: var(--bm-input-backgroud-color);

      &.am-input-focus {
        border: 2px solid var(--bm-primary-color);
      }

      .bm-icon {
        padding: 0;
      }
    }

    .am-input-control input {
      .font-size(28px);
      .font-family-m;
    }

    .am-input-extra {
      margin-left: 32px;
      max-height: 40px;
    }

    &-send {
      font-size: 24px;
      color: var(--bm-primary-color);
    }

    &-password-icon {
      font-size: 24px;
      color: var(--bm-primary-color);
    }

    &-password-icon svg {
      fill: var(--bm-primary-color);
      font-size: 28px;
    }
  }

  &-mobile-password {
    margin-top: 32px;

    .am-input-item {
      height: 88px;
      border-radius: 12px;
      border: 2px solid var(--bm-border-color);
      background-color: var(--bm-input-backgroud-color);

      &.am-input-focus {
        border: 2px solid var(--bm-primary-color);
      }

      .bm-icon {
        padding: 0;
      }
    }

    .am-input-control input {
      .font-size(28px);
      .font-family-m;
    }

    .am-input-extra {
      margin-left: 32px;
      max-height: 40px;
    }

    .password-check {
      margin-top: 8px;
      color: var(--bm-error-color);
    }
  }

  &-signin-button {
    margin-top: 32px;
  }
}
.am-modal-alert-content {
  .status-success {
    font-size: 250px;
  }
}
