@import '~bm-public.less';

.bm-p-new-order-detail {
  .dark-bc;
  .circle-back-icon-order-detail {
    background-color: var(--bm-light-b-color-t60);
    border: 2PX solid var(--bm-light-b-color-t40);
    border-radius: 50%;
  }
  .background-top.with-bg {
    height: 700PX;
    background-color: var(--bm-primary-color);
    // background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.3)), to(rgba(71, 71, 71, 0.51))), url(https://assets.website-files.com/605173a…/60d6ecc…_bg-image.jpeg);
    // background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(71, 71, 71, 0.51)), url(https://assets.website-files.com/605173a…/60d6ecc…_bg-image.jpeg);
    // background-position: 0px 0px, 100% 50%;
    // background-size: auto, cover;
  }
  .qr-code-display {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20PX;
    margin-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .text-block-77.white {
    margin-bottom: 20PX;
    font-family: 'Sf pro display', sans-serif;
    color: var(--bm-light-background-color);
    font-weight: 600;
  }
  .text-block-77 {
    margin-right: auto;
    margin-bottom: 5PX;
    margin-left: auto;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-size: 18PX;
    font-weight: 700;
    text-align: left;
  }
  .div-block-505 {
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding: 13PX;
    border-style: solid;
    border-width: 2PX;
    border-color: rgba(163,162,161,.65);
    border-radius: 12PX;
    // background-color: rgba(255, 255, 255, 0);
  }
  .div-block-504 {
    width: 250PX;
    height: 250PX;
    margin-right: 0px;
    margin-left: 0px;
    // background-color: #fff;
    // background-image: url(https://assets.website-files.com/605173a…/60d6e6d…_timg.png);
    background-position: 50% 50%;
    background-size: 90%;
    background-repeat: no-repeat;
  }
  .div-block-485 {
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .div-block-485.payment-confirmation {
    padding-top: 0px;
  }
  .right-block.rounded-all {
    padding-top: 50PX;
  }
  .right-block>div {
    box-sizing: border-box;
  }
  .delivery-info-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: auto;
    max-width: 100%;
    min-width: 100%;
    margin-right: 0px;
    margin-bottom: 20PX;
    padding: 20PX;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 8PX;
    background-color: var(--bm-light-background-color);
    box-shadow: 1PX 1PX 18PX 0 var(--bm-shadow-color-t50); 
  }
  .order-confirmation-info {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 20PX 0px 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-style: solid;
    border-width: 0px;
    border-color: #000 #000 #eaeaea;
    color: var(--bm-text-title-color);
    cursor: auto;
  } 
  .div-block-506 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 20PX;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .text-block-101 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-size: 30PX;
    font-weight: 700;
  }
  .text-block-102 {
    margin-right: 0px;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-size: 20PX;
    font-weight: 700;
    line-height: 34PX;
  }
  .div-block-507 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .secondary-payment-confirmation-buttons-copy {
    position: relative;
    left: auto;
    top: 0px;
    right: 0%;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: 55PX;
    margin-right: 20PX;
    padding: 15PX;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    // border-style: solid;
    // border-width: 1PX;
    // border-color: #a39d9d;
    border-radius: 8PX;
    background-color: var(--bm-primary-color);
    color: var(--bm-light-background-color);
    cursor: pointer;
    box-sizing: border-box;
  }
  .text-block-67.grey {
    margin-bottom: 20PX;
    color: var(--bm-text-color);
  }
  .text-block-67.bold,
  .order-flex.bold {
    margin-bottom: 0px;
    font-family: 'Sf pro display', sans-serif;
    font-weight: 600;
    text-transform: none;
  }
  .order-flex.bold {
    font-size: 18PX;
  }
  .time-name {
    color: var(--bm-text-title-color);;
  }
  .time {
    color: var(--bm-text-color);
  }
  .order-confirmation-info-padding {
    padding: 20PX;
    background-color: var(--bm-light-background-color);
    border-radius: 8PX;
    margin-bottom: 20PX;
    box-shadow: 1PX 1PX 18PX 0 var(--bm-shadow-color-t50); 
  }
  .order-flex {
    display: flex;
    justify-content: space-between;
  }
  .order-summary-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: auto;
    max-width: 100%;
    min-width: 100%;
    margin-right: 0px;
    margin-bottom: 30PX;
    padding: 20PX;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 8PX;
    box-shadow: 1PX 1PX 18PX 0 var(--bm-shadow-color-t50); 
    background-color: var(--bm-light-background-color);
  }
  .div-block-493.with-margin {
    padding-top: 0px;
    padding-bottom: 0px;
    border-bottom-width: 0px;
  }

  // .div-block-493 {
  //   padding-top: 20PX;
  //   padding-bottom: 20PX;
  //   border-bottom: 1PX solid var(--bm-border-color);
  //   color: #333;
  // }
  .div-block-493 {
    border-width: 0;
    padding-bottom: 0;
    padding-top: 0;
  }

  .div-block-484 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 15PX;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  } 
  .text-block-68 {
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-title-color);
    font-size: 18PX;
  }
  .text-block-98 {
    margin-right: auto;
    margin-bottom: 0px;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-size: 18PX;
    font-weight: 400;
    color: var(--bm-text-title-color);
  }
  .delivery-address {
    font-size: 17PX;
    color: var(--bm-text-title-color);
  }
  .div-block-483 {
    width: 100%;
    height: 1PX;
    margin-top: 20PX;
    margin-bottom: 20PX;
    background-color: var(--bm-border-color);
  }
  .image-199 {
    margin-right: 10PX;
    .bm-icon {
      width: 35PX;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .payment {
    display: flex;
    div:last-child {
      line-height: 30PX;
    }
  }
  .text-block-67 {
    margin-bottom: 0px;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-title-color);
    font-size: 18PX;
    font-weight: 400;
  }
  .left-block.payment-confirmation {
    margin-top: 0px;
    padding-top: 50PX;
  }
  .order-block {
    margin-bottom: 20PX;
    padding: 20PX;
    border-radius: 8PX;
    background-color: var(--bm-light-background-color);
    box-shadow: 0 0 10PX 0 var(--bm-shadow-color-t50); 
  }
  .check-out-info.restaurant-info {
    padding: 20PX 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .left-block {
    width: auto;
    height: auto;
    margin-top: 100PX;
    margin-right: 20PX;
    padding: 0px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-radius: 12PX;
    background-color: rgba(255, 255, 255, 0);
    box-shadow: none;
    flex: 1 1;
  }
  .nav_bg.streched {
    position: fixed;
    z-index: 9800000;
    height: auto;
    max-height: 70PX;
    min-height: 70PX;
    border-bottom: 0px solid rgba(0, 0, 0, 0.1);
    background-color: var(--bm-primary-color);
    background-image: none;
    background-size: auto;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .nav_bg {
    position: -webkit-sticky;
    position: sticky;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    height: 52px;
    background-color: var(--bm-primary-color);
  }
  .container-stretched_1 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    max-height: 70PX;
    max-width: none;
    min-height: 70PX;
    margin-right: 60PX;
    margin-left: 60PX;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .background-top {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    height: 260PX;
    background-color: var(--bm-primary-color);
  }
  .section {
    background-color: var(--bm-dark-background-color);
  }
  .right-block {
    position: -webkit-sticky;
    position: sticky;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 40%;
    height: auto;
    margin-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .text-block-17 {
    font-family: 'Sf pro display', sans-serif;
    font-size: 18PX;
    font-weight: 600;
  }

  &-skeleton {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 100;
    .skeleton-bm-c-dining {
      height: 650px;
      background-color: #e7e7ef;
      padding-top: var(--bm-safe-area-top);
    }
    .skeleton-icon {
      height: 30px;
      .circle-back-icon-outlined {
        background-color: #f2f2f2 !important;
        margin-left: 25px;
        top: 25px;
      }
    }

    .skeleton-page-content {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: var(--bm-dark-background-color);
    }
    .skeleton-main {
      position: absolute;
      top: -560px;
      padding: 30px;
      padding-top: unset;
      width: 100%;
      box-sizing: border-box;
    }
    .skeleton-top1,
    .skeleton-top2,
    .skeleton-top3  {
      background-color: #fff;
      margin: auto;
      height: 26px;
    }
    .skeleton-top1 {
      width: 300px;
    }
    .skeleton-top2 {
      width: 200px;
      margin-top: 10px;
      margin-bottom: 26px;
    }
    .skeleton-top3 {
      height: 380px;
      width: 380px;
      background-color: unset;
      border-radius: 20px;
      border: 5px solid #fff;
      padding: 14px;
      box-sizing: border-box;
      div {
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 15px;
      }
    }
    .skeleton-ds-content {
      margin-top: 30px;
      padding: 30px;
      background-color: #fff;
      border-radius: 10px;
      div {
        .skeleton-color;
      }
      .skeleton-order-tip {
        height: 25px;
        width: 80%;
        margin-bottom: 30px;
      }
      .skeleton-buttons {
        height: 45px;
        width: 28%;
        border-radius: 10px;
      }
    }
    .skeleton-shipping-method {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      padding: 30px;
      background-color: #fff;
      div {
        height: 34px;
        .skeleton-color;
        width: 20%;
      }
      div:first-child {
        width: 40%;
      }
    }
    .skeleton-content {
      background-color: #fff;
      margin-top: 30px;
    }
    .skeleton-listings-item {
      border-top: 1px solid #eee;
      display: flex;
      justify-content: space-between;
      padding: 20px 30px;
      .item-icon-wrapper1 {
        width: 128px;
        height: 128px;
        .skeleton-color;
        border-radius: 10px;
      }
      .item-icon-wrapper2 {
        width: 55%;
        height: 128px;
        .wrapper2-1 {
          height: 25px;
          width: 100%;
        }
        div {
          height: 20px;
          .skeleton-color;
        }
        .wrapper2-2 {
          width: 50%;
          margin: 16px 0 46px 0;
        }
      }
      .item-icon-wrapper3 {
        .skeleton-color;
        width: 10%;
        height: 30px;
        margin-left: 60px;
      }
    }

    .line {
      margin-top: 30px;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      padding: 0 30px;
      div {
        width: 40%;
        height: 35px;
        .skeleton-color;
      }
      div:last-child {
        width: 16%;
      }
    }
    .line-total {
      padding: 0 30px 30px 30px;
      display: flex;
      justify-content: space-between;
      div {
        width: 20%;
        height: 40px;
        .skeleton-color;
      }
    }
    .skeleton-transaction {
      margin-top: 30px;
      background-color: #fff;
      padding: 30px;
      div:last-child {
        margin-bottom: 0;
      }
    }
    .skeleton-line1,
    .skeleton-line2,
    .skeleton-line3,
    .skeleton-line4 {
      display: flex;
      justify-content: space-between;
      height: 26px;
      margin-bottom: 20px;
      div {
        .skeleton-color;
      }
    }
    .skeleton-line1 {
      div {
        width: 25%;
      }
      div:last-child {
        width: 60%;
      }
    }
    .skeleton-line2 {
      div {
        width: 20%;
      }
      div:last-child {
        width: 15%;
      }
    }
    .skeleton-line3 {
      div {
        width: 16%;
      }
      div:last-child {
        width: 40%;
      }
    }
    .skeleton-line4 {
      div {
        width: 18%;
      }
      div:last-child {
        width: 55%;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .bm-p-new-order-detail {
    .background-top.with-bg {
      height: 600PX;
    }
    .div-block-485.payment-confirmation {
      margin-top: 0;
    }
    .right-block.rounded-all {
      overflow: visible;
      margin-bottom: 20PX;
      padding-top: 0px;
      border-bottom-left-radius: 12PX;
      border-bottom-right-radius: 12PX;
    }
    .delivery-info-block {
      margin-bottom: 20PX;
      padding: 0px;
      border-radius: 8PX;
      box-shadow: 1PX 1PX 8PX 0 var(--bm-shadow-color-t50); 
    }
    .secondary-payment-confirmation-buttons-copy {
      position: static;
      top: 0px;
      right: 0%;
      margin-right: 20PX;
    }
    .order-confirmation-info {
      margin-top: 20PX;
      margin-right: 20PX;
      margin-left: 20PX;
      border-bottom-width: 0px;
    }
    .order-summary-block {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 0px;
      padding: 0px;
      border-radius: 8PX;
      box-shadow: 0 1PX 7PX 0 var(--bm-shadow-color-t50); 
    }
    .order-confirmation-info-padding {
      box-shadow: 0 1PX 7PX 0 var(--bm-shadow-color-t50); 
    }
    .div-block-493.with-margin {
      margin: 20PX;
    }
    .div-block-483 {
      margin-top: 20PX;
      margin-bottom: 20PX;
    }
    .left-block.payment-confirmation {
      padding-top: 0px;
    }
    .order-block {
      border-radius: 8PX;
    }
    .check-out-info.restaurant-info {
      margin-right: 0px;
      margin-left: 0px;
      padding-right: 0px;
      padding-left: 0px;
    }
    .left-block {
      width: 100%;
      margin-top: 0px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      box-shadow: none;
    }
    .div-block-483 {
      margin-top: 20PX;
      margin-bottom: 20PX;
    }
    .container-stretched_1 {
      margin-right: 30PX;
      margin-left: 30PX;
    }
    .right-block {
      position: static;
      overflow: hidden;
      width: 100%;
      -webkit-box-ordinal-group: 0;
      -webkit-order: -1;
      -ms-flex-order: -1;
      order: -1;
      border-top-left-radius: 12PX;
      border-top-right-radius: 12PX;
    }
    .div-block-485 {
      margin-top: 89PX;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }




  }
}

@media screen and (max-width: 767px) {
  .bm-p-new-order-detail {
    .qr-code-display {
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .text-block-77 {
      margin-right: 0px;
      margin-left: 0px;
      font-size: 15PX;
    }
    .text-block-67,
    .order-flex {
      margin-bottom: 0px;
      font-family: 'Fontsfree net sfprodisplay', sans-serif;
      color: var(--bm-text-color);
      font-size: 18PX;
      font-weight: 400;
    }
    .text-block-67.bold {
      font-size: 18PX;
    }
    .order-flex.bold {
      font-size: 15PX;
    }
    .secondary-payment-confirmation-buttons-copy {
      left: auto;
      top: -22%;
      right: -5%;
      bottom: 0%;
    }
    .delivery-address {
      .address {
        font-size: 15PX;
      }
    }
    .nav_bg.streched {
      max-height: none;
      max-width: none;
      min-height: 60PX;
    }
    .container-stretched_1.both {
      width: auto;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
    .container-stretched_1 {
      z-index: 800;
      max-height: 60PX;
      min-height: 60PX;
    }
    .text-block-17 {
      font-size: 18PX;
    }



  }
}
@media screen and (max-width: 479px) {
  .bm-p-new-order-detail {
    .background-top.with-bg {
      height: 450PX;
    }
    .qr-code-display {
      margin-bottom: 20PX;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .text-block-77.white {
      text-align: center;
    }
    .text-block-77 {
      margin-bottom: 0px;
    }
    .div-block-505 {
      padding: 10PX;
    }
    .div-block-504 {
      width: 200PX;
      height: 200PX;
    }
    .div-block-485.payment-confirmation {
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .right-block.rounded-all {
      margin-bottom: 10PX;
    }
    .delivery-info-block {
      margin-bottom: 10PX;
    }
    .order-confirmation-info {
      margin-top: 0px;
      margin-right: 0px;
      margin-left: 0px;
      padding: 13PX;
    }
    .text-block-101 {
      margin-bottom: 0px;
      font-size: 22PX;
    }
    .text-block-102 {
      margin-right: 0px;
      font-size: 18PX;
    }
    .text-block-67.grey {
      margin-bottom: 10PX;
    }
    .text-block-67,
    .order-flex {
      margin-bottom: 0px;
      font-size: 14PX;
    }
    .text-block-67.bold {
      font-size: 14PX;
    }
    .secondary-payment-confirmation-buttons-copy {
      left: auto;
      top: 0%;
      right: 0%;
      bottom: 0%;
      width: auto;
      height: auto;
      margin-right: 15PX ;
      padding: 10PX 20PX;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
    }
    .order-summary-block {
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    .order-confirmation-info-padding {
      margin-bottom: 10PX;
    }
    .div-block-493.with-margin {
      margin: 13PX;
    }
    .div-block-484 {
      margin-bottom: 10PX;
    }
    .text-block-98,
    .text-block-68 {
      font-size: 14PX;
    }
    .delivery-address {
      .address {
        font-size: 13PX;
      }
    }
    .image-199 {
      .bm-icon {
        width: 26PX;
        height: 28PX;
      }
    }
    .text-block-67 {
      margin-bottom: 0px;
      font-size: 14PX;
      margin-top: 0;
    }
    .order-block {
      margin-bottom: 10PX;
      padding: 13PX;
      box-shadow: 0 0 10PX 0 var(--bm-shadow-color-t50); 
    }
    .check-out-info.restaurant-info {
      margin-right: 0px;
      margin-left: 0px;
      padding: 13PX 13PX 13PX 0px;
    }
    .nav_bg.streched {
      max-height: 50PX;
      min-height: 50PX;
    }
    .container-stretched_1 {
      max-height: 50PX;
      min-height: 50PX;
      margin-right: 16PX;
      margin-left: 17PX;
    }
    .background-top {
      height: 180PX;
    }
    .text-block-17 {
      font-size: 14PX;
    }




  }
}