@import '~bm-public.less';

.bm-p-account {
  background-color: var(--bm-light-background-color);
  padding-bottom: 90PX;
  .account-page-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 940PX;
    position: relative;
    display: block;
    overflow: visible;
    margin-top: auto;
    margin-bottom: auto;
    .avatar {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-size: contain;
    }
    @media screen and (max-width: 991px) {
      max-width: 728PX;
      .personal-msg {
        .avatar-wrap {
          .avatar-b {
            width: 100PX;
            height: 100PX;
            .edit-icon-wrap {
              width: 30PX;
              height: 30PX;
            }
          }
        }
        .sign-btn-wrap {
          .sign-btn {
            width: 100PX;
            height: 38PX;
            line-height: 38PX;
            font-size: 18PX;
            border-radius: 10PX;
          }
        }
      }
      .sign-out-btn {
        height: 60PX;
        margin: 24PX;
        border-radius: 10PX;
        line-height: 60PX;
        font-size: 20PX;
      }
    }
    @media screen and (max-width: 767px) {
      .personal-msg {
        .avatar-wrap {
          margin-bottom: 40PX;
        }
      }
      .personal-setting {
        padding-left: 20PX;
      }
    }
    @media screen and (max-width: 479px) {
      margin-top: 0px;
      margin-bottom: 0px;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      max-width: none;
      .personal-msg {
        .avatar-wrap {
          margin-bottom: 20PX;
          .avatar-b {
            width: 70PX;
            height: 70PX;
          }
        }
        .sign-btn-wrap {
          .sign-btn {
            width: 70PX;
            height: 28PX;
            line-height: 28PX;
            font-size: 14PX;
            border-radius: 8PX;
          }
        }
      }
      .sign-out-btn {
        height: 50PX;
        margin: 22PX;
        border-radius: 8PX;
        line-height: 50PX;
        font-size: 18PX;
      }
      .personal-setting {
        padding-left: 16PX;
      }
    }
  }
  .personal-msg {
    padding: 60PX 0 20PX;

    .avatar-wrap {
      display: flex;
      justify-content: center;
      margin-bottom: 40PX;

      .avatar-b {
        width: 120PX;
        height: 120PX;
        border-radius: 50%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--bm-primary-color-t12);

        .avatar-icon {
          font-size: 62px;
          padding: 0;
          border-radius: 50%;
          color: var(--bm-light-background-color);
        }

        .edit-icon-wrap {
          position: absolute;
          right: 0;
          bottom: 0;
          background-color: var(--bm-light-background-color);
          width: 35PX;
          height: 35PX;
          border-radius: 50%;
          .edit-icon {
            width: 100%;
            height: 100%;
            padding: 0;
          }
        }
      }
    }

    .sign-btn-wrap {
      justify-content: center;
      display: flex;

      .sign-btn {
        width: 120PX;
        height: 40PX;
        border-radius: 12PX;
        background-color: var(--bm-primary-color);
        color: var(--bm-light-background-color);
        font-size: 20PX;
        text-align: center;
        line-height: 40PX;
        .font-family-b;
      }
    }
  }

  .sign-out-btn {
    height: 65PX;
    background-color: var(--bm-primary-color);
    color: var(--bm-light-background-color);
    margin: 32PX;
    border-radius: 12PX;
    text-align: center;
    line-height: 65PX;
    font-size: 24PX;
    .font-family-b;
  }
}
