@import '~bm-public.less';

.bm-p-stores {
  .dark-bc;

  // .pc-header {
  //   height: calc(var(--bm-safe-area-top) + 88px);
  //   // background-color: var(--bm-primary-color);
  //   background-color: var(--bm-dark-background-color);
  // }

  // &.has-service-types .pc-header {
  //   height: calc(var(--bm-safe-area-top) + 180px);
  // }

  .menus-icon {
    font-size: 40px;
  }

  .pc-footer {
    height: 152px;
    background-color: var(--bm-dark-background-color);
  }

  .nav-right-content {
    color: #fff;
  }

  .page-content {
    .dark-bc;
  }

  &-store-list {
    margin-top: 16px;
    padding: 0 32px;
  }

  .no-stores {
    display: block;
    position: static;
    top: 88px;
    bottom: 152px;
    width: 100%;
  }

  &.has-service-types .no-stores {
    top: 180px;
  }

  .panel-bar {
    margin-top: 32px;
    padding: 0 32px;
    display: flex;
    justify-content: space-between;

    .bar-left {
      display: flex;
      align-items: center;
      .title-color;
      .font-size(36px);
      .font-family-b;
    }

    .bar-right {
      display: flex;
      align-items: center;
      .text-s-color;

      .bm-icon {
        font-size: 32px;
      }
    }
  }

  &-recommended-stores {
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    width: 100%;
    // padding: 0 32px;

    .store-list {
      display: flex;
      overflow-y: auto;
      padding-left: 32px;

      .store-img-wrapper {
        height: 268px;
        background-image: url(/images/default-store.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }

      .bm-c-store-list-item {
        white-space: nowrap;
        margin: 0;

        .store-info {
          width: 500px;
        }

        &:not(:first-child) {
          margin-left: 32px;
        }
      }

      .last-space {
        height: 100%;
        width: 32px;
      }
    }
  }

  .bar-icons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;

    .scan-outlined {
      font-size: 40px;
    }

    .search-outlined {
      font-size: 40px;
    }

    .mark {
      position: absolute;
      display: flex;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      top: calc(var(--bm-safe-area-top) + 8px);
      right: 0.4rem;
      background-color: var(--bm-light-background-color);
      font-size: 0.3rem;
      color: var(--bm-primary-color);
      justify-content: center;
    }
  }

  &-service-types {
    position: fixed;
    .phone & {
      left: 0;
      right: 0;
    }
    top: calc(var(--bm-safe-area-top) + 88px);
  }

  .no-more-content {
    position: relative;
    display: flex;
    color: var(--bm-text-secondary-color-t60);
    justify-content: center;

    span {
      padding: 0 24px;
      background-color: var(--bm-dark-background-color);
      z-index: 2;
    }

    &::before {
      content: ' ';
      background-color: var(--bm-text-secondary-color-t60);
      width: 50%;
      height: 1px;
      position: absolute;
      top: 20px;
    }
  }

  &-map-filter {
    position: fixed;
    display: flex;
    bottom: 2rem;
    left: 256px;
    right: 256px;
    height: 80px;
    .font-size(24px);
    z-index: 9;
    width: 200px;
    margin: auto;

    &-wapper {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 64px;
      background-color: var(--bm-light-background-color);
      box-shadow: 0 0 20px 0 var(--bm-shadow-color);

      :hover{
        color: var(--bm-primary-color);
      }

      &-line {
        width: 2px;
        height: 32px;
        background-color: var(--bm-border-color);
        // margin-left: 16px;
        // margin-right: 16px;
      }

      &-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
      }
    }
  }
}
.bm-c-store-filter-modal-wapper {
  font-size: 0.32rem;
}
.web {
  .skeleton-store {
    display: flex;
  }
  .skeleton-store-item:first-child {
    margin-right: 40px;
  }
}
