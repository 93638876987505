@import '~bm-public.less';

.sign-in-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60PX;
  margin-bottom: 20PX;
  padding: 15PX 20PX;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4PX;
  background-color: var(--bm-primary-color);;
  color: #fff;
  text-decoration: none;
}
.input-style {
  border: 1PX solid rgba(0, 69, 144, 0.09)!important;
    border-radius: 8PX!important;
    background-color: #ffffff!important;
    font-size: 16PX;
}
.bm-c-user-components {
  .card-icon-close {
    font-size: 40PX;
    margin-top: 5PX;
    color: var(--bm-text-color);
  }

  &-phone-number {
    .am-list-item.am-input-item {
      height: 88PX;
      border-radius: 12PX;
      border: 2PX solid var(--bm-border-color);
      background-color: var(--bm-input-backgroud-color);

      &.am-input-focus {
        border: 2PX solid var(--bm-primary-color);
      }

      .bm-icon {
        padding: 0;
      }
    }

    // .am-list-line {
    // }

    .am-input-label {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .am-input-control input {
      .font-size(28PX);
      .font-family-m;
    }

    .am-list-item .am-input-label.am-input-label-5 {
      width: auto;
    }

    .am-input-extra {
      margin-left: 32PX;
      max-height: 40PX;
    }

    .card-icon-close {
      font-size: 40PX;
      color: var(--bm-text-color);
    }

    .am-input-error-extra {
      display: none;
    }
  }

  &-inputextra {
    display: flex;
    height: 100%;
    align-items: center;
    color: #333;
    font-size: 16PX;
    font-weight: 600;

    &-phone-number {
      margin-right: 6PX;
    }
  }

  &-password {
    margin-top: 32PX;
    &-text-field {
      .input-style();
    }

    .am-input-item {
      height: 88PX;
      border-radius: 12PX;
      border: 2PX solid var(--bm-border-color);
      background-color: var(--bm-input-backgroud-color);

      &.am-input-focus {
        border: 2PX solid var(--bm-primary-color);
      }

      .bm-icon {
        padding: 0;
      }
    }

    .am-input-control input {
      .font-size(28PX);
      .font-family-m;
    }

    .am-input-extra {
      margin-left: 32PX;
      max-height: 40PX;
    }

    .card-icon-close {
      font-size: 40PX;
      color: var(--bm-text-color);
    }
  }

  &-signin-button {
    margin-top: 32PX;
  }

  &-forgot {
    color: var(--bm-primary-color);
    font-size: 24PX;
  }

  &-item-button {
    display: flex;

    &-back {
      display: flex;
      align-items: center;
      width: 176PX;
      margin-left: 32PX;
      margin-bottom: 36PX;
      background-color: var(--bm-primary-color);
      color: var(--bm-light-background-color);
      justify-content: center;
      border-radius: 12PX;
    }
  }

  &-item-button-main {
    .sign-in-block();
    width: 100%;
    box-sizing: border-box;
    &-icon {
      margin-right: 8PX;
      padding-top: 0PX;
      padding-left: 0PX;
      text-align: center;
      white-space: normal;
    }

    &-text {
      font-family: 'Sf pro display', sans-serif;
      font-size: 18PX;
    }

    &.selected {
      background-color: var(--bm-light-background-color);
      color: var(--bm-primary-color);
      border: 2PX solid var(--bm-primary-color);
    }

    .bm-icon-init svg {
      width: 22PX;
      height: 22PX;
      fill: var(--bm-light-background-color);
    }

    .bm-icon-selected svg {
      fill: var(--bm-primary-color);
    }
  }

  &-facebook-button {
    .sign-in-block();

    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    background-color: #485a96;
    box-sizing: border-box;
    font-family: 'Sf pro display', sans-serif;
    font-size: 18PX;

    &-icon {
      width: 20PX;
      height: 20PX;
      margin-right: 8PX;
    }
  }

  &-apple-button {
    .sign-in-block();
    
    background-color: var(--bm-light-background-color);
    color: var(---bm-text-title-color);
    border: 2PX solid var(--bm-text-title-color);
    box-sizing: border-box;
    &-icon {
      margin-right: 8PX;
    }
  }

  &-wechat-button {
    .sign-in-block();
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    background-color: #60ce74;
    &-icon {
      margin-right: 8PX;
    }
  }
  &-text-field {
    .input-style();
  }
  @media screen and (max-width: 479PX) {
    &-item-button-main {
      text-decoration: none;
      &-text {
        font-size: 16PX;
      }
    }
    &-facebook-button {
      text-decoration: none;
      font-size: 16PX;
    }
    &-apple-button {
      text-decoration: none;
      margin-bottom: 0PX;
      -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
      border-style: solid;
      border-width: 1PX;
      border-color: #666;
      background-color: #fff;
      color: #333;
    }
    &-wechat-button {
      text-decoration: none;
    }
  }
}
.login-logo-wrap {
  padding: 32PX 84PX;
  background-color: var(--bm-primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  .login-logo {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 180PX;
  }
}
