@import '~bm-public.less';

.bm-p-store-group {
  .dark-bc;

  .pc-header {
    // height: calc(var(--bm-safe-area-top) + 88px);
    // background-color: var(--bm-primary-color);
    background-color: var(--bm-dark-background-color);
  }

  .am-navbar-title {
    .font-size(40px);
  }

  .pc-footer {
    // height: 188px;
    background-color: var(--bm-dark-background-color);
  }

  .page-content {
    .dark-bc;

    padding: 0 32px;
    padding-top: 32px;
  }
}
