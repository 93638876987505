@import '~bm-public.less';
.bm-c-layout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  .title-color;
  .font-family-r;
  .font-size(28px);
  display: flex;
  flex-direction: column;
  user-select: none;
  .page-content {
    min-height: 86%;
  }
  
  &.show-splash {
    display: none;
  }
  .am-navbar {
    // height: 88px;
    padding-top: var(--bm-safe-area-top);
  }
  .page-content-wrapper {
    // position: relative;
    // height: 100%;
    // overflow: auto;

    /* display: inline-block; */
    flex: 1;
  }
  .page-content-wrapper-web {
    overflow: auto;
  }
  .bm-loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background-color: var(--bm-modal-b-color);

    .co-checking {
      position: absolute;
      top: 0;
      width: 216px;
      height: 216px;
      margin-top: 200px;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: center;
    }

    .co-checking-loading {
      width: 60px;
      height: 60px;

      .am-icon-loading {
        width: 100%;
        height: 100%;
      }
    }

    .co-checking-text {
      .font-size(32px);
      text-align: center;

      margin-top: 24px;
      color: var(--bm-light-background-color);
    }
  }

  .am-navbar-left-icon {
    .left-outlined{
      margin-left: -26px;
    }
    .circle-back-icon-outlined,
    .circle-back-icon {
      margin-left: unset;
    }
  }

  .bm-navbar-fixed {
    position: fixed;

    .phone & {
      left: 0;
      right: 0;
    }
    z-index: 5;

    .am-navbar-title {
      .font-size(40px);
      .font-family-s;
    }
  }

  .nav-right-content {
    display: flex;
    align-items: center;
  }

  .nav_bg.streched {
    padding-top: var(--bm-safe-area-top);
  }
  .pc-header {
    height: var(--bm-safe-area-top);
  }
  .html-embed-7 {
    width: 45PX;
    height: 45PX;
    color: var(--bm-light-background-color);
    padding: 0;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .circle.html-embed-7 {
    background-color: var(--bm-light-b-color-t60);
    border: 1PX solid var(--bm-light-b-color-t40);
    border-radius: 50%;
    margin-left: unset;
    width: 34PX;
    height: 34PX;
    color: var(--bm-text-title-color);
  }
  .pc-footer {
    position: initial;
    left: 0%;
    top: 100vh;
    right: 0%;
    bottom: 0%;
    height: unset;
  }
  .footer {
    overflow: visible;
  }
  &.bm-p-category,
  &.bm-p-stores {
    .is-store-name {
      font-size: 18PX;
    }
    .is-store-name {
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
      max-width: 700PX;
    }
    .down {
      height: 46PX;
      width: 33PX;
    }
    .page-content {
      padding-bottom: 100PX;
    }
  }
  &.bm-p-checkout,
  &.bm-p-signin,
  &.bm-p-signup,
  &.bm-p-forgot {
    .page-content-wrapper-web {
      overflow: unset;
    }
    .page-content {
      width: 100%;
      height: 100%;
    }
  }
  &.bm-p-checkout {
    .div-block-520 {
      margin: 100px auto;
    }
  }
  @media screen and (max-width: 991px) {
    &.bm-p-category,
    &.bm-p-stores {
      .is-store-name {
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        max-width: 500PX;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .html-embed-7 {
      width: 37PX;
      height: 34PX;
      color: var(--bm-light-background-color);
    }
    .bm-p-setting-new {
      background-color: var(--bm-light-background-color);
    }
    &.bm-p-category,
    &.bm-p-stores {
      .is-store-name {
        max-width: 400PX;
      }
    }
    &.bm-p-menu-grid {
      padding-bottom: 81PX;
    }

    &.bm-p-checkout {
      .div-block-520 {
        margin: unset;
      }
    }
  }
  @media screen and (max-width: 479px) {
    .html-embed-7,
    .circle.html-embed-7 {
      width: 30PX;
      height: 30PX;
    }
    &.bm-p-category,
    &.bm-p-stores {
      .is-store-name {
        max-width: 130PX;
      }
    }
  }
}
.setting-header {
  .left-outlined{
    margin-left: -26px;
  }
  .circle-back-icon-outlined {
    margin-left: unset;
  }
}
.bm-p-address-list.phone {
  .div-block-638.address {
    >div {
      display: grid;
      .div-block-488 {
        max-width: 76%;
      }
      .text-block-77 {
        overflow-wrap: anywhere;
      }
    }
  }
}
.bm-c-layout.phone.bm-p-favorite {
  .nav-bar {
    position: static;
  }
}
.am-modal-mask,
.am-modal-wrap {
  z-index: 9999999 !important;
}

@media screen and (max-width: 767px) {
  .phone .div-block-547.payment {
    padding-top: 40PX;
  }
}
@media screen and (max-width: 479px) {
  .bm-c-layout.phone{
    .components-phone {
      position: unset;
    }
  }
  .phone {
    .div-block-547.payment {
      padding-top: 80PX;
    }
  }
  .bm-c-select-pay-method.phone,
  .bm-p-setting.phone {
    .div-block-547.payment {
      padding-top: 50PX;
    }
  }
}
.bm-p-pay-results,
.bm-p-news,
.bm-p-pay-bill,
.bm-p-qrcode-shopping,
.bm-p-membership,
.bm-p-EShopPage,
.bm-p-records,
.bm-p-select-stores,
.bm-p-order-detail {
  .dark-bc;
}

.bm-p-brands,
.bm-p-menu-list,
.bm-p-qrcode-shopping,
.bm-p-qrcode-shopping-cart {
  .pc-header {
    height: unset;
  }
}
.bm-p-select-stores {
  .pc-header {
    height: unset;
  }
  .page-content-wrapper {
    position: relative;
    height: 100%;
    overflow: auto;
  }
}
.bm-p-setting.phone {
  .components-phone {
    position: absolute;
  }
}
.bm-p-category,
.bm-p-blog-detail,
.bm-p-my-id,
.bm-p-device-info {
  background-color: var(--bm-light-background-color);
}
.bm-p-privilege {
  .page-content-wrapper  {
    background-color: var(--bm-dark-background-color);
  }
}
.bm-p-account,
.bm-p-records,
.bm-p-order-detail,
.bm-p-membership,
.bm-p-about-us,
.bm-p-news,
.bm-p-EShopPage,
.bm-p-select-stores {
  padding-bottom: calc(var(--bm-safe-area-top) * 2);
}
.bm-p-booking-detail {
  >div:first-child {
    .nav_bg.streched.without-line {
      background-color: var(--bm-light-background-color);
      .nav-bar-title,
      .html-embed-7 {
        color: var(--bm-text-title-color);
      }
    }
  }
}
.bm-p-order-detail {
  .page-content-wrapper-web {
    overflow: unset;
  }
}
@media screen and (max-width: 767px) {
  .html-embed-7 {
    width: 37px;
    height: 34px;
    color: var(--bm-light-background-color);
  }
  .bm-p-address-list,
  .bm-p-favorite {
    height: 100%;
    .dark-bc;
  }
  .bm-c-select-pay-method {
    .components-phone  {
      background-color: var(--bm-light-background-color);
    }
  }
  .bm-p-setting.phone {
    .div-block-547.payment {
      padding-top: calc(var(--bm-safe-area-top) + 70PX);
    }
    .components-phone {
      top: 0;
    }
  }
  .bm-p-use-points {
    .banner-content {
      margin-top: 0;
    }
    .member-wrapper {
      background-color: var(--bm-dark-background-color);
    }
  }
  .bm-p-address-list.web {
    .div-block-547.address {
      padding-top: 0;
    }
    .components-phone {
      top: 60PX;
    }
  }
}

@media screen and (max-width: 479PX) {
  .bm-p-membership {
    background-color: var(--bm-light-background-color);
  }
  .bm-p-records.phone {
    .tabs-2.w-tabs {
      padding-top: 50PX;
    }
    .w-tabs-point-content {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .bm-p-use-points.phone {
    .tabs-2.w-tabs {
      padding-top: 70PX;
    }
    .banner-content.tabs-2 {
      padding-top: 42PX;
    }
  }
  .bm-p-setting.phone {
    .div-block-547.payment {
      padding-top: calc(var(--bm-safe-area-top) + 50PX);
    }
    .components-phone {
      top: 0;
    }
  }
  .bm-p-setting.web {
    .components-phone {
      top: 50PX;
    }
  }
  .bm-p-address-list.web {
    .div-block-547.address {
      padding-top: 0;
    }
    .components-phone {
      top: 50PX;
    }
  }
  .bm-p-select-stores {
    .pc-header {
      height: 80PX;
      padding-top: var(--bm-safe-area-top);
    }
  }
}

