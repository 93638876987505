@import '~bm-public.less';

.bm-p-purchase-order-detail {
  .dark-bc;

  .pc-header {
    height: var(--bm-safe-area-top);
  }

  .pc-footer {
    .footer-07 {
      margin: 0;
    }
  }

  .back-icon {
    color: var(--bm-light-background-color);
  }

  .am-navbar {
    .am-navbar-title {
      color: var(--bm-light-background-color);
      .font-size(40px);
    }
  }

  .modal-wrapper-2 {
    position: fixed;
    left: 0PX;
    top: 0PX;
    right: 0PX;
    bottom: 0PX;
    z-index: 999999999;
    display: flex;
    overflow: auto;
    padding: 0PX 40PX;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-perspective: 850PX;
    perspective: 850PX;
    cursor: default;
  }

  .c-popup-close {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 5;
    display: block;
    background-color: rgba(0, 0, 0, 0.09);
  }

  .purchase-order-detail {
    position: relative;
    z-index: 0;

    &-style-b-color {
      position: absolute;
      top: 0;
      width: 100%;
      height: 190PX;
      background-color: var(--bm-primary-color);
      z-index: 1;
    }

    &-section {
      position: relative;
      z-index: 2;
      padding: 0 40PX 0PX;
    }

    &-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 940PX;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    &-data {
      &-tab {
        margin-top: 20PX;
        padding: 20PX;
        border-radius: 8PX;
        background-color: var(--bm-light-background-color);
        box-shadow: 0px 4px 10px rgba(60, 80, 111, 0.05);
  
        &-number {
          font-size: 28px;
          line-height: 32px;
          color: var(--bm-text-title-color);
          margin-bottom: 24px;
        }
  
        &-supplier-name {
          font-size: 40px;
          line-height: 40px;
          font-weight: bold;
          color: #000000;
          padding-bottom: 32px;
          border-bottom: 1px solid var(--bm-border-color);
          margin-bottom: 34px;
        }
  
        &-information-line {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
          font-size: 18PX;
          line-height: 20PX;
  
          &-title {
            color: var(--bm-skeleton-color);
          }
  
          &-data {
            color: var(--bm-text-title-color);

            &.payment-method {
              text-transform: capitalize;
            }

            &.deferred {
              border-radius: 8px;
              border: 1px solid var(--bm-error-color);
              padding: 6PX;
              color: var(--bm-error-color);
            }

            &.canceled {
              border-radius: 8px;
              padding: 6PX;
              color: var(--bm-skeleton-color-t30);
              border: 1px solid var(--bm-skeleton-color-t30);
            }

            &.paid {
              border-radius: 8px;
              padding: 6PX;
              color: var(--bm-warning-color);
              border: 1px solid var(--bm-warning-color);
            }
          }
        }

        &-bottom {
          display: flex;
          margin-top: 20PX;
        }

        &-button {
          font-size: 14PX;
          line-height: 20PX;
          font-weight: bold;
          border-radius: 12px;
          flex: 1;
          display: flex;
          justify-content: center;
          padding: 15PX 0;
    
          &.cancel-button {
            border: 2px solid #828282;
          }

          &.interval {
            margin-left: 27px;
          }
    
          &.receive-button {
            background-color: var(--bm-primary-color);
            color: var(--bm-light-background-color);
          }
        }
      }
    }

    &-line-item {
      display: flex;
      align-items: center;
      padding-bottom: 20PX;
      margin-bottom: 20PX;
      border-bottom: 2px solid rgba(235, 239, 242, 0.5);

      &-img {
        width: 140px;
        height: 140px;
        margin-right: 20px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px;
        background-image: url(/images/default-line-item.png);
      }

      &-info {
        flex: 1;

        &-line {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: var(--bm-text-title-color);

          &-name {
            flex: 1;
            font-weight: bold;
            font-size: 28px;
            line-height: 32px;
            margin-bottom: 12px;
          }

          &-quantity {
            font-size: 28px;
            line-height: 28px;
            margin-bottom: 12px;
          }

          &-code {
            margin-bottom: 36px;
            font-size: 24px;
            line-height: 28px;
            color: var(--bm-text-secondary-color);
          }

          &-price {
            font-size: 24px;
            line-height: 28px;
            color: var(--bm-text-secondary-color-t60);
          }

          &-total {
            font-size: 28px;
            line-height: 36px;
          }
        }
      }

      &-card {
        height: auto;
        width: 58%;
        margin-top: 60PX;
        margin-right: 20PX;
        padding: 20PX;
        align-self: flex-start;
        -webkit-align-self: flex-start;
        -ms-flex-item-align: start;
        border-radius: 12PX;
        background-color: var(--bm-light-background-color);

        &-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 600;
          font-size: 32px;
          line-height: 32px;
          color: var(--bm-text-title-color);
          margin-bottom: 40px;
          border-bottom: 2px solid var(--bm-border-color-t60);
          padding-bottom: 30px;

          &-button {
            font-weight: normal;
            border-radius: 8px;
            background-color: var(--bm-primary-color);
            padding: 20px 40px;
            color: var(--bm-light-background-color);
          }
        }

        .am-textarea-control textarea {
          font-size: 28px;
    
          &::-webkit-input-placeholder {
            font-size: 28px;
          }
    
          &::-moz-placeholder {
            font-size: 28px;
          }
    
          &::-moz-placeholder {
            font-size: 28px;
          }
        }
      }
    }

    &-remarks {
      font-size: 16PX;
      color: var(--bm-text-color);

      &-title {
        font-weight: bold;
        font-size: 28px;
        line-height: 32px;
        color: var(--bm-text-title-color);
        margin-top: 30px;
        margin-bottom: 24px;
      }
    }

    &-bottom {
      background-color: var(--bm-light-background-color);
      padding: 36px 56px;
      display: none;
    }

    &-popover-button {
      font-weight: 600;
      font-size: 30px;
      line-height: 32px;
      border-radius: 8px;
      width: 156px;
      height: 88px;
      display: flex;
      align-items: center;
      padding-left: 28px;
    }

    &-receive {
      &-bottom {
        padding: 4px 56px 42px 54px;
        text-align: center;
      }

      &-button {
        border-radius: 12px;
        padding: 32px 0;
        font-size: 36px;
        line-height: 36px;

        &.create {
          margin-top: 32px;
          border: 2px solid var(--bm-primary-color);
          color: var(--bm-primary-color);
        }

        &.create-receive {
          background-color: var(--bm-primary-color);
          color: var(--bm-light-background-color);
        }
      }
    }

    &-button {
      font-size: 40px;
      line-height: 44px;
      font-weight: bold;
      border-radius: 12px;
      flex: 1;
      display: flex;
      justify-content: center;
      padding: 31px 0;

      &.return-button {
        border: 2px solid #828282;
        color: #828282;
      }

      &.cancel-button {
        border: 2px solid #828282;
      }

      &.receive-button {
        background-color: var(--bm-primary-color);
        color: var(--bm-light-background-color);
      }

      &.interval {
        margin-left: 27px;
      }
    }

    &-bottom-ipad {
      display: none;
    }
  }

  .flex-pop-up {
    &.widen {
      height: 700PX;
      overflow: scroll;
      background-color: var(--bm-dark-background-color);
      padding: 40PX;
      text-align: left;
    }

    .purchase-order-detail {
      &-data-tab {
        margin-top: 0;
      }

      &-line-item-card {
        width: auto;
        margin: 0;
        margin-right: 0;
        margin-top: 30PX;
      }

      &-receive {
        &-bottom {
          padding: 0;
          margin-top: 30PX;
          text-align: center;
        }
  
        &-button {
          border-radius: 12px;
          padding: 32px 0;
          font-size: 36px;
          line-height: 36px;
  
          &.create {
            margin-top: 32px;
            border: 2px solid var(--bm-primary-color);
            color: var(--bm-primary-color);
          }
  
          &.create-receive {
            background-color: var(--bm-primary-color);
            color: var(--bm-light-background-color);
          }
          &.disable-create {
            opacity: 0.5;
          }
        }
      }
    }
  }

  .content-panel-wrap {
    position: -webkit-sticky;
    position: sticky;
    left: auto;
    top: 40PX;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 40%;
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }

  .am-modal-body {
    background-color: var(--bm-checkout-backgroud-color);
  }

  &.am-modal-popup {
    height: 90%;
  }

  .am-modal-content {
    text-align: left;
  }

  .item-count {
    display: flex;
    align-items: center;

    .count {
      justify-content: center;
      max-width: 44px;
      font-size: 28px;

      .am-list-item {
        background-color: var(--bm-light-background-color);
        border: hidden;
        padding: 0px;
        color: var(--bm-primary-color);
        height: auto;
        min-height: initial;
      }

      .am-list-line {
        padding: 0px;

        input {
          text-align: center;
          font-size: 28px;
          padding: 0;
        }
      }
    }

    .item-minus {
      margin-right: 8px;
      font-size: 44px;
      color: var(--bm-border-color);
    }

    .item-plus {
      font-size: 44px;
      margin-left: 8px;
      color: var(--bm-primary-color);
    }
  }

  @media screen and (max-width: 991px) {
    .purchase-order-detail {
      &-data-tab-bottom {
        display: none;
      }

      &-bottom-ipad {
        display: flex;
        padding: 36px 56px;
        background-color: var(--bm-light-background-color);
        position: -webkit-sticky;
        position: sticky;
        left: 0%;
        top: 0%;
        right: 0%;
        bottom: 0%;
        z-index: 9000;
        height: auto;
        margin-top: 0vh;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-box-pack: end;
        -ms-flex-pack: end;

        .purchase-order-detail-button {
          font-size: 14PX;
          line-height: 20PX;
          font-weight: bold;
          border-radius: 12px;
          flex: 1;
          display: flex;
          justify-content: center;
          padding: 15PX 0;
    
          &.cancel-button {
            border: 2px solid #828282;
          }

          &.interval {
            margin-left: 27px;
          }
    
          &.receive-button {
            background-color: var(--bm-primary-color);
            color: var(--bm-light-background-color);
          }
        }
      }

      &-style-b-color {
        height: 110PX;
      }

      &-section {
        padding: 0 35PX 30PX;
      }

      &-content {
        display: block;
      }

      .content-panel-wrap {
        position: relative;
        margin: 0 20PX;
        width: auto;

        .store-card {
          margin: 0;
        }
      }

      &-data-tab {
        margin-top: 0;
      }
      
      &-line-item-card {
        margin: 70PX 20PX 0;
        width: auto;
        background-color: transparent;
        background-color: var(--bm-light-background-color);
      }
    }
  }

  @media screen and (max-width: 767px) {
    .modal-wrapper-2 {
      left: 0%;
      top: 0%;
      right: 0%;
      bottom: auto;
      z-index: 9007199254740991;
      height: auto;
      min-height: 100vh;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
      -ms-flex-align: end;
      align-items: flex-end;
      -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
      align-self: flex-end;
    }
  }
  
  @media screen and (max-width: 479px) {
    .purchase-order-detail {
      &-section {
        padding: 0 12PX 75PX 15PX;
      }

      .content-panel-wrap {
        margin: 0;
      }

      &-line-item-card {
        margin: 120px 0 0;
      }

      &-bottom-ipad {
        display: none;
      }

      &-bottom {
        display: flex;
      }

      &-data {
        &-tab {
          &-information-line {
            font-size: 14PX;
          }
        }
      }

      &-item {
        padding: 22px;
        &-state {
          .arrival {
            font-size: 28px;
          }
        }
      }
    }

    .modal-wrapper-2 {
      height: 100vh;
      min-height: 100vh;
      padding: 0px 0px 60PX;
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
      -ms-flex-align: end;
      align-items: flex-end;
      -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
      align-self: flex-end;
      background-color: hsla(0, 0%, 100%, 0);
    }

    .flex-pop-up {
      &.widen {
        margin: 0;
        height: 80%;
        padding: 0;
        border-top-left-radius: 8PX;
        border-top-right-radius: 8PX;

        .purchase-order-detail{
          &-data-tab {
            margin-top: 30px;
          }
          &-line-item-card {
            margin-top: 20PX;
          }
        }
      }
    }
  }
}
