@import '~bm-public.less';

.bm-p-forgot {

 .am-input-extra {
  margin-right: 10PX;
 }
  // .pc-header {
  //   height: calc(var(--bm-safe-area-top) + 88px);
  //   background-color: var(--bm-primary-color);
  // }

}
