@import '~bm-public.less';

.bm-p-modifier {
  .light-bc;

  .back-icon {
    color: var(--bm-text-title-color);
  }

  .pc-header {
    height: calc(var(--bm-safe-area-top) + 88px);
    background-color: #fff;
  }

  .am-navbar {
    .light-bc;

    border-bottom: 2px solid var(--bm-border-color);
  }

  .am-navbar-right {
    color: var(--bm-primary-color);
    margin-right: 0;
  }

  .nav-right {
    .font-size(32px);

    flex: 1;
    text-align: right;
    height: 88px;
    line-height: 88px;
    padding-right: 32px;
  }

  .page-content {
    padding-bottom: 32px;
  }

  .modifier-title {
    display: flex;
    justify-content: space-between;
    background-color: var(--bm-checkout-backgroud-color);
    padding: 0 32px;
    align-items: center;

    .modifier-name {
      padding: 20px 32px 20px 0;

      .childen-modifier {
        font-size: 32px;
        line-height: 36px;
        .font-family-b;
      }

      .midifier-tip {
        margin-top: 8px;
        color: var(--bm-text-secondary-color);
        font-size: 24px;
        line-height: 28px;
      }

      .midifier-tip-warn {
        color: var(--bm-error-color);
      }
    }

    .select-icon {
      font-size: 48px;
    }

    .select-header-icon {
      font-size: 48px;
      border: 2px solid var(--bm-border-color);
      border-radius: 50%;
      height: 48px;
      color: var(--bm-text-color);
    }
  }

  .modifier-group-hide {
    display: none;
  }

  .select-header-icon {
    transition-duration: 0.5s;
  }

  .select-icon-hide {
    transform: rotate(180deg);
    transition-duration: 0.5s;
  }

  .modifier-group {
    padding: 32px 32px 0;
    display: block;

    .modifier-wrapper {
      min-height: 112px;
      border-radius: 12px;
      padding: 0 32px;
      border: 2px solid var(--bm-border-color);
      margin-bottom: 32px;
      display: flex;
      align-items: center;

      .m {
        flex: 1;
        color: var(--bm-text-title-color);
        font-size: 30px;
        padding: 32px 32px 32px 0;

        .sub-price {
          margin-top: 8px;
          font-size: 24px;
          color: var(--bm-text-secondary-color);
        }

        .sub-name {
          font-size: 30px;
          color: var(--bm-text-title-color);
        }
      }

      .select-icon {
        font-size: 48px;
      }

      .checked {
        color: var(--bm-primary-color);
      }

      .item-count {
        display: flex;
        align-items: center;

        .count {
          .font-size(28px);
          .font-family-b;
          .text-color;

          padding: 0 8px;
        }
      }

      .item-minus {
        margin-right: 10px;
        font-size: 48px;
        color: var(--bm-primary-color-t30);
      }

      .item-plus {
        margin-left: 10px;
        font-size: 48px;
        color: var(--bm-primary-color);
      }

      .item-options {
        padding: 12px 20px;
        border-radius: 8px;
        background-color: var(--bm-primary-color);
        color: var(--bm-light-background-color);
      }
      &.sold-out-modifier {
        opacity: 0.7;
      }
    }

    .modifier-wrapper-selected {
      border: 2px solid var(--bm-primary-color);
    }

    .modifier-wrapper-disabled {
      opacity: 0.5;
    }
  }

  .modifier-item {
    padding: 32px;
    display: flex;

    .item-img-wrapper {
      width: 140px;
      height: 140px;
      margin-right: 32px;
    }

    .item-img {
      width: 100%;
      height: 140px;
      border-radius: 6px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .item-content {
      flex: 1;

      .title {
        font-size: 40px;
        line-height: 56px;
        color: var(--bm-text-title-color);
        margin-bottom: 36px;
        .font-family-b;
      }

      .price {
        font-size: 36px;
        line-height: 28px;
        color: var(--bm-text-title-color);
      }
    }
  }
  .modifier-content {
    padding-bottom: 232px;
  }

  .primary-modifier-content {
    padding-bottom: 344px;
  }
  .listing-action-wrap {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 32px 32px 40px;
    box-shadow: 0px -4px 20px rgba(60, 80, 111, 0.05);
    box-sizing: border-box;
    display: flex;
    .light-bc;
    .item-action {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 34px;
      .bm-icon {
        font-size: 80px;
        color: var(--bm-primary-color);
      }
      .count {
        margin: 0 30px;
      }
      .disable-minus {
        opacity: 0.6;
      }
    }
    .add-to-cart {
      height: 100px;
      line-height: 100px;
      flex: 1;
    }
  }
}
