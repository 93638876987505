@import '~bm-public.less';

.bm-p-use-points {
  background-color: var(--bm-dark-background-color);
  // .pc-header {
  //   height: calc(var(--bm-safe-area-top) + 88px);
  // }
  // &.has-header {
  //   .pc-header {
  //     height: calc(var(--bm-safe-area-top) + 180px);
  //   }
  // }
  .redeem-success {
    margin: auto;
    padding-top: 60PX;
    background-color: var(--bm-dark-background-color);
  }
  .redeem-success-explain {
    padding-top: 50PX;
    padding-bottom: 20PX;
    text-align: center;
  }

  .navbar-top {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 88px;
    background-color: var(--bm-primary-color);
    color: var(--bm-light-background-color);
    .navbar-left, .navbar-title, .navbar-right {
      flex: 1;
      height: 100%;
      .font-family-s;
      display: flex;
    }
    .navbar-left {
      padding-left: 30px;
    }
    .navbar-title {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      white-space: nowrap;
      text-align: center;
      .font-size(40px);
      line-height: 88px;
    }
  }

  .navbar-buttom {
    position: fixed;
    left: 0;
    right: 0;
    top: calc(var(--bm-safe-area-top) + 88px);
    &-layout {
      height: 92px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: var(--bm-light-background-color);
      box-shadow: 0px 8px 20px rgba(60, 80, 111, 0.05);
  
      .order-type {
        padding: 10px 36px;
        font-weight: bold;
        text-align: center;
        font-size: 28px;
        line-height: 32px;
  
        &.selected {
          .font-family-b;
          .p-b-color;
  
          color: var(--bm-light-background-color);
          border-radius: 400px;
        }
      }
    }
  }

  .card {
    border-radius: 8px;
    background-color: var(--bm-light-background-color);
    margin: 32px;
    margin-bottom: 166px;

    .use-point {
      font-size: 32px;
      line-height: 36px;
      margin: 0 36px;
      padding: 32px 0 38px 0;

      .available-points-wrap {
        border-bottom: 2px solid var(--bm-border-color);
      }

      .available-points {
        display: flex;
        justify-content: space-between;
        padding-bottom: 14px;
        font-size: 24px;
        color: var(--bm-text-color);
      }

      .available-balance {
        display: flex;
        justify-content: space-between;
        padding-bottom: 14px;
        font-weight: 500;
      }

      .reward-status {
        margin-top: 30px;
        font-weight: 500;
      }
      .reward-content {
        margin-top: 12px;
        display: flex;
        padding: 16px 32px;
        justify-content: space-between;
        background-color: var(--bm-dark-background-color);
        border: 2px solid var(--bm-border-color);
        border-radius: 8px;
        .reward-content-item-title {
          font-size: 20px;
          line-height: 26px;
          color: var(--bm-text-secondary-color);
        }
        .reward-item {
          font-size: 24px;
          line-height: 30px;
          color: var(--bm-text-color);
          margin-top: 16px;
        }
        .reward-content-item:not(:first-child) {
          text-align: right;
        }
      }

      .reward-rule {
        margin-top: 32px;
        font-size: 24px;
        line-height: 28px;
        color: var(--bm-skeleton-color);
      }

      .min-spending {
        margin-top: 4px;
        font-size: 24px;
        line-height: 28px;
        color: var(--bm-skeleton-color);
        padding-bottom: 30px;
        border-bottom: 2px solid var(--bm-border-color);
      }

      .reward {
        font-weight: 500;
        margin-top: 24px;
      }

      .reward-select-point {
        margin-top: 16px;
        font-size: 22px;
        line-height: 28px;
      }

      .reward-input {
        padding-bottom: 14px;
        border-bottom: 2px solid var(--bm-border-color);

        .currency-symbol {
          margin-top: 14px;
          margin-right: 16px;
        }

        .total-points {
          padding: 10px 0;
          font-size: 24px;
          color: var(--bm-text-color);
        }

        .amount {
          font-weight: bold;
          font-size: 112px;
          line-height: 112px;
          height: 112px;
        }
      }
      
      .choose {
        display: grid;
        margin-top: 10px;
        justify-content: space-between;
        grid-template-columns: repeat(auto-fill, 186px);

        .choose-item {
          width: 186px;
          height: 86px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #E3E7F1;
          border: 2px solid #96A6CF;
          border-radius: 8px;
          margin-top: 22px;
        }
      }
    }

    .point-history {
      font-weight: 500;
      padding-bottom: 42px;

      .recent-acitivities {
        margin: 0 34px;
        padding: 24px 0 8px 0;
        font-size: 32px;
        line-height: 36px;
      }

      .points-item {
        margin: 20px 18px 0 18px;
        padding: 0 20px;
        height: 86px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: fade(#E3E7F1, 25%);
        font-size: 24px;
        line-height: 28px;

        .points-item-date {
          display: flex;
          align-items: center;
          
          .bm-icon {
            margin-right: 14px;
          }
        }
      }
    }
  }

  .card:first-child {
    margin-bottom: 32px;
  }

  .member-card {
    margin: 34px 36px;
    border-radius: 12px;
    background-color: var(--bm-light-background-color);

    .member-card-top {
      height: 64px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    .member-card-content {
      display: flex;
      padding-bottom: 24px;

      .member-card-content-left {
        .member-card-grade {
          font-weight: bold;
          font-size: 32px;
          line-height: 52px;
          margin: 30px 0 0 32px;
        }
    
        .member-card-point-exchange {
          font-size: 32px;
          line-height: 52px;
          margin-left: 32px;
          padding-bottom: 12px;
        }
    
        .member-card-upgrade-rules {
          display: flex;
          align-items: center;
          margin-left: 32px;
          margin: 12px 0 0 32px;
    
          .member-card-upgrade-rules-title {
            font-size: 28px;
            line-height: 32px;
            margin-right: 26px;
          }
    
          .member-card-upgrade-rules-amount {
            font-size: 40px;
            line-height: 32px;
          }
        }
    
        .member-card-view {
          font-weight: 500;
          margin-top: 22px;
          margin-left: 32px;
          font-size: 24px;
          line-height: 28px;
          color: var(--bm-skeleton-color-t20);

          .bm-icon {
            font-size: 20px;
          }
        }
      }

      .member-card-content-right {
        flex: 1;
        align-self: flex-end;

        .member-card-icon {
          text-align: right;
          
          .bm-icon {
            font-size: 128px;
            padding: 16px
          }
        }
      }
    }

    .member-card-detail {
      margin-left: 32px;
      white-space: pre-line;
      font-size: 28px;
      line-height: 40px;
      padding-bottom: 24px;
    }
  }

  .show {
    display: block;
  }

  .hide {
    display: none;
  }

  .bottom-botton {
    position: fixed;
    bottom: 0;
    .phone & {
      left: 0;
      right: 0;
    }
    z-index: 990;
    overflow: hidden;
    padding: 32px 60px;

    .pay-with-point {
      padding: 34px 0;
      font-size: 32px;
      font-weight: bold;
      background-color: var(--bm-primary-color-tap);
      text-align: center;
      border-radius: 12px;
      color: var(--bm-light-background-color);
    }
  }

  .disabled {
    opacity: 0.5;
  }

  .redeem-success {
    &-icon {
      display: flex;
      justify-content: center;
      margin-top: 22px;

      .bm-icon {
        font-size: 282px;
      }
    }

    &-title {
      font-weight: bold;
      font-size: 32px;
      line-height: 36px;
      text-align: center;
    }

    &-card {
      // position: absolute;
      top: 536px;
      bottom: 0;
      width: 100%;
      background-color: var(--bm-light-background-color);
      border-radius: 36px 36px 0 0;
      margin-top: 82px;
    }

    &-tip {
      font-weight: 500;
      font-size: 36px;
      line-height: 42px;
      text-align: center;
      margin: 0 82px;
      padding: 94px 42px 82px 42px;
      border-bottom: 2px solid var(--bm-border-color);
    }

    &-info {
      display: flex;
      margin: 40px 82px;
      justify-content: space-between;
      font-size: 28px;
      line-height: 32px;
    }

    &-explain {
      // margin: 0 82px;
      // font-size: 24px;
      // line-height: 28px;
      // border-top: 2px solid var(--bm-border-color);
      // padding: 82px 38px 0 38px;
      // text-align: center;
    }
  }

  .reward-p {
    p {
      margin-bottom: 0;
    }
    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    .redeem-success {
      padding: 120PX 12PX 12PX;
    }
  }
  @media screen and (max-width: 479px) { 
    .redeem-success {
      padding: 120PX 12PX 12PX;
      width: 100%;
    }
    .redeem-success-explain {
      padding-top: 20PX;
      padding-bottom: 20PX;
    }
  }
}
.bm-p-use-points.web {
  .bottom-botton {
    box-sizing: border-box;
    max-width: 25.6rem;
    width: 100% !important;
  }
}
