@import '~bm-public.less';

.bm-c-pay-info {
  margin-top: 64px;
  padding: 32px;
  border-radius: 12px;
  background-color: var(--bm-light-background-color);
  box-sizing: border-box;
  .pi-loading {
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 80px;

    .am-icon-loading {
      width: 50px;
      height: 50px;
    }
  }

  .pi-bar {
    display: flex;
    padding-bottom: 32px;
    min-height: 36px;
    border-bottom: 2px solid var(--bm-border-color);
  }

  .bar-item {
    display: flex;
    margin-top: 18px;
  }

  .item-msg {
    display: flex;
    flex: 1;
  }

  .item-icon {
    display: flex;
  }

  .pi-card.bm-icon {
    font-size: 36px;
  }

  .pi-loading.bm-icon {
    font-size: 32px;
    color: var(--bm-text-secondary-color);
    padding: 12px;
    box-sizing: border-box;
  }

  .item-title {
    display: flex;
    flex: 1;
    margin-left: 16px;
    align-items: center;

    &.no-item-icon {
      margin-left: 0;
    }
  }

  .item-price {
    display: flex;
    align-items: center;
  }

  .store-title {
    flex: 1;
    .font-size(32px);
    .font-family-s;

    padding-right: 24px;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .add-pay-method {
    display: flex;
    align-items: center;
    .font-family-m;

    margin-top: 32px;
    color: var(--bm-primary-color);

    .bm-icon {
      padding: 0;
    }
  }

  .reference-msg {
    margin-bottom: 44px;
    text-align: center;
    padding: 16px 0;
    border-bottom: 2px dashed var(--bm-border-color);
    position: relative;

    .reference-id {
      .font-family-b;

      font-size: 56px;
      line-height: 68px;
    }

    .reference-title {
      font-size: 26px;
      line-height: 30px;
      color: var(--bm-text-color);
    }

    .left-circle,
    .right-circle {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: var(--bm-dark-background-color);
      position: absolute;
    }

    .left-circle {
      left: -44px;
    }

    .right-circle {
      right: -44px;
    }
  }

  .reference {
    border-bottom: none;
  }
  .check-out-info.restaurant-info {
    padding: 20PX 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .check-out-info {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 20PX 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 0px 0px 1PX;
    border-color: var(--bm-border-color-t60);
    color: var(--bm-text-title-color);
    cursor: auto;
    .gift-card-title {
      display: block;
      .gift-card-balance {
        color: var(--bm-text-secondary-color);
      }
    }
  }
  .text-block-67.bold {
    margin-bottom: 0px;
    font-family: 'Sf pro display', sans-serif;
    font-weight: 600;
    text-transform: none;
  }
  .text-block-67 {
    margin-bottom: 0px;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-title-color);
    font-size: 18PX;
    font-weight: 400;
  }
  .check-out-info.no-line,
  .add-pay-method {
    padding: 15PX;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom-width: 0px;
    font-size: 18PX;
    color: var(--bm-primary-color);
    font-weight: 400;
    margin: 0;
    .plus-outlined {
      width: 34PX;
      height: 37PX;
      margin-bottom: 2PX;
      font-size: unset;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .check-out-info.no-line  .image-199{
    svg {
      width: unset;
      height: unset;
    }
  }
 
  .div-block-487.open-modal {
    position: static;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;
    margin-left: auto;
    .text-block-69 {
      color: var(--bm-text-title-color);;
    }
  }
  .div-block-487 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: auto;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    cursor: pointer;
    .right-outlined {
      width: 30PX;
      height: 25PX;
      padding: 0;
    }
  }
  
}
@media screen and (max-width: 991px) {
  .bm-c-pay-info {
    .check-out-info.restaurant-info {
      margin-right: 0px;
      margin-left: 0px;
      padding-right: 0px;
      padding-left: 0px;
    }
    .text-block-67 {
      margin-bottom: 0px;
      font-family: 'Fontsfree net sfprodisplay', sans-serif;
      color: var(--bm-text-title-color);
      font-size: 18PX;
      font-weight: 400;
      line-height: unset;
    }
    .html-embed-34 {
      height: 31PX;
      width: 30PX;
    }

  }
}
@media screen and (max-width: 767px) {
  .bm-c-pay-info {
    .text-block-67.bold {
      font-size: 18PX;
    }
  } 
}
@media screen and (max-width: 479px) {
  .bm-c-pay-info {
    .check-out-info.restaurant-info {
      margin-right: 0px;
      margin-left: 0px;
      padding: 13PX 13PX 13PX 0px;
    }
    .check-out-info.no-line {
      padding: 8PX;
    }
    .text-block-67.bold {
      font-size: 14PX;
    }
    .text-block-67 {
      margin-bottom: 0px;
      font-size: 14PX;
    }
    .add-pay-method {
      font-size: 14PX;
    }
     .add-pay-method .plus-outlined {
      width: 25PX;
      height: 25PX;
    }
    .bm-c-pay-info .add-pay-method {
      padding-left: 5PX;
    }
    .text-block-69 {
      margin-right: 0px;
      font-size: 14PX;
    }
    .html-embed-34 {
      position: relative;
      margin-right: 0px;
    }

  }
  
}
