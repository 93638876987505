@import '~bm-public.less';

.bm-p-confirm-order {
  background-color: var(--bm-dark-background-color);
  .page-content-wrapper  {
    .modal-wrapper {
      z-index: 999999999;
    }
    .modal-body.coupon {
      box-sizing: border-box;
      background-color: var(--bm-light-background-color);
      .title {
        position: sticky;
        top: 0;
        z-index: 999;
        left: 0;
        background-color: var(--bm-light-background-color);
        width: 100%;
        height: 30PX;
        padding-top: 20PX;
        padding-bottom: 20PX;
        padding-bottom: 20PX;
        padding-left: 30PX;
        margin-bottom: 0;
      }
      .left-outlined {
        position: absolute;
      }
      .title-tilte {
        font-size: 28PX;
        color: black;
        font-weight: 600;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
      }
      .div-block-549 {
        padding: 30PX;
        padding-top: 50PX;
      }
      .html-embed-7 {
        color: #000000;
      }
      .nav_bg.streched.w-nav svg {
        color: #000000;
      }
      button {
        background-color: var(--bm-text-disabled-color);
        border: none;
        text-align: center;
        font-size: 18PX;
        padding: 9PX;
        margin-top: 10PX;
        margin-left: auto;
        font-weight: 500;
        margin-bottom: 30PX;
        margin-right: 30PX;
      }
    }
  }

  .co-checking-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9001;
    background-color: transparent;

    .co-checking {
      width: 216px;
      height: 216px;
      border-radius: 12px;
      background-color: var(--bm-loading-background);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .co-checking-loading {
      width: 60px;
      height: 60px;

      .am-icon-loading {
        width: 100%;
        height: 100%;
      }
    }

    .co-checking-text {
      .font-size(32px);
      text-align: center;

      margin-top: 24px;
      color: var(--bm-light-background-color);
    }
  }

  .skeleton {
    &-co-page-content {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: var(--bm-light-background-color);
      z-index: 999;
      .style-b-color {
        position: absolute;
        top: 0;
        width: 100%;
        height: 64px;
        background-color: var(--bm-text-title-color);
        opacity: 0.1;
      }
    }
    &-confirm-pay {
      padding-top: 22px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100%;
    }
    
    &-confirm-info {
      display: flex;
      flex-direction: column;

      &-item1 {
        width: 252px;
        height: 28px;
        .skeleton-color;
      }

      &-item2 {
        width: 142px;
        height: 32px;
        margin-top: 12px;
        .skeleton-color;
      }

      &-pay-btn {
        width: 304px;
        height: 80px;
        .skeleton-color;
      }
    }

    &-dining-style {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100%;

      &-left {
        width: 150px;
        height: 48px;
        .skeleton-color;
      }

      &-right {
        width: 220px;
        height: 48px;
        .skeleton-color;
      }
    }

    &-co-content {
      &-panel {
        margin: 32px;
        padding: 32px;
        background-color: #FFF;
        border-radius: 12px;
        position: relative;
        z-index: 2;
        margin: 0px 32px 0 32px;
        padding-top: 32px;
        border-radius: 12px;
        .light-bc;
        z-index: 12;
        &:nth-child(3) {
          margin-top: 32px;
        }
      }
      &-store-title {
        height: 32px;
        width: 400px;
        .skeleton-color;
        margin-bottom: 32px;
      }

      &-pick-up-msg-wrap {
        .shipping-method-wrap {
          display: flex;
          justify-content: space-between;
          margin-bottom: 32px;
        }
        .pick-up {
          width: 200px;
          height: 32px;
          .skeleton-color;

        }
        .pick-up-msg {
          display: flex;
          margin-top: 20px;
          justify-content: space-between;
          .pickup-address-wrap {
            display: flex;
          }
          .icon {
            width: 32px;
            height: 32px;
            .skeleton-color;
          }
          .address-wrap {
            flex: 1;
          }
          .pick-up-address-detail {
            width: 180px;
            height: 32px;
            .skeleton-color;
            margin: 20px 10px 0;
          }
          .pick-up-address {
            height: 32px;
            margin: 0 10px;
            flex: 1;
            .skeleton-color;
          }
        }
      }
      &-shipping-method-title {
        width: 200px;
        height: 32px;
        .skeleton-color;
        margin-right: 20px;
      }
      &-shipping-method-time {
        width: 150px;
        height: 32px;
        .skeleton-color;
      }

      &-listing {
        display: flex;

        &-img {
          width: 144px;
          height: 144px;
          .skeleton-color;
          border-radius: 12px;
        }

        &-info {
          flex: 1;
          margin-left: 20px;
          &-l1 {
            display: flex;
            justify-content: space-between;

            .info-l1-item1 {
              width: 300px;
              height: 32px;
              .skeleton-color;
            }

            .info-l1-item2 {

              width: 100px;
              height: 32px;
              .skeleton-color;
            }
          }

          &-l2 {
            width: 240px;
            height: 28px;
            margin-top: 8px;
            .skeleton-color;
          }

          &-l3 {
            width: 140px;
            height: 28px;
            margin-top: 40px;
            .skeleton-color;
          }
        }
      }

      &-line {
        width: 100%;
        height: 2px;
        margin: 20px 0;
        .skeleton-color;

        &.line-2 {
          margin: 16px 0;
        }
      }

      &-listing-total {
        &-box {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;

          .listing-total-item {
            &1 {
              width: 228px;
              height: 36px;
              .skeleton-color;
            }

            &2 {
              width: 100px;
              height: 36px;
              .skeleton-color;
            }

            &3 {
              width: 136px;
              height: 48px;
              .skeleton-color;
            }

            &4 {
              width: 154px;
              height: 48px;
              .skeleton-color;
            }
          }
        }
      }
    }

    &-co-pay-method {
      &-title {
        width: 180px;
        height: 48px;
        .skeleton-color;
      }

      &-info {
        display: flex;
        justify-content: space-between;
        &-left {
          display: flex;
        }

        &-item1 {
          width: 84px;
          height: 48px;
          .skeleton-color;
        }

        &-item2 {
          width: 168px;
          height: 48px;
          margin-left: 10px;
          .skeleton-color;
        }

        &-item3 {
          width: 148px;
          height: 48px;
          .skeleton-color;
        }
      }
    }
  }

  &.loading-order-info {
    .pc-header {
      .skeleton-color;
    }
    .style-b-color,
    .am-navbar {
      background-color: var(--bm-text-title-color);
      opacity: 0.1;
    }
  }

  .page-content-wrapper-web {
    overflow: unset;
  }
  
  .page-content-wrapper {
    .dark-bc;
  }
  .page-content {
    padding-bottom: 100PX;
  }
  .section {
    background-color: var(--bm-dark-background-color);
  }
  .nav_bg.streched.w-nav {
    background-color: var(--bm-primary-color);
    svg {
      color: var(--bm-light-background-color);
    }
  }
  .nav_bg.streched {
    position: fixed;
    z-index: 99;
    height: auto;
    max-height: 70PX;
    min-height: 70PX;
    border-bottom: 0px solid rgba(0, 0, 0, 0.1);
    background-color: hsla(0, 0%, 100%, 0);
    background-image: none;
    background-size: auto;
  }
  .div-block-7.open-modal {
    z-index: 100;
  }
  .container-stretched_1 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    max-height: 70PX;
    max-width: none;
    min-height: 70PX;
    margin-right: 60PX;
    margin-left: 60PX;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .background-top {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    height: 260PX;
    background-color: var(--bm-primary-color);
  }
  .div-block-485 {
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .html-embed-7.left-outlined {
    width: 45PX;
    height: 45PX;
    font-size: unset;
  }
  svg {
    width: 100%;
    height: 100%;
  }
  .left-block {
    width: auto;
    height: auto;
    margin-top: 0;
    margin-right: 20PX;
    padding: 0px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-radius: 12PX;
    background-color: rgba(255, 255, 255, 0);
    box-shadow: none;
    flex: 1;
  }
  .right-block {
    position: -webkit-sticky;
    position: sticky;
    left: auto;
    top: 100PX;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 40%;
    height: auto;
    margin-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .check-out {
    position: -webkit-sticky;
    position: sticky;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: auto;
    max-width: 100%;
    min-width: 100%;
    margin-top: 0;
    margin-right: 0px;
    margin-bottom: 0px;
    padding: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 12PX;
    background-color: rgba(255, 255, 255, 0);
    box-shadow: none;
  }
  .div-block-510 {
    box-sizing: border-box;
    margin-bottom: 20PX;
    padding: 20PX;
    border-radius: 8PX;    
    background-color: var(--bm-light-background-color);
    box-shadow: 1PX 1PX 10PX 0 rgba(0, 0, 0, 0.24);
  }
  .make-payment {
    position: -webkit-sticky;
    position: sticky;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 9000;
    display: none;
    height: auto;
    margin-top: 0vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    background-color: transparent;
  }
  .bottom-nav {
    position: sticky;
    top: auto;
    z-index: auto;
    width: 100vw;
    height: auto;
    padding-top: 15PX;
    padding-bottom: 15PX;
    background-color: #fff;
    box-shadow: 1PX 1PX 4PX -1PX #5e5e5e;
    box-sizing: border-box;
  }
  .bm-p-shopping-cart .text-block-90 {
    margin-bottom: 10PX;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-title-color);
    font-size: 18PX;
    font-weight: 700;
  }
  .text-block-91 {
    color: var(--bm-text-secondary-color);
    font-size: 14PX;
  }
  .pay-now-block {
    position: relative;
    left: auto;
    top: 0;
    right: 0%;
    bottom: auto;
    display: flex;
    width: 100%;
    height: 55PX;
    margin-top: 20PX;
    margin-right: 0;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    border-radius: 8PX;
    text-decoration: none;
  }
  .bm-p-shopping-cart .text-block-17 {
    font-family: 'Sf pro display', sans-serif;
    font-size: 18PX;
    font-weight: 600;
  }
  .text-block-90.less-spacing {
    margin-bottom: 5PX;
    font-size: 20PX;
  }
  .text-block-90 {
    margin-bottom: 10PX;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-title-color);
    font-size: 18PX;
    font-weight: 700;
  }
  .text-block-17 {
    font-family: 'Sf pro display', sans-serif;
    font-size: 18PX;
    font-weight: 600;
  }
  .am-button-primary {
    border: 0;
  }
  .form-3 {
    padding: 15PX;
  }
  .log-in-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 60PX;
    margin-bottom: 0px;
    padding: 15PX 20PX;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4PX;
    background-color: var(--bm-primary-color);
    color: var(--bm-light-background-color);
    cursor: pointer;
  }
  .top-header.no-line {
    border-bottom-width: 0px;
  }



  .payment-info-block {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: auto;
    max-width: 100%;
    min-width: 100%;
    margin-right: 0px;
    margin-bottom: 30PX;
    padding: 13PX;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 8PX;
    background-color: var(--bm-light-background-color);
    box-shadow: 1PX 1PX 18PX 0 rgba(58, 58, 58, 0.39)
  }
  .content-panel {
    padding: 0;
    margin: 0;
    .add-pay-method {
      padding: 0;
      margin: 0;
    }
    .add-pay-method {
      padding: 13PX;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      border-bottom-width: 0px;
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      cursor: auto;
    }
  }
  .left-block {
    .payment-info-block {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: auto;
      height: auto;
      max-width: 100%;
      min-width: 100%;
      margin-right: 0px;
      margin-bottom: 30PX;
      padding: 13PX;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
      align-self: stretch;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
      border-radius: 8px;
      background-color: #fff;
      box-shadow: 1PX 1PX 18PX 0 rgb(58, 58, 58, 0.39);
    }
  }
  .check-out-info {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 20PX 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 0px 0px 1PX;
    border-color: #000 #000 #eaeaea;
    color: var(--bm-text-title-color);
    cursor: auto;
  }
  .bm-c-select-pay-method {
    background-color: var(--bm-light-background-color);
  }
  .image-199 {
    .bm-icon  {
      width: 30PX;
      height: 30PX;
      font-size: unset;
      padding: 0;
    }
  }
  .text-block-210.pc {
    display: block;
  }
  .text-block-210 {
    margin: 10PX 10PX 0px 0px;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-secondary-color);
    font-size: 14PX;
    text-align: left;
    display: none;
    word-break: break-word;
    .bold-text-3 {
      word-break: break-word;
    }
  }
  .bold-text-3 {
    color: #4c95ff;
  }

  .error-modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    &-content {
      background-color: var(--bm-light-background-color);
      max-height: 640PX;
      border-radius: 12PX;
      width: 260PX;
      padding: 16PX;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .am-button {
        width: 100%;
        margin-top: 24px;
      }
      .msg {
        color:var(--bm-error-color);
        font-weight: 500;
        margin-top: 16PX;
        font-size: 18PX;
        margin-bottom: 12px;
        text-align: center;
        padding: 0 16PX;
        line-height: 24PX;
      }
      &-icon {
        max-width: 120px;
        max-height: 120px;
        overflow: hidden;
      }
    }
  }

  .enter-cvv-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    .enter-cvv-content {
      background-color: var(--bm-light-background-color);
      height: 180PX;
      border-radius: 12PX;
      width: 260PX;
      padding: 16PX;
      .enter-cvv-title {
        margin-bottom: 20PX;
      }
      .cvv {
        margin-bottom: 30PX;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .bm-p-confirm-order {
    .div-block-485 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .right-block {
      position: static;
      width: 100%;
      -webkit-box-ordinal-group: 0;
      order: unset;
      border-top-left-radius: 12PX;
      border-top-right-radius: 12PX;
    }
    .div-block-510 {
      margin-bottom: 20PX;
      display: none;
    }
    .make-payment {
      position: fixed;
      bottom: 0;
      top: unset;
      display: block;
      margin-top: 0vh;
    }
    .bottom-nav.cart-process {
      padding: 27PX;
    }  
    .bottom-nav {
      padding-top: 15PX;
      padding-bottom: 15PX;
    }
    .text-block-90.less-spacing {
      margin-bottom: 5PX;
      font-size: 20PX;
    }
    .bm-p-shopping-cart .pay-now-block {
      position: static;
      top: 0;
      right: 0%;
      width: 200PX;
      margin-top: 0;
      margin-right: 0;
      padding: 20PX;
      color: #fff;
    }
    .checkout-tablet {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .pay-now-block {
      position: static;
      top: 0;
      right: 0%;
      width: 200PX;
      margin-top: 0;
      margin-right: 0;
      padding: 20PX;
      color: #fff;
    }
    .check-out {
      margin-bottom: 0px;
      padding: 0px;
      border-radius: 0px;
      box-shadow: none;
      display: block;
      margin-top: 0;
    }
    .left-block {
      width: 100%;
      margin-top: 0px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      box-shadow: none;
      .payment-info-block {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding: 20PX;
        border-radius: 8PX;
        box-shadow: 1PX 1PX 18PX 0 rgba(58, 58, 58, 0.39);
        margin-bottom: 0px;
      }
    }
    .payment-info-block {
      margin-bottom: 15PX;
    }
    .text-block-210 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: block;
      margin-top: 20PX;
      font-size: 20PX;
      max-width: 728PX;
      margin: auto;
    }
    .bold-text-3 {
      font-size: 16PX;
    }
    .image-199 {
      .bm-icon  {
        width: 28PX;
        height: 22PX;
      }
    }

  }
}
@media screen and (max-width: 767px) {
  .bm-p-confirm-order {
    .page-content-wrapper  {
      .modal-body.coupon {
        overflow: scroll;
      }
    }
    .html-embed-7.left-outlined {
      width: 37PX;
      height: 34PX;
    }
    .left-block {
      box-shadow: none;
    }
    .bottom-nav {
      top: 100vh;
    }
    .container.bottom {
      max-width: 100%;
    }
    .checkout-tablet {
      position: static;
    }
    .pay-now-block {
      left: auto;
      top: -22%;
      bottom: 0;
    }
    .text-block-17 {
      font-size: 18PX;
    }  



    .left-block {
      .payment-info-block {
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }
    .text-block-210 {
      margin-top: 10PX;
      margin-bottom: 0px;
      font-size: 16PX;
      line-height: 16PX;
    }
  }

}

@media screen and (max-width: 479px) {
  .bm-p-confirm-order {
    .page-content-wrapper  {
      .modal-body.coupon {
        .title {
          position: fixed;
          .html-embed-7.left-outlined {
            width: 40PX;
            height: 40PX;
          }
        }
        button {
          font-size: 16PX;
          font-weight: 500;
        }
        .title-tilte {
          font-size: 24PX;
        }
      }
    }
    .nav_bg.streched {
      max-height: 50PX;
      min-height: 50PX;
    }
    .background-top {
      height: 180PX;
    }
    .div-block-485 {
      margin-top: 5PX;
    }
    .container-stretched_1 {
      max-height: 50PX;
      min-height: 50PX;
      margin-right: 16PX;
      margin-left: 17PX;
    }
    .bottom-nav.cart-process {
      padding: 20PX 12PX;
    }
    .bottom-nav {
      padding-top: 10PX;
      padding-bottom: 10PX;
    }
    .container {
      margin-top: 0;
      margin-bottom: 0;
      flex: 1 1;
    }
    .checkout-tablet {
      display: flex;
      max-width: none;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
    }
    .text-block-90.less-spacing {
      align-self: flex-start;
      font-size: 16PX;
    }
    .text-block-91 {
      align-self: flex-start;
    }
    .pay-now-block {
      left: auto;
      top: 0%;
      right: 0%;
      bottom: 0%;
      width: auto;
    }
    .text-block-17 {
      font-size: 14PX;
    }  
    .html-embed-7.left-outlined {
      width: 30PX;
      height: 30PX;
    }



    .left-block {
      .payment-info-block {
        margin-bottom: 20PX;
        padding-right: 13PX;
        padding-left: 13PX;
      }
    }
    .text-block-210 {
      margin-top: 0px;
      font-size: 14PX;
      line-height: 16PX;
    }
    .bold-text-3 {
      font-size: 14PX;
      word-break: break-all;
    }
    .payment-info-block {
      padding: 8PX;
    }
    .log-in-button {
      height: 50PX;
      font-size: 14PX;
    }
  }

  
 
}
