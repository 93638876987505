@import '~bm-public.less';

.bm-p-queueings {
  background: var(--bm-dark-background-color);

  .pc-header {
    height: calc(var(--bm-safe-area-top) + 88px);
  }

  // .back-icon {
  //   color: var(--bm-text-title-color);
  // }

  .am-navbar {
    .light-bc;

    .am-navbar-title {
      color: var(--bm-text-title-color);
      .font-size(40px);
    }
  }

  .queueing-item {
    margin: 40px 40px 0 40px;
    background: var(--bm-light-background-color);
    padding: 44px 40px;
    border-radius: 16px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 40px;
    }

    .queueing-item-store-logo {
      width: 60px;
      height: 60px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      border-radius: 50%;
      background-image: url(/images/default-store.png);

      .image {
        width: 60px;
        height: 60px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
      }
    }

    .queueing-item-store {
      flex: 1;

      .queueing-item-store-title {
        font-weight: 500;
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 16px;
      }

      .queueing-item-time {
        font-size: 28px;
        line-height: 28px;
        color: var(--bm-text-secondary-color);
      }
    }

    .queueing-item-information {
      width: 162px;
      text-align: center;
      border-left: 2px solid #efeeee;
      box-sizing: border-box;

      .queueing-item-number {
        font-size: 32px;
        line-height: 32px;
        margin-bottom: 8px;
      }

      .queueing-item-number-tip {
        font-size: 20px;
        line-height: 20px;
      }
    }

    .opacity {
      opacity: 0.5;
    }
  }

  .loading-data {
    display: flex;
    .font-size(32px);

    justify-content: center;
    align-items: center;
    padding: 32px;

    .loading-msg {
      margin-left: 12px;
    }

    .am-icon-loading {
      width: 32px;
      height: 32px;
    }
  }

  .no-queueings {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 356px;

    .img {
      svg {
        width: 300px;
        height: 300px;
      }
    }

    .no-queueings-tip {
      margin-top: 20px;
      font-weight: bold;
      font-size: 32px;
      line-height: 36px;
      color: var(--bm-text-color);
      text-align: center;
    }
  }
}
