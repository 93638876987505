@import '~bm-public.less';

.bm-p-news {
  // .light-bc;

  // .pc-header {
  //   height: calc(var(--bm-safe-area-top) + 180px);
  // }
  .side-bar-icon {
    font-size: 40px;
  }
  .no-content-panel {
    display: flex;
    flex-direction: column;
    margin-top: 300px;
    justify-content: center;
    align-items: center;

    .bm-icon {
      font-size: 300px;
    }

    .no-content-info {
      color: var(--bm-text-secondary-color);
    }
  }

  .navbar-buttom {
    height: 88px;
    display: flex;
    align-items: center;
    padding: 0 32px;
    background-color: var(--bm-light-background-color);
    box-shadow: 0px 8px 20px rgba(60, 80, 111, 0.05);
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 9;

    top: calc(var(--bm-safe-area-top) + 88px);

    .order-type {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 28px;
      line-height: 32px;
      margin-right: 10px;
      padding: 10px 20px;
    }

    .selected {
      background-color: var(--bm-primary-color);
      border-radius: 400px;
      color: var(--bm-light-background-color);
    }
  }
}
.bm-p-news.web {
  .navbar-buttom {
    margin: auto;
  }
}
