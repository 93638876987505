@import '~bm-public.less';

.bm-p-store-configures {
  .pc-header {
    height: calc(var(--bm-safe-area-top) + 88px);
  }

  .back-icon {
    color: var(--bm-text-title-color);
  }

  .page-content {
    margin-bottom: 196px;
  }

  .am-navbar {
    .light-bc;

    border-bottom: 2px solid var(--bm-border-color);

    .am-navbar-title {
      color: var(--bm-text-title-color);
      .font-size(40px);
    }
  }

  .category {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--bm-text-disabled-color);

    .category-title {
      font-weight: 500;
      font-size: 32px;
      line-height: 32px;
      margin-left: 32px;
    }

    .fold-icon {
      background-color: #FFFFFF;
      border-radius: 50%;
      margin: 20px 20px 20px 0;
      .bm-icon {
        font-size: 48px;
        display: flex;
        padding: 0;
      }
    }
  }

  .information-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 32px;

    &-title {
      font-size: 28px;
      line-height: 32px;
      color: var(--bm-text-secondary-color);
    }

    &-data {
      font-size: 24px;
      line-height: 28px;
    }
  }

  .input-line {
    margin: 34px 0 0 32px;
    border-bottom: 2px solid var(--bm-border-color);

    .other-orders-input {
      padding-top: 8px;
      padding-bottom: 32px;
    }

    .am-list-item.am-input-item {
      padding: 0;
      border: none;
      background: none;
      height: 36px;
    }

    .am-list-item {
      height: 36px;
      min-height: 36px;
      line-height: 36px;
  
      .am-input-control {
        input {
          padding: 0;
          font-size: 28px;
          line-height: 36px;
        }
      }
    }

    .time-select {
      display: flex;
      margin: 32px 32px 28px 0;
      overflow-x: scroll;
      overflow-y: hidden;

      div {
        font-weight: 500;
        font-size: 28px;
        line-height: 32px;
        min-width: 112px;
        padding: 16px 0;
        text-align: center;
        border: 2px solid #828282;
        border-radius: 12px;
        margin-right: 32px;
      }

      .select {
        background-color: var(--bm-primary-color);
        color: var(--bm-light-background-color);
        border: none;
        min-width: 116px;
      }
    }
  }

  .title {
    font-size: 28px;
    line-height: 32px;
    color: var(--bm-text-secondary-color);
  }
  .settings {
    .settings-option {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 32px;
      border-radius: 12px;
      border: 2px solid #BDBDBD;
  
      &-title {
        margin-left: 32px;
        font-size: 30px;
        line-height: 32px;
      }
  
      .bm-icon {
        font-size: 48px;
        display: flex;
        padding: 0;
        margin: 32px 32px 32px 0;
      }
    }

    .select {
      border: 2px solid var(--bm-primary-color);

      .bm-icon {
        color: var(--bm-primary-color);
      }
    }
  }

  .bottom-botton {
    display: flex;
    position: fixed;
    bottom: 0;
    .phone & {
      left: 0;
      right: 0;
    }
    z-index: 990;
    background-color: #fff;
    overflow: hidden;
    box-shadow: 0 0 10px 0 var(--bm-shadow-color);
    padding: 32px;

    .activated-botton {
      flex: 1;
      padding: 34px 0;
      font-size: 32px;
      font-weight: bold;
      background-color: var(--bm-primary-color-tap);
      text-align: center;
      border-radius: 12px;
      color: var(--bm-light-background-color);
    }
  }
}
