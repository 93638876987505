@import '~bm-public.less';

.bm-c-select-pay-method {
  background-color: var(--bm-light-background-color);
  .choose-payment-block.bm-c-select-pay-method {
    overflow: scroll;
    height: 600PX;
    padding: 0px;
    background-color: var(--bm-light-background-color);
  }
  .w-radio {
    display: block;
    margin-bottom: 5PX;
    padding-left: 20PX;
  }
  .gift-card-title {
    text-align: left;
    .card-balance {
      color: var(--bm-text-secondary-color);
    }
  }
  .selected-icon {
    color: var(--bm-primary-color);
  }

  .last-digits {
    margin-left: 8px;
  }
  .payment-selection {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 10PX;
    padding: 30PX 20PX;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1PX solid var(--bm-border-color-t60);
    >div:first-child {
      width: 23PX;
      height: 23PX;
      margin-right: 10PX;
    }
    .image-200 {
      .bm-icon {
        width: 23PX;
        height: 23PX;
      }
     }
  } 
  .flex-pop-up {
    .bm-c-select-pay-method {
      background-color: var(--bm-light-background-color);
    }
  }
  // .choose-payment-block {
  //   .bm-c-select-pay-method {
  //     background-color: var(--bm-light-background-color);
  //   }
  // }
  .w-form-label {
    display: inline-block;
    cursor: pointer;
    font-weight: normal;
    margin-bottom: 0; 
  }
  .radio-button-label {
    margin-bottom: 0px;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-size: 18PX;
    font-weight: 400;
  }
  .payment-icons {
    margin-left: auto;
  }
  .image-200 {
    .bm-icon {
      max-width: none;
      min-width: 30PX;
      margin-left: 10PX;
    }
  }
  svg {
    width: 100%;
    height: 100%;
  }
  .bm-icon {
    padding: 0;
    font-size: unset;
  }
}
@media screen and (max-width: 767px) {
  .bm-c-select-pay-method {
    .choose-payment-block {
      overflow: visible;
      height: auto;
      border-radius: 12PX;
    }
  }
}
@media screen and (max-width: 479px) {
  .bm-c-select-pay-method {
    .w-radio {
      padding: 20PX 15PX 20PX 20PX;
    }
    .radio-button-label {
      font-size: 16PX;
    }
    .payment-selection {
      .image-200 {
        .bm-icon {
          width: 19PX;
          height: 19PX;
        }
      }
    }
  }
}