@import '~bm-public.less';
.bm-p-booking {
  background-color: var(--bm-light-background-color);
  overflow: scroll;
  .pc-header {
    height: calc(var(--bm-safe-area-top));
  }
  .icon-head {
    border-radius: 50%;
    width: 30PX;
    height: 30PX;
    margin-bottom: 8PX;
  }
  .icon-body {
    width: 45PX;
    height: 28PX;
    border-radius: 12PX 12PX 8PX 8PX;
    line-height: 28PX;
    text-align: center;
    font-weight: 600;
    font-size: 18PX;
  }
  .icon-head.select,
  .icon-body.select,
  .w-form-label.selected-title,
  .w-radio.select-date {
    background: var(--bm-primary-color);
    color: var(--bm-light-background-color);
  }
  // .label-1b {
  //   color: unset;
  // }
  .am-list-item {
    padding: 0 0 0 20PX;
  }
  .am-list-item:not(:last-child) .am-list-line {
    border-bottom: 0ch;
  }
  .am-list-item {
    box-sizing: border-box;
  }
  .am-input-item {
    background-color: var(--bm-light-background-color);
  }

  .text-field {
    height: 60PX;
    margin-bottom: 20PX;
    padding-right: 140PX;
    padding-left: 20PX;
    border-style: solid;
    border-width: 1PX;
    border-color: rgba(235,239,242,.5);
    border-radius: 8PX;
    font-family: 'Sf pro display', sans-serif;
    font-size: 16PX;
    font-weight: 600;
    background-color: hsla(0,0%,96.5%,.4);
  }
  .am-list-item .am-input-control input{
    font-weight: 600;
    font-size: 16PX;
  }
  .am-list-item .am-input-control {
    font-size: unset;
  }
  .text-field {
    padding-right: 0;
  }
  .form-2 .am-input-focus {
    border: 1PX solid var(--bm-primary-color);
  }
  .bm-c-user-components-div-block-106 .am-input-control {
    font-size: unset;
    padding-left: 111PX;
  }
  .am-textarea-control textarea {
    font-size: unset;
  }
  .disabled {
    opacity: .5;
  }
  .submit-button-booking.open-modal {
    color: var(--bm-light-background-color);
  }


  .radio-block {
    .icon-head.select,
    .icon-body.select {
      background: var(--bm-primary-color);
      color: var(--bm-light-background-color)
    }
  }
  .submit-button-booking.open-modal {
    background: var(--bm-primary-color);
  }
  .am-list-item .am-input-control input {
    font-size: unset;
  }
  .bm-icon {
    font-size: unset;
  }
  .bm-c-country-code-areacode-dialcode {
    font-size: 16PX;
  }
  .am-list-item.am-input-error .am-input-error-extra {
    height: unset;
  }
  .am-list-line .am-input-control input::-webkit-input-placeholder {
    font-size: unset;
  }
  .input-phone .am-input-control {
    padding-left: 110PX;
  }
  .am-list-item .am-input-label.am-input-label-5 {
    width: unset;
  }
  .select-date {
    background: var(--bm-primary-color);
    color: var(--bm-light-background-color);
  }
  .unselect {
    background: var(--bm-checkout-backgroud-color);
  }
  .form-2 .am-list-item:not(:last-child) .am-list-line::after {
    height: 0;
  }
  .is-aviailble {
    display: flex;
    justify-content: inherit;
  }
  .booking-is-aviailble {
    display: -ms-flexbox;
    display: flex;
    font-size: 16PX;
    >div:first-child {
      margin-right: 20PX;
      color: var(--bm-text-secondary-color);
    }
    >div {
      display: flex;
    }
    >div:last-child {
      color: #dea6a6;
    }
    .bm-icon {
      width: 20PX;
      height: 20PX;
      padding: 0;
      line-height: 20PX;
      margin-right: 5PX;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .aviailble {
      color: var(--bm-text-secondary-color);
    }
    .unaviailble {
      color: #dea6a6;
    }
    .unaviailble svg {
      background-color: #ffd2d2;
    }
  }
}
.div-block-106 {
  .am-list-item {
    box-sizing: border-box;
  }
  .am-list-item.am-input-error {
    .am-input-error-extra {
      height: 0;
    }
  }
}
.bm-icon.card-icon-close.close-outlined {
  svg {
    width: 100%;
    height: 100%;
  }
}
.bm-c-user-components-phone-number .am-list-item .am-list-line .am-input-label.am-input-label-5 {
 width: 160PX;
}

.head-bg.nav_bg.nav-blur.with-line {
  background-color: var(--bm-light-background-color);
}
.nav_bg.nav-blur {
  max-height: 70PX;
  min-height: 70PX;
  background-size: auto;
}
.nav_bg.nav-blur.with-line {
  position: -webkit-sticky;
  position: sticky;
  border-bottom-width: 1PX;
  border-bottom-color: #eaeaea;
  background-color: #fff;
}
.html-embed-7.dark {
  color: var(--bm-text-title-color);
}
.booking-left.open-modal{
  color: #fff;
  svg {
    width: 100%;
    height: 100%;
  }
}
.html-embed-7.left-outlined {
  width: 48PX;
  font-size: unset;
}
.nav-bar-title.dark {
  color: var(--bm-text-title-color);
}
.section.booking {
  padding-top: 0px;
  margin-top: 0;
  background-color: unset;
}
.container.booking {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 30PX;
  padding-bottom: 30PX;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1PX solid #eaeaea;
}
.heading-4-2 {
  margin-top: 0px;
  margin-bottom: 30PX;
  font-family: 'Sf pro display', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 20PX;
  font-weight: 600;
  text-align: left;
}
.div-block-103 {
  margin-top: 0px;
}
.w-form {
  margin: 0 0 15PX;
}
.form-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.div-block-102 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.radio-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 40PX;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.radio-button-attendees {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: auto;
  margin-right: 0px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.icon-people {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
}

.w-radio {
  display: block;
  margin-bottom: 5PX;
  padding-left: 20PX;
}
label {
  display: block;
  margin-bottom: 5PX;
  font-weight: bold;
}
.radio-button-date {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: auto;
  margin-right: 0px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.date-info {
  display: block;
  margin-bottom: 20PX;
  padding: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border: 0px solid #000;
  border-radius: 0px;
  font-family: 'Sf pro display', sans-serif;
  color: #666;
  font-size: 18PX;
  font-weight: 600;
  cursor: pointer;
}
.label {
  position: static;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: block;
  width: auto;
  height: auto;
  margin-bottom: 0px;
  padding: 11PX 12PX;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  border: 0px solid #000;
  border-radius: 200PX;
  background-color: transparent;
  font-family: 'Sf pro display', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 18PX;
  font-weight: 600;
  text-align: center;
}
.div-block-105 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  margin-bottom: 20PX;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.radio-button-time {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: auto;
  margin-right: 40PX;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.label-1a {
  position: static;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: block;
  width: auto;
  height: auto;
  margin-bottom: 0px;
  padding: 20PX 50PX;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  border-style: solid;
  border-width: 1PX;
  border-color: rgba(0, 69, 144, 0.09);
  border-radius: 8PX;
  background-color: transparent;
  font-family: 'Sf pro display', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 18PX;
  font-weight: 600;
  text-align: center;
}
.label-1b {
  position: static;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: block;
  width: auto;
  height: auto;
  margin-bottom: 0px;
  padding: 11PX 12PX;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  border-style: solid;
  border-width: 1PX;
  border-color: rgba(0, 69, 144, 0.09);
  border-radius: 8PX;
  background-color: transparent;
  font-family: 'Sf pro display', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 18PX;
  font-weight: 600;
  text-align: center;
}
.w-input, .w-select {
  display: block;
  width: 100%;
  height: 38PX;
  padding: 8PX 12PX;
  margin-bottom: 10PX;
  font-size: 14PX;
  line-height: 1.42857143;
  color: #333333;
  // vertical-align: middle;
  background-color: #ffffff;
  border: 1PX solid #cccccc;
}
textarea.w-input, textarea.w-select {
  height: auto;
}
.textarea {
  height: auto;
  min-height: 200PX;
  padding-top: 20PX;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-style: solid;
  border-width: 1PX;
  border-color: rgba(0, 69, 144, 0.09);
  border-radius: 8PX;
  font-family: 'Sf pro display', sans-serif;
  font-size: 16PX;
  font-weight: 600;
}
.container.booking.no-line {
  border-bottom-width: 0px;
}
.submit-button-booking {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60PX;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8PX;
  cursor: pointer;
}
.submit-text {
  display: block;
  font-family: 'Sf pro display', sans-serif;
  color: #fff;
  font-size: 20PX;
  font-weight: 600;
}
.time-disable {
  background-color: #FFD2D2;
  border-color: #DEA6A6;
  color: #DEA6A6;
}
@media screen and (max-width: 991px) {
  .heading-4-2 {
    margin-bottom: 10PX;
    font-size: 17PX;
  }
}
@media screen and (max-width: 767px) {
  .bm-p-booking { 
    .am-list-item .am-input-control input {
      font-size: 14PX;
    }
    .icon-body {
      font-size: 16PX;
    }
    .booking-is-aviailble {
      font-size: 14PX;
    }
  }
  .nav_bg.nav-blur.with-line {
    z-index: 800000;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .html-embed-7.left-outlined {
    width: 40PX;
  }
  .nav_bg.nav-blur {
    max-height: none;
    max-width: none;
    min-height: 60PX;
  }
  .container.booking {
    padding-top: 20PX;
    padding-bottom: 20PX;
  }
  .heading-4-2 {
    margin-bottom: 20PX;
    font-size: 16PX;
  }
  .form-block-2 {
    margin-bottom: 0px;
  }
  .radio-block {
    margin-right: 20PX;
  }
  .date-info {
    margin-bottom: 10PX;
    font-size: 16PX;
  }
  .label {
    padding: 14PX 15PX;
    background-color: rgba(150, 100, 100, 0);
    font-size: 16PX;
  }
  .radio-button-time {
    margin-right: 20PX;
  }
  .label-1a {
    padding: 10PX 30PX;
    font-size: 14PX;
  }
  .label-1b {
    padding: 10PX 20PX;
    font-size: 14PX;
  }
  .bm-p-booking {
    .text-field.w-input {
      font-size: 14PX;
    }
  }
  .textarea {
    min-height: 160PX;
    font-size: 14PX;
  }
  .submit-button-booking {
    height: 55PX;
  }
  .submit-text {
    font-size: 16PX;
  }
}
@media screen and (max-width: 479px) {
  .bm-p-booking {
    .icon-head {
      width: 25PX;
      height: 25PX;
      margin-bottom: 5PX;
    }
    .icon-body {
      width: 40PX;
      height: 25PX;
      border-radius: 10PX 10PX 5PX 5PX;
      line-height: 25PX;
    }
    .booking-is-aviailble {
      font-size: 12PX;
      .bm-icon {
        width: 16PX;
        height: 16PX;
        line-height: 16PX;
      }
    }
  }
  .nav_bg.nav-blur {
      max-height: 50PX;
      min-height: 50PX;
  }
  .html-embed-7.left-outlined {
    width: 36PX;
  }
  .container.booking {
    padding-top: 15PX;
    padding-bottom: 15PX;
  }
  .heading-4-2 {
    margin-top: 0px;
    margin-bottom: 15PX;
    font-size: 16PX;
    line-height: 16PX;
  }
  .date-info {
    margin-bottom: 5PX;
  }
  .label {
    padding-top: 13PX;
    padding-bottom: 13PX;
    background-color: rgba(150, 100, 100, 0);
    font-size: 14PX;
  }
  .div-block-105 {
    margin-bottom: 10PX;
  }
  .bm-p-booking {
    .text-field.w-input {
      height: 50PX;
    }
  }
  .container.booking.no-line {
    padding-bottom: 0px;
  }
}