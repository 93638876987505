@import '~bm-public.less';

.bm-p-pickup-locations {
  .light-bc;

  .pc-header {
    height: calc(var(--bm-safe-area-top) + 88px);
  }

  .back-icon {
    color: var(--bm-light-background-color);
  }

  .am-navbar {

    .am-navbar-title {
      color: var(--bm-light-background-color);
      .font-size(40px);
    }
  }
  .address-item {
    padding: 32px;
    border-bottom:  2px solid var(--bm-border-color);
    display: flex;
    justify-content: space-between;
    .address {
      font-size: 28px;
      color: var(--bm-text-title-color);
      line-height: 32px;
    }
    .bm-icon {
      font-size: 40px;
    }
  }
}
