@import '~bm-public.less';

.bm-p-signin {
  @media screen and (max-width: 767px) {
    &.section-2 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 95vh;
      padding-bottom: 20px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      background-image: none;
      background-size: auto;
    }
  }
}
@media screen and (max-width: 479px) {
  &.section-2 {
    height: 95vh;
    padding-bottom: 0px;
  }
}
