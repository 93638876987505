@import '~bm-public.less';

.bm-p-queueing-detail {
  background: var(--bm-dark-background-color);

  .pc-header {
    height: calc(var(--bm-safe-area-top) + 88px);
  }

  .back-icon {
    color: var(--bm-text-title-color);
  }

  .am-navbar {
    .light-bc;

    .am-navbar-title {
      color: var(--bm-text-title-color);
      .font-size(40px);
    }
  }

  .queueing-current {
    text-align: center;

    .queueing-current-tip {
      margin-top: 60px;
      font-weight: 600;
      font-size: 40px;
      line-height: 60px;
      color: var(--bm-text-secondary-color);
    }

    .queueing-current-number {
      font-weight: 600;
      font-size: 68px;
      line-height: 60px;
      margin-top: 32px;
      color: var(--bm-primary-color);
    }
  }

  .queueing-card {
    margin: 66px 40px 0 40px;
    padding: 40px 0;
    border-radius: 24px;
    background-color: var(--bm-light-background-color);

    .queueing-store {
      display: flex;
      margin-bottom: 20px;
      padding: 0 60px;
      align-items: center;

      .queueing-store-logo {
        margin-right: 20px;
        width: 88px;
        height: 88px;
        display: flex;
        align-items: center;
        border-radius: 50%;
        background-image: url(/images/default-store.png);

        .image {
          width: 88px;
          height: 88px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 50%;
        }
      }

      .queueing-store-information {
        .queueing-store-title {
          font-weight: 500;
          font-size: 36px;
          line-height: 36px;
          margin-bottom: 16px;
        }

        .queueing-store-address {
          font-size: 28px;
          line-height: 28px;
          color: var(--bm-text-secondary-color);
        }
      }
    }

    .queueing-card-gap {
      padding: 20px 40px;
      background-image: radial-gradient(circle at left, var(--bm-dark-background-color), var(--bm-dark-background-color) 20px, transparent 0%), radial-gradient(circle at right, var(--bm-dark-background-color), var(--bm-dark-background-color) 20px, transparent 0%);

      .queueing-card-gap-hr {
        padding: 0;
        margin: 0;
        border: none;
        border-top: 2px dashed var(--bm-border-color);
      }
    }

    .queueing-user {
      padding: 44px 0 64px 0;
      margin: 0 40px;
      border-bottom: 2px dashed var(--bm-border-color);

      .queueing-user-number {
        font-weight: bold;
        font-size: 102px;
        line-height: 102px;
        text-align: center;
        margin-bottom: 16px;
      }

      .queueing-user-number-tip {
        text-align: center;
        font-size: 30px;
        line-height: 36px;
        color: var(--bm-text-secondary-color);
      }
    }

    .queueing-expired-gap {
      height: 20px;
      text-align: center;
      background-image: radial-gradient(circle at bottom left, var(--bm-dark-background-color), var(--bm-dark-background-color) 20px, transparent 0%), radial-gradient(circle at bottom right, var(--bm-dark-background-color), var(--bm-dark-background-color) 20px, transparent 0%);
    }

    .queueing-expired {
      padding: 64px 0;
      text-align: center;
      background: var(--bm-text-disabled-color);
      color: var(--bm-text-secondary-color);
      background-image: radial-gradient(circle at top left, var(--bm-dark-background-color), var(--bm-dark-background-color) 20px, transparent 0%), radial-gradient(circle at top right, var(--bm-dark-background-color), var(--bm-dark-background-color) 20px, transparent 0%);

      .queueing-expired-number {
        font-weight: bold;
        font-size: 102px;
        line-height: 72px;
        margin-bottom: 16px;
      }

      .queueing-expired-number-tip {
        font-size: 30px;
        line-height: 36px;
        opacity: 0.5;
        margin-bottom: 32px;
      }

      .queueing-expired-sign {
        display: flex;
        justify-content: center;

        .queueing-expired-sign-text {
          border-radius: 52px;
          font-weight: 500;
          font-size: 28px;
          line-height: 28px;
          padding: 14px 24px;
          border: 2px solid var(--bm-modal-b-color);
        }
      }
    }

    .queueing-time {
      display: flex;
      font-size: 28px;
      line-height: 60px;
      margin-top: 24px;
      padding: 0 40px;

      .queueing-time-data {
        flex: 1;
        text-align: right;
      }
    }

    .queueing-seats {
      display: flex;
      font-size: 28px;
      line-height: 60px;
      padding: 0 40px;

      .queueing-seats-data {
        flex: 1;
        text-align: right;
      }
    }
  }
}
