@import '~bm-public.less';

.bm-p-express-order {
  .light-bc;

  .pc-header {
    height: calc(var(--bm-safe-area-top) + 88px);
  }

  .page-content {
    overflow-x: hidden;
    padding-bottom: 200px;
  }

  .pc-footer {
    height: 152px;
  }

  .back-icon {
    color: var(--bm-light-background-color);
  }

  .am-navbar {
    .am-navbar-title {
      color: var(--bm-light-background-color);
      .font-size(40px);
    }
  }
.main-store-wrap {
    position: relative;
    z-index: 0;
    .style-b-color {
      position: absolute;
      top: 0;
      width: 100%;
      height: 64px;
      .p-b-color;
      z-index: 1;
    }
    .main-store-tab {
      position: relative;
      z-index: 2;
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      margin: 0px 32px 30px 32px;
      padding-top: 32px;
      padding-bottom: 32px;
      border-radius: 12px;
      background-color: var(--bm-light-background-color);
      box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.18);
      .main-store-tab-img-title-wrap {
        display: flex;
        align-items: center;
        width: 100%;
        .main-store-img {
          width: 80px;
          height: 80px;
          margin-left: 32px;
          margin-right: 32px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 50%;
        }
        .main-store-title {
          font-size: 30px;
          color: var(--bm-primary-color);
          line-height: 48px;
          font-weight: 600;
          margin-right: 88px;
        }
        .down {
          position: absolute;
          font-size: 40px;
          margin-right: 32px;
          right: 0;
        }
      }
      .main-store-info-con {
        display: flex;
        margin-left: 135px;
        margin-right: 40px;
        .bm-icon {
          .font-size(28px);
          display: inline;
          padding: 0;
          margin-right: 8px;
          color: var(--bm-primary-color);
        }
        .con {
          .font-size(24px);
          color: var(--bm-text-color);
        }
      }
    }
  }
  .store-info-wrap {
     display: flex;
     background-color: var(--bm-checkout-backgroud-color);
     align-items: center;
     padding: 0 32px;
     margin-bottom: 20px;
     .store-img {
       width: 120px;
       height: 80px;
       background-position: center center;
       background-repeat: no-repeat;
       background-size: cover;
       margin-right: 32px;
     }
     .store-name {
       font-size: 36px;
       font-weight: 600;
       color: var(--bm-text-title-color);
     }
     .total {
       font-size: 28px;
       font-weight: 600;
       color: var(--bm-text-secondary-color);
     }
  }
  .express-order-msg-wrap {
    padding: 32px 0;
    margin: 0 32px;
    border-bottom: 2px solid var(--bm-checkout-backgroud-color);
    .express-order-msg {
      display: flex;
      justify-content: space-between;
      &:not(:first-child) {
        margin-bottom: 24px;
      }
      .title {
        font-size: 24px;
        line-height: 32px;
        color: var(--bm-text-color);
        line-height: 32px;
      }
      .con {
        font-size: 24px;
        line-height: 32px;
        color: var(--bm-text-title-color);
        line-height: 32px;
      }
    }
  }
  .express-order-content {
    padding-left: 32px;
    .sub-menus-title {
      font-size: 36px;
      font-weight: bold;
      padding: 26px 0;
      color: var(--bm-text-secondary-color);
    }
  }
  .side-bar-icon {
    font-size: 40px;
  }
  .menu-item {
    display: flex;
    padding:20px 32px 20px 0;
    border-bottom: 2px solid var(--bm-border-color);
    .item-content {
      flex: 1;
      .item-info {
        .title {
          .font-size(28px);
          .font-family-b;
          .title-color;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .code {
          .font-size(28px);
          color: var(--bm-text-secondary-color);
          margin-top: 18px;
        }
      }
      .item-price {
        margin-top: 32px;
        .font-size(28px);
        color: var(--bm-text-title-color);
      }
    }
    .item-img-wrapper {
      width: 160px;
      height: 160px;
      border-radius: 6px;
      .item-img {
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 6px;
      }
    }
    .item-action {
      position: absolute;
      right: 20px;
      top: 12px;
      .count {
        display: flex;
        min-width: 18px;
        padding: 0 10px;
        height: 38px;
        border-radius: 18px;
        justify-content: center;
        align-items: center;
        .font-size(20px);
        .font-family-m;
        color: #fff;
        background-color: var(--bm-primary-color);
      }
    }
  }

  .express-order-action-wrap {
    padding: 20px 32px 60px;
    .express-order-action {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      .add-item {
        padding: 0 20px;
        height: 76px;
        border-radius: 12px;
        border: 2px solid var(--bm-primary-color);
        line-height: 76px;
        color: var(--bm-primary-color);
      }
    }
    .express-order-remark-wrap {
      .title {
        font-size: 28px;
        color: var(--bm-text-title-color);
        font-weight: 600;
        line-height: 32px;
        margin-bottom: 26px;
      }
    }
  }
  .bm-c-express-order-list {
    padding-bottom: 0;
    .menu-panel-special {
      .menu-panel-title {
        background-color: #fff;
      }
    }
  }
  &-skeleton {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #f5f7f8;
    z-index: 999;
    .skeleton-icon {
      position: fixed;
      border-radius: 0 !important;
      height: 74px;
      .skeleton-color;
      z-index: 999;
      width: 100%;
      padding: 14px 0 8px 20px;
    }
    .left-outlined {
      background-color: #fff;
      border-radius: 50%;
      height: 50px;
      width: 50px;
    }
    .skeleton-div {
      .skeleton-color;
      height: 150px;
    }
    .skeleton-content {
      position: relative;
      width: 100%;
      padding: 0 32px;
      height: 100%;
      box-sizing: border-box;
      background-color: #f5f7f8;
      .skeleton-main {
        position: absolute;
        width: 91%;
        top: -36px;
        height: 200px;
        .main-cri {
          background-color: #fff;
          border-radius: 8px;
          display: flex;
          padding: 22px 44px 26px 24px;
        }
        .main-left {
          border-radius: 50% !important;
          width: 70px;
          height: 70px;
          .skeleton-color;
          margin-right: 16px;
        }
        .mian-right {
          width: 84%;
          margin-top: 18px;
          div {
            border-radius: 4px;
          }
        }
        .mian-right1 {
          width: 100%;
          height: 32px;
          .skeleton-color;
          margin-bottom: 20px;
        }
        .main-right2 {
          display: flex;
          .main-right2-left {
            width: 24px;
            height: 24px;
            margin-right: 12px;
            .skeleton-color;
          }
          .main-right2-right {
            div {
              height: 24px;
              width: 480px;
              .skeleton-color;
            }
            div:first-child {
              margin-bottom: 12px;
            }
          }
        }
        .mian-div {
          padding: 32px 28px 34px 26px;
          display: flex;
          margin-top: 30px;
          background-color: #fff;
          border-radius: 8px;
        }
        .mian-div-left {
          width: 80px;
          .skeleton-color;
          margin-right: 32px;
          height: 66px;
        }
        .mian-div-right {
          display: flex;
          width: 80%;
          margin-top: 18px;
          justify-content: space-between;
          div {
            .skeleton-color;
            height: 32px;
          }
        }
        .mian-div-right-left {
          width: 220px;
        }
        .mian-div-right-right {
          width: 140px;
        }
      }
    }
    .skeleton-footer-line {
      position: fixed;
      bottom: 1.6rem;
      left: 0;
      right: 0;
      height: 2px;
      z-index: 9;
      .skeleton-color;
    }
    .skeleton-footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      height: o.9rem;
      z-index: 9;
      display: flex;
      padding: 16px 20px 30px 20px;
      background-color: #fff;
      justify-content: space-around;
      div.skeleton-footer-icon {
        width: 65px;
      }
      .icon-item {
        width: 100%;
        height: 40px;
        .skeleton-color;
        margin-bottom: 10px;
        border-radius: 4px;
      }
      .icon-item-title {
        height: 16px;
      }
    }
  }
}
