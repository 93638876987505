@import '~bm-public.less';

.news-true {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 22PX;
  grid-row-gap: 22PX;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.news-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 450PX;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-radius: 12PX;
  box-shadow: 1PX 1PX 7PX -3PX rgba(0, 0, 0, 0.41);
}

.div-block-82 {
  position: relative;
  width: 100%;
  height: 30%;
  padding: 14PX;
  border-top-left-radius: 8PX;
  border-top-right-radius: 8PX;
  background-color: #fff;
  box-sizing: border-box;
}

.div-block-83 {
  width: 100%;
  height: 70%;
  // background-image: url('../images/pexels-caio-58461.jpeg');
  background-position: 50% 50%;
  background-size: cover;
}

.bm-c-news-list {
  padding: 32px 32px 200px 32px;
  .section {
    padding: 0;
  }
  .news-true {
    display: unset;
  }
}

.bm-p-menu-list {
  .section {
    display: block;
    margin-bottom: 0PX;
    padding: 40PX;
    flex-direction: column;
    align-items: center;
  }
}
.section.store-info {
  padding-top: 20PX;
}
@media screen and (max-width: 991PX) {
  .news-block {
    height: 400PX;
  }

  .div-block-82 {
    padding-top: 10PX;
    padding-bottom: 10PX;
  }

  .bm-p-menu-list {
    .section {
      padding: 30PX 35PX;
    }
  }
}
@media screen and (max-width: 767PX) {
  .news-true {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  
  .news-block {
    height: 300PX;
    border-radius: 8PX;
  }

  .div-block-82 {
    height: 40%;
  }
  .bm-p-menu-list {
    .section {
      padding: 15PX 25PX 20PX;
    }
  }
}

@media screen and (max-width: 479PX) {
  .div-block-82 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 30%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .bm-p-menu-list {
    .section {
      padding: 10PX 12PX 15PX;
    }
  }
}