@import '~bm-public.less';

.bm-p-good-received-notes {
  .dark-bc;

  .pc-header {
    height: var(--bm-safe-area-top);
  }

  .back-icon {
    color: var(--bm-light-background-color);
  }

  .am-navbar {

    border-bottom: 2px solid var(--bm-border-color);

    .am-navbar-title {
      color: var(--bm-light-background-color);
      .font-size(40px);
    }
  }

  .good-received-note-item {
    margin: 16px 42px;
    border-radius: 12px;
    background-color: var(--bm-light-background-color);
    padding: 36px 32px 24px 36px;

    &-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;

      &.number-and-state {
        margin: 0;
        padding-bottom: 18px;
        border-bottom: 2px solid rgba(235, 239, 242, 0.5);
        margin-bottom: 30px;
      }

      &-title {
        font-size: 24px;
        line-height: 32px;
        color: var(--bm-text-color);
      }

      &-data {
        font-size: 26px;
        line-height: 32px;
        color: var(--bm-text-title-color);
      }

      &-number {
        font-weight: 600;
        font-size: 28px;
        line-height: 32px;
        color: var(--bm-text-title-color);
      }

      &-state {
        font-size: 28px;
        line-height: 32px;
        border: 2px solid var(--bm-error-color);
        border-radius: 8px;
        padding: 2px 8px;
        color: var(--bm-error-color);

        &.received {
          color: var(--bm-tag-delivery-color);
          border-color: var(--bm-tag-delivery-color);
        }

        &.cancelled {
          color: var(--bm-skeleton-color-t30);
          border-color: var(--bm-skeleton-color-t30);
        }
      }
    }
  }

  .loading-data {
    display: flex;
    .font-size(32px);

    justify-content: center;
    align-items: center;
    padding: 32px;

    .loading-msg {
      margin-left: 12px;
    }

    .am-icon-loading {
      width: 32px;
      height: 32px;
    }
  }

  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 356px;

    .img {
      svg {
        width: 300px;
        height: 300px;
      }
    }

    .no-data-tip {
      margin-top: 20px;
      font-weight: bold;
      font-size: 32px;
      line-height: 36px;
      color: var(--bm-text-color);
      text-align: center;
    }
  }
}
