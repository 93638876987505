@import '~bm-public.less';

.bm-p-records {
  background-color: var(--bm-dark-background-color);

  &-booking {
    background-color: var(--bm-light-background-color);
  }
  // .pc-header {
  //   height: calc(var(--bm-safe-area-top) + 88px);
  // }
  &.has-header {
    .pc-header {
      height: calc(var(--bm-safe-area-top) + 180px);
    }
  }

  .navbar-left, .navbar-title, .navbar-right {
    flex: 1;
    height: 100%;
    .font-family-s;
    display: flex;
  }
  .navbar-left {
    padding-left: 30px;
  }
  .navbar-title {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    white-space: nowrap;
    text-align: center;
    .font-size(40px);
    line-height: 88px;
  }

  .navbar-buttom {
    position: fixed;
    top: calc(var(--bm-safe-area-top) + 88px);
    height: 92px;
    padding: 0 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--bm-light-background-color);
    box-shadow: 0px 8px 20px rgba(60, 80, 111, 0.05);
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    z-index: 9;

    .order-type {
      padding: 10px 0;
      font-weight: bold;
      flex: 1;
      text-align: center;
      font-size: 28px;
      line-height: 32px;

      &.selected {
        .font-family-b;
        .p-b-color;

        color: var(--bm-light-background-color);
        border-radius: 400px;
      }
    }
  }

  .content {
    padding-bottom: 150px;
    .booking {
      padding: 0 32px;
    }

    .booking-reservations {
      margin-top: 48px;
      font-weight: bold;
      font-size: 44px;
      line-height: 48px;
    }

    .booking-no {
      margin-top: 64px;
      font-size: 28px;
      line-height: 32px;
      letter-spacing: 1.5px;
    }

    .booking-new-booking {
      margin: 52px 0 80px 0;
      text-align: center;
      padding: 34px 0;
      background-color: var(--bm-primary-color);
      border-radius: 12px;
      font-size: 32px;
      line-height: 32px;
      font-weight: bold;
      color: var(--bm-light-background-color);
    }

    .booking-card {
      margin-top: 48px;
      padding: 32px 32px 46px 32px;
      background: var(--bm-light-background-color);
      border: 1.6px solid rgba(136, 144, 151, 0.2);
      box-sizing: border-box;
      box-shadow: 0px 4px 12px rgba(16, 37, 78, 0.08);
      border-radius: 12px;

      &:last-child {
        margin-bottom: 174px;
      }
  
      .booking-store {
        display: flex;
        align-items: center;
        font-weight: bold;
        margin-bottom: 32px;
  
        .booking-store-title {
          font-size: 32px;
          line-height: 36px;
        }
      }

      .booking-card-gap-hr {
        padding: 0;
        margin: 0;
        border: none;
        border-top: 2px solid var(--bm-border-color);
      }
  
      .booking-hr {
        padding: 0;
        margin: 0 100px 32px 100px;
        border: none;
        border-top: 2px solid var(--bm-border-color);
      }
  
      .booking-record {
        display: flex;
        text-align: center;
        margin: 32px 100px 16px 100px;
      }
  
      .booking-record-date {
        flex: 1;
      }
  
      .booking-record-time {
        flex: 1;
      }
  
      .booking-record-seat {
        flex: 1;
      }

      .booking-record-data {
        font-weight: bold;
        font-size: 32px;
        line-height: 32px;
      }
  
      .booking-record-title {
        font-size: 24px;
        line-height: 28px;
        color: var(--bm-text-secondary-color);
        margin-top: 16px;
      }
  
      .booking-information-item {
        display: flex;
        align-items: center;
        font-size: 28px;
        line-height: 32px;
        margin-top: 16px;
  
        .booking-information-item-title {
          color: var(--bm-text-color);
        }
  
        .booking-information-item-data {
          text-align: right;
          flex: 1;
        }

        .booking-information-item-status {
          text-align: right;
          flex: 1;
          font-weight: 600;
        }
      }
  
      .booking-information-note {
        font-size: 28px;
        margin-top: 16px;
  
        .booking-information-note-title {
          line-height: 32px;
          color: var(--bm-text-color);
        }
  
        .booking-information-note-data {
          line-height: 28px;
          margin-top: 14px;
          color: var(--bm-text-secondary-color);
        }
      }
    }

    .order-item {
      background-color: var(--bm-light-background-color);
      border-radius: 8px;
      margin: 32px;
      display: flex;

      &:last-child {
        margin-bottom: 64px;
      }

      .order-item-store-logo {
        width: 216px;
        height: 176px;
        margin-right: 28px;
        display: flex;
        align-items: center;
        border-radius: 8px 0 0 8px;
        background-image: url(/images/default-store.png);
  
        .image {
          width: 216px;
          height: 176px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          border-radius: 8px 0 0 8px;
        }
      }

      .order-item-info {
        flex: 1;

        .order-item-top {
          display: flex;
          padding-top: 30px;
          padding-right: 24px;
          line-height: 28px;
          div {
            font-size: 24px;
          }
          .order-item-id {
            font-weight: 500;
            color: #605b5b;
          }
          .order-item-button {
            color: #1ac51a;
            opacity: 0.6;
            flex: 1;
            display: flex;
            justify-content: flex-end;
          }
        }

        .order-item-store-name {
          margin-top: 16px;
          font-weight: bold;
          font-size: 28px;
          line-height: 32px;
        }

        .order-item-bottom {
          display: flex;
          font-size: 24px;
          margin-top: 16px;
          margin-right: 24px;
          line-height: 28px;

          .order-item-total {
            font-weight: bold;
            color: #FFC635;
          }

          .bar {
            margin: 0 20px;
            color: #dadada;
          }
        }
      }
    }

    .point {
      .point-item {
        font-weight: 500;

        .point-item-top {
          display: flex;
          justify-content: space-between;
          margin: 0 32px;
          height: 60px;
          align-items: center;

          .point-item-date {
            font-size: 26px;
            line-height: 32px;
          }

          .point-item-tip {
            text-transform: uppercase;
            font-size: 24px;
            line-height: 28px;
            color: var(--bm-text-secondary-color);
          }
        }
        .point-item-bottom {
          background-color: var(--bm-light-background-color);

          .point-item-data {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 32px;
            font-size: 28px;
            line-height: 32px;
            height: 120px;
            border-bottom: 2px solid var(--bm-border-color);
          }
        }
      }
    }

    .loading-data {
      display: flex;
      .font-size(32px);
  
      justify-content: center;
      align-items: center;
      padding: 32px;
  
      .loading-msg {
        margin-left: 12px;
      }
  
      .am-icon-loading {
        width: 32px;
        height: 32px;
      }
    }

    .no-orders {
      margin-top: 204px;
      display: flex;
      align-items: center;
      justify-content: center;
  
      .img {
        text-align: center;
  
        svg {
          height: 300px;
          width: 300px;
        }
      }
  
      .no-order {
        margin-top: 20px;
        font-weight: bold;
        font-size: 32px;
        line-height: 36px;
        color: var(--bm-text-color);
        text-align: center;
      }
  
      .no-order-tip {
        margin-top: 16px;
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        text-align: center;
        color: var(--bm-text-secondary-color);
      }
    }
  }
  .side-bar-icon {
    font-size: 40px;
  }

  .page-content-wrapper {
    background-color: var(--bm-dark-background-color);
  }
  &-skeleton {
    position: absolute;
    left: 0;
    top: calc(var(--bm-safe-area-top) + 80PX);
    right: 0;
    bottom: 0;
    z-index: 8;
    background-color: var(--bm-dark-background-color);
    .order {
      .order-item {
        border-radius: 8px;
        margin: 32px;
        background-color: var(--bm-light-background-color);
        display: flex;
      }
      .item-logo {
        width: 216px;
        height: 176px;
        margin-right: 28px;
        display: flex;
        .skeleton-color;
        border-radius: 0;
      }
      .item-info {
        max-width: unset;
        flex: 1;
        .top {
          display: flex;
          height: 28px;
          div {
            .skeleton-color;
          }
          .topbox {
            width: 125px;
          }
          .bar {
            width: 3px;
            margin: 0 20px;
          }
        }
        .center {
          margin: 25px 0;
          height: 28px;
          width: 400px;
          .skeleton-color;
        }
        .bottom {
          display: flex;
          margin-top: 16px;
          margin-right: 24px;
          height: 28px;
          justify-content: space-between;
          div {
            .skeleton-color;
          }
          .bottom-total {
            width: 100px;
          }
          .bottom-button {
            width: 110px;
          }
        }
      }
    }
    .point {
      background-color: #fff;
      height: 100%;
      .box1 {
        padding: 15px 28px;
        display: flex;
        justify-content: space-between;
        background-color: var(--bm-dark-background-color);
        div {
          width: 180px;
          height: 30px;
          .skeleton-color;
        }
      }
      .box2 {
        padding: 0 28px;
        .left {
          width: 400px;
        }
        .right {
          width: 120px;
          height: 28px;
        }
      }
      .box2>div {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e8e8e8;
        padding: 45px  0;
        div {
          .skeleton-color;
        }
      }
    }
    .booking {
      padding: 48px 32px;
      height: 100%;
      background-color: #fff;
      .box1 {
        width: 70%;
        height: 45px;
        .skeleton-color;
      }
      .box2 {
        width: 75%;
        height: 23px;
        margin: 64px 0 52px 0;
        .skeleton-color;
      }
      .box3 {
        border-radius: 12px;
        height: 102px;
        .skeleton-color;
        width: 100%;
      }
      .box4 {
        .skeleton-color;
        margin: 80px 0 40px 0 ;
        height: 48px;
        width: 70%;
      }
      .box5 {
        border-radius: 12px;
        border: 1px solid #e6e6e6;
        box-shadow: 0 0 20spx #e6e6e6;
        padding: 28px;
        .box5-top {
          padding: 5px 0 30px 0;
          div {
            width: 150px;
            height: 25px;
            .skeleton-color;
          }
        }
        .box5-center {
          display: flex;
          border-bottom: 1px solid #e6e6e6;
          border-top: 1px solid #e6e6e6;
          justify-content: center;
        }
        .box5-center>div {
          padding: 30px 0 20px 0;
          margin-right: 45px;
        }
        .center-top {
          width: 55px;
          height: 32px;
          margin-bottom: 25px;
          .skeleton-color;
        }
        .center-bottom {
          width: 38px;
          height: 20px;
          .skeleton-color;
          margin: auto;
        }
      }
      .box5-buttom-box {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        div {
          .skeleton-color;
          width: 100px;
          height: 22px;
        }
      }
      .box5-buttom-box.top {
        margin-top: 28px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    &-skeleton {
      position: absolute;
      left: 0;
      top: calc(var(--bm-safe-area-top) + 60PX);
    }
  }
  @media screen and (max-width: 479px) {
    &-skeleton {
      position: absolute;
      left: 0;
      top: calc(var(--bm-safe-area-top) + 50PX);
    }
  }
}
