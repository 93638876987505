@import '~bm-public.less';

.bm-c-feedback-list {
  .statistics {
    display: flex;
    border-bottom: 2px solid var(--bm-border-color-t60);
    padding: 34px 0 22px 32px;

    .statistics-total-score {
      font-weight: bold;
      font-size: 64px;
      line-height: 76px;
      display: flex;
      align-items: center;
    }

    .statistics-y-axis {
      width: 28px;
      border-right: 2px solid var(--bm-skeleton-color-t30);
      margin: 18px 30px 18px 0;
    }

    .statistics-feedback-number {
      font-size: 24px;
      line-height: 32px;
      display: flex;
      align-items: center;
      color: var(--bm-text-secondary-color);
    }

    .statistics-star {
      margin-top: 8px;

      .star-top {
        color: #f2f3f8;
      }

      .star-bottom {
        color: #ffb802;
      }

      .statistics-star-item {
        margin-right: 12px;
        padding: 0;
      }

      .statistics-half-star {
        width: 14px;
      }

      svg {
        width: 28px;
        height: 28px;
      }
    }
  }

  .feedback-item {
    margin-left: 32px;
    border-bottom: 2px solid var(--bm-border-color);
    padding: 36px 32px 42px 0;
    display: flex;

    .user-avatar-img-wrapper {
      width: 60px;
      height: 60px;
      margin-right: 32px;
      border-radius: 50%;
      background-image: url('/images/default-store.png');

      .user-avatar-img {
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
      }
    }

    .item-content {
      flex: 1;

      .item-content-top {
        .item-name-date {
          display: flex;

          .user-name {
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
          }

          .feedback-creatd-at {
            flex: 1;
            font-size: 20px;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            color: var(--bm-text-secondary-color);
          }
        }

        .item-stars {
          margin-top: 8px;
          display: flex;

          .star-top {
            color: #f2f3f8;
          }

          .star-bottom {
            color: #ffb802;
          }

          .star-item {
            padding: 0;
            margin-right: 8px;
          }

          .half-star {
            width: 10px;
          }

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }

      .item-content-bottom {
        .feedback-content-label {
          display: flex;
          flex-wrap: wrap;

          .label-item {
            margin-top: 16px;
            margin-right: 16px;
            padding: 12px 16px;
            font-size: 24px;
            line-height: 28px;
            display: flex;
            align-items: center;
            background: var(--bm-text-disabled-color);
            border-radius: 8px;
          }
        }
      }
    }
  }

  .loading-data {
    display: flex;
    .font-size(32px);

    justify-content: center;
    align-items: center;
    padding: 32px;

    .loading-msg {
      margin-left: 12px;
    }

    .am-icon-loading {
      width: 32px;
      height: 32px;
    }
  }

  .no-feedbacks {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;

    .img {
      svg {
        width: 300px;
        height: 300px;
      }
    }

    .no-feedbacks-tip {
      font-weight: bold;
      font-size: 32px;
      line-height: 36px;
      color: var(--bm-text-color);
      text-align: center;
    }
  }
}
