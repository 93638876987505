@import '~bm-public.less';

.bm-c-sign-up {
  &-wrap {
    padding: 48px 32px 0;
  }

  &-email-text {
    margin-top: 20px;
    color: var(--bm-text-secondary-color);
    font-size: 28px;
  }

  &-username {
    margin-bottom: 32px;

    .am-input-item {
      height: 88px;
      border-radius: 12px;
      border: 2px solid var(--bm-border-color);
      background-color: var(--bm-input-backgroud-color);

      &.am-input-focus {
        border: 2px solid var(--bm-primary-color);
      }

      .bm-icon {
        padding: 0;
      }
    }

    .am-input-control input {
      .font-size(28px);
      .font-family-m;
    }

    .am-input-extra {
      margin-left: 32px;
      max-height: 40px;
    }
  }

  &-mobile-verify {
    margin-top: 32px;

    .am-input-item {
      height: 88px;
      border-radius: 12px;
      border: 2px solid var(--bm-border-color);
      background-color: var(--bm-input-backgroud-color);

      &.am-input-focus {
        border: 2px solid var(--bm-primary-color);
      }

      .bm-icon {
        padding: 0;
      }
    }

    .am-input-control input {
      .font-size(28px);
      .font-family-m;
    }

    .am-input-extra {
      margin-left: 32px;
      max-height: 40px;
    }

    &-send {
      font-size: 24px;
      color: var(--bm-primary-color);
    }

    &-send-disabled {
      font-size: 24px;
      color: var(--bm-primary-color-t50);
    }

    &-password-icon {
      font-size: 24px;
      color: var(--bm-primary-color);
    }

    &-password-icon svg {
      fill: var(--bm-primary-color);
      font-size: 28px;
    }
  }

  &-mobile-password {
    margin-top: 32px;

    .am-input-item {
      height: 88px;
      border-radius: 12px;
      border: 2px solid var(--bm-border-color);
      background-color: var(--bm-input-backgroud-color);

      &.am-input-focus {
        border: 2px solid var(--bm-primary-color);
      }

      .bm-icon {
        padding: 0;
      }
    }

    .am-input-control input {
      .font-size(28px);
      .font-family-m;
    }

    .am-input-extra {
      margin-left: 32px;
      max-height: 40px;
    }

    .password-check {
      margin-top: 8px;
      color: var(--bm-error-color);
    }
  }

  &-signin-button {
    margin-top: 32px;
  }

  &-notice-user {
    display: flex;
    align-items: center;
    margin-top: 36px;

    &-icon {
      font-size: 40px;
      color: var(--bm-primary-color);
    }

    &-content {
      margin-left: 16px;
    }

    &-font {
      color: var(--bm-primary-color);
      text-decoration: underline;
    }
  }

  /* // for DatePicker scroll wheel
  &-mobile-date {
    margin-top: 32px;
    .date-pick-wrap {
      height: 88px;
      border-radius: 12px;
      border: 2px solid var(--bm-border-color);
      background-color: var(--bm-input-backgroud-color);
      .am-list-item {
        .am-list-line {
          background-color: var(--bm-input-backgroud-color);
          padding-left: 30px;
          .font-size(28px);
          .font-family-m;
          .am-list-extra {
            color: rgb(187, 187, 187);
          }
        }
        &.selected {
          .am-list-line {
            .am-list-extra {
              color: #000;
            }
          }
        }
      }
    }
    .date-pick-msg {
      margin-top: 16px;
      margin-left: 30px;
    }
  }
  */

  &-mobile-date {
    margin-top: 24px;
    .birthday-title {
      padding-bottom: 16px;
    }
    .date-pick-wrap {
      display: flex; 
      // flex-direction: row; 
      .am-input-item {
        height: 88px;
        width: 100%;
        border-radius: 12px;
        border: 2px solid var(--bm-border-color);
        background-color: var(--bm-input-backgroud-color);

        &.am-input-focus {
          border: 2px solid var(--bm-primary-color);
        }

        .bm-icon {
          padding: 0;
        }
      }
      .am-input-item:not(:last-child) {
        margin-right: 32px
      }

      .am-input-control input {
        .font-size(28px);
        .font-family-m;
      }

      .am-input-extra {
        margin-left: 32px;
        max-height: 40px;
      }
    }
    .date-pick-msg {
      margin-top: 16px;
      //margin-left: 30px;
    }
  }

  &-mobile-gender {
    margin-top: 32px;
    height: 88px;
    border-radius: 12px;
    border: 2px solid var(--bm-border-color);
    background-color: var(--bm-input-backgroud-color);
    display: flex;
    align-items: center;
    padding: 0 22px 0 30px;
    justify-content: space-between;
    
    .gender-name {
      .font-size(32px);
      .font-family-m;
      color: rgb(187, 187, 187);
      &.selected {
        color: #000;
      }
    }
    .down-icon {
      font-size: 40px;
    }

  }
}
