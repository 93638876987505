@import '~bm-public.less';
.bm-c-store-filter-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9820;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  .light-bc;

  &.visible {
    top: 0;
    transition: top 0.3s ease;
  }

  &.hide {
    top: 100%;
    transition: top 0.5s ease;
  }
}
.show-filter-modal.modal-wrapper-2 {
  display: flex;
  .am-slider-dot {
    transform: translateY(-50%);
    top: 50%;
  }
  .am-slider-mark-text {
    margin-top: 12PX;
  }
}
.bm-c-store-filter-modal {
  &-content-slider {
    padding: 20PX 20PX 30PX;
    margin: 25PX;
  }
  .am-slider-mark {
    top: 30PX;
  }
}
.c-popup-close {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 5;
  display: block;
  background-color: rgba(0, 0, 0, 0.09);
}
.flex-pop-up.widen {
  height: auto;
  padding-bottom: 20PX;
}
.flex-pop-up {
  position: relative;
  z-index: 10;
  display: block;
  overflow: visible;
  width: 600PX;
  height: auto;
  margin: auto;
  padding: 0px 0px 40PX;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 0px solid transparent;
  border-radius: 6PX;
  background-color: #fff;
  box-shadow: 0 9PX 50PX -14PX #000;
  color: #313131;
  text-align: center;
}
.top-header.widen {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 20PX;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.top-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 80PX;
  margin-bottom: 10PX;
  padding-top: 10PX;
  padding-bottom: 10PX;
  padding-left: 20PX;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1PX solid #eaeaea;
}
.heading-2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Sf pro display', sans-serif;
  font-weight: 600;
}
h2 {
  margin-top: 20PX;
  margin-bottom: 10PX;
  font-size: 32PX;
  line-height: 36PX;
  font-weight: bold;
}
.popup-body-content {
  margin-bottom: 0px;
  padding-right: 20PX;
  padding-left: 20PX;
}
.heading-4-1 {
  margin-top: 0px;
  margin-bottom: 10PX;
  font-family: 'Sf pro display', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 20PX;
  font-weight: 600;
  text-align: left;
}
.div-block-101 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.filter-block.current {
  margin-right: 20PX;
  margin-bottom: 20PX;
  background-color: var(--bm-primary-color);
  color: var(--bm-light-background-color);
  font-size: 18PX;
}
.filter-block {
  display: inline-block;
  margin-right: 20PX;
  margin-bottom: 20PX;
  padding: 8PX 30PX;
  border-radius: 40PX;
  background-color: #eaeaea;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  font-size: 16PX;
  font-weight: 400;
  cursor: pointer;
}
.submir-button.close-modal {
  margin-top: 0px;
  margin-right: 0px;
  margin-left: 0px;
  cursor: pointer;
}
.submir-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 55PX;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8PX;
  background-color: var(--bm-primary-color);
  font-family: 'Sf pro display', sans-serif;
  color: #fff;
  font-size: 18PX;
  font-weight: 600;
}
.x-icon {
  display: none;
  width: 20PX;
  color: var(--bm-text-title-color);
}
.submir-button.close-modal.widen {
  margin-left: 20PX;
  margin-right: 20PX;
  bottom: 20PX;
}
@media screen and (max-width: 991px) {
  .heading-4-1 {
    margin-bottom: 10PX;
    font-size: 17PX;
  }
}
@media screen and (max-width: 767px) {
  .top-header.widen {
    display: -ms-flexbox;
    display: flex;
    padding: 30PX 20PX 0;
  }
  .show-filter-modal.modal-wrapper-2 {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    z-index: 9007199254740991;
    height: auto;
    min-height: 100vh;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .c-popup-close.close-modal {
    display: block;
  }
  .c-popup-close {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  .close-icon {
    position: relative;
    top: 40PX;
    right: 40PX;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: auto;
  }
  .flex-pop-up.widen {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
  }
  .flex-pop-up {
    width: 100vw;
    height: auto;
    margin-bottom: 0px;
    padding-bottom: 20PX;
    border-radius: 0px;
    box-shadow: none;
  }
  .top-header {
    height: 60PX;
    padding-top: 20PX;
    padding-right: 20PX;
    padding-bottom: 20PX;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .heading-2 {
    font-size: 27PX;
  }
  .x-icon {
    display: block;
  }
  .popup-body-content {
    overflow-y: scroll;
    padding-top: 20PX 0 100PX;
  }
  .heading-4-1 {
    margin-bottom: 10PX;
    font-size: 18PX;
  }
  .filter-block.current {
    margin-right: 10PX;
    margin-bottom: 10PX;
  }
  .submir-button.close-modal.widen {
    position: static;
    left: 0%;
    top: auto;
    right: 0%;
    margin-bottom: 30PX;
  }
}
@media screen and (max-width: 479px) {
  .bm-c-store-filter-modal {
    &-content-slider {
      &-content-slider {
        padding: 20PX 20PX 30PX;
        margin: 20PX;
      }
      .am-slider-mark {
        top: 20PX;
        font-size: 16PX;
      }
    }
  }
  .show-filter-modal.modal-wrapper-2 {
    background-color: hsla(0, 0%, 100%, 0);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-height: 100%;
    font-style: normal;
    font-weight: 400;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    perspective: unset;
  }
  .c-popup-close {
    display: none;
  }
  .flex-pop-up.widen {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9820;
    left: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .flex-pop-up {
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    width: 100vw;
    height: auto;
    min-height: auto;
    min-width: 100vw;
    margin-top: auto;
    padding: 0px 0px 15PX;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    justify-content: unset;
  }
  // .top-header {
  //   margin-bottom: 0;
  // }
  .heading-2 {
    font-size: 27PX;
  }
  .popup-body-content {
    overflow-y: scroll;
    margin-bottom: 20PX;
    height: auto;
    min-height: auto;
    padding: 25PX 12PX 0;
  }
  .heading-4-1 {
    margin-top: 0px;
    margin-bottom: 15PX;
    font-size: 16PX;
    line-height: 16PX;
  }
  .filter-block {
    margin-right: 15PX;
    margin-bottom: 15PX;
    padding: 5PX 20PX;
  }
  .submir-button.close-modal.widen {
    bottom: 20PX;
    min-height: 50PX;
    color: var(--bm-light-background-color);
    background-color: var(--bm-primary-color);
    display: -ms-flexbox;
    display: flex;
    margin-top: auto;
    height: 50PX;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--bm-light-background-color);
    background-color: var(--bm-primary-color);
  }
  .top-header.widen {
    display: -ms-flexbox;
    display: flex;
    padding-top: calc(var(--bm-safe-area-top) + 20PX);;
  }
  .flex-pop-up.widen {
    background-color: var(--bm-light-background-color);
    padding-bottom: 0;
  }
  .submir-button.close-modal {
    -webkit-transition: top .3s ease;
    -o-transition: top .3s ease;
    transition: top .3s ease;
    position: fixed;
    left: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: var(--bm-light-background-color);
  }
}