.font-size(@size) {
  font-size: @size;
  line-height: @size + 4px;
}

.p-color {
  color: var(--bm-primary-color);
}

.p-b-color {
  background-color: var(--bm-primary-color);
}

.text-color {
  color: var(--bm-text-color);
}

.title-color {
  color: var(--bm-text-title-color);
}

.text-s-color {
  color: var(--bm-text-secondary-color);
}

.text-d-color {
  color: var(--bm-text-disabled-color);
}

.font-family-b {
  font-style: normal;
  font-weight: bold;
}

.font-family-m {
  font-style: normal;
  font-weight: normal;
}

.font-family-r {
  font-style: normal;
  font-weight: normal;
}

.font-family-s {
  font-style: normal;
  font-weight: normal;
}

.dark-bc {
  background-color: var(--bm-dark-background-color);
}

.light-bc {
  background-color: var(--bm-light-background-color);
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.skeleton-color {
  background-image: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  background-position: 100% 50%;
  animation: skeleton-loading 1.4s ease infinite;
  border-radius: 4px;
}

.gender-list {
  padding-left: 32px;
  border-top: 2px solid var(--bm-border-color);

  .gender-wrap {
    display: flex;
    height: 104px;
    justify-content: space-between;
    align-items: center;
    padding-right: 32px;
    border-bottom: 2px solid var(--bm-border-color);

    .gender {
      font-size: 32px;
      color: var(--bm-text-title-color);
    }

    .checked {
      font-size: 40px;
      color: var(--bm-primary-color);
    }
  }
}

.modal-body-left {
  .am-modal-body {
    max-height: 600px;
    text-align: center;
    white-space: pre-wrap;
  }
}
