@import '~bm-public.less';

.bm-c-shopping-cart-bar {
  &.div-block-97 {
      position: fixed;
      left: 0%;
      right: 0%;
      bottom: 40PX;
      z-index: 9800002;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: auto;
      margin-top: 0vh;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      background-color: transparent;
  }
  &.has-tab-bar {
    bottom: 98PX;
  }
  .float-block {
    // display: none;
    // margin-bottom: 40PX;
  }

  .add-cart-float {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 65PX;
    margin-bottom: 0PX;
    border-radius: 9PX;
    background-color: #fff;
    box-shadow: 1PX 1PX 10PX 0 #ececec;
  }

  .total-price-display {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 80%;
    height: 100%;
    padding-right: 20PX;
    padding-left: 20PX;
  }

  .cart-icon {
    position: relative;
    width: 35PX;
    margin-right: 20PX;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  
  .quanity {
    position: absolute;
    left: 22PX;
    top: 0%;
    right: 0%;
    bottom: 20PX;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 25PX;
    height: 25PX;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 16PX;
    background-color: var(--bm-primary-color);
    font-family: 'Sf pro display', sans-serif;
    color: #fff;
    font-weight: 600;
  }

  .html-embed-13 {
    margin-top: 9PX;
    color: #333;
  }

  .text-block-17 {
    font-family: 'Sf pro display', sans-serif;
    color: #fff;
    font-size: 22PX;
    font-weight: 600;
  }

  .text-block-6 {
    max-width: none;
    margin-top: 0PX;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: #333;
    font-size: 18PX;
  }

  .check-out-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 20%;
    height: 100%;
    margin-left: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--bm-primary-color);
    text-decoration: none;

  }

  .w-inline-block {
    max-width: 100%;
  }


  @media screen and (max-width: 991PX) {
    &.div-block-97 {
      position: fixed;
      margin-top: 0vh;
    }

    .text-block-6 {
      font-size: 18PX;
    }
  }

  @media screen and (max-width: 767PX) {
    .float-block {
      padding-right: 25PX;
      padding-left: 25PX;
    }

    .total-price-display {
      width: 60%;
    }

    .text-block-6 {
      font-size: 14PX;
    }

    .check-out-block {
      width: 40%;
    }

    .text-block-17 {
      font-size: 18PX;
    }
  }

  @media screen and (max-width: 479PX) {
    .float-block {
      padding-right: 12PX;
      padding-left: 12PX;
    }

    .add-cart-float {
      height: 50PX;
      border-radius: 6PX;
    }

    .total-price-display {
      padding-right: 10PX;
      padding-left: 10PX;
    }

    .cart-icon {
      width: 30PX;
    }
    .quanity {
      width: 22PX;
      height: 22PX;
      font-size: 12PX;
    }

    .text-block-6 {
      font-family: 'Sf pro display', sans-serif;
      font-size: 14PX;
      font-weight: 600;
    }
  }

}
#root.web .router-wrapper #shopping-cart-bar.bm-c-shopping-cart-bar.div-block-97{
  max-width: 100%;
}
