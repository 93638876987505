@import '~bm-public.less';

.bm-c-brands-categories {
  padding: 0 40PX 40PX;
  display: block;
  margin-top: auto;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  
  .bm-c-brands-categories-wrapper {
    display: flex;
  }
}
.bm-c-brands-categories-item {
    overflow: visible;
    width: 400PX;
    margin-right: 20PX;
    margin-left: auto;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    color: var(--bm-text-title-color);;
    cursor: pointer;
}
.box-categories-img,
.categories-img  {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: auto;
    height: 250PX;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 12PX;
    background-image: url(/images/default-store.png);
    background-size: cover;
    background-position: 50%;
}
.box-categories-img {
  margin-bottom: 20PX;
}
.categories-img {
  width: 100%;
}
.categories-title {
  font-family: 'Sf pro display', sans-serif;
  font-weight: 600;
  font-size: 18PX;
  line-height: 24PX;
  margin-top: 10PX;
}
.categories-description {
  margin-bottom: 0PX;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: #999;
  font-size: 16PX;
  text-align: left;
  position: relative;
  display: block;
  overflow: visible;
}
.w-container-item {
  position: relative; 
  display: -webkit-box; 
  display: -webkit-flex; 
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  width: 100%;
}
.title {
  position: relative;
  margin-bottom: 30PX;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.title-item:focus {
  outline: 0;
}
@media screen and (max-width: 991px){
  .bm-c-brands-categories {
      padding: 0 35PX 30PX;
  }
  .categories-title {
    margin-bottom: 5PX;
  }
  .categories-description{
    font-size: 14PX;
  }
}

@media screen and (max-width: 767px){
  .bm-c-brands-categories {
      padding: 0 25PX 20PX;
  }
  .bm-c-brands-categories-item {
    width: 300PX;
  }
  .box-categories-img,
  .categories-img {
    height: 200PX;
    border-radius: 8PX;
  }
  .categories-description{
    margin-bottom: 0PX;
    font-size: 14PX;
    line-height: 16PX;
  }
}

@media screen and (max-width: 479px){
  .bm-c-brands-categories {
      padding: 0 12PX 15PX;
  }
  .bm-c-brands-categories-item {
    width: 290PX;
    margin-right: 15PX;
  }
  .box-categories-img,
  .categories-img {
    height: 170PX;
    border-radius: 10PX;
  }
  .box-categories-img {
    margin-bottom: 10PX;
  }
  .categories-title  {
    margin-top: 5PX;
    margin-bottom: 5PX;
    font-size: 16PX;
    line-height: 22PX;
  }
  .categories-description{
    font-size: 12PX;
    line-height: 16PX;
  }
}
