@import '~bm-public.less';

.bm-p-store-map {
  .light-bc;

  .pc-header {
    height: calc(var(--bm-safe-area-top) + 88px);
    background-color: var(--bm-primary-color);
  }

  .pc-footer {
    background-color: var(--bm-light-background-color);
  }

  .page-content {
    .light-bc;
  }

  &-content {
    background-color: var(--bm-light-background-color);
  }

  &-item {
    display: flex;
    align-items: center;
    margin: 10px;
    color: var(--bm-primary-color);
  }
}
.bm-p-store-map.web {
  .bm-c-store-list  {
    display: flex;
    flex-flow: column;
  }
  .bm-c-store-list-icon-left-wapper {
    flex: 1;
  }
  .bm-c-store-list-icon-left {
    z-index: 999;
    background-color: #fff;
  }
}
