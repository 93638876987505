body {
  font-family:
    'Helvetica Neue',
    'Helvetica',
    'PingFang SC',
    'Hiragino Sans GB',
    'Microsoft Sans Serif',
    'SimSun',
    'Verdana',
    'Microsoft YaHei',
    'WenQuanYi Micro Hei',
    'sans-serif';
}

.lazyload-wrapper {
  height: 100%;
  width: 100%;
}
