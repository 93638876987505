.bm-c-animate {
  &-none {
    &-enter {
      z-index: 8;

      &-active {
        transition: all 0s ease-in-out;
      }
    }

    &-exit {
      opacity: 0;

      &-active {
        transition: all 0s ease-in-out;
      }
    }
  }

  &-move {
    &-from-left {
      &-enter {
        // position: fixed !important;
        transform: translateX(-100%);
        z-index: 9999;

        &-active {
          transform: translateX(0);
          transition: all 0.2s ease;
        }
      }

      &-exit {
        // position: fixed !important;
        transform: translateX(0);

        &-active {
          transform: translateX(100%);
          transition: all 0.2s ease;
        }
      }
    }

    &-from-right {
      &-enter {
        // position: fixed !important;
        transform: translateX(100%);
        z-index: 9999;

        &-active {
          transform: translateX(0);
          transition: all 0.2s ease;
        }
      }

      &-exit {
        // position: fixed !important;
        transform: translateX(0);

        &-active {
          transform: translateX(-100%);
          transition: all 0.2s ease;
        }
      }
    }

    &-from-top {
      &-enter {
        .web & {
          position: absolute !important;
          top: -100% !important;
        }
        .phone & {
          position: fixed !important;
          transform: translateY(-100%);
        }
        height: 100%;
        overflow: auto;

        &-active {
          .web & {
            top: 0 !important;
          }
          .phone & {
            transform: translateY(0);
          }
          transition: all 0.2s ease;
        }
      }

      &-exit {
        .web & {
          position: absolute !important;
          top: 0 !important;
        }
        .phone & {
          position: fixed !important;
          transform: translateY(0);
        }
        height: 100%;
        overflow: auto;

        &-active {
          .web & {
            top: 100% !important;
          }
          .phone & {
            transform: translateY(100%);
          }
          transition: all 0.2s ease;
        }
      }
    }

    &-from-bottom {
      &-enter {
        .web & {
          position: absolute !important;
          top: 100% !important;
        }
        .phone & {
          position: fixed !important;
          transform: translateY(100%);
        }
        height: 100%;
        overflow: auto;

        &-active {
          .web & {
            top: 0 !important;
          }
          .phone & {
            transform: translateY(0);
          }
          transition: all 0.2s ease;
        }
      }

      &-exit {
        .web & {
          position: absolute !important;
          top: 0 !important;
        }
        .phone & {
          position: fixed !important;
          transform: translateY(0);
        }
        height: 100%;
        overflow: auto;

        &-active {
          .web & {
            top: -100% !important;
          }
          .phone & {
            transform: translateY(-100%);
          }
          transition: all 0.2s ease;
        }
      }
    }
  }

  &-fade {
    &-from-left {
      &-enter {
        // position: fixed !important;
        z-index: 2;
        transform: translateX(-100%);

        &-active {
          z-index: 2;
          transform: translateX(0);
          transition: all 0.2s ease-in-out;
        }
      }

      &-exit {
        // position: fixed !important;
        z-index: 1;
        opacity: 1;

        &-active {
          z-index: 1;
          opacity: 0.3;
          transition: all 0.2s ease-in-out;
        }
      }
    }

    &-back-left {
      &-enter {
        // position: fixed !important;
        z-index: 1;

        &-active {
          z-index: 1;
          transition: all 0.2s ease-in-out;
        }
      }

      &-exit {
        // position: fixed !important;
        z-index: 2;
        opacity: 1;
        transform: translateX(0);

        &-active {
          z-index: 2;
          opacity: 0.3;
          transform: translateX(-100%);
          transition: all 0.2s ease-in-out;
        }
      }
    }

    &-from-right {
      &-enter {
        .web & {
          position: absolute !important;
          left: 100% !important;
        }
        .phone & {
          position: fixed !important;
          transform: translateX(100%);
        }
        z-index: 9999;

        &-active {
          transform: translateX(0);
          .web & {
            left: 0 !important;
          }
          .phone & {
            transform: translateX(0);
          }
          transition: all 0.2s ease-in-out;
        }
      }

      &-exit {
        .web & {
          position: absolute !important;
        }
        .phone & {
          position: fixed !important;
        }
        opacity: 1;

        &-active {
          opacity: 0.3;
          transition: all 0.2s ease-in-out;
        }
      }
    }

    &-back-right {
      &-enter {
        .web & {
          position: absolute !important;
        }
        .phone & {
          position: fixed !important;
        }
        z-index: 1;

        &-active {
          transition: all 0.1s ease-in-out;
        }
      }

      &-exit {
        .web & {
          position: absolute !important;
          left: 0 !important;
        }
        .phone & {
          position: fixed !important;
          transform: translateX(0);
        }
        z-index: 9999;

        &-active {
          .web & {
            left: 100% !important;
          }
          .phone & {
            transform: translateX(100%);
          }
          transition: all 0.2s ease-in-out;
        }
      }
    }

    &-from-top {
      &-enter {
        .web & {
          position: absolute !important;
          top: -100% !important;
        }
        .phone & {
          position: fixed !important;
          transform: translateY(-100%);
        }
        height: 100%;
        overflow: auto;
        z-index: 2;

        &-active {
          z-index: 2;
          .web & {
            position: absolute !important;
            top: 0 !important;
          }
          .phone & {
            position: fixed !important;
            transform: translateY(0);
          }
          transition: all 0.2s ease-in-out;
        }
      }

      &-exit {
        .web & {
          position: absolute !important;
        }
        .phone & {
          position: fixed !important;
        }
        height: 100%;
        overflow: auto;
        z-index: 1;
        opacity: 1;

        &-active {
          z-index: 1;
          opacity: 0.3;
          transition: all 0.2s ease-in-out;
        }
      }
    }

    &-back-top {
      &-enter {
        .web & {
          position: absolute !important;
        }
        .phone & {
          position: fixed !important;
        }
        height: 100%;
        overflow: auto;
        z-index: 1;

        &-active {
          z-index: 1;
          transition: all 0.2s ease-in-out;
        }
      }

      &-exit {
        .web & {
          position: absolute !important;
          top: 0 !important;
        }
        .phone & {
          position: fixed !important;
          transform: translateY(0);
        }
        height: 100%;
        overflow: auto;
        z-index: 2;
        opacity: 1;

        &-active {
          z-index: 2;
          opacity: 0.3;
          .web & {
            top: -100% !important;
          }
          .phone & {
            transform: translateY(-100%);
          }
          transition: all 0.2s ease-in-out;
        }
      }
    }

    &-from-bottom {
      &-enter {
        .web & {
          position: absolute !important;
          top: 100% !important;
        }
        .phone & {
          position: fixed !important;
          transform: translateY(100%);
        }
        height: 100%;
        overflow: auto;
        z-index: 2;

        &-active {
          z-index: 2;
          .web & {
            position: absolute !important;
            top: 0 !important;
          }
          .phone & {
            position: fixed !important;
            transform: translateY(0);
          }
          transition: all 0.2s ease-in-out;
        }
      }

      &-exit {
        .web & {
          position: absolute !important;
        }
        .phone & {
          position: fixed !important;
        }
        height: 100%;
        overflow: auto;
        z-index: 1;
        opacity: 1;

        &-active {
          z-index: 1;
          opacity: 0.3;
          transition: all 0.2s ease-in-out;
        }
      }
    }

    &-back-bottom {
      &-enter {
        .web & {
          position: absolute !important;
        }
        .phone & {
          position: fixed !important;
        }
        height: 100%;
        overflow: auto;
        z-index: 1;

        &-active {
          z-index: 1;
          transition: all 0.2s ease-in-out;
        }
      }

      &-exit {
        .web & {
          position: absolute !important;
          top: 0;
        }
        .phone & {
          position: fixed !important;
          transform: translateY(0);
        }
        height: 100%;
        overflow: auto;
        z-index: 2;
        opacity: 1;

        &-active {
          z-index: 2;
          opacity: 0.3;
          .web & {
            top: 100%;
          }
          .phone & {
            transform: translateY(100%);
          }
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }

  &-material-forward,
  &-reveal-forward {
    &-enter {
      box-sizing: border-box;
      .web & {
        position: absolute !important;
      }
      .phone & {
        position: fixed !important;
      }
      z-index: 2;
      overflow: auto;

      &-active {
        box-sizing: border-box;
        .web & {
          position: absolute !important;
        }
        .phone & {
          position: fixed !important;
        }
        z-index: 2;
        overflow: auto;
        transition: all 0.2s ease-in-out;
      }
    }

    &-exit {
      z-index: 1;

      &-active {
        z-index: 1;
      }
    }
  }

  &-material-backward,
  &-reveal-backward {
    &-enter {
      z-index: 1;

      &-active {
        z-index: 1;
      }
    }

    &-exit {
      opacity: 1;

      &-active {
        opacity: 0;
        transition: all 0.4s ease;
      }
    }
  }
}
