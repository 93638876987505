@import '~bm-public.less';
.section {
  display: block;
  margin-bottom: 0PX;
  padding: 40PX;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  &.membershipSection {
    margin-top: 0;
    background-color: var(--bm-dark-background-color)
  }
}
.container {
  position: relative;
  display: block;
  overflow: visible;
}
.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940PX;
}
.profile-block-not-member {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 20PX;
  padding: 20PX 0PX;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8PX;
  background-color: var(--bm-light-background-color);
  box-shadow: 1PX 1PX 20PX 0 rgba(0, 0, 0, 0.16);
  text-align: center;
}
.profile-block-tablet-view {
  display: none;
  width: 100%;
  padding: 20PX 0PX;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}
.top_profile-part {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 60PX;
  padding: 30PX 21PX 30PX 33PX;
  border-radius: 12PX;
  background-color: var(--bm-light-background-color);
  box-shadow: 1PX 1PX 20PX 0 rgba(0, 0, 0, 0.16);
}
.div-block-469 {
  width: 50%;
}
.profile-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 30PX;
  padding: 20PX 0PX;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}
.div-block-468 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.dp_big {
  width: 70PX;
  height: 70PX;
  margin-right: 20PX;
  border-radius: 50%;
  background-color: var(--bm-primary-color-t12);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bm-light-background-color);
  &.not-login-yet {
    background-color: #eaeaea;
    background-image: url(https://assets.website-files.com/605173a…/60d9970…_member-icon.svg);
    background-position: 50% 50%;
    background-size: 60%;
    background-repeat: no-repeat;
  }
  .avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-size: cover;
  }
}
.div-block-465 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  &.not-yet-login {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.div_block200 {
  margin-bottom: 0px;
  font-family: 'Sf pro display', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 18PX;
  font-weight: 600;
}
.div-block-513 {
  width: 1PX;
  height: 17PX;
  margin-right: 10PX;
  margin-left: 10PX;
  background-color: #a39d9d;
}
.profile_name {
  margin-bottom: 10PX;
  font-family: 'Sf pro display', sans-serif;
  color: #333;
  font-size: 18PX;
  font-weight: 600;
}
.profile_birthday {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: #333;
  font-size: 16PX;
  font-weight: 400;
}
.div-block-479 {
  position: relative;
}
.html-embed-29 {
  width: 30PX;
  height: 30PX;
  cursor: pointer;
}
.html-embed-33 {
  width: 30PX;
  height: 30PX;
  cursor: pointer;
}
.div-block-475 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.div-block-474 {
  position: relative;
  margin-bottom: 20PX;
}
.spend_now_button {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 140PX;
  height: 50PX;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12PX;
  background-color: var(--bm-primary-color);
  cursor: pointer;
}
.text-block-65 {
  font-family: 'Sf pro display', sans-serif;
  color: var(--bm-light-background-color);
  font-size: 16PX;
  font-weight: 600;
}
.text-block-62 {
  margin-bottom: 10PX;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: #a39d9d;
}
.text-block-63 {
  font-family: 'Sf pro display', sans-serif;
  font-size: 50PX;
  margin-top: 20PX;
  color: var(--bm-primary-color);
}
.text_block_100 {
  margin-bottom: 0PX;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: #333;
  font-size: 16PX;
  font-weight: 400;
}
.div-block-472 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  min-height: 90PX;
  margin-top: 20PX;
  margin-bottom: 20PX;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  .membership-level {
    position: relative;
    height: 134px;
    .progress-bar-wrap {
      position: absolute;
      left: 0;
      background-color: #DFDFDF;
      height: 18px;
      width: 100%;
      top: 40px;
      .progress-bar {
        height: 18px;
        background-color: var(--bm-primary-color);
      }
    }
    .membership-name {
      margin-top: 10px;
      font-size: 24px;
    }
    .membership-icon {
      margin-top: 15px;
      padding: 0;
      font-size: 70px;
    }
    .current-icon {
      font-size: 90px;
      padding: 0;
    }
    &:not(:last-child) {
      flex: 1;
    }
    &:first-child{
      .progress-bar-wrap, .progress-bar {
        border-top-left-radius: 18px;
        border-bottom-left-radius: 18px;
      }
    }
    &:last-child{
      .progress-bar-wrap, .progress-bar {
        border-top-right-radius: 18px;
        border-bottom-right-radius: 18px;
        width: 20px;
      }
    }
  }
}
.membership_progress_tracking {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.div-block-470 {
  width: 100%;
  height: 10PX;
  border-radius: 30PX;
  background-color: #dfdfdf;
}
.div-block-470-copy {
  position: absolute;
  width: 40%;
  height: 10PX;
  border-radius: 30PX;
  background-color: #004590;
}
.lowest {
  position: absolute;
  top: auto;
  max-width: none;
}
.lowest.active {
  max-width: 100PX;
  min-width: 100PX;
}
.medium {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: auto;
  max-width: 90PX;
  margin-right: auto;
  margin-left: auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.highest {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: auto;
  max-width: none;
}
.text_block_100 {
  margin-bottom: 0PX;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: #333;
  font-size: 16PX;
  font-weight: 400;
}
.bold-text {
  color: #004590;
}
.bold-text-2 {
  color: #004590;
}
.div-block-466 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  margin: 0PX 0PX 0PX 30PX;
  padding-right: 30PX;
  padding-left: 30PX;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-left: 0PX solid #e2e2e2;
  border-radius: 0PX;
  align-items: center;
}
.card_interface {
  display: block;
  width: 100%;
  margin-left: 0PX;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: var(--bm-light-background-color);
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 24PX;
  margin-bottom: 70PX;
  margin-top: 20PX;
}
.qr_code_profile_interface {
  width: 50%;
  height: 50%;
  border: 2PX solid #000;
  border-radius: 10PX;
  margin-bottom: 70PX;
}
.bottom_profile_part {
  margin-top: 0PX;
  padding: 23PX;
  background-color: var(--bm-light-background-color);
  box-shadow: 1PX 1PX 20PX 0 rgba(0, 0, 0, 0.16);
}
.bottom_profile_part.no-token {
  border-radius: 8PX;
}
.text-block-103 {
  margin-bottom: 20PX;
  font-family: 'Sf pro display', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 18PX;
  line-height: 26PX;
  text-align: center;
}
.div-block-478 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 20PX;
  grid-row-gap: 20PX;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
.option_block {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100PX;
  padding: 20PX;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #333;
  cursor: pointer;
}
.html-embed-30 {
  width: 30PX;
  height: 30PX;
  margin-right: 10PX;
}
.text-block-66 {
  font-family: 'Sf pro display', sans-serif;
  font-size: 16PX;
  font-weight: 600;
}
.html-embed-31 {
  position: absolute;
  left: auto;
  top: auto;
  right: 20PX;
  bottom: auto;
  width: 30PX;
  height: 30PX;
  color: #666;
}
span {
  top: -12PX;
  z-index: 1;
  sup.am-badge-text {
    background-color: red;
    font-size: 14PX;
    line-height: 18PX;
    min-width: 10PX;
    padding: 0PX 4PX 0 4PX;
    height: 18PX;
  }
}
.expire {
  position: absolute;
  bottom: 29%;
  right: 10%;
  color: var(--bm-light-background-color);
  font-size: 16PX;
  text-shadow: #000 0px 0px 20px;
}
.card-name-lg {
  position: absolute;
  bottom: 0;
  text-align: center;
  .card-name {
    font-size: 20PX;
    margin-bottom: 5PX;
    .bm-icon {
      font-size: 20PX;
    }
  }
  .member-tag {
    padding: 0;
    margin-right: 5PX;
  }
  .accumulated-spending {
    font-size: 16PX;
    color: var(--bm-text-secondary-color-t60);
  }
}
.card-name-sm {
  display: none;
  .accumulated-spending {
    color: var(--bm-text-secondary-color-t60);
  }
}
.loyalty-stamp-bottom {
  font-size: 18PX;
  color: #a39d9d;
  margin-top: 12PX;
}
.text-block-62 {
  font-size: 18PX;
}
.loyalty-stamp-span {
  font-size: 24PX;
  color: var(--bm-primary-color);
}
.no-div-block-466 {
  height: 100%;
}
@media screen and (max-width: 991PX) {
  .expire {
    bottom: 5%;
    right: 30%;
  }
  .card-name-lg {
    display: none;
  }
  .card-name-sm {
    position: absolute;
    top: 125PX;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    text-align: center;
    width: 42%;
    .card-name,
    .bm-icon {
      font-size: 18PX;
    }
    .member-tag,
    .accumulated-spending {
      font-size: 14PX;
    }
  }
  .section {
    padding: 30PX 35PX;
  }
  .w-container {
    max-width: 728PX;
  }
  .profile-block-not-member {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 20PX;
    padding-bottom: 20PX;
  }
  .div-block-468.not-login-yet {
    margin-bottom: 0px;
  }
  .dp_big.not-login-yet {
    width: 60PX;
    height: 60PX;
    background-color: #eaeaea;
  }
  .profile-block-tablet-view {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0PX;
    padding-bottom: 0PX;
    box-sizing: border-box;
  }
  .top_profile-part {
    position: relative;
    height: auto;
    margin-top: 125PX;
    margin-bottom: 30PX;
    padding-top: 220PX;
    padding-bottom: 40PX;
    flex-direction: column;
  }
  .div-block-469 {
    width: 100%;
  }
  .profile-block {
    display: none;
  }
  .spend_now_button {
    border-radius: 8PX;
  }
  .div-block-472 {
    width: 100%;
  }
  .lowest.active {
    max-width: 90PX;
    min-width: 90PX;
  }
  .div-block-466 {
    position: absolute;
    left: 0PX;
    top: -102PX;
    width: 100%;
    height: 200PX;
    margin-left: 0PX;
    padding-right: 0PX;
    padding-left: 0PX;
  }
  .card_interface {
    width: auto;
    height: 100%;
    background-color: hsla(0, 0%, 100%, 0);
    margin-top: 0;
    margin-bottom: 0;
  }
  .qr_code_profile_interface {
    height: 100%;
    width: auto;
  }
  .div-block-478 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .option_block {
    border-bottom: 1PX solid #eaeaea;
  }
  .option_block.no-line {
    border-bottom-width: 0PX;
  }
}

@media screen and (max-width: 767PX) {
  .expire {
    bottom: -5%;
    right: 25%;
    font-size: 14PX;
  }
  .card-name-sm {
    top: 145PX;
    width: 66%;
  }
  .section {
    padding: 15PX 25PX 40PX;
  }
  .profile-block-not-member {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 20PX;
    padding-bottom: 20PX;
    padding-left: 0PX;
    background-color: var(--bm-light-background-color);
    box-shadow: 1PX 1PX 20PX 0 rgb(0 0 0 / 16%);
  }
  .profile-block-tablet-view {
    padding-top: 20PX;
    padding-bottom: 20PX;
    padding-left: 20PX;
  }
  .div-block-468.not-login-yet {
    margin-bottom: 0px;
  }
  .top_profile-part {
    padding-top: 250PX;
    padding-left: 21PX;
  }
  .profile-block {
    padding-top: 20PX;
    padding-bottom: 20PX;
    padding-left: 20PX;
  }
  .spend_now_button {
    border-radius: 6PX;
  }
  .text-block-63 {
    font-size: 40PX;
    margin-top: 12PX;
  }
  .div-block-472 {
    min-height: 70PX;
  }
  .lowest.active {
    max-width: 80PX;
    min-width: 80PX;
  }
  .medium {
    max-width: 70PX;
  }
  .highest {
    max-width: 70PX;
  }
  .div-block-466 {
    left: 0PX;
    padding-right: 0PX;
    padding-left: 0PX;
  }
  .card_interface {
    display: block;
    height: 250PX;
  }
  .qr_code_profile_interface {
    background-size: contain;
  }
  .text-block-103 {
    font-size: 16PX;
  }
  .option_block {
    height: auto;
    padding-top: 20PX;
    padding-bottom: 20PX;
  }
  span {
    sup.am-badge-text {
      font-size: 12PX;
      line-height: 16PX;
      min-width: 10PX;
      padding: 1PX 4PX 0PX 4PX;
      height: 16PX;
    }
  }
  .bottom_profile_part {
    margin-bottom: 60PX;
  }

}

@media screen and (max-width: 479PX) {
  .bm-p-membership.membership-primary {
    .section.membershipSection {
      background-color: var(--bm-primary-color);
    }
    .profile_name,
    .profile_birthday,
    .profile-block-tablet-view,
    .div_block200 {
      color: var(--bm-light-background-color);
    }
    .dp_big {
      background-color: var(--bm-light-b-color-t40);
    }
    .profile_birthday  {
      color: var(--bm-light-b-color-t40);
    }
    .div-block-513 {
      background-color: var(--bm-light-background-color);
    }
  }
  .loyalty-stamp-bottom {
    margin-top: 16PX;
  }
  .loyalty-stamp-bottom,
  .text-block-62 {
    font-size: 14PX;
  }
  .loyalty-stamp-span {
    font-size: 18PX;
  }
  .expire {
    bottom: 5%;
    right: 20%;
    font-size: 12PX;
  }
  .card-name-sm {
    font-size: 14PX;
    top: 132PX;
    width: 86%;

    .card-name,
    .bm-icon  {
      font-size: 14PX;
    }
    .accumulated-spending {
      margin-top: 5PX;
      font-size: 12PX;
      color: var(--bm-text-secondary-color-t60);
    }
  }
  .div-block-478 {
    display: block;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .tablet-view-profile {
    margin-top: 20PX;
  }
  .text-block-66 {
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-size: 14PX;
    font-weight: 400;
  }
  .section {
    padding: 15PX 12PX;
  }
  .container {
    margin-top: 0PX;
    margin-bottom: 0PX;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .w-container {
    max-width: none;
  }
  .profile-block-not-member {
    height: auto;
    margin-top: 20PX;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: hsla(0, 0%, 100%, 0);
    box-shadow: none;
  }
  .div_block200 {
    font-size: 16PX;
  }
  .profile-block-tablet-view {
    height: auto;
    padding-top: 0PX;
    padding-bottom: 0PX;
    padding-left: 0PX;
  }
  .top_profile-part {
    margin: 132PX -12PX 0PX;
    padding-top: 210PX;
    padding-right: 12PX;
    padding-left: 12PX;
    border-radius: 20PX 20PX 0PX 0PX;
    background-color: var(--bm-light-background-color);
    box-shadow: none;
  }
  .dp_big {
    width: 50PX;
    height: 50PX;
    margin-right: 10PX;
    &.not-login-yet {
      width: 40PX;
      height: 40PX;
      background-size: 60%;
    }
  }
  .profile_name {
    font-size: 14PX;
  }
  .profile_birthday {
    font-size: 12PX;
  }
  .html-embed-29 {
    width: 25PX;
    height: 25PX;
  }
  .spend_now_button {
    width: 100PX;
    height: 45PX;
  }
  .text-block-65 {
    font-size: 12PX;
  }
  .text-block-62 {
    margin-bottom: 0PX;
  }
  .text-block-63 {
    font-size: 30PX;
    margin-top: 12PX;
    font-weight: 500
  }
  .text_block_100 {
    font-size: 14PX;
  }
  .lowest.active {
    max-width: 65PX;
    min-width: 65PX;
  }
  .medium {
    max-width: 55PX;
  }
  .highest {
    max-width: 55PX;
  }
  .text_block_100 {
    font-size: 14PX;
  }
  .div-block-466 {
    left: 0PX;
    height: auto;
  }
  .card_interface {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 86%;
    height: auto;
    max-height: 220PX;
  }
  .qr_code_profile_interface {
    width: 50%;
    max-height: 190PX;
    max-width: 190PX;
  }
  .bottom_profile_part {
    height: auto;
    margin-right: -12PX;
    margin-bottom: -15PX;
    margin-left: -12PX;
    padding-top: 0px;
    padding-right: 30PX;
    padding-left: 30PX;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: var(--bm-light-background-color);
    box-shadow: none;
  }
  .bottom_profile_part.no-token {
    border-radius: 20PX 20PX 0px 0px;
  }
  .text-block-103 {
    margin-right: -30PX;
    margin-bottom: 0PX;
    margin-left: -30PX;
    padding: 20PX 60PX;
    border-bottom: 1PX solid #eaeaea;
    font-size: 14PX;
    line-height: 22PX;
  }
  .option_block {
    // padding: 10PX;
    margin-right: -30px;
    padding: 20px 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .html-embed-30 {
    width: 25PX;
  }
  span {
    sup.am-badge-text {
      font-size: 11PX;
      line-height: 14PX;
      padding: 1PX 3PX 0 3PX;
      height: 15PX;
    }
  }
  .bottom_profile_part {
    padding-bottom: 80PX;
  }
}
