@import '~bm-public.less';

.bm-c-search-content {
  display: flex;
  flex-direction: column;
  background-color: var(--bm-dark-background-color);

  &-history-nav {
    display: flex;
    align-items: center;
    margin: 48px 32px 0;
    font-size: 32px;
    font-weight: bold;
    justify-content: space-between;
  }

  &-history-nav-recyclebin {
    font-size: 30px;
  }

  &-history-data {
    display: flex;
    flex-wrap: wrap;
    height: 192px;
    overflow: hidden;
    margin-right: 32px;
  }

  &-history-item {
    display: flex;
    align-items: center;
    margin-top: 32px;
    margin-left: 32px;
    border-radius: 18px;
    padding: 16px 28px;
    background-color: var(--bm-light-background-color);
    height: 32px;
  }
}

.bm-c-search-result {
  display: flex;
  flex-direction: column;
  background-color: var(--bm-dark-background-color);

  &-result-nav {
    display: flex;
    align-items: center;
    margin: 48px 32px;
    font-size: 32px;
    font-weight: bold;
    justify-content: space-between;
  }

  &-result-explore {
    color: var(--bm-text-secondary-color);
  }

  &-list {
    border-top: 2px solid var(--bm-shadow-color);
    padding-top: 32px;
  }

  &-store-list {
    margin-top: 16px;
    // padding: 0 32px;

    &-item {
      background-color: var(--bm-light-background-color);
    }
  }
}
