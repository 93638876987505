@import '~bm-public.less';

.bm-p-stores-by-booking {
  .dark-bc;

  .pc-header {
    height: calc(var(--bm-safe-area-top) + 88px);
    // background-color: var(--bm-primary-color);
    background-color: var(--bm-dark-background-color);
  }
  
  .am-navbar {
    background-color: var(--bm-primary-color);
    color: var(--bm-light-background-color);
  }

  &-store-list {
    margin-top: 40px;
    padding: 0 32px;
  }

  .no-stores {
    .coming-soon {
      .bm-c-status-wrapper {
        position: relative;
        padding: 0;
        margin-left: 64px;

        .status-title {
          font-size: 44px;
          line-height: 32px;
          position: absolute;
          bottom: 80px;
          color: var(--bm-skeleton-color);
          font-weight: normal;
          left: 0;
        }
      }
    }
    .bm-icon {
      font-size: 400px;
      color: var(--bm-primary-color);
    }
  }
}