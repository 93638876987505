@import '~bm-public.less';

.bm-p-about-us {
  .dark-bc;
  .section.tinted.white {
    .dark-bc;
  }
  .tab-pane-tab-3 {
    box-sizing: border-box;
  }
  .branch-card {
    width: 100%;
  }
  .news-true {
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 16PX;
    grid-row-gap: 16PX;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .menu-w-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 940PX;
    flex: 1 1;
  }
  .branch-card {
    padding-bottom: 0;
   }
  .branch-card.w-inline-block {
    width: 100%;
    margin-bottom: 0px;
  }
  @media screen and (max-width: 991px) {
    .menu-w-container {
      max-width: 728PX;
    }
  }
  @media screen and (max-width: 767px) {
    .news-true {
      grid-template-columns: 1fr;
    }
    .branch-card {
      width: 100%;
      height: 300PX;
      margin-bottom: 15PX;
    }
  }
  @media screen and (max-width: 479px) {
    .branch-card {
      width: 100%;
      height: 200PX;
      margin-right: auto;
      margin-bottom: 10PX;
    }
    .div-block-99 {
      display: flex;
      height: 23%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      -webkit-box-pack: start;
      justify-content: flex-start;
    }
  }
  .no-content-panel {
    display: flex;
    flex-direction: column;
    margin-top: 300px;
    justify-content: center;
    align-items: center;

    .bm-icon {
      font-size: 300px;
    }

    .no-content-info {
      color: var(--bm-text-secondary-color);
    }
  }
}
