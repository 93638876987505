@import '~bm-public.less';

.bm-p-qrcode-shopping {
  // &-nav-bar {
  //   display: none;
  // }
  background-color: var(--bm-light-background-color);
  .bm-navbar-fixed {
    background: linear-gradient(180deg, rgba(105, 105, 105, 0.3) 6.73%, rgba(89, 89, 89, 0) 100%);
  }

  .order-completed {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 2px solid var(--bm-border-color-t60);

    .bm-icon {
      font-size: 300px;
    }

    &-text {
      margin-bottom: 48px;
      font-size: 32px;
      font-weight: bold;
      line-height: 36px;
    }

    &-wrap {
      padding: 0 48px;
    }
  }

  .qrcode-msg-wrap {
    margin-top: -24px;
    padding-top: 18px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    position: relative;
    background-color: var(--bm-light-background-color);
    .qrcode-msg {
      display: flex;
      justify-content: space-between;
      margin: 0 48px;
      border-bottom: 2px solid var(--bm-border-color-t60);
      padding: 32px 0;
      .qrcode-msg-item {
        flex: 1;
        padding: 12px 0;
        position: relative;
        .item-title {
          font-size: 28px;
          line-height: 32px;
          color: var(--bm-text-secondary-color);
          margin-bottom: 8px;
        }
        .item-con {
          font-size: 32px;
          font-weight: bold;
          line-height: 36px;
          color: var(--bm-text-title-color);
        }
      }
      .qrcode-msg-item:nth-child(1)::after {
        content: ' ';
        background-color: var(--bm-border-color-t60);
        width: 2px;
        height: 100px;
        position: absolute;
        top: 12px;
        right: 0;
      }
      .qrcode-msg-item:nth-child(2){
        padding-left: 48px;
      }
    }
  }

  &-skeleton#skeleton {
    .qrcode-msg {
      .qrcode-msg-item:nth-child(1)::after {
        content: ' ';
        background-color: none;
        width: 0px;
        height: 0;
        position: absolute;
        top: 12px;
        right: 0;
      }
      .item-title {
        height: 32px;
        .skeleton-color;
      }
      .item-con {
        height: 36px;
        width: 160px;
        .skeleton-color;
      }
    }
  }

  &-error-wapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-icon {
      .font-size(3rem);
      margin-top: 64px;
    }

    &-title {
      .font-size(32px);
      font-weight: 700;
      margin-top: 80px;
    }

    &-title-extra {
      .font-size(28px);
      color: var(--bm-text-color);
      margin-top: 16px;
    }

    &-button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--bm-primary-color);
      width: 332px;
      margin-top: 120px;
      height: 88px;
      color: var(--bm-light-background-color);
      border-radius: 16px;
    }
  }
}
