@import '~bm-public.less';

.bm-p-brand-list {
  background-color: var(--bm-dark-background-color);

  .pc-header {
    height: calc(var(--bm-safe-area-top) + 88px);
  }

  .navbar-top {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 88px;
    background-color: var(--bm-primary-color);
    color: var(--bm-light-background-color);
    .navbar-left, .navbar-title, .navbar-right {
      flex: 1;
      height: 100%;
      .font-family-s;
      display: flex;
    }
    .navbar-left {
      padding-left: 30px;
    }
    .navbar-title {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      white-space: nowrap;
      text-align: center;
      .font-size(40px);
      line-height: 88px;
    }
  }

  .navbar-buttom {
    height: 92px;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    align-items: center;
    background-color: var(--bm-light-background-color);
    box-shadow: 0px 8px 20px rgba(60, 80, 111, 0.05);

    .order-type {
      padding: 0 8px;
      text-align: center;
      white-space:nowrap;
      font-size: 28px;
      line-height: 32px;
      color: var(--bm-text-color);

      &:first-child {
        padding-left: 14px;
      }

      &:last-child {
        padding-right: 14px;
      }
      .category {
        padding: 8px 16px;

        &.selected {
          background-color: var(--bm-primary-color);
          color: var(--bm-light-background-color);
          border-radius: 400px;
        }
      }
    }
  }

  .brands {
    margin-left: 32px;
    margin-right: 32px;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 330px);

    .brands-item {
      width: 330px;
      height: 324px;
      background: var(--bm-light-background-color);
      border-radius: 12px;
      margin-bottom: 34px;

      .brands-item-title {
        display: -webkit-box;
        height: 66px;
        font-weight: bold;
        font-size: 28px;
        line-height: 32px;
        margin-left: 16px;
        -webkit-box-align: center;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .brands-item-img {
        height: 258px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 12px 12px 0 0;
        background-image: url(/images/default-store.png);
      }
    }
  }

  .brands-all {
    margin: 48px 32px 32px 32px;
    display: flex;
    align-items: center;

    &-rectangle {
      height: 28px;
      width: 8px;
      background: var(--bm-primary-color);
      border-radius: 200px;
      margin-right: 16px;
    }

    &-title {
      font-weight: bold;
      font-size: 36px;
      line-height: 36px;
    }
  }
}
