.bm-c-swiper {
  width: 100vw;
  overflow: hidden;

  &-wrap {
    width: calc(100vw + 360px);
    position: relative;
  }

  &-left {
    width: 100vw;
  }

  &-right {
    width: 360px;
    height: 100%;
    right: 0;
    top: 0;
    position: absolute;
    margin-right: 36px;
  }
}
