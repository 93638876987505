@import '~bm-public.less';

.bm-c-bottom-bar {
  &.share-email-content-wrap {
    height: 95%;
    .am-modal-body {
      border-top-right-radius: 30px;
      border-top-left-radius: 30px;
    }
    .am-modal-content {
      height: 95%;
      border-top-right-radius: 30px;
      border-top-left-radius: 30px;
      .header-icon {
        display: none;
      }
    }
  }
  &-mask {
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--bm-modal-b-color);
    width: 100%;
    height: 100%;
    transition: opacity 0.3s ease-out;
    opacity: 0;
    z-index: 1;
    visibility: hidden;
    &.show-bottom-bar-mask{
      opacity: 1;
      visibility: visible;
    }
  }
  .section {
    height: 100%;
  }
  .container {
    height: 100%;
  }

  &-content {
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    background-color: var(--bm-light-background-color);
    transform: translateX(-100%);
    transition: transform 0.2s ease-out;
    &.show-bottom-bar-content {
      transform: translateX(0);
    }
    &-header {
      margin: 0 20px;
      border-bottom: 2px dashed var(--bm-border-color);
    }
    &-title {
      font-size: 36px;
      line-height: 48px;
      margin: 40px 0;
      padding-left: 20px;
      color: var(--bm-text-title-color);
      text-align: center;
      .font-family-b;
    }
    &-storeName {
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      margin: 40px 0;
    }
    &-wrap {
      padding: 40px 30px;
      display: grid;
      grid-template-columns: repeat(4,25%);
      &-item {
        text-align: center;
      }
    }

    &-content {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-copy {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-word {
        font-size: 24px;
        line-height: 32px;
        color: #BDBDBD;
      }
      &-icon {
        margin-right: 5px;
      }
    }
    
  }
  .share-email-content {
    height: 95%;
    padding: 32px;
    overflow: auto;
    .email-content {
      height: 85%;
      overflow: auto;
      text-align: left;
      font-size: 30px;
      color: var(--bm-text-title-color);
      padding-top: 32px;
      .edit-email {
        background-color: transparent;
        border: none;
        padding-left: 0;
        textarea {
          font-size: 30px;
          color: var(--bm-text-title-color);
          text-align: left;
          white-space: pre-wrap;
          font-family: 'Helvetica Neue', 'Helvetica', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft Sans Serif', 'SimSun', 'Verdana', 'Microsoft YaHei', 'WenQuanYi Micro Hei', 'sans-serif';
        }
      }
    }
    .share-btn-wrap {
      display: flex;
      justify-content: space-between;
      div {
        font-size: 30px;
      }
      .btn-cancel {
        color: var(--bm-text-color);
      }
      .btn-send {
        color: var(--bm-primary-color);
      }
    }
    .email-msg {
      display: flex;
      align-items: center;
      border-bottom: 2px solid var(--bm-border-color);
      padding: 24px 0;
      
      .email-title {
        color: var(--bm-text-color);
        font-size: 30px;
      }
      .share-email {
        flex: 1;
        border: none;
        background-color: transparent;
        height: 42px;
        min-height: 42px;
        input {
          font-size: 30px;
          color: var(--bm-text-title-color);
        }
      }
      .msg {
        font-size: 30px;
        margin-left: 30px;
        color: var(--bm-text-title-color);
        text-align: left;
      }
    }
  }
}