@import '~bm-public.less';

.bm-c-star-show {
  position: relative;

  .star-bottom {
    position: absolute;
    top: 0;
  }
}
