@import '~bm-public.less';
.bm-app {
  &-loading {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background-color: #F2F3F8;
    padding-bottom: 75PX;
    height: 100%;

    .loading-img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 200px;
      background-color: #000b;
      border-radius: 14px;

      .loading-text {
        color: var(--bm-light-background-color);
      }

      .bm-icon {
        margin-top: -40px;
        width: 96px;
        color: var(--bm-primary-color);
        font-size: 100px;
      }

      img {
        width: 88px;
        height: 88px;
        margin-top: -14px;
      }
    }
  }
  &-loading-bgc {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &-page-loading {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    background-color: var(--bm-light-background-color);
    padding-bottom: 75PX;
    height: 100%;

    .loading-data-img {
      width: 300px;
      height: 300px;
      margin-top: -90px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .loading-data-text {
      margin-left: 20px;
      font-size: 32px;
      margin-top: -60px;
    }
  }

  &-no-stores {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: var(--bm-light-background-color);
  }
}

#root {
  .am-button.bm-btn-lg {
    height: 100px;
    line-height: 100px;
    font-size: 32px;
    .font-family-b;
  }

  .am-button.bm-btn-md {
    height: 80px;
    line-height: 80px;
    font-size: 32px;
    .font-family-b;
  }

  .am-button.bm-btn-sm {
    height: 60px;
    line-height: 60px;
    font-size: 24px;
    .font-family-b;
  }

  // .circle-back-icon {
  //   background-color: var(--bm-light-b-color-t60);
  //   border: 2px solid var(--bm-light-b-color-t40);
  //   border-radius: 50%;
  //   font-size: 40px;
  // }

  .circle-back-icon-outlined {
    background-color: var(--bm-light-background-color);
    border-radius: 50%;
    font-size: 40px;
    color: var(--bm-text-title-color);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  }
}

.am-modal-mask {
  z-index: 999 !important;
  background-color: var(--bm-modal-b-color) !important;
}

.am-modal-wrap {
  user-select: none;
  // max-width: 1920px;
  margin: auto;
  .am-modal-transparent {
    width: 72%;
  }

  .am-modal-transparent .am-modal-content {
    padding-top: 36px;
    border-radius: 28px;
  }

  .am-modal-header .am-modal-title {
    .font-family-s;
  }

  .am-modal-button-group-v .am-modal-button {
    .font-family-r;

    height: 88px !important;
    padding: 22px !important;
  }

  .am-modal-button-group-v .am-modal-button,
  .am-modal-header .am-modal-title {
    .title-color;

    font-size: 30px;
    line-height: 44px;
  }

  .am-modal-transparent .am-modal-content .am-modal-body {
    padding: 8px 32px 36px;

    .am-modal-alert-content {
      .title-color;

      font-size: 24px;
      line-height: 36px;
      white-space: pre-wrap;
      letter-spacing: 1px;
    }
  }
}
