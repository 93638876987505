@import '~bm-public.less';

.bm-p-queueing {
  background: var(--bm-dark-background-color);
  overflow: scroll;
  .pc-header {
    height: calc(var(--bm-safe-area-top));
  }
  .clipped {
    box-sizing: border-box;
    .submit-button-modal {
      box-sizing: border-box;
    }
  }
  .am-list-line {
    .am-input-control input{
      padding-left: 52%;
    }
  }
  .am-list-item {
    .am-list-line {
      padding-right: 0;
    }
  }
  .submit-button-modal.opacity {
    opacity: .5;
  }
  .submit-button-modal {
    background: var(--bm-primary-color);
    color: var(--bm-light-background-color);
  }
  .queueing-card-gap {
    .queueing-card-gap-hr {
      padding: 0;
      margin: 0;
      border: none;
      border-top: 2px dashed var(--bm-border-color);
    }
  }
  .queueing-card-gap {
    width: 600PX;
  }
  .queueing-show {
    height: 0;
  }
  .am-list-item.am-input-item {
    height: 80PX;
  }
  .queueing-success-user {
    padding-bottom: 64PX;
  }
  .queueing-success-user-number {
    font-weight: bold;
    font-size: 55PX;
    line-height: 55PX;
    text-align: center;
    margin-bottom: 20PX;
  }

  .queueing-success-user-number-tip {
    text-align: center;
    font-size: 24PX;
    line-height: 24PX;
    color: var(--bm-text-secondary-color);
  }
  .queueing-record-divider.line-divider-top {
    top: 61%;
  }
  .queueing-success-time {
    display: flex;
    font-size: 20PX;
    line-height: 40PX;
    margin-top: 30PX;
    padding: 0 40PX;
  }
  .queueing-success-time-data {
    flex: 1;
    text-align: right;
  }
  .queueing-success-seats {
    display: flex;
    font-size: 20PX;
    line-height: 40PX;
    padding: 0 40PX;

    .queueing-success-seats-data {
      flex: 1;
      text-align: right;
    }
  }
  .no-justify-content {
    justify-content: unset;
  }
}
.html-embed-7.dark {
  color: var(--bm-text-title-color);
  svg {
    width: 100%;
    height: 100%;
  }
}
.section.booking.tinted {
  padding-bottom: 40PX;
  background: var(--bm-dark-background-color);
}
.wrapper {
  position: relative;
  margin-bottom: 30PX;
}
.div-block-450 {
  position: absolute;
  left: auto;
  top: 25%;
  right: 1%;
  bottom: auto;
}
.html-embed-20 {
  width: 40PX;
  color: #666;
  svg {
    width: 100%;
  }
}
.heading-7 {
  font-family: 'Sf pro display', sans-serif;
  color: #666;
  font-weight: 600;
}
.text-block-46.third {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: #9fa2a5;
  font-size: 18PX;
}
.text-block-46 {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 18PX;
}
.div-block-449 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 30PX;
  grid-row-gap: 30PX;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
.heading-4-4 {
  margin-top: 0px;
  margin-bottom: 20PX;
  font-family: 'Sf pro display', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 20PX;
  font-weight: 600;
  text-align: left;
}
.div-block-451 {
  position: relative;
  height: 200PX;
  padding-top: 0px;
  border-radius: 8PX;
}
.div-block-452 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 60%;
  padding-right: 40PX;
  padding-left: 40PX;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 2PX dashed #ebeff2;
  box-sizing: border-box;
}
.calling-char {
  margin-top: 0px;
  margin-right: 20PX;
  margin-bottom: 0px;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: #fff;
  font-size: 70PX;
  line-height: 30PX;
  font-weight: 700;
}
.calling-char.tinted {
  background-color: rgba(242, 243, 248, 0);
  color: rgba(255, 255, 255, 0.61);
}
.tinted {
  background-color: #f2f3f8;
}
.div-block-453 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40%;
  padding-right: 40PX;
  padding-left: 40PX;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.text-block-50 {
  color: #fff;
  font-size: 30PX;
  line-height: 26PX;
}
.strip {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  width: 3%;
  height: 100%;
  border-top-left-radius: 8PX;
  border-bottom-left-radius: 8PX;
}
.ticket-wrapper-1 {
  filter: drop-shadow(2PX 6PX 3PX rgba(50, 50, 0, 0.4));
}
.clipped.shorten {
  width: 600PX;
  height: 600PX;
}
.clipped {
  -webkit-clip-path: url(#my-clip-path-1);
  clip-path: url(#my-clip-path-1);
}
.clipped {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 600PX;
  height: 650PX;
  margin-right: auto;
  margin-left: auto;
  padding: 0px 26PX 26PX;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 14PX;
  background-color: #fff;
}
.div-block-443 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30%;
  margin-bottom: 20PX;
  padding-bottom: 20PX;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 0px dashed #eaeaea;
}
.div-block-442 {
  width: 60PX;
  height: 60PX;
  margin-right: 20PX;
  border-radius: 40PX;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.text-block-48 {
  margin-bottom: 10PX;
  font-family: 'Sf pro display', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 18PX;
  text-align: left;
}
.text-block-49 {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: #9fa2a5;
  font-size: 18PX;
  text-align: left;
}
.line-divider-top._1 {
  top: 25%;
}
.line-divider-top {
  position: absolute;
  left: auto;
  top: 15%;
  right: auto;
  bottom: auto;
  width: 90%;
  height: 1PX;
  border-bottom: 2PX dashed #eaeaea;
}
.div-block-455 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 80PX;
  padding-top: 0px;
  padding-right: 20PX;
  padding-left: 20PX;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8PX;
  background-color: #f5f5f5;
}
.input-people {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: #a39d9d;
  font-size: 18PX;
  font-weight: 400;
  position: relative;
  .queueing-placeholder {
    position: absolute;
    width: 100%;
    text-align: center;
    height: 80PX;
    line-height: 80PX;
  }
}
.text-block-52 {
  margin-top: 20PX;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: #9fa2a5;
  font-size: 16PX;
  line-height: 24PX;
}
.submit-button-modal.close-modal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.submit-button-modal {
  width: 100%;
  height: 55PX;
  padding: 17PX;
  border-radius: 8PX;
}
.clipped {
  -webkit-clip-path: url(#my-clip-path-1);
  clip-path: url(#my-clip-path-1);
}
.text-block-47 {
  font-family: 'Sf pro display', sans-serif;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}
@media screen and (max-width: 991px) {
  .heading-4-4 {
    margin-bottom: 10PX;
    font-size: 17PX;
  }
  .div-block-451 {
    height: 170PX;
  }
  .calling-char {
    font-size: 50PX;
  }
  .text-block-50 {
    font-size: 26PX;
  }
  .input-people {
    .div-block-455.am-input-item {
      height: 70PX;
    }
    .queueing-placeholder {
      height: 70PX;
      line-height: 70PX;
    }
  }
}
@media screen and (max-width: 767px) {
  .bm-p-queueing {
    .queueing-card-gap {
      width: 100%;
    }
    .queueing-success-user {
      padding-bottom: 64PX;
      padding-top: 64PX;
    }
    .queueing-success-user-number {
      font-size: 45PX;
    }
    .queueing-success-user-number-tip {
      font-size: 20PX;
    }
  }
  .html-embed-7.dark.queueing-left {
    height: 34PX;
  }
  .heading-7 {
    font-size: 18PX;
  }
  .text-block-46.third {
    font-size: 14PX;
  }
  .text-block-46 {
    font-size: 14PX;
  }
  .div-block-449 {
    grid-column-gap: 16PX;
    grid-row-gap: 16PX;
  }
  .heading-4-4 {
    margin-bottom: 10PX;
    font-size: 16PX;
  }
  .div-block-451 {
    height: 120PX;
  }
  .div-block-452 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .calling-char {
    font-size: 40PX;
 }
 .text-block-50 {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  font-size: 18PX;
  }
  .clipped.shorten {
    width: 100%;
    padding: 40PX;
  }
  .clipped {
    width: 90%;
    height: 570PX;
    padding-top: 27PX;
    border-radius: 8PX;
  }
  .div-block-443 {
    height: auto;
    padding-top: 10PX;
    padding-bottom: 10PX;
  }
  .line-divider-top._1 {
    left: 24PX;
  }
  .input-people {
    .div-block-455.am-input-item {
      height: 60PX;
    }
    .queueing-placeholder {
      height: 60PX;
      line-height: 60PX;
    }
  }
  .text-block-47 {
    font-size: 16PX;
  }
  .text-block-52 {
    font-size: 14PX;
    line-height: 20PX;
  }
}
@media screen and (max-width: 479px) {
  .bm-p-queueing {
    .queueing-success-user {
      padding: 26PX 0;
    }
    .queueing-success-user-number {
      font-size: 30PX;
      margin-bottom: unset;
    }
    .queueing-success-user-number-tip {
      font-size: 18PX;
    }
    .queueing-success-seats,
    .queueing-success-time {
      font-size: 16PX;
      line-height: 40PX;
      padding: 0;
    }
  }
  .section.booking.tinted {
    margin-top: 0px;
  }
  .html-embed-20 {
    width: 26PX;
  }
  .heading-7 {
    margin-bottom: 0px;
    font-size: 16PX;
  }
  .text-block-46 {
    font-size: 14PX;
  }
  .div-block-449 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .ticket-display-block {
    width: 47%; 
  }
  .heading-4-4 {
    margin-top: 0px;
    margin-bottom: 10PX;
    font-size: 16PX;
    line-height: 16PX;
  }
  .div-block-451 {
    height: 100PX;
  }
  .div-block-452 {
    padding-right: 0;
    padding-left: 22PX;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .calling-char.tinted {
    margin-right: 10PX;
  }
  .calling-char {
    font-size: 32PX;
  }
  .div-block-453 {
    padding-right: 22PX;
    padding-left: 22PX;
  }
  .text-block-50 {
    font-size: 16PX;
  }
  .clipped.shorten {
    height: 430PX;
    padding: 26PX;
  }
  .clipped {
    width: 100%;
    height: 550PX;
  }
  .div-block-443 {
    height: auto;
  }
  .div-block-442 {
    width: 50PX;
    height: 50PX;
    margin-right: 10PX;
  }
  .text-block-48 {
    margin-bottom: 5PX;
    font-size: 16PX;
  }
  .text-block-49 {
    font-size: 14PX;
  }
  .line-divider-top {
    left: 12PX;
    width: 91%;
  }

  .input-people {
    font-size: 16PX;
  }
  .input-people {
    .div-block-455.am-input-item {
      height: 55PX;
    }
    .queueing-placeholder {
      height: 55PX;
      line-height: 55PX;
    }
  }
  .text-block-52 {
    font-size: 13PX;
    line-height: 16PX;
  }
}
