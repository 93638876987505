@import '~bm-public.less';

.bm-c-data-entry {
  margin-bottom: 32px;

  .dew-title {
    .font-size(24px);
    .title-color;

    margin-bottom: 16px;
  }

  .am-input-item {
    height: 88px;
    border-radius: 12px;
    border: 2px solid var(--bm-border-color);
    background-color: var(--bm-input-backgroud-color);

    &.am-input-focus {
      border: 2px solid var(--bm-primary-color);
    }

    .bm-icon {
      padding: 0;
    }
  }

  .am-list-item.am-input-item {
    padding-left: 32px;
  }

  .am-list-line {
    padding-right: 32px;
  }

  .error-msg {
    .font-size(20px);
    .font-family-s;

    color: var(--bm-error-color);
    margin-top: 16px;
  }

  .am-input-control input {
    .font-size(28px);
    .font-family-m;
  }

  .am-input-label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 32px;
  }

  .am-input-extra {
    margin-left: 32px;
    max-height: 40px;
  }

  .am-list-item .am-input-label.am-input-label-5 {
    width: auto;
  }
}
