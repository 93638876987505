@import '~bm-public.less';

.bm-p-deferred-order-detail {
  .page-content {
    position: relative;
    background-color: var(--bm-dark-background-color);
    padding-bottom: 50PX;
  }
  .page-content-wrapper {
    background-color: var(--bm-dark-background-color);
  }
  .section {
    display: block;
    margin-top: auto;
    margin-bottom: 0;
    padding: 15PX 20PX 20PX;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0);
  }
  .background-top {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    height: 200PX;
    background-color: var(--bm-primary-color);
  }
  .div-block-485 {
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .left-block {
    width: auto;
    height: auto;
    margin-top: 0;
    margin-right: 20PX;
    padding: 0;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-radius: 12PX;
    background-color: rgba(255, 255, 255, 0);
    box-shadow: none;
    flex: 1;
  }
  .right-block {
    position: -webkit-sticky;
    position: sticky;
    left: auto;
    top: 100PX;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 40%;
    height: auto;
    margin-top: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .left-block._58 {
    width: 58%;
  }
  .po-history-block {
    width: 100%;
    margin-bottom: 20PX;
    padding: 20PX;
    border-radius: 8PX;
    background-color: #fff;
    box-shadow: 0 0 8PX -1PX rgb(0 0 0 / 66%);
    box-sizing: border-box;
  }
  .div-block-652 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .div-block-652.head {
    margin-bottom: 20PX;
    padding-bottom: 0PX;
    padding-left: 10PX;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-bottom: 1PX none rgba(160, 21, 28, 0.4);
    border-left: 5PX none #a0151c;
  }
  .deffered-invoices-supplier {
    margin: 0 auto 10PX 0;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: #a0151c;
    font-size: 14PX;
    line-height: 150%;
    font-weight: 700;
  }
  .deffered-invoices-supplier.black {
    margin-bottom: 7PX;
    color: #333;
  }
  .div-block-652.head.deffered-oder-detail {
    border-bottom-style: solid;
    border-bottom-color: #f5f5f5;
    border-left-style: none;
  }
  .div-block-652._100 {
    width: 100%;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .div-block-653 {
    background-color: transparent;
  }
  .div-block-653._100 {
    width: 100%;
  }
  .price-cart {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 100%;
    margin-top: auto;
    margin-bottom: 10PX;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .text-block-6 {
    max-width: none;
    margin-top: 0;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    font-family: 'Sf pro display', sans-serif;
    color: #333;
    font-size: 18PX;
    font-weight: 600;
  }
  .text-block-6._18.space {
    margin-right: 8PX;
  }
  .text-block-223 {
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: #666;
    font-size: 18PX;
  }
  .w-inline-block {
    max-width: 100%;
    display: inline-block;
  }
  .link-block-10 {
    margin-bottom: 5PX;
    margin-left: 10PX;
    text-decoration: none;
  }
  .primary-payment-confirmation-buttons {
    padding: 10PX;
    background-color: var(--bm-primary-color);
    color: var(--bm-light-background-color);
    border-radius: 4PX;
    .text-block-17 {
      font-size: 15PX;
    }
  }
  .div-block-572 {
    display: block;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .heading-4-1 {
    margin-top: 0;
    margin-bottom: 5PX;
    font-family: 'Sf pro display', sans-serif;
    color: #333;
    font-size: 14PX;
    font-weight: 600;
    text-align: left;
  }
  .text-block-98 {
    margin-right: auto;
    margin-bottom: 0;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-size: 9PX;
    font-weight: 400;
    color: #666;
  }
  .text-block-98.space {
    margin-bottom: 10PX;
  }
  .div-block-482-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 10PX;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .div-block-482-copy:not(:last-child).with-space {
    padding-bottom: 10PX;
    border-bottom: 1PX solid var(--bm-border-color);
  }
  .div-block-481-copy {
    width: 50PX;
    height: 50PX;
    min-height: 80PX;
    min-width: 80PX;
    margin-right: 10PX;
    border-radius: 6PX;
    background-position: 50% 50%;
    background-size: cover;
  }
  .div-block-578 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .item-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 100%;
    margin-bottom: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .item-info.checkout-process {
    position: relative;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }
  .item-info ._107 {
    position: absolute;
    left: auto;
    top: 1PX;
    right: 0%;
    bottom: 0%;
    margin-bottom: 0;
    margin-left: auto;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: #333;
    font-size: 9PX;
    font-weight: 400;
  }
  .text-block-6._18.rightalign {
      margin-left: auto;
  }

  .right-block-birdymart {
    position: -webkit-sticky;
    position: sticky;
    left: auto;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 40%;
    height: auto;
    margin-top: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .info-tool {
    box-sizing: border-box;
    position: -webkit-sticky;
    position: sticky;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: auto;
    max-width: 100%;
    min-width: 100%;
    // margin-top: 10PX;
    margin-right: 0;
    margin-bottom: 0;
    padding: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 6PX;
    background-color: rgba(255, 255, 255, 0);
    box-shadow: none;
  }
  // store info
  .div-block-510 {
    box-sizing: border-box;
    margin-bottom: 20PX;
    padding: 20PX;
    border-radius: 8PX;    
    background-color: var(--bm-light-background-color);
    // background-color: var(--bm-primary-color);
    box-shadow: 1PX 1PX 10PX 0 rgba(0, 0, 0, 0.24);
  }
  .div-block-514 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    .div-block-515  {
      flex: 1;
    }
  }
  .div-block-511 {
    width: 60PX;
    height: 60PX;
    min-height: 60PX;
    min-width: 60PX;
    margin-right: 20PX;
    border-radius: 50%;
    // background-image: url(https://assets.website-files.com/605173a…/60790db…_dp2.jpeg);
    background-position: 50% 50%;
    background-size: cover;
  }
  .text-block-104 {
    display: flex;
    justify-content: space-between;
    font-size: 16PX;
    line-height: 18PX;
    font-weight: 700;
    >div:first-child {
      padding-top: 6PX;
      color: var(--bm-primary-color);
    }
    .down-outlined {
      width: 28PX;
      height: 28PX;
    }
  }
  .info-tool {
    ._107 {
      display: flex;
      font-size: 14PX;
      font-weight: 400;
      color: var(--bm-text-color);
      .map-outlined {
        width: 20PX;
        height: 20PX;
        color: var(--bm-primary-color);
      }
    }
  }
  .div-block-574 {
    margin-top: 10PX;
  }
  .quick-filter-group {
    display: flex;
    flex-wrap: wrap;
    .status {
      padding: 6PX;
      border-radius: 6PX;
      color: var(--bm-text-secondary-color);
      border: 1PX solid var(--bm-text-secondary-color);
      margin: 0 10PX 10PX 0;
      font-size: 16PX;
    }
    .selected {
      color: var(--bm-light-background-color);
      background-color: var(--bm-primary-color);
      border: 1PX solid var(--bm-primary-color);
    }
  }
  .order-summary-block {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: auto;
    max-width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 15PX;
    padding: 20PX;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 8PX;
    background-color: #fff;
    box-shadow: 1PX 1PX 9PX 0 rgb(58 58 58 / 39%);
  }
  .div-block-506 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 10PX;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .div-block-506._1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 10PX;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-bottom: 1PX solid var(--bm-border-color);
    color: #333;
  }
  .text-block-101 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-size: 16PX;
    font-weight: 700;
  }
  .text-block-101.red {
    color: var(--bm-primary-color);
  }
  .div-block-493.no-line {
    padding-bottom: 0;
    border-bottom-width: 0;
  }

  .div-block-493 {
    padding-top: 10PX;
    padding-bottom: 10PX;
    border-bottom: 1PX solid var(--bm-border-color);
    color: #333;
  }
  .div-block-484 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 7PX;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .div-block-484.item {
    height: 16PX;
  }
  .item-name {
    margin-right: auto;
    margin-bottom: 0;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: #666;
    font-size: 13PX;
    font-weight: 400;
  }
  .text-block-68 {
    margin-bottom: 0;
    padding: 0;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: #333;
    font-size: 13PX;
  }
  .item-name.bold {
    color: #333;
    font-weight: 700;
  }
  .text-block-68.bold {
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-weight: 700;
  }
  .make-payment {
    position: fixed;
    left: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 9000;
    display: none;
    height: auto;
    margin-top: 0vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    background-color: transparent;
  }
  .bottom-nav {
    position: -webkit-sticky;
    position: sticky;
    top: auto;
    z-index: auto;
    width: 100%;
    box-sizing: border-box;
    height: auto;
    padding-top: 7PX;
    padding-bottom: 7PX;
    background-color: #fff;
    box-shadow: 1PX 1PX 2PX -1PX #5e5e5e;
  }
  .container {
    position: relative;
    display: block;
    overflow: visible;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
  }
  .div-block-507 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
  .div-block-507.with-space {
    margin-top: 10PX;
    text-decoration: none;
  }
  .div-block-509 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .primary-payment-confirmation-buttons {
    position: relative;
    left: auto;
    top: 0;
    right: 0%;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: 28PX;
    margin-right: 0;
    padding: 7PX;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-radius: 4PX;
    background-color: #004590;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
  .primary-payment-confirmation-buttons.red {
    background-color: var(--bm-primary-color);
  }


  @media screen and (max-width: 991px) {
    .make-payment {
      // position: -webkit-sticky;
      // position: sticky;
      display: block;
      margin-top: 0vh;
    }
    .div-block-507.with-space {
      display: none;
    }
    .bottom-nav {
      padding-top: 7PX;
      padding-bottom: 7PX;
    }
    .bottom-nav.cart-process {
      padding: 12PX;
    }
    .container.bottom._20px {
      padding-right: 17PX;
      padding-left: 17PX
    }
    .background-top {
      height: 100PX;
    }
    .div-block-510 {
      margin-bottom: 20PX;
    }
    .div-block-485 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .left-block {
      width: 100%;
      margin-top: 15PX;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      box-shadow: none;
    }
    .left-block._58 {
      width: auto;
      margin-right: 0;
      -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
    }
    .po-history-block {
      margin-bottom: 40PX;
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 8PX;
      // background-color: rgba(255, 255, 255, 0);
      box-shadow: none;
    }
    .div-block-652.head {
      padding: 16PX;
      border-style: solid;
      border-width: 1PX;
      border-color: #f5f5f5;
      border-radius: 8PX;
      background-color: #fff;
    }
    .div-block-652.head.deffered-oder-detail.merge {
      margin-bottom: 0;
      padding: 10PX 0 0;
      border-style: none;
      border-color: #000 #000 #a39d9d;
      background-color: transparent;
    }
    .text-block-6 {
      font-size: 14PX;
    }
    .text-block-6._18 {
      font-size: 18PX;
    }
    .price-cart {
      -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
    }
    .div-block-482-copy {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .item-info {
      max-width: 90%;
    }
    .item-info.checkout-process {
      max-width: none;
    }
    .right-block-birdymart {
      position: static;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      overflow: visible;
      width: 100%;
      -webkit-box-ordinal-group: 0;
      -webkit-order: -1;
      -ms-flex-order: -1;
      order: -1;
      border-top-left-radius: 6PX;
      border-top-right-radius: 6PX;
    }
    .info-tool {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 0;
      border-radius: 0;
      box-shadow: none;
    }
    .div-block-510.no-shadow-tablet {
      padding: 5PX 0;
      background-color: hsla(0, 0%, 100%, 0);
      box-shadow: none;
    }
    .order-summary-block {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 0;
      border-radius: 8PX;
      box-shadow: 0 1PX 3PX 0 rgb(58 58 58 / 39%);
    }
    .div-block-506._1 {
      margin-bottom: 0;
    }
    .text-block-101 {
      font-size: 16PX;
    }
    .div-block-484 {
      margin-bottom: 5PX;
    }
    .div-block-653._100 {
      border-bottom: 1PX solid var(--bm-border-color);
    }
    .div-block-653._100.merge {
      padding-bottom: 5PX;
    }
    .po-history-block.deffered-order.merge {
      width: auto;
      background-color: #fff;
      box-shadow: 1PX 0 3PX 0 rgb(58 58 58 / 39%);
    }
    .po-history-block.deffered-order {
      margin-bottom: 15PX;
    }
    .div-block-572.with-spacing {
      padding: 9PX;
      border-radius: 3PX;
      background-color: #fff;
      box-shadow: 1PX 1PX 6PX -3PX #747474;
    }
    .div-block-572.with-spacing.deferred-order.merge {
      padding: 10PX 0;
      border: 1PX none #000;
      background-color: transparent;
      box-shadow: none;
    }
  
  }
  @media screen and (max-width: 767px) {
    .left-block {
      box-shadow: none;
    }
    // .po-history-block {
    //   padding-right: 0;
    //   padding-left: 0;
    // }
    .deffered-invoices-supplier {
      font-size: 14PX;
    }
    .text-block-223 {
      font-size: 16PX;
    }
    .text-block-98 {
      max-width: 40%;
      margin-right: 0;
    }
    .item-info.checkout-process {
      max-width: 100%;
    }
    .info-tool {
      padding-right: 0;
      padding-left: 0;
    }
    .div-block-510.no-shadow-tablet {
      padding-right: 0;
    }
  }
  @media screen and (max-width: 479px) {
    .background-top {
      height: 100PX;
    }
    .deffered-invoices-supplier {
      font-size: 14PX;
    }
    .price-cart {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .text-block-6 {
      -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
      align-self: flex-end;
      font-family: 'Sf pro display', sans-serif;
      font-size: 14PX;
      font-weight: 600;
    }
    .text-block-6._18 {
      font-size: 14PX;
    }
    .text-block-6._18.space {
      margin-right: 4PX;
    }
    .text-block-223 {
      font-size: 14PX;
    }
    .quick-filter-group {
      .status {
        font-size: 14PX;
      }
    }
    .div-block-510.no-shadow-tablet.margin {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
  .no-content-panel {
    display: flex;
    flex-direction: column;
    margin-top: 150PX;
    justify-content: center;
    align-items: center;

    .bm-icon {
      font-size: 150PX;
    }

    .no-content-info {
      color: var(--bm-text-secondary-color);
    }
  }
}
