@import '~bm-public.less';

.bm-p-icons {
  .light-bc;

  .pc-header,
  .pc-footer {
    display: none;
  }

  .am-list-item {
    width: 100%;
  }

  .page-content {
    position: absolute;
    top: 88px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    .light-bc;
  }

  .icon-list {
    display: grid;
    overflow: auto;
    justify-content: center;
    grid-column-gap: 24px;
    width: 100%;
    margin-bottom: 32px;
    grid-template-columns: repeat(auto-fill, 200px);
  }

  &-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: var(--bm-light-background-color);
  }

  &-item {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    margin-top: 24px;
    color: var(--bm-primary-color);

    span {
      margin-top: 10px;
      font-size: 18px;
    }

    svg {
      max-width: 60px;
      max-height: 60px;
    }
  }

  .icon-title {
    padding: 4px 12px;

    &:hover {
      background-color: var(--bm-skeleton-color-t20);
    }
  }

  .icon-img {
    display: flex;
    justify-content: center;
    width: 200px;
    padding: 0 12px;

    &:hover {
      background-color: var(--bm-skeleton-color-t20);
    }
  }

  &-copy {
    margin-top: -10px;

    &-wrapper {
      height: 0;
      display: flex;
      position: fixed;
    }
  }
}
