@import '~bm-public.less';
.bm-c-tab-bar {
  position: fixed;
  bottom: 0;
  z-index: 102;
  background-color: var(--bm-light-background-color);
  height: auto;
  padding: 15PX 0;
  // box-shadow: 1PX 1PX 4PX -1PX #5e5e5e;
  box-shadow: 0 0 8PX 0 var(--bm-shadow-color);
  max-width: unset !important;
  left: 0;
  right: 0;
  display: none;
  &.show-splash {
    display: none;
  }
  .item-icon-wrapper {
    position: relative;
  }
  span {
    position: absolute;
    top: -12PX;
    left: 90%;
    z-index: 1;
    sup.am-badge-text {
      background-color: red;
      font-size: 24PX;
      line-height: 30PX;
      min-width: 16PX;
      padding: 1PX 7PX 0 8PX;
      height: 30PX;
    }
  }
  .item-title {
    margin-top: 0;
    transform: scale(0.95, 0.95);
    white-space: nowrap;
    margin-right: 0px;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-size: 17PX;
  }
}
.bm-c-tab-bar-container {
  height: auto;
  display: flex;
  user-select: none;
}
.bm-c-tab-bar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  .text-s-color;
}

.bm-c-tab-bar-item.selected {
  color: var(--bm-primary-color);
}
.item-icon {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-items: center;
  font-size: 35PX;
  padding: 2PX;
  width: 30PX;
  margin-bottom: 5PX;
}
.bm-c-tab-bar {
  svg {
    width: 100%;
  }
}


@media screen and (max-width: 991px){
  .item-icon {
    width: 25PX;
    font-size: 26PX;
  }
  .bm-c-tab-bar {
    padding: 15PX 0;
    .item-title {
      font-size: 16PX;
    }
  }
}
@media screen and (max-width: 767px){
  .bm-c-tab-bar {
    display: block;
    .item-title {
      font-size: 14PX;
    }
    span {
      top: -6PX;
      sup.am-badge-text {
        font-size: 12PX;
        line-height: 16PX;
        padding: 1PX 1PX 0 1PX;
        height: 17PX;
      }
    }
  }
}
@media screen and (max-width: 479px){
  .bm-c-tab-bar {
    padding: 10PX 0;
    .item-title {
      width: auto;
      height: 20PX;
      line-height: 20PX;
      font-family: 'Fontsfree net sfprodisplay', sans-serif;
      font-size: 11PX;
    }
    span {
      top: -6PX;
      sup.am-badge-text {
        line-height: 15PX;
        padding: 1PX 0PX 0 0PX;
        height: 15PX;
      }
    }
  }
}