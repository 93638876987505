@import '~bm-public.less';

.bm-p-store-association {
  background-color: var(--bm-light-background-color);

  .pc-header {
    height: calc(var(--bm-safe-area-top) + 88px);
  }

  .back-icon {
    color: var(--bm-text-title-color);
  }

  .am-navbar {
    .light-bc;

    border-bottom: 2px solid var(--bm-border-color);

    .am-navbar-title {
      color: var(--bm-text-title-color);
      font-size: 40px;
      font-weight: 600;
    }
  }

  .input-line {
    margin: 32px 0 0 32px;
    padding-bottom: 32px;
    border-bottom: 2px solid var(--bm-border-color);

    .title {
      margin-bottom: 8px;
      color: var(--bm-text-secondary-color);
    }
  }

  .am-list-item.am-input-item {
    height: 36px;
    padding: 0;
    border: none;
    background: none;
  }

  .am-list-item {
    height: 36px;
    min-height: 36px;
    line-height: 36px;

    .am-input-control {
      font-size: 32px;

      input {
        padding: 0;
        font-size: 32px;
        line-height: 36px;
      }
    }
  }

  .shop-type-input {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .shop-type {
      line-height: 36px;
      font-size: 32px;
    }

    .right-icon {
      font-size: 32px;
      margin-right: 42px;
    }
  }

  .shop-type-list {
    padding-left: 32px;
    border-top: 2px solid var(--bm-border-color);

    .shop-type-wrap {
      display: flex;
      height: 104px;
      justify-content: space-between;
      align-items: center;
      padding-right: 32px;
      border-bottom: 2px solid var(--bm-border-color);

      .shop-type {
        font-size: 32px;
        color: var(--bm-text-title-color);
      }

      .checked {
        font-size: 40px;
        color: var(--bm-primary-color);
      }
    }
  }

  .disabled {
    opacity: 0.5;
  }

  .bottom-botton {
    position: fixed;
    bottom: 0;
    .phone & {
      left: 0;
      right: 0;
    }
    background-color: #fff;
    padding: 32px;

    .save-botton {
      padding: 34px 0;
      font-size: 32px;
      font-weight: bold;
      background-color: var(--bm-primary-color-tap);
      text-align: center;
      border-radius: 12px;
      color: var(--bm-light-background-color);
    }
  }
}