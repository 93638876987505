@import '~bm-public.less';

.bm-p-pay-card {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  display: flex;
  overflow: auto;
  justify-content: center;
  align-items: center;
  perspective: 850PX;
  cursor: default;
  background-color: transparent;
  .c-popup-close-2 {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 9999;
    display: block;
    background-color: var(--bm-modal-b-color);
    cursor: pointer;
  }
  .close-modal.c-popup-close-2 {
    width: unset;
    height: unset;
  }
  .flex-pop-up.widen {
    z-index: 9999;
    justify-content: unset;
  }
  .error-msg,
  .dew-title {
    text-align: initial;
  }
  .save-card {
    margin: 0 20PX;
  }
  .close-outlined {
    width: 100%;
  }
  .form-3 {
    padding: 15PX;
  }
  .delete-text {
    margin-left: auto;
    color: var(--bm-error-color);
    font-size: 18PX;
  }
  .top-header.no-line {
    padding-right: 20PX;
    border-bottom-width: 0px;
  }
}
@media screen and (max-width: 767px) {
  .bm-p-pay-card {
    .top-header {
      .x-icon  {
        width: 37PX;
        height: 30PX;
      }
    }
  }
}
@media screen and (max-width: 479px) {
  .bm-p-pay-card {
    .top-header {
      .x-icon  {
        width: 30PX;
        height: 30PX;
      }
    }
  }
}


