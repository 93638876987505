@import '~bm-public.less';

.bm-c-country-code {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  // .country-flag  img {
  //   width: 100%;
  //   max-width: 80%;
  // }
  &-down-icon {
    width: 20PX;
    height: 20PX;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    color: var(--bm-text-title-color);
  }
  &-areacode-dialcode {
    font-size: 16PX;
  }
}

.bm-c-country-code-modal {
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
  }
  .search-outlined{
    width: 20PX;
    margin-right: 10PX;
    border-style: solid;
    border-width: 0px;
    border-color: #ebeff2;
    color: #a39d9d;
  }
}
.modal-wrapper-2.visible {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 1;
  display: flex;
  transition: opacity 0.4s ease-out;
}
.modal-wrapper-2.hide {
  transform: translate3d(0px, 600PX, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d; 
  opacity: 0; 
  display: none;
  transition: opacity 0.4s ease-out;
}
.flex-pop-up.widen.fixed {
  overflow: scroll;
  height: 800PX;
  padding-bottom: 0px;
}
.fixed-block {
  position: -webkit-sticky;
  position: sticky;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  padding-bottom: 10PX;
  background-color: var(--bm-light-background-color);
  // box-shadow: 1PX 1PX 3PX 0 #fcf6f6;
  .am-list-item.am-input-item {
    height: 55PX;
    margin-bottom: 0;
    margin-top: 10PX;
  }
  .am-list-item {
    min-height: unset;
  }
}
.close-icon {
  position: absolute;
  top: 30PX;
  right: 30PX;
  z-index: 20;
  display: none;
  width: 25PX;
  cursor: pointer;
}
.html-embed-22 {
  display: none;
}
.div-block-400 {
  margin-bottom: 0px;
  padding-right: 20PX;
  padding-left: 20PX;
}
.search-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 55PX;
  margin-bottom: 0PX;
  padding-left: 20PX;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1PX;
  border-color: #ebeff2;
  border-radius: 8PX;
  cursor: text;
}
.html-embed-17 {
  width: 20PX;
  margin-right: 10PX;
  border-style: solid;
  border-width: 0px;
  border-color: #ebeff2;
  color: #a39d9d;
}
.pop-up-section {
  padding-top: 20PX;
  padding-bottom: 20PX;
  padding-left: 20PX;
  background-color: #f5f5f5;
}
.heading-4-3 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Sf pro display', sans-serif;
  color: #333;
  font-size: 20PX;
  font-weight: 600;
  text-align: left;
}
.pop-up-section.white {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 20PX;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1PX solid #eaeaea;
  background-color: #fff;
  cursor: pointer;
}
.div-block-108 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.country-flag {
  width: 30PX;
  margin-right: 10PX;
}
.text-block-21 {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  font-size: 18PX;
  font-weight: 400;
}
.heading-4-1.procurement {
  margin-bottom: 10PX;
  font-size: 28PX;
}
@media screen and (max-width: 991px) {
  .heading-4-3 {
    margin-bottom: 10PX;
    font-size: 17PX;
  }
}
@media screen and (max-width: 767px) {
  .bm-c-country-code-modal {
    .close-outlined {
      display: block;
      width: 29PX;
      height: 33PX;
    }
  }
  .flex-pop-up.widen.fixed {
    height: 100vh;
  }
  .close-icon.absolute {
    position: absolute;
    top: 25PX;
    right: 20PX;
  }
  .heading-4-1.procurement {
    font-size: 20PX;
  }
  .html-embed-22 {
    display: block;
    width: 15PX;
    height: 15PX;
  }
  .div-block-400 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .pop-up-section {
    padding-top: 10PX;
    padding-bottom: 10PX;
  }
  .heading-4-3 {
    margin-bottom: 0px;
    font-size: 16PX;
  }
  .pop-up-section.white {
    padding-top: 15PX;
    padding-bottom: 15PX;
  }
  .text-block-21 {
    font-size: 16PX;
  }
  .bm-c-country-code {
    .fixed-block {
      position: fixed;
    }
    .flex-pop-up.widen {
      padding-top: 145PX;
    }
  }
}
@media screen and (max-width: 479px) {
  .bm-c-country-code {
    .flex-pop-up.widen {
      padding-top: 130PX;
    }
  }
  .heading-4-1.procurement {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .div-block-400 {
    height: auto;
    min-height: auto;
    padding-right: 12PX;
    padding-left: 12PX;
  }
  .search-bar {
    height: 50PX;
  }
  .heading-4-3 {
    margin-top: 0px;
    margin-bottom: 15PX;
    font-size: 16PX;
    line-height: 16PX;
  }
  .am-list-item .am-list-line .am-input-label.am-input-label-5 {
    width: 100PX;
  }
}
.bm-c-country-code {
  .country-flag {
    margin-right: 5PX;
  }
  &-areacode-dialcode {
    font-size: 14PX;
  }
}