@import '~bm-public.less';

.bm-p-qrcode-shopping-cart {
  .scancode-info-wrap {
    margin: 32px;
  }
  .content-panel-wrap {
    position: relative;
    z-index: 0;
    .style-b-color {
      position: absolute;
      top: 0;
      width: 100%;
      height: 64px;
      .p-b-color;
      z-index: 1;
    }
    .content-panel {
      position: relative;
      z-index: 2;
      flex: 1;
      padding-top: 0;
      &:nth-child(2) {
        margin-top: 0;
      }
    }

  }

  .scancode-order-info-wrap {
    background-color: var(--bm-light-background-color);
    border-radius: 12px;
    padding: 32px;
    margin: 32px;
    .item-info {
      display: flex;
      justify-content: space-between;
      &:not(:last-child) {
       margin-bottom: 16px;
     }
     .item-msg {
       font-size: 28px;
       color: var(--bm-text-title-color);
       line-height: 32px;
     }
     .total {
       font-weight: bold;
     }
    }
  }

  .pay-btn.disable-btn {
    opacity: 0.4;
  }

}
