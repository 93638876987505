.bm-c-custom-page {
  
  &-img {
    width: 100%;
  }
  &-content {
    img {
      width: 100%;
    }
  }
  a {
    word-break: break-word;
  }
  
}